<template>
    <div class="error-container">
        <span class="file-upload-error disabled">
            Va rugam incarcati cel putin un fisier media.
        </span>

        <span class="file-size-upload-error disabled">
            Marimea fisierelor este {{ totalFileSize }}MB. Nu puteti incarca mai
            mult de 1024MB
        </span>

        <span class="file-number-maximum-error disabled">
            Nu puteti incarca mai mult de 10 fisiere.
        </span>

        <span class="file-size-format-error disabled">
            Extensia {{ extensionForbidden }} nu este acceptata.
        </span>
    </div>
</template>
  
<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ErrorContainer',
    props: {
        totalFileSize: Number,
        extensionForbidden: String,
    },
});
</script>