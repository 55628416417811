<template>
  <div class="wrapper-content memories-collection admin">

    <select-event-modal :offers="offers" :showSelectEventModal="showSelectEventModal" v-if="this.showSelectEventModal"
      @closeModal="closeSelectEventModal()" />
    <share-link-modal :showShareLinkModal="seeShareLinkModal" v-if="this.seeShareLinkModal" :offerId="selectedOfferId"
      @closeModal="closeShareLinkModal" />

    <div class="header">
      <div class="mobile-only">
        <div class="menu-toggle">
          <span class="material-symbols-rounded" @click="openDrawer()">
            menu
          </span>
        </div>
      </div>
      <h1>Memories Collections</h1>

    </div>
    <div class="content-loading" v-if="loadingCollections">
      <vLottiePlayer name="lottie" class="lottie" loop
        :animationData="require('@/assets/lottie/simpleDotsLoader.json')" />
    </div>
    <div class="content" v-else>
      <div class="grid-content">
        <div v-for="collection in collections" :key="collection.id" class="card"
          @click="this.$router.push(`/memoriesCollectionAdmin/${collection.offerId}`)">

          <div class="image">
            <img src="@/assets/img/genericBackground.jpg">
            <p class="dash-circle">{{ collection.name }}</p>
          </div>

          <div class="card-content">
            <p>{{ collection.numberOfPhotos }} poze <br> {{ collection.numberOfVideos }} video</p>

            <div class="actions" @click.stop>
              <div class="secondary-button">
                <span class="material-symbols-rounded" @click="showSeeShareLinkModal(collection.offerId)">
                  share
                </span>
              </div>
              <div class="primary-button">
                <span class="material-symbols-rounded">
                  download
                </span>
              </div>

            </div>
          </div>



        </div>
        <button class="button-fixed" @click="showSelectEvent()">
          <img src="@/assets/img/uploadImageIcon.svg" alt="" width="20px" />
          <span>Adaugă colecție nouă</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import SelectEventModal from "@/views/memoriesCollection/components/SelectEventModal.vue";
import { useAuth0 } from '@auth0/auth0-vue';
import { getGalleries, getOffersPreview } from '@/api';

import { useShare } from "@vueuse/core";
import ShareLinkModal from "@/views/memoriesCollection/components/ShareLinkModal.vue";

export default {
  name: 'Memories-Collections',
  components: {
    SelectEventModal,

    ShareLinkModal
  },

  setup() {
    const { share } = useShare();
    const { user, getAccessTokenSilently } = useAuth0();
    const showSelectEventModal = ref(false);
    const loadingCollections = ref(true);
    const collections = ref([]);

    const baseLink = ref(process.env.VUE_APP_eventApp_url);
    const selectedOfferId = ref('');

    const offers = ref([]);

    const isMobile = () => {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    };

    const seeShareLinkModal = ref(false);

    const showSeeShareLinkModal = (offerId) => {
      if (isMobile()) {
        share({
          title: "Invitația mea la eveniment!",
          text: "Link-ul catre invitația mea este:",
          url:
            selectedOfferId.value +
            "/memoriesCollection/" +
            offerId.value
        });
      } else {
        selectedOfferId.value = offerId
        seeShareLinkModal.value = true;
      }
    };

    const closeShareLinkModal = () => {
      seeShareLinkModal.value = false;
    };

    const openDrawer = () => {
      document.querySelector('.drawer-wrapper').classList.add('open');
    };

    const showSelectEvent = () => {
      showSelectEventModal.value = true;
    };

    const closeSelectEventModal = () => {
      if (event.target.classList.contains('modal')) {
        showSelectEventModal.value = false;
      }
    };

    const getOffers = async () => {
      const phone = user?.value.name
      const token = await getAccessTokenSilently()

      offers.value = await getOffersPreview(token, phone);
    }

    const getCollections = async () => {
      try {
        const phone = user?.value.name
        const token = await getAccessTokenSilently()

        collections.value = await getGalleries(token, phone);
        loadingCollections.value = false;
      } catch (error) {
        console.error(error);
      }
    };

    onMounted(() => {
      getCollections()
      getOffers()
    });

    return {
      seeShareLinkModal,
      closeShareLinkModal,
      showSeeShareLinkModal,
      showSelectEventModal,
      loadingCollections,
      collections,
      offers,
      openDrawer,
      showSelectEvent,
      closeSelectEventModal,
      getCollections,
      selectedOfferId
    };
  }
}
</script>
