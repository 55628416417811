<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div id="image-container" class="mx-auto flex flex-wrap justify-content-center h-auto">
    <div v-for="(item, index) in localItems" :key="index" style=" width: 90px; height: 90px;"
      class="overflow-hidden  relative image-box mt-3 ml-3 block border-2 shadow-xl rounded-md">
      <!--      preview images     -->
      <template v-if="item.type == 'image'">
        <div class="absolute h-full w-full bg-gray-100 rounded opacity-30"
          :class="(item.status == 'uploaded') && 'hidden'" />
        <div class="absolute tool-bar-img flex delete-container" :class="(item.status == 'uploaded') && 'hidden'">
          <i v-if="item.status == 'uploading'" title="Cancel Upload"
            class=" transition-colors	duration-700 cursor-pointer hover:text-red-400  fas fa-ban "
            @click="cancelUpload(index)" />
          <i v-if="item.status == 'pending' || item.status == 'cancel'" title="Delete"
            class=" transition-colors	duration-700 cursor-pointer hover:text-red-400  fas fa-trash-alt"
            @click="destroy(index, item.url, item.type)" />
        </div>

        <img style="height: 100px; width: 100px;" width="100px" height="100px" class="upload-images object-cover " :src="item.url">
        <circle-progress v-if="item.status == 'uploading'" :percent="item.percent" class="absolute " />
      </template>

      <!--      preview Videos     -->

      <template v-else-if="item.type == 'video'">
        <div class="absolute h-full w-full bg-gray-100 rounded opacity-30"
          :class="(item.status == 'uploaded') && 'hidden'" />
        <div class="absolute tool-bar-img flex delete-container" :class="(item.status == 'uploaded') && 'hidden'">
          <i v-if="item.status == 'uploading'" title="Cancel Upload"
            class=" transition-colors	duration-700 cursor-pointer hover:text-red-400  fas fa-ban "
            @click="cancelUpload(index)" />
          <i v-if="item.status == 'pending' || item.status == 'cancel'" title="Delete"
            class=" transition-colors	duration-700 cursor-pointer hover:text-red-400  fas fa-trash-alt"
            @click="destroy(index, item.url, item.type)" />
        </div>

        <video class=" object-cover" style="height: 100px;" width="100px" height="100px" autoplay loop muted playsinline>
          <source :src="item.url">
        </video>
        <circle-progress v-if="item.status == 'uploading'" :percent="item.percent" class="absolute " />
      </template>

      <!--      preview Files     -->
      <template v-else>
        <div class="absolute h-full w-full bg-gray-100 rounded opacity-30"
          :class="(item.status == 'uploaded') && 'hidden'" />
        <div
          class="absolute tool-bar-img flex justify-content-start mb-6 text-gray-400 space-x-3 bg-gray-200 my-2 ml-2 bg-gray-100 w-max		p-2 rounded-lg "
          :class="(item.status == 'uploaded') && 'hidden'">
          <i v-if="item.status == 'uploading'" title="Cancel Upload"
            class=" transition-colors	duration-700 cursor-pointer hover:text-red-400  fas fa-ban "
            @click="cancelUpload(index)" />
          <i v-if="item.status == 'pending' || item.status == 'cancel'" title="Delete"
            class=" transition-colors	duration-700 cursor-pointer hover:text-red-400  fas fa-trash-alt"
            @click="destroy(index, item.url, item.type)" />
        </div>

        <img style="height: 100px;" width="100px" height="100px" class="upload-images object-cover " :src="fileSrc">
        <circle-progress v-if="item.status == 'uploading'" :percent="item.percent" class="absolute " />
      </template>
    </div>
    <div v-if="!isAllNullExceptOne()" style="width: 90px; height: 90px;"
      class="add-more cursor-pointer overflow-hidden  relative image-box block border-2 shadow-xl rounded-md"
      @click="uploadAction()">
      <!-- Your content for the "add item" container -->
      <span class="mt-1 flex justify-center rounded-md">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="#000!important" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.42857 12.5714H1.57143C1.12619 12.5714 0.752976 12.4208 0.451786 12.1196C0.150595 11.8185 0 11.4452 0 11C0 10.5548 0.150595 10.1815 0.451786 9.88036C0.752976 9.57917 1.12619 9.42857 1.57143 9.42857H9.42857V1.57143C9.42857 1.12619 9.57917 0.752976 9.88036 0.451786C10.1815 0.150595 10.5548 0 11 0C11.4452 0 11.8185 0.150595 12.1196 0.451786C12.4208 0.752976 12.5714 1.12619 12.5714 1.57143V9.42857H20.4286C20.8738 9.42857 21.247 9.57917 21.5482 9.88036C21.8494 10.1815 22 10.5548 22 11C22 11.4452 21.8494 11.8185 21.5482 12.1196C21.247 12.4208 20.8738 12.5714 20.4286 12.5714H12.5714V20.4286C12.5714 20.8738 12.4208 21.247 12.1196 21.5482C11.8185 21.8494 11.4452 22 11 22C10.5548 22 10.1815 21.8494 9.88036 21.5482C9.57917 21.247 9.42857 20.8738 9.42857 20.4286V12.5714Z" />
        </svg>
      </span>

    </div>
  </div>
</template>

<script>
import CircleProgress from "./CircleProgress";
import fileSrc from "./art/file.png"


export default {
  components: {
    'circle-progress': CircleProgress,
  },
  props: ['items', 'files'],
  data: function () {
    return {
      fileSrc: fileSrc,
      localItems: this.items,
      localFiles: this.files
    }
  },
  methods: {
    uploadAction() {
      this.$emit('upload-action',);
    },
    destroy: function (index, file) {

      URL.revokeObjectURL(file);
      this.localItems.splice(index, 1);
      this.localFiles.splice(index, 1);
      if (!this.$parent.isAllUploading()) this.$parent.$data.uploading = false;

    },

    cancelUpload: function (index) {

      this.localItems[index].source.cancel();
      this.localItems[index].status = 'cancel';
      if (!this.$parent.isAllUploading()) this.$parent.$data.uploading = false;
    },
    isAllNullExceptOne: function () {

      let result = this.files.reduce((total, cur) => Number(Boolean(cur)) + total)
      return result == 1;
    },

  }
}
</script>
<style scoped>
#image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-start {
  justify-content: flex-start;
}

.ml-3 {
  margin-left: 0.75rem;
}

.h-auto {
  height: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.relative {
  position: relative;
}

.image-box {
  width: 90px;
  height: 90px;
}

.mt-3 {
  margin-top: 0.75rem;
}

.block {
  display: block;
}

.border-2 {
  border-width: 2px;
}

.shadow-xl {
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
}

.rounded-md {
  border-radius: 0.5rem;
}

.hidden {
  display: none;
}

.text-gray-400 {
  color: #9ca3af;
}

.space-x-3 {
  margin-right: 0.75rem;
}

.bg-gray-200 {
  background-color: #edf2f7;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.bg-gray-100 {
  background-color: #f7fafc;
}

.w-max {
  width: max-content;
}

.p-2 {
  padding: 0.5rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.transition-colors {
  transition-property: color;
}

.duration-700 {
  transition-duration: 700ms;
}

.cursor-pointer {
  cursor: pointer;
}

.hover:text-red-400:hover {
  color: #f87171;
}

.fas {
  font-family: 'Font Awesome 5 Free';
}

.fa-ban::before {
  content: '\f05e';
}

.fa-trash-alt::before {
  content: '\f2ed';
}

.object-cover {
  object-fit: cover;
}

.absolute {
  position: absolute;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
</style>