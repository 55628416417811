<template>
    <div class="wrapper-content settings">
        <!-- TODO: Add loading animation -->
        <div v-if="loading">Loading..</div>
        <div v-else>
            <change-profile-details-modal :showChangeProfileDetailsModal="showChangeProfileDetails"
                v-if="this.showChangeProfileDetailsModal" @closeModal="closeChangeProfileDetails()" />
            <div class="content">
                <div class="header">
                    <div class="vertical-align gap-10 pointer splash-rounded splash-padding" @click="this.$router.back()">
                        <span class="material-symbols-rounded">
                            arrow_back
                        </span>
                        <h2>Inapoi</h2>
                    </div>
                    <h1 class="dash-circle">Setările contului</h1>
                </div>
                <div class="account">
                    <div class="profile-image">
                        <img src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png" />
                    </div>

                    <div class="account-details">
                        <p class="sub-header">Contul tău</p>
                        <h1>{{ firstName }} {{ lastName }}</h1>
                        <h2>{{ emailAddress }}<br>{{ phoneNumber }}</h2>
                    </div>
                </div>
                <div class="menu-items">
                    <div class="menu-item card" @click="showChangeProfileDetails">
                        <span class="material-symbols-rounded">
                            account_circle
                        </span>
                        <p>
                            Setările contului
                        </p>
                    </div>
                    <div class="menu-item card">
                        <span class="material-symbols-rounded">
                            call_quality
                        </span>
                        <p>
                            Cere asistență
                        </p>
                    </div>
                    <div class="menu-item card">
                        <span class="material-symbols-rounded">
                            help
                        </span>
                        <p>
                            Intrebări frecvente
                        </p>
                    </div>
                    <div class="menu-item card">
                        <span class="material-symbols-rounded">
                            admin_panel_settings
                        </span>
                        <p>
                            Politica confidențialitate
                        </p>
                    </div>
                    <div class="menu-item card">
                        <span class="material-symbols-rounded">
                            cookie
                        </span>
                        <p>
                            Politica cookies
                        </p>
                    </div>
                    <div class="menu-item card">
                        <span class="material-symbols-rounded">
                            local_police
                        </span>
                        <p>
                            Termeni și condiții
                        </p>
                    </div>
                    <div class="menu-item card">
                        <span class="material-symbols-rounded">
                            apartment
                        </span>
                        <p>
                            Despre noi
                        </p>
                    </div>
                    <div class="menu-item card">
                        <span class="material-symbols-rounded">
                            captive_portal
                        </span>
                        <p>
                            Vezi websiteul nostru
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template> 
  
<script>
import { ref, onMounted } from 'vue';
import { useAuth0 } from '@auth0/auth0-vue';
import { getEventAppUser } from '@/api.js'
import ChangeProfileDetailsModal from "@/views/settings/components/ChangeProfileDetailsModal.vue";

export default {
    name: 'Settings-Page',
    components: {
        ChangeProfileDetailsModal
    },
    setup() {
        const showChangeProfileDetailsModal = ref(false);
        const { getAccessTokenSilently, user } = useAuth0();

        const firstName = ref('');
        const lastName = ref('');
        const phoneNumber = ref(user.value.phone_number);
        const emailAddress = ref('myemail@mail.com');
        const loading = ref(true);

        const openDrawer = () => {
            document.querySelector('.drawer-wrapper').classList.add('open');
        };

        const showChangeProfileDetails = () => {
            showChangeProfileDetailsModal.value = true;
        };

        const closeChangeProfileDetails = () => {
            showChangeProfileDetailsModal.value = false;
        };

        const getUser = async () => {
            const token = await getAccessTokenSilently();


            return await getEventAppUser(token, user.value.phone_number)
        }

        onMounted(async () => {
            const user = await getUser();
            firstName.value = user.firstName;
            lastName.value = user.lastName;
            emailAddress.value = user.email;

            loading.value = false;
        });

        return {
            showChangeProfileDetailsModal,
            firstName,
            lastName,
            phoneNumber,
            emailAddress,
            loading,
            openDrawer,
            showChangeProfileDetails,
            closeChangeProfileDetails
        };
    }
}
</script>
  