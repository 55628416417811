<template>
    <span>
      <svg
        fill="white"
        x="0px"
        y="0px"
        width="100%"
        height="100%"
        viewBox="0 0 512 512"
      >
        <path
          d="M298.3,256L298.3,256L298.3,256L131.1,81.9c-4.2-4.3-4.1-11.4,0.2-15.8l29.9-30.6c4.3-4.4,11.3-4.5,15.5-0.2l204.2,212.7 c2.2,2.2,3.2,5.2,3,8.1c0.1,3-0.9,5.9-3,8.1L176.7,476.8c-4.2,4.3-11.2,4.2-15.5-0.2L131.3,446c-4.3-4.4-4.4-11.5-0.2-15.8 L298.3,256z"
        />
      </svg>
    </span>
  </template>