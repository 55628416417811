<template>
  <table class="table_component">
    <thead v-if="this.isDesktop()">
      <tr>
        <th>
          <div class="table-header-vertical-align">Confirmat    
          </div>
        </th>
        <th>
          <div class="table-header-vertical-align">Nume și prenume  
          </div>
        </th>
        <th>
          <div class="table-header-vertical-align">Pers  
          </div>
        </th>
        <th>
          <div class="table-header-vertical-align">Invitatie</div>
        </th>
        <th>
          <div class="table-header-vertical-align">Data confirmare    
          </div>
        </th>
       
        <!-- <th>
          <div class="table-header-vertical-align">Status
          </div>
        </th> -->
        <th>
          Actiuni
        </th>
      </tr>
    </thead>
    <tbody>
      <slot></slot>
    </tbody>
  </table>
</template>


<script>


export default {
    name: 'Table-Component',
    components: {
    },
    data() {
        return {
          
        }
    },
    methods: {

        isDesktop() {
        const screenWidth = window.innerWidth;
        return screenWidth > 991.98;
        },
    }
}

</script>
