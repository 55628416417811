<template>
  <table class="table_component">
    <thead v-if="this.isDesktop()">
      <tr>
        <th>
          <div class="table-header-vertical-align">
            Nume
            <span class="icon material-symbols-rounded fill splash-rounded"
              >swap_vert</span
            >
          </div>
        </th>
        <th>Actiuni</th>
      </tr>
    </thead>
    <tbody>
      <slot></slot>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "Table-Component",
  components: {},
  data() {
    return {};
  },
  methods: {
    isDesktop() {
      const screenWidth = window.innerWidth;
      return screenWidth > 991.98;
    },
  },
};
</script>
