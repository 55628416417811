<template>
  <div class="confirmation-modal-wrapper" id="confirm-invitation-modal" :class="{ active: showConfirmInvitationModal }"
    @click="closeModalFromBackground">
    <div class="modal-content">
      <div class="modal-header">
        <h1>Formular RSVP</h1>
        <span class="material-symbols-rounded" @click="closeModal()">
          close
        </span>
      </div>
      <div v-show="isResultsSent" class="confirmed-denied">
        <vLottiePlayer class="lottie" name="lottie" loop :animationData="animation" />
        <h2>Răspunsul tău a fost trimis cu succes!</h2>
      </div>
      <form ref="formElement" v-if="!isResultsSent" @submit.prevent>
        <div v-if="invitees.length > 0">
          <div class="input-wrapper">
            <input class="input" v-model="invitees[0].name" type="text" id="name" name="name" placeholder-shown="true"
              autocomplete="off" required minlength="2" placeholder="Numele și prenumele tău*"
              data-pristine-minlength-message="Introduceti minim 2 caractere"
              data-pristine-required-message="Câmp obligatoriu" />
          </div>
          <div class="input-wrapper phone-number">
            <vue-tel-input v-model="invitees[0].phone" :input-options="{
              placeholder: 'Scrie numărul tău de telefon',
            }" :defaultCountry="'RO'" :enabledFlags="true" :enabledCountryCode="true" :autoFormat="true" mode="auto"
              :validCharactersOnly="true"></vue-tel-input>
            <div v-if="phoneError" class="text-help">Câmp obligatoriu sau lungime incorectă</div>

          </div>
          <div class="number-of-persons-section">
            <h3>Număr de persoane</h3>
            <div class="number-of-persons-container">
              <div class="number-of-persons">
                <span class="material-symbols-rounded fill" :class="{ active: invitees.length > 0 }">
                  person
                </span>
                <span class="material-symbols-rounded fill" :class="{ active: invitees.length > 1 }">
                  person
                </span>
                <span class="material-symbols-rounded fill" :class="{ active: invitees.length > 2 }">
                  person
                </span>
                <span class="material-symbols-rounded fill" :class="{ active: invitees.length > 3 }">
                  person
                </span>
                <span class="material-symbols-rounded fill" :class="{ active: invitees.length > 4 }">
                  person
                </span>
              </div>

              <div class="persons-actions">
                <div class="rounded-button" @click="removePersons()">
                  <span class="material-symbols-rounded fill"> remove </span>
                </div>
                <p>{{ invitees.length }}</p>
                <div class="rounded-button" @click="addPersons()">
                  <span class="material-symbols-rounded fill"> add </span>
                </div>
              </div>
            </div>
          </div>

          <div v-for="invitee in invitees" :key="invitee" class="person-questions">
            <h3 v-if="invitees.indexOf(invitee) == 0">
              Nume invitat (persoana care
              completează)
            </h3>
            <h3 v-else>Persoana {{ invitees.indexOf(invitee) + 1 }} (însoțitor)</h3>

            <div class="input-wrapper">
              <input class="input" v-model="invitee.name" type="text"
                :id="'nameInvitee' + invitees.indexOf(invitee) + 1" name="name" placeholder-shown="true"
                autocomplete="off" required minlength="2" placeholder="Numele și prenumele tău*"
                data-pristine-minlength-message="Introduceti minim 2 caractere"
                data-pristine-required-message="Câmp obligatoriu" />
            </div>
            <div v-for="question in invitation.questions" :key="question" :id="'question' +
              invitees.indexOf(invitee) +
              invitation.questions.indexOf(question)
              " class="question">
              <div class="question-title">
                <p>{{ question.value }}</p>
              </div>
              <div class="input-wrapper">
                <select v-model="invitees[invitees.indexOf(invitee)].questions.find(
                  (q) => q.questionId === question.id
                ).answerId
                  ">
                  <option v-for="answer in question.answers" :key="answer.id" :value="answer.id">
                    {{ answer.value }}
                  </option>
                </select>
              </div>

              <div class="alert alert-danger margin-top-10" style="display: none">
                <i class="icon-remove-sign"></i>Te rog să ne spui dacă dorești
                cazare.
              </div>
            </div>
          </div>

          <button class="small secondary edit-questions" v-if="!invitation.published && invitation.questions.length > 0"
            @click="this.$router.push(`/invitation/${offerId}/wizard`)">
            <p class="text">Editează întrebarile</p>
            <span class="material-symbols-rounded"> edit </span>
          </button>

          <button class="small secondary add-questions" v-if="
            !invitation.published &&
            invitation.questions.length == 0 &&
            isEditable
          " @click="this.$router.push(`/invitation/${offerId}/wizard`)">
            <p class="text">Adaugă întrebare</p>
            <span class="material-symbols-rounded"> add </span>
          </button>
          <!-- TODO: De facut altcumva validarea form ului -->
          <div class="buttons">
            <button @click.prevent="sendInvitationResponse(false)">
              Nu pot să particip
            </button>
            <button class="accept" @click.prevent="sendInvitationResponse(true)">
              Da, confirm prezența
            </button>
          </div>
          <p class="time-limit">
            Data limită de confirmare: {{ formatDateDeadline }}
          </p>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, toRefs, computed, onMounted, onBeforeMount, nextTick } from "vue";
import * as Pristine from "pristinejs";
import { useRoute } from "vue-router";
import animationData from '@/assets/lottie/checkmarkAnimated.json';
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

export default {
  name: "ConfirmInvitationModal",
  props: {
    showConfirmInvitationModal: Boolean,
    invitationData: { type: Object, required: true },
    offerId: { type: String, required: true },
    isInvitationEditable: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VueTelInput
  },
  setup(props, { emit }) {
    const { showConfirmInvitationModal } = toRefs(props);
    const invitation = ref(props.invitationData);
    const route = useRoute();
    const isEditable = ref(props.isInvitationEditable);

    const invitees = ref([]);
    const formElement = ref(null);
    let pristine;
    const phoneError = ref(false);

    const isSentConfirmed = ref(false);
    const isSentDenied = ref(false);
    const isResultsSent = ref(false);

    const numberOfPersons = ref(1);
    const defaultConfig = {
      classTo: "input-wrapper",
      errorClass: "has-danger",
      successClass: "has-success",
      errorTextParent: "input-wrapper",
      errorTextClass: "text-help",
    };

    const closeModal = () => {
      emit("closeModal");
    };

    const closeModalFromBackground = (event) => {
      if (event.target.classList.contains("confirmation-modal-wrapper")) {
        closeModal();
      }
    };

    const addPersons = () => {
      invitees.value.push({ questions: getQuestions() });
      nextTick(() => {
        pristine = new Pristine(formElement.value, defaultConfig, true);
      });
    };

    const removePersons = () => {
      if (invitees.value.length > 1) {
        invitees.value.pop();
      }
    };

    const isWithinDeadline = computed(() => {
      if (!invitation.value || !invitation.value.deadline) {
        return false;
      }

      const deadline = new Date(invitation.value.deadline);
      const today = new Date();

      // Check if 'deadline' is an invalid date
      if (isNaN(deadline.getTime())) {
        return false;
      }

      return deadline > today;
    });

    const sendInvitationResponse = (confirm) => {
      if (formifyClient()) {
        if (confirm) {
          invitees.value[0].confirmed = true;
          isSentDenied.value = false;
          isSentConfirmed.value = true;
          isResultsSent.value = true;
        } else {
          invitees.value[0].confirmed = false;
          isSentConfirmed.value = false;
          isSentDenied.value = true;
          isResultsSent.value = true;
        }
        emit("sendInvitationResponse", invitees.value);
      }
    };

    const formatDateDeadline = computed(() => {
      let deadline = null;
      deadline = new Date(invitation.value.deadline);

      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const date = new Date(deadline);
      const day = date.getDate();
      const monthIndex = date.getMonth();
      const year = date.getFullYear();

      return `${day < 10 ? "0" + day : day} ${months[monthIndex]} ${year}`;
    });

    const validatePhone = () => {
      phoneError.value = !invitees.value[0].phone;
      return !phoneError.value;
    };

    const formifyClient = () => {
      pristine.addValidator(document.querySelector('.phone-number input'), validatePhone, "Câmp obligatoriu", 2, false);
      const valid = pristine.validate();
      if (valid) {
        pristine.reset();
        return true;
      }
      return false;
    };

    const getQuestions = () => {
      const questions = [];
      invitation.value.questions.forEach((question) => {
        questions.push({
          questionId: question.id,
          answerId: question.answers[0].id,
        });
      });

      return questions;
    };

    const changeLottieColor = (data, color) => {
      const updatedData = JSON.parse(JSON.stringify(data));
      updatedData.layers.forEach(layer => {
        if (layer.shapes) {
          layer.shapes.forEach(shape => {
            if (shape.it) {
              shape.it.forEach(item => {
                if (item.ty === 'fl' && item.c) {
                  item.c.k = hexToRgbArray(color);
                }
              });
            }
          });
        }
      });
      return updatedData;
    };

    const hexToRgbArray = (hex) => {
      hex = hex.replace(/^#/, '');
      const bigint = parseInt(hex, 16);
      return [((bigint >> 16) & 255) / 255, ((bigint >> 8) & 255) / 255, (bigint & 255) / 255, 1];
    };

    const animation = ref(changeLottieColor(animationData, '#d1aea7'));

    onBeforeMount(() => {
      if (!route.path.includes("demo")) {
        invitees.value = [{ questions: getQuestions() }];
      }
    });

    onMounted(() => {
      pristine = new Pristine(formElement.value, defaultConfig);

      pristine.addValidator(
        formElement.value.querySelector('input[name="phone"]'),
        (value) => value && value.length > 0,
        "Câmp obligatoriu",
        2,
        false
      );

    });

    return {
      isEditable,
      numberOfPersons,
      isWithinDeadline,
      formElement,
      isSentConfirmed,
      isSentDenied,
      isResultsSent,
      sendInvitationResponse,
      closeModal,
      closeModalFromBackground,
      addPersons,
      removePersons,
      invitees,
      invitation,
      formatDateDeadline,
      animation,
    };
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
