<template>
  <div>
    <notifications position="top right" classes="notification" :max="4" :duration="2500">
      <template #body="props">
        <div class="notification">
          <div class="content">
            <p class="notification-title">
              {{ props.item.title }}
            </p>
            <div class="notification-content">
              <div v-html="props.item.text" />
            </div>
          </div>
          <span class="icon material-symbols-rounded fill splash-rounded" @click="props.close">
            close
          </span>
        </div>
      </template>
    </notifications>
    <ModalGeneral :isVisible="modalState.display" />
    <div v-if="isLoading && isUIloading" class="global-loader">
      <vLottiePlayer name="lottie" class="lottie" loop
        :animationData="require('@/assets/lottie/simpleDotsLoader.json')" />
    </div>
    <div v-else class="wrapper" :class="{ client: isClient }" ref="isClientElement">
      <drawer-component :isAuthenticated="isAuthenticated" @logout="handleLogout" v-if="isDrawerDisplayed" />
      <router-view />
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import DrawerComponent from "@/components/Drawer.vue";
import { useAuth0 } from "@auth0/auth0-vue";
import LoaderAnimationJSON from "@/assets/lottie/simpleDotsLoader.json";
import { Notifications } from "@kyvg/vue3-notification";
import { useRouter } from "vue-router";
import ModalGeneral from "./components/ModalGeneral.vue";
import { displayModal, modalState } from "@/store/modals.js";


export default {
  name: "MyPrivent",
  metaInfo: {
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  },
  components: {
    DrawerComponent,
    Notifications,
    ModalGeneral,
  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        this.isUIloading = false;
      }
    };
  },
  data() {
    return {
      LoaderAnimationJSON,
      modalState: modalState,
      isUIloading: true,
    };
  },
  methods: {
    openModal() {
      displayModal(
        true,
        "succes",
        "Acesta este titlul modalului",
        "Acestea este un subtitlu mai lung",
        "Okay"
      );
    },
  },
  setup() {
    const { isAuthenticated, isLoading, logout } = useAuth0();
    const isAuthenticatedComputed = computed(() => isAuthenticated.value);
    const LoaderAnimationJSONRef = ref(LoaderAnimationJSON);
    const route = useRouter();

    const handleLogout = () => {
      logout({
        returnTo: process.env.VUE_APP_eventApp_url,
      });
    };

    const isDrawerDisplayed = computed(() => {
      if (
        (route.currentRoute.value.path.includes("invitation") &&
          !route.currentRoute.value.path.includes("invitations")) ||
        route.currentRoute.value.path.includes("edit") ||
        (route.currentRoute.value.path.includes("memoriesCollection") &&
          !route.currentRoute.value.path.includes("memoriesCollectionAdmin") &&
          !route.currentRoute.value.path.includes("memoriesCollections")) || route.currentRoute.value.path.includes("printer")
      ) {
        return false;
      }
      return true;
    });

    const isClient = computed(() => {
      if (
        route.currentRoute.value.path.includes("invitation") &&
        !route.currentRoute.value.path.includes("edit") &&
        !route.currentRoute.value.path.includes("wizard") &&
        !route.currentRoute.value.path.includes("select") &&
        !route.currentRoute.value.path.includes("invitations") &&
        route.currentRoute.value.path.includes("memoriesCollection")
      ) {
        return true;
      }
      return false;
    });

    return {
      handleLogout,
      LoaderAnimationJSONRef,
      isDrawerDisplayed,
      isClient,
      isAuthenticated: isAuthenticatedComputed,
      isLoading,
    };
  },
};
</script>