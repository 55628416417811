<template>
  <div class="tool-bar flex space-x-3 w-max	rounded-lg ">
    <i @click="uploadAll()" title="Start Upload"
      class="transition-colors	duration-700 cursor-pointer  hover:text-green-500 fas fa-arrow-circle-up"></i>
    <i @click="cancelUploadAll()" title="Cancel Upload"
      class=" transition-colors	duration-700 cursor-pointer hover:text-red-400  fas fa-ban"></i>
    <i @click="deleteAll()" title="Delete"
      class=" transition-colors	duration-700 cursor-pointer hover:text-red-400  fas fa-trash-alt"></i>

  </div>
</template>

<script>
// import axios from 'axios'
import blobStorageHelper from '@/helpers/blobstoragehelper.js';

export default {
  methods: {
    uploadAll: async function () {

      document
        .querySelector(".button")
        .classList.add("loading");

      if (this.$parent.$data.uploading || this.$parent.$data.files.length == 0 || this.$parent.$data.files.every(thing => thing === null)) return;

      this.$parent.$data.uploading = true;

      const promises = [];


      await blobStorageHelper.createContainerIfNotExists(this.$parent.$props.id);

      this.$parent.$data.files.forEach((cur, index) => {
        if (cur == null) return;

        this.$parent.$data.request.append('file', cur);
        this.$parent.$data.temporaryFiles[index].status = 'uploading';
        this.$parent.$data.temporaryFiles[index].source = this.$parent.createCancelToken();

        // Define a progress callback
        const progressCallback = (progress) => {
          this.$parent.$data.temporaryFiles[index].percent = progress
        };

        const uploadPromise = blobStorageHelper.uploadFile(this.$parent.$data.files[index], progressCallback, this.$parent.$props.id)
          .then((url) => {
            this.$parent.$data.temporaryFiles[index].status = 'uploaded';
            this.$parent.$data.temporaryFiles[index].url = url;

            this.$parent.$data.files[index] = null;
            if (this.$parent.$data.files.length - 1 === index) {
              this.$parent.$data.uploading = false;
            }
            if (!this.$parent.isAllUploading()) {
              this.$parent.$data.uploading = false;
            }
          })
          .catch(function (error) {
            console.log(error);
          });

        promises.push(uploadPromise);

        this.$parent.$data.request.delete('file');
      });

      // Wait for all promises to resolve
      Promise.all(promises)
        .then(() => {
          this.$emit("filesSaved")
          // Perform actions after all promises are finished
        })
        .catch((error) => {
          console.error('Error:', error);
        });


    },
    deleteAll: function () {

      if (this.$parent.$data.uploading) this.cancelUploadAll();

      this.$parent.$data.temporaryFiles.forEach(cur => {
        URL.revokeObjectURL(cur);
      })
      this.$parent.$data.temporaryFiles = [];
      this.$parent.$data.files = [];
      this.$parent.$data.request.delete('file');

    },
    cancelUploadAll: function () {

      this.$parent.$data.uploading = false;

      this.$parent.$data.temporaryFiles.forEach(cur => {
        cur.source.cancel();
        cur.status = 'cancel';
      });

    },

  }
}
</script>

<style scoped>
.flex {
  display: flex;
}

.justify-start {
  justify-content: flex-start;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.text-gray-400 {
  color: #9ca3af;
}

.space-x-3> :not(template)~ :not(template) {
  margin-left: 0.75rem;
}

.bg-gray-200 {
  background-color: #edf2f7;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.bg-gray-100 {
  background-color: #f7fafc;
}

.w-max {
  width: max-content;
}

.p-2 {
  padding: 0.5rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.transition-colors {
  transition-property: color;
}

.duration-700 {
  transition-duration: 700ms;
}

.cursor-pointer {
  cursor: pointer;
}

.hover:text-green-500:hover {
  color: #10b981;
}

.hover:text-red-400:hover {
  color: #f87171;
}

.fas {
  font-family: 'Font Awesome 5 Free';
}

.fa-arrow-circle-up::before {
  content: '\f0aa';
}

.fa-ban::before {
  content: '\f05e';
}

.fa-trash-alt::before {
  content: '\f2ed';
}
</style>