<template>
  <div class="card-invitation" :class="[
    modelName,
    'style-' + selectedThemeColor,
    { isEditable: isEditable },
  ]">
    <confirm-invitation-modal :invitationData="invitation" :showConfirmInvitationModal="showConfirmInvitationModal"
      v-if="showConfirmInvitationModal" @closeModal="closeConfirmationModal" :offerId="offerId"
      @sendInvitationResponse="sendInvitationResponse" :isEditable="isEditable" />

    <image-cropper-modal :showImageCropperModal="showImageCropperModal" :initialImage="initialImage"
      @cropped="handleCroppedImage" @closeModal="closeImageCropperModal" :aspectRatio="initialImageAspectRatio"
      v-if="showImageCropperModal" />

    <div class="invitation-card-builder desktop" v-if="isEditable && !checkIfMobile()">
      <div class="theme-selector">
        <label class="label" for="adress">Theme selector</label>
        <div class="themes">
          <div v-for="(themeColors, index) in themeColors" :key="index" class="theme"
            :class="{ selected: selectedThemeColor === index }" @click="handleThemeChange(index)">
            <div v-for="(color, colorIndex) in themeColors" :key="colorIndex" class="theme-color"
              :style="{ backgroundColor: color }"></div>
          </div>
        </div>
      </div>

      <div class="overlay-editor">
        <div class="input-wrapper textSpacingWrapper">
          <div class="multi-range-inputs">
            <label class="label">Setări fundal</label>

            <div class="overlay-image-selector" @click="
              isEditable ? this.$refs.invitationImageInput.click() : null
              ">
              <input type="file" @change="handleChangeImage" accept="image/*" style="display: none"
                ref="invitationImageInput" />

              <div class="image-input" :style="{
                background: 'url(' + backgroundImage + ')',
                'background-size': 'cover',
              }"></div>
              <p>Schimbă imagine</p>
            </div>

            <div class="overlay-color-selector">
              <color-picker pickerType="chrome" v-model:pureColor="backgroundColorOverlay"
                @update:pureColor="handleOverlayColorChange" />
              <p>Schimbă overlay</p>
            </div>
          </div>
        </div>
      </div>

      <div class="element-selector" :class="{ inactive: !isActiveObject }">
        <div class="input-wrapper textAreaWrapper" :class="{ inactive: !isTextElement }">
          <label class="label" for="adress">Text editor</label>
          <textarea class="input" type="text" v-model="textareaValue" name="street_address" placeholder=""
            autocomplete="off" rows="5" minlength="4" data-pristine-minlength-message="Introduceti minim 4 caractere"
            data-pristine-required-message="Câmp obligatoriu" />
        </div>

        <div class="input-wrapper fontSelectWrapper" :class="{ inactive: !isTextElement }">
          <div class="row">
            <label class="label" for="name">Font family</label>
          </div>
          <div class="fonts">
            <div class="list">
              <div class="displayed-font" v-for="font in fontOptions" :key="font" :value="font"
                :class="{ selected: font == fontFamilyValue }" @click="handleFontChange(font)">
                <p :style="'font-family: ' + font">{{ font }}</p>
                <span class="material-symbols-rounded"> done </span>
              </div>
            </div>
          </div>
        </div>

        <div class="input-wrapper fontSizeWrapper" :class="{ inactive: !isTextElement }">
          <div class="row" for="fontSize">
            <label class="label" for="name">Font size</label>
            <!-- <label class="reset" for="name">Clear </label> -->
          </div>
          <div class="row">
            <div class="range-input-slider">
              <input type="range" class="range" id="fontSize" min="8" max="112" step="1" v-model="fontSizeValue"
                @input="handleFontSizeChange" />

              <input type="number" class="range-input" id="fontSizeText" min="8" max="112" v-model="fontSizeValue"
                @change="handleFontSizeChange" />
            </div>
          </div>
        </div>

        <div class="input-wrapper colorWrapper">
          <label class="label" for="name">Font color</label>

          <div class="color-selector">
            <div class="color-option">
              <color-picker pickerType="chrome" v-model:pureColor="fontColorValue"
                @update:pureColor="handleColorChange" />
              <span class="material-symbols-rounded" :class="{ light: isColorTooDark }">
                palette
              </span>
            </div>

            <div v-for="color in colorOptions" :key="color" class="color-option" :style="{ backgroundColor: color }"
              @click="handleColorChange(color)"></div>
          </div>
        </div>

        <div class="input-wrapper textAdvancedWrapper">
          <label class="label" for="name">Text alignment and transform</label>
          <div class="text-advanced">
            <div class="text-format">
              <span class="material-symbols-rounded" :class="{ selected: fontAlignmentValue == 'left' }"
                @click="handleFontAlignmentChange('left')">
                format_align_left
              </span>
              <span class="material-symbols-rounded" :class="{ selected: fontAlignmentValue == 'center' }"
                @click="handleFontAlignmentChange('center')">
                format_align_center
              </span>
              <span class="material-symbols-rounded" :class="{ selected: fontAlignmentValue == 'right' }"
                @click="handleFontAlignmentChange('right')">
                format_align_right
              </span>
            </div>
            <div class="text-transform">
              <span class="material-symbols-rounded" @click="handleFontTransformChange('uppercase')">
                uppercase
              </span>
              <span class="material-symbols-rounded" @click="handleFontTransformChange('lowercase')">
                lowercase
              </span>
              <span class="material-symbols-rounded" @click="handleFontTransformChange('matchcase')">
                match_case
              </span>
            </div>
          </div>
        </div>

        <div class="input-wrapper textSpacingWrapper">
          <label class="label" for="name">Text spacing</label>
          <div class="multi-range-inputs">
            <label class="label">Letter spacing</label>
            <div class="range-input-slider">
              <input type="range" class="range" id="fontSize" min="0" max="200" step="1" v-model="fontLetterSpacing"
                @input="handleFontLetterSpacing" />

              <input type="text" class="range-input display-only" readonly :value="fontLetterSpacing + '%'" />
            </div>
            <label class="label">Line height</label>
            <div class="range-input-slider">
              <input type="range" class="range" id="fontSize" min="0.5" max="2.5" step="0.1" v-model="fontLineHeight"
                @input="handleFontLineHeightChange" />

              <input type="text" class="range-input display-only" readonly :value="fontLineHeight" />
            </div>
          </div>
        </div>
      </div>
      <button class="primary medium col" @click="save">Salvează</button>
    </div>

    <div class="invitation-card-builder mobile" v-else-if="isEditable && checkIfMobile()">
      <div class="bottom-editor-bar" v-show="!isActiveObject && !isThemeColorShown && !isImageOverlayShown">
        <div class="horizontal">
          <div class="disclaimer">
            <h3>Editor avansat</h3>
            <h2>Selectează un element</h2>
          </div>

          <div class="other-settings">
            <div class="editor-element" @click="isImageOverlayShown = true">
              <span class="material-symbols-rounded"> gradient </span>
              Imagine
            </div>
            <div class="editor-element" @click="isThemeColorShown = true">
              <span class="material-symbols-rounded"> palette </span>
              Temă
            </div>
          </div>
        </div>
        <button class="button primary small" @click="save">Save</button>
      </div>

      <div class="bottom-editor-modal theme-selector" v-show="isThemeColorShown">
        <div class="close" @click="isThemeColorShown = false">
          Inchide
          <span class="material-symbols-rounded"> close </span>
        </div>
        <label class="label" for="adress">Theme selector</label>
        <div class="themes">
          <div v-for="(themeColors, index) in themeColors" :key="index" class="theme"
            :class="{ selected: selectedThemeColor === index }" @click="handleThemeChange(index)">
            <div v-for="(color, colorIndex) in themeColors" :key="colorIndex" class="theme-color"
              :style="{ backgroundColor: color }"></div>
          </div>
        </div>
      </div>

      <div class="bottom-editor-modal image-overlay" v-show="isImageOverlayShown">
        <div class="close" @click="isImageOverlayShown = false">
          Inchide
          <span class="material-symbols-rounded"> close </span>
        </div>
        <label class="label" for="adress">Setări fundal</label>

        <div class="overlay-editor">
          <div class="overlay-image-selector" @click="isEditable ? this.$refs.invitationImageInput.click() : null">
            <input type="file" @change="handleChangeImage" accept="image/*" style="display: none"
              ref="invitationImageInput" />

            <div class="image-input" :style="{
              background: 'url(' + backgroundImage + ')',
              'background-size': 'cover',
            }"></div>
            <p>Schimbă imagine</p>
          </div>

          <div class="overlay-color-selector">
            <color-picker pickerType="chrome" v-model:pureColor="backgroundColorOverlay"
              @update:pureColor="handleOverlayColorChange" />
            <p>Schimbă culoare</p>
          </div>
        </div>
      </div>

      <div class="bottom-editor-modal element-selector" v-show="isActiveObject">
        <div class="close" @click="isActiveObject = false">
          Inchide
          <span class="material-symbols-rounded"> close </span>
        </div>
        <div class="horizontal">
          <div class="editor-element" @click="isTextareaShown = true" :class="{ inactive: !isTextElement }">
            <span class="material-symbols-rounded"> keyboard_onscreen </span>
            Edit text
          </div>
          <div class="editor-element" @click="isFontFamilyShown = true" :class="{ inactive: !isTextElement }">
            <span class="material-symbols-rounded"> serif </span>
            Font
          </div>
          <div class="editor-element" @click="isFontSizeShown = true" :class="{ inactive: !isTextElement }">
            <span class="material-symbols-rounded"> format_size </span>
            Mărime
          </div>
          <div class="editor-element" @click="isFontColorShown = true">
            <span class="material-symbols-rounded"> format_color_text </span>
            Culoare
          </div>
          <div class="editor-element" @click="isFontAdvancedTransformShown = true"
            :class="{ inactive: !isTextElement }">
            <span class="material-symbols-rounded"> format_align_left </span>
            Transform
          </div>
          <div class="editor-element" @click="isFontTextSpacingShown = true" :class="{ inactive: !isTextElement }">
            <span class="material-symbols-rounded"> format_line_spacing </span>
            Spațiere
          </div>
        </div>
      </div>

      <div class="bottom-editor-modal text-editor" v-show="isTextareaShown && isActiveObject">
        <div class="close" @click="isTextareaShown = false">
          Inchide
          <span class="material-symbols-rounded"> close </span>
        </div>

        <div class="input-wrapper textAreaWrapper">
          <label class="label" for="adress">Text editor</label>
          <textarea class="input" type="text" v-model="textareaValue" name="street_address" placeholder=""
            autocomplete="off" rows="5" minlength="4" data-pristine-minlength-message="Introduceti minim 4 caractere"
            data-pristine-required-message="Câmp obligatoriu" />
        </div>
      </div>

      <div class="bottom-editor-modal font-family-editor" v-show="isFontFamilyShown && isActiveObject">
        <div class="close" @click="isFontFamilyShown = false">
          Inchide
          <span class="material-symbols-rounded"> close </span>
        </div>

        <div class="input-wrapper fontSelectWrapper">
          <div class="row">
            <label class="label" for="name">Font family</label>
            <!-- <label class="reset" for="name">Clear </label> -->
          </div>
          <div class="fonts">
            <div class="list">
              <div class="displayed-font" v-for="font in fontOptions" :key="font" :value="font"
                :class="{ selected: font == fontFamilyValue }" @click="handleFontChange(font)">
                <p :style="'font-family: ' + font">{{ font }}</p>
                <span class="material-symbols-rounded"> done </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bottom-editor-modal font-size-editor" v-show="isFontSizeShown && isActiveObject">
        <div class="close" @click="isFontSizeShown = false">
          Inchide
          <span class="material-symbols-rounded"> close </span>
        </div>

        <div class="input-wrapper fontSizeWrapper">
          <div class="row" for="fontSize">
            <label class="label" for="name">Font size</label>
            <!-- <label class="reset" for="name">Clear </label> -->
          </div>
          <div class="row">
            <div class="range-input-slider">
              <input type="range" class="range" id="fontSize" min="8" max="112" step="1" v-model="fontSizeValue"
                @input="handleFontSizeChange" />

              <input type="number" class="range-input" id="fontSizeText" min="8" max="112" v-model="fontSizeValue"
                @change="handleFontSizeChange" />
            </div>
          </div>
        </div>
      </div>

      <div class="bottom-editor-modal font-color-editor" v-show="isFontColorShown && isActiveObject">
        <div class="close" @click="isFontColorShown = false">
          Inchide
          <span class="material-symbols-rounded"> close </span>
        </div>

        <div class="input-wrapper">
          <label class="label" for="name">Font color</label>
          <div class="color-selector">
            <div class="color-option">
              <color-picker pickerType="chrome" v-model:pureColor="fontColorValue" @update:pureColor="handleColorChange"
                blurClose="true" />
              <span class="material-symbols-rounded" :class="{ light: isColorTooDark }">
                palette
              </span>
            </div>

            <div v-for="color in colorOptions" :key="color" class="color-option" :style="{ backgroundColor: color }"
              @click="handleColorChange(color)"></div>
          </div>
        </div>
      </div>

      <div class="bottom-editor-modal font-advanced-editor" v-show="isFontAdvancedTransformShown && isActiveObject">
        <div class="close" @click="isFontAdvancedTransformShown = false">
          Inchide
          <span class="material-symbols-rounded"> close </span>
        </div>
        <div class="input-wrapper textAdvancedWrapper">
          <label class="label" for="name">Text alignment and transform</label>
          <div class="text-advanced">
            <div class="text-format">
              <span class="material-symbols-rounded" :class="{ selected: fontAlignmentValue == 'left' }"
                @click="handleFontAlignmentChange('left')">
                format_align_left
              </span>
              <span class="material-symbols-rounded" :class="{ selected: fontAlignmentValue == 'center' }"
                @click="handleFontAlignmentChange('center')">
                format_align_center
              </span>
              <span class="material-symbols-rounded" :class="{ selected: fontAlignmentValue == 'right' }"
                @click="handleFontAlignmentChange('right')">
                format_align_right
              </span>
            </div>
            <div class="text-transform">
              <span class="material-symbols-rounded" @click="handleFontTransformChange('uppercase')">
                uppercase
              </span>
              <span class="material-symbols-rounded" @click="handleFontTransformChange('lowercase')">
                lowercase
              </span>
              <span class="material-symbols-rounded" @click="handleFontTransformChange('matchcase')">
                match_case
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="bottom-editor-modal font-advanced-editor" v-show="isFontTextSpacingShown && isActiveObject">
        <div class="close" @click="isFontTextSpacingShown = false">
          Inchide
          <span class="material-symbols-rounded"> close </span>
        </div>

        <div class="input-wrapper textSpacingWrapper">
          <label class="label" for="name">Text spacing</label>
          <div class="multi-range-inputs">
            <label class="label">Letter spacing</label>
            <div class="range-input-slider">
              <input type="range" class="range" id="fontSize" min="0" max="200" step="1" v-model="fontLetterSpacing"
                @input="handleFontLetterSpacing" />

              <input type="text" class="range-input display-only" readonly :value="fontLetterSpacing + '%'" />
            </div>
            <label class="label">Line height</label>
            <div class="range-input-slider">
              <input type="range" class="range" id="fontSize" min="0.5" max="2.5" step="0.1" v-model="fontLineHeight"
                @input="handleFontLineHeightChange" />

              <input type="text" class="range-input display-only" readonly :value="fontLineHeight" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="invitation-card-wrapper">
      <span class="loader" v-if="isLoading"></span>
      <div class="envelope-wrapper" v-else>
        <div class="lid one">
          <img class="stamp" src="@/assets/img/stampSource.png" />
        </div>
        <div class="lid two">
          <img class="stamp" src="@/assets/img/stampSourceBack.png" />
        </div>
        <div class="envelope"></div>
        <div class="letter">
          <div class="invitation-content" :class="{ isEditable: isEditable }" ref="cardImage" :style="{
            background:
              'linear-gradient(0deg, ' +
              backgroundColorOverlay +
              ',' +
              backgroundColorOverlay +
              ')' +
              ', url(' +
              backgroundImage +
              ')',
            'background-size': 'cover',
          }">
            <canvas ref="can" id="theCanvas"></canvas>
          </div>
        </div>
      </div>
    </div>

    <div class="confirmation-wrapper" v-if="!isEditable">
      <button class="location-button" @click="openGoogleMaps">
        <span class="material-symbols-rounded"> pin_drop </span>
      </button>
      <button class="main-button" :invitationData="invitation" :isEditable="isEditable"
        @click="openConfirmationModal()">
        Confirmă prezența
      </button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, onUnmounted } from "vue";
import { fabric } from "fabric";
import ConfirmInvitationModal from "@/views/invitation/components/ConfirmInvitationModal.vue";
import blobStorageHelper from "@/helpers/blobstoragehelper.js";
import { useRoute } from "vue-router";
import { notify } from "@kyvg/vue3-notification";
import FontFaceObserver from "fontfaceobserver";
import themeHelper from "./cardInvitationModels/useTemplate";
import ImageCropperModal from "@/views/invitation/components/ImageCropperModal.vue";

import { ColorPicker } from "vue3-colorpicker";
import "vue3-colorpicker/style.css";

export default {
  name: "Wedding-Minimalist",
  components: {
    ConfirmInvitationModal,
    ColorPicker,
    ImageCropperModal,
  },
  props: {
    invitationData: {
      type: Object,
      default: null,
    },
    selectedInvitationTemplate: {
      type: Number,
      default: 1,
    },
    offerId: {
      type: String,
      default: "",
    },
    isInvitationEditable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    checkIfMobile() {
      return window.innerWidth < 767.98;
    },
  },
  setup(props, { emit }) {
    const route = useRoute();
    const isLoading = ref(true);
    const isDemo = ref(false);

    const invitation = ref(props.invitationData);
    const uploadProgress = ref(0);
    const uploadingImage = ref(false);

    const isEditable = ref(props.isInvitationEditable);
    const canvas = ref(null);
    const theme = ref("default");
    const showConfirmInvitationModal = ref(false);
    const backgroundImage = ref("");
    const backgroundColorOverlay = ref("");

    const computedBackgroundColor = ref("#222222");

    const isImageLoaded = ref(false);
    const data = ref(
      invitation.value !== null && invitation.value.json !== null
        ? invitation.value.json
        : themeHelper.selectTheme(invitation.value.templateName)
    );

    const modelName = ref(invitation.value.templateName);
    const themeColors = ref(data.value.data.themeColors);
    const selectedThemeColor = ref(
      invitation.value !== null &&
        invitation.value.json !== null &&
        invitation.value.json.templateTheme
        ? data.value.templateTheme
        : invitation.value.templateTheme
    );
    const isThemeColorShown = ref(false);
    const isImageOverlayShown = ref(false);

    const showImageCropperModal = ref(false);
    const initialImage = ref(null);
    const initialImageLocation = ref(null);
    const initialImageAspectRatio = ref(8 / 11);

    const fontOptions = ref([
      "Imperial Script",
      "MonteCarlo",
      "Petemoss",
      "Pinyon Script",
      "MADE Mirage",
      "Montserrat",
      "Satoshi-Variable",
      "Bebas Neue",
      "Cabin",
      "Playfair Display",
      "Gelica",
      "EyesomeScript",
      "Playwrite US Trad"
    ]);
    const colorOptions = ref([
      "rgb(246, 195, 199)",
      "rgb(255, 237, 199)",
      "rgb(251, 248, 199)",
      "rgb(216, 234, 208)",
      "rgb(198, 224, 247)",
      "rgb(225, 195, 223)",
      "rgb(242, 191, 220)",
      "rgb(241, 132, 130)",
      "rgb(253, 212, 123)",
      "rgb(249, 239, 129)",
      "rgb(170, 210, 159)",
      "rgb(132, 196, 236)",
      "rgb(167, 118, 180)",
      "rgb(223, 125, 179)",
      "rgb(233, 67, 66)",
      "rgb(245, 150, 47)",
      "rgb(250, 234, 48)",
      "rgb(123, 188, 98)",
      "rgb(76, 136, 203)",
      "rgb(133, 75, 152)",
      "rgb(213, 30, 134)",
      "rgb(229, 28, 34)",
      "rgb(239, 126, 23)",
      "rgb(249, 228, 21)",
      "rgb(95, 180, 81)",
      "rgb(49, 112, 183)",
      "rgb(116, 66, 149)",
      "rgb(208, 2, 110)",
      "rgb(255, 255, 255)",
      "rgb(204, 204, 204)",
      "rgb(153, 153, 153)",
      "rgb(102, 102, 102)",
      "rgb(51, 51, 51)",
      "rgb(0, 0, 0)",
    ]);

    const isActiveObject = ref(false);
    const textareaValue = ref("");
    const isTextareaShown = ref(false);
    const fontFamilyValue = ref("");
    const isFontFamilyShown = ref(false);
    const fontSizeValue = ref("");
    const isFontSizeShown = ref(false);
    const fontColorValue = ref("");
    const isFontColorShown = ref(false);
    const fontAlignmentValue = ref("");
    const isFontAdvancedTransformShown = ref(false);
    const fontLetterSpacing = ref("");
    const fontLineHeight = ref("");
    const isFontTextSpacingShown = ref(false);
    const isColorTooDark = ref(false);
    const isTextElement = ref(true);

    onMounted(async () => {
      backgroundImage.value = data.value.data.backgroundImage;

      if (data.value.data.backgroundColorOverlay == null) {
        backgroundColorOverlay.value = "#FFFFFF00";
      } else {
        backgroundColorOverlay.value = data.value.data.backgroundColorOverlay;
      }

      try {
        isLoading.value = false;
        await checkImageLoaded();

        preloadFonts().then(() => {
          initCanvas();
          if (isEditable.value) {
            console.log(isEditable.value);
          }
        });

        if (isEditable.value) {
          document.querySelector(".envelope-wrapper").classList.add("animated");
          document
            .querySelector(".envelope-wrapper")
            .classList.add("letter-up");
          document.querySelector(".envelope").classList.add("hide-index");
          document.querySelector(".letter").classList.add("rotate");
          document.querySelector(".envelope-wrapper").classList.add("finish");
        } else {
          setTimeout(() => {
            document
              .querySelector(".envelope-wrapper")
              .classList.add("animated");
          }, 1000);
          setTimeout(() => {
            document
              .querySelector(".envelope-wrapper")
              .classList.add("letter-up");
          }, 3250);
          setTimeout(() => {
            document.querySelector(".envelope").classList.add("hide-index");
          }, 4250);
          setTimeout(() => {
            document.querySelector(".letter").classList.add("rotate");
            document.querySelector(".envelope-wrapper").classList.add("finish");
          }, 5500);
        }

        window.addEventListener("resize", resizeCanvas);
      } catch (error) {
        isLoading.value = true;
        notify({
          type: "error",
          title: "Error",
          text: "Cevas a mers gresit. Va rugam sa incercati din nou.",
        });
      }
    });

    onUnmounted(() => { });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", resizeCanvas);
      if (route.path.includes("demo")) {
        isDemo.value = true;
      }
    });

    const openGoogleMaps = () => {
      console.log(data.value);

      // if (data.value.data.location && !data.value.isEditable) {
      //   const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      //     data.value.data.location
      //   )}`;
      //   window.open(url, "_blank");
      // }
      window.open(`https://maps.app.goo.gl/QKBesCSEBi3x6xcn9`, "_blank");
    };
    const initCanvas = () => {
      canvas.value = new fabric.Canvas("theCanvas", {
        width: 800,
        height: 1100,
        selection: isEditable.value,
      });

      if (!isEditable.value) {
        canvas.value.forEachObject((obj) => {
          obj.selectable = false;
          obj.evented = false;
        });
        canvas.value.selection = false;
        canvas.value.hoverCursor = "default";
        canvas.value.renderAll();
      }

      canvas.value.on("mouse:down", function (options) {
        if (!isEditable.value) {
          canvas.value.discardActiveObject();
          canvas.value.requestRenderAll();
        }
      });

      canvas.value.on("mouse:dblclick", function (options) {
        if (isEditable.value) {
          const target = options.target;
          if (target && target.type === "i-text") {
            canvas.value.setActiveObject(target);
            target.enterEditing();
          }
        }
      });

      if (invitation.value !== null && invitation.value.json !== null) {
        canvas.value.clear();
        canvas.value.loadFromJSON(
          JSON.stringify(invitation.value.json.canvas),
          function () {
            canvas.value.renderAll.bind(canvas.value);
            if (!isEditable.value) {
              canvas.value.forEachObject((obj) => {
                obj.selectable = false;
                obj.evented = false;
              });
              canvas.value.selection = false;
              canvas.value.hoverCursor = "default";
              canvas.value.renderAll();
            }
          }
        );
      } else {
        addElementsToCanvas();
      }

      resizeCanvas();

      if (isEditable.value) {
        handleCanvasEvents();

        document
          .querySelector(".input-wrapper.textAreaWrapper textarea")
          .addEventListener("input", (event) => {
            const newText = event.target.value;
            const activeObject = canvas.value.getActiveObject();
            if (activeObject && activeObject.type === "i-text") {
              activeObject.set("text", newText);
              canvas.value.renderAll();
            }
          });
      }
    };

    const updateRefObjectValues = () => {
      const activeObject = canvas.value.getActiveObject();
      if (activeObject && activeObject.type === "i-text") {
        textareaValue.value = activeObject.text;
        fontFamilyValue.value = activeObject.fontFamily;
        fontSizeValue.value = activeObject.fontSize;
        fontColorValue.value = activeObject.fill;
        fontAlignmentValue.value = activeObject.textAlign;
        fontLetterSpacing.value = activeObject.charSpacing;
        fontLineHeight.value = activeObject.lineHeight;
        isActiveObject.value = true;
        isTextElement.value = true;
      } else if (activeObject && activeObject.type === "rect") {
        textareaValue.value = "";
        fontFamilyValue.value = "";
        fontSizeValue.value = "";
        fontColorValue.value = activeObject.fill;
        isActiveObject.value = true;
        isTextElement.value = false;
      } else {
        textareaValue.value = "";
        fontFamilyValue.value = "";
        fontSizeValue.value = "";
        fontColorValue.value = "";
        isActiveObject.value = false;
        isTextElement.value = true;
      }
    };

    const handleCanvasEvents = () => {
      canvas.value.on("selection:created", updateRefObjectValues);
      canvas.value.on("selection:updated", updateRefObjectValues);

      canvas.value.on("selection:cleared", () => {
        textareaValue.value = "";
        fontFamilyValue.value = "";
        fontSizeValue.value = "";
        fontColorValue.value = "";
        fontAlignmentValue.value = "";
        isActiveObject.value = false;
        isTextElement.value = true;
      });

      canvas.value.on("object:modified", updateRefObjectValues);
    };

    function handleFontChange(font) {
      const activeObject = canvas.value.getActiveObject();
      if (activeObject && activeObject.type === "i-text") {
        fontFamilyValue.value = font;
        activeObject.set("fontFamily", font);
        canvas.value.renderAll();
      }
    }

    function handleThemeChange(index) {
      data.value.themeStyle = index;
      selectedThemeColor.value = index;
      backgroundColorOverlay.value = data.value.data.themeColors[index][6];

      canvas.value.getObjects().forEach((obj) => {
        obj.set("fill", themeColors.value[selectedThemeColor.value][1]);
      });
      canvas.value.renderAll();
    }

    function handleFontSizeChange() {
      const activeObject = canvas.value.getActiveObject();
      if (activeObject && activeObject.type === "i-text") {
        if (fontSizeValue.value > 112) {
          fontSizeValue.value = 112;
        } else if (fontSizeValue.value < 8) {
          fontSizeValue.value = 8;
        }

        activeObject.set("fontSize", fontSizeValue.value);
        canvas.value.renderAll();
      }
    }

    function handleColorChange(color) {
      checkIfColorIsTooDark(color);
      const activeObject = canvas.value.getActiveObject();
      if (activeObject) {
        activeObject.set("fill", color);
        canvas.value.renderAll();
      }
    }

    function handleOverlayColorChange(color) { }

    function handleFontAlignmentChange(alignment) {
      const activeObject = canvas.value.getActiveObject();
      if (activeObject && activeObject.type === "i-text") {
        fontAlignmentValue.value = alignment;
        activeObject.set("textAlign", alignment);
        canvas.value.renderAll();
      }
    }

    function handleFontTransformChange(transform) {
      const activeObject = canvas.value.getActiveObject();
      if (activeObject && activeObject.type === "i-text") {
        let newText;
        switch (transform) {
          case "uppercase":
            newText = activeObject.text.toUpperCase();
            break;
          case "lowercase":
            newText = activeObject.text.toLowerCase();
            break;
          case "matchcase":
            newText = activeObject.text.replace(/\b\w/g, (char) =>
              char.toUpperCase()
            );
            break;
          default:
            return;
        }
        activeObject.set("text", newText);
        canvas.value.requestRenderAll();
      }
    }

    function handleFontLetterSpacing() {
      const activeObject = canvas.value.getActiveObject();
      if (activeObject && activeObject.type === "i-text") {
        activeObject.set("charSpacing", fontLetterSpacing.value);
        canvas.value.renderAll();
      }
    }

    function handleFontLineHeightChange() {
      const activeObject = canvas.value.getActiveObject();
      if (activeObject && activeObject.type === "i-text") {
        activeObject.set("lineHeight", fontLineHeight.value);
        canvas.value.renderAll();
      }
    }

    function checkIfColorIsTooDark(color) {
      if (!color) {
        console.log("Color is undefined");
        return false;
      }

      const hexToRgb = (hex) => {
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, (m, r, g, b) => {
          return r + r + g + g + b + b;
        });

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
          ? [
            parseInt(result[1], 16),
            parseInt(result[2], 16),
            parseInt(result[3], 16),
          ]
          : null;
      };

      color = color.toLowerCase();

      let rgbValues = [];
      if (color.startsWith("#")) {
        rgbValues = hexToRgb(color);
      } else if (color.startsWith("rgb")) {
        rgbValues = color.match(/\d+/g);
      }

      if (!rgbValues || rgbValues.length < 3) {
        return false;
      }

      const [r, g, b] = rgbValues.map(Number);

      const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;

      const threshold = 0.2;

      isColorTooDark.value = luminance < threshold;
    }

    const preloadFonts = () => {
      const fontPromises = fontOptions.value.map((fontName) => {
        const fontObserver = new FontFaceObserver(fontName);
        return fontObserver.load(null, 12000);
      });
      return Promise.all(fontPromises);
    };

    const resizeCanvas = () => {
      const outerCanvasContainer = document.querySelector(
        ".invitation-content"
      );

      const ratio = canvas.value.getWidth() / canvas.value.getHeight();
      const containerWidth = outerCanvasContainer.clientWidth;

      const scale = containerWidth / canvas.value.getWidth();
      const zoom = canvas.value.getZoom() * scale;
      canvas.value.setDimensions({
        width: containerWidth,
        height: containerWidth / ratio,
      });
      canvas.value.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
    };

    const addElementsToCanvas = () => {
      data.value.elements.forEach((element) => {
        let fabricObject;
        if (element.type === "textbox") {
          fabricObject = new fabric.IText(element.text, {
            width: element.width,
            left: element.left,
            top: element.top,
            fontFamily: element.fontFamily,
            fontSize: element.fontSize,
            lineHeight: element.lineHeight,
            textAlign: element.textAlign,
            fill: themeColors.value[selectedThemeColor.value][1],
            editable: isEditable.value,
            selectable: isEditable.value,
            hasControls: isEditable.value,
            hoverCursor: isEditable.value ? "grab" : "default",
            evented: isEditable.value,
            charSpacing: element.charSpacing || 0,
            textTransform: element.textTransform || 'none',
            fontWeight: element.fontWeight || 'normal',
            fontStyle: element.fontStyle || 'normal',
            underline: element.underline || false,
            overline: element.overline || false,
            linethrough: element.linethrough || false,
          });
        } else if (element.type === "rect") {
          fabricObject = new fabric.Rect({
            left: element.left,
            top: element.top,
            width: element.width,
            height: element.height,
            fill: themeColors.value[selectedThemeColor.value][1],
            editable: isEditable.value,
            selectable: isEditable.value,
            hasControls: isEditable.value,
            hoverCursor: isEditable.value ? "grab" : "default",
            evented: isEditable.value,
          });
        }

        if (fabricObject) {
          canvas.value.add(fabricObject);
        }
      });

      if (!isEditable.value) {
        canvas.value.forEachObject((obj) => {
          obj.selectable = false;
          obj.evented = false;
        });
        canvas.value.selection = false;
        canvas.value.hoverCursor = "default";
      }

      canvas.value.renderAll();
    };

    const save = () => {
      data.value.canvas = canvas.value.toJSON();
      data.value.templateTheme = selectedThemeColor.value;
      data.value.data.backgroundImage = backgroundImage.value;
      data.value.data.backgroundColorOverlay = backgroundColorOverlay.value;

      emit("saveJson", data.value);
    };

    const checkImageLoaded = () => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = data.value.data.backgroundImage;

        img.onload = () => {
          isImageLoaded.value = true;
          resolve();
        };

        img.onerror = () => {
          reject(new Error("Failed to load the image"));
        };
      });
    };

    const progressCallback = (progress) => {
      uploadProgress.value = Math.round(progress);
    };

    const uploadImage = async (file) => {
      uploadingImage.value = true;

      const url = await blobStorageHelper.uploadImageToStorage(
        file,
        props.offerId,
        progressCallback
      );

      uploadingImage.value = false;
      backgroundImage.value = url;
      return url;
    };

    const closeImageCropperModal = () => {
      showImageCropperModal.value = false;
    };

    const handleChangeImage = async (event) => {
      const file = event.target.files[0];
      if (file) {
        initialImageLocation.value = data.value.backgroundImage;
        initialImageAspectRatio.value = 8 / 11;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          initialImage.value = e.target.result;
          showImageCropperModal.value = true;
        };
      }
    };

    const handleCroppedImage = async (file) => {
      if (file) {
        const imageURL = await uploadImage(file);

        switch (initialImageLocation.value) {
          case data.value.backgroundImage:
            data.value.backgroundImage = imageURL;
            break;
        }
      }
    };

    const openConfirmationModal = () => {
      showConfirmInvitationModal.value = true;
      setTimeout(() => {
        document
          .querySelector(".confirmation-modal-wrapper")
          .classList.add("animated");
      }, 100);
    };
    const closeConfirmationModal = () => {
      document
        .querySelector(".confirmation-modal-wrapper")
        .classList.remove("animated");
      setTimeout(() => {
        showConfirmInvitationModal.value = false;
      }, 300);
    };

    const sendInvitationResponse = (response) => {
      emit("sendInvitationResponse", response);
    };

    return {
      isLoading,
      canvas,
      theme,
      data,
      modelName,
      themeColors,
      selectedThemeColor,
      showConfirmInvitationModal,
      backgroundImage,
      backgroundColorOverlay,
      computedBackgroundColor,
      showImageCropperModal,
      initialImage,
      initialImageLocation,
      initialImageAspectRatio,
      closeImageCropperModal,
      handleCroppedImage,
      textareaValue,
      isActiveObject,
      fontFamilyValue,
      fontSizeValue,
      fontColorValue,
      fontAlignmentValue,
      fontLetterSpacing,
      fontLineHeight,
      isThemeColorShown,
      isImageOverlayShown,
      isTextareaShown,
      isFontFamilyShown,
      isFontSizeShown,
      isFontColorShown,
      isFontAdvancedTransformShown,
      isFontTextSpacingShown,
      handleThemeChange,
      handleFontChange,
      handleFontSizeChange,
      handleColorChange,
      handleOverlayColorChange,
      handleFontAlignmentChange,
      handleFontTransformChange,
      handleFontLetterSpacing,
      handleFontLineHeightChange,
      isColorTooDark,
      isTextElement,
      fontOptions,
      colorOptions,
      isImageLoaded,
      isEditable,
      invitation,
      sendInvitationResponse,
      initCanvas,
      resizeCanvas,
      closeConfirmationModal,
      openConfirmationModal,
      handleChangeImage,
      checkImageLoaded,
      addElementsToCanvas,
      save,
      openGoogleMaps,
    };
  },
};
</script>
