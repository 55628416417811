<template>
  <div class="wrapper-content floor-plan">
    <view-floor-plan-table-modal
      v-if="viewFloorPlanModal"
      :showViewFloorPlanModal="viewFloorPlanModal"
      :guestlist="guestlist"
      :persons="selectedTable?.persons"
      :tableId="selectedTableName"
      :tableSize="selectedTable?.tableSize"
      @closeModal="closeViewFloorPlanModal()"
      @addPersonsToTable="addPersonsToTable"
      @removePersonFromTable="removePersonFromTable"
      @save="saveDesign"
    />

    <div class="header space-between hide-bg-on-mobile">
      <div class="mobile-only">
        <div class="menu-toggle">
          <span class="material-symbols-rounded" @click="openDrawer()">
            menu
          </span>
        </div>
      </div>

      <div class="tablet-only">
        <div
          class="vertical-align gap-5 splash-rounded splash-padding"
          @click="this.$router.go(-1)"
        >
          <span class="material-symbols-rounded"> arrow_back </span>
          <h1>Event Floorplan</h1>
        </div>
      </div>

      <div class="actions">
        <!-- TODO: Leo trebuie sters clickul asta dupa ce implementezi autosaveul, componenta face tot ce trebuie singura, doar modifica valoarea isSaving -->

        <autosave-component
          :isSaving="isSaving"
          @update:isSaving="isSaving = $event"
        />

        <div
          class="header-button secondary"
          @click="
            this.$router.push(`/floorplan/${offerId}/${floorplanId}/builder`)
          "
        >
          <div class="highlight">
            <span class="material-symbols-rounded"> edit </span>
          </div>
          <div class="text">Editează</div>
        </div>
        <div class="header-button primary" @click="exportCanvasAsImage">
          <div class="highlight">
            <span class="material-symbols-rounded"> print </span>
          </div>
          <div class="text">Export</div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-loader-overlay" v-if="isLoading">
        <vLottiePlayer
          name="lottie"
          class="lottie"
          loop
          :animationData="require('@/assets/lottie/simpleDotsLoader.json')"
        />
      </div>
      <div class="content-disclaimer" v-if="this.isCanvasEmpty">
        <span class="material-symbols-outlined"> gpp_maybe </span>
        <p>
          Floorplanul tău nu are nici o masă.
          <a
            @click="
              this.$router.push(`/floorplan/${offerId}/${floorplanId}/builder`)
            "
            >Intră în modulul de editare</a
          >
          pentru a adăuga mese
        </p>
      </div>
      <div
        ref="canvasContainer"
        class="canvas-container"
        style="width: 100%; height: 100%; position: relative"
      >
        <canvas ref="canvas"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import { fabric } from "fabric";
import { getFloorplan, getGuestlist, updateFloorplan } from "@/api";
import { useAuth0 } from "@auth0/auth0-vue";
import blobstoragehelper from "@/helpers/blobstoragehelper";
import ViewFloorPlanTableModal from "@/views/floorPlan/components/ViewFloorPlanTableModal.vue";
import AutosaveComponent from "@/components/AutosaveComponent.vue";
import anime from "animejs";
import $ from "jquery";

export default {
  data() {
    return {
      canvas: null,
      realWidth: 20,
      realHeight: 10,
      scaleFactor: 1,
      heightText: null,
      widthText: null,
      selection: true,
      showAddTableBool: false,
      tableType: null,
      offerId: null,
      floorplanId: 0,
      viewFloorPlanModal: false,
      guestlist: [],
      token: null,
      selectedTable: null,
      selectedTableName: "",
      isLoading: true,
      floorplan: null,
      isCanvasEmpty: false,
      isSaving: false,
    };
  },
  components: {
    ViewFloorPlanTableModal,
    AutosaveComponent,
  },
  async mounted() {
    const { getAccessTokenSilently } = useAuth0();
    this.token = await getAccessTokenSilently();

    this.offerId = this.$route.params.offerId;
    this.floorplanId = this.$route.params.floorplanId;

    await this.loadGuestlist();
    await this.loadSavedDesign();

    this.realWidth = this.floorplan.realWidth;
    this.realHeight = this.floorplan.realHeight;
    this.shapeIndex = this.floorplan.shapeIndex;

    this.isLoading = false;

    this.initializeCanvas();

    window.addEventListener("resize", this.resizeCanvas);

    this.updateTableInfo();
  },
  methods: {
    openDrawer() {
      document.querySelector(".drawer-wrapper").classList.add("open");
    },
    triggerAutosave() {
      this.isSaving = !this.isSaving;
    },
    exportCanvasAsImage() {
      const canvasElement = this.$refs.canvas;
      const width = canvasElement.width;
      const height = canvasElement.height;

      const offScreenCanvas = document.createElement("canvas");
      offScreenCanvas.width = height;
      offScreenCanvas.height = width;
      const ctx = offScreenCanvas.getContext("2d");

      ctx.translate(height / 2, width / 2);
      ctx.rotate(Math.PI / 2);
      ctx.drawImage(canvasElement, -width / 2, -height / 2);

      const dataURL = offScreenCanvas.toDataURL("image/png");

      localStorage.setItem("exportedCanvasImage", dataURL);
      this.$router.push({ name: "Floor Plan Printer" });
    },

    showViewFloorPlanModal() {
      this.viewFloorPlanModal = true;
    },

    closeViewFloorPlanModal() {
      this.viewFloorPlanModal = false;
    },

    async loadGuestlist() {
      const guestlistComplete = await getGuestlist(this.token, this.offerId);
      guestlistComplete.forEach((guest) => {
        guest.head = guest;
        this.guestlist.push(guest);
        guest.companion.forEach((companion) => {
          companion.head = guest;
          this.guestlist.push(companion);
        });
      });
    },
    async loadSavedDesign() {
      this.floorplan = await getFloorplan(
        this.token,
        this.offerId,
        this.floorplanId
      );
      const url = this.floorplan.url;

      if (url !== null && url.length > 0) {
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            const file = new File([blob], "filename", { type: "mime/type" });

            if (file) {
              const reader = new FileReader();
              reader.onload = (e) => {
                const data = JSON.parse(e.target.result);
                const scaleX =
                  this.$refs.canvasContainer.clientWidth / data.realWidth;
                const scaleY =
                  this.$refs.canvasContainer.clientHeight / data.realHeight;
                const containerWidth = this.$refs.canvasContainer.clientWidth;
                const containerHeight = this.$refs.canvasContainer.clientHeight;

                this.canvas.loadFromJSON(data.canvasData, () => {
                  this.canvas.forEachObject((obj) => {
                    obj.set({
                      scaleX: obj.scaleX * scaleX,
                      scaleY: obj.scaleY * scaleY,
                      left: obj.left * scaleX,
                      top: obj.top * scaleY,
                      selectable: false,
                      hasControls: false,
                      lockRotation: true,
                      lockScalingX: true,
                      lockScalingY: true,
                      hasRotatingPoint: false,
                      cornerSize: "0",
                      cornerColor: "#515151",
                      hoverCursor: "pointer",
                    });
                    obj.setCoords();
                  });
                  this.addDimensionText(containerWidth, containerHeight);
                  this.canvas.backgroundColor = "#FFFDFB";
                  this.canvas.renderAll();
                  // Call checkCanvasEmpty here after canvas is fully loaded
                  this.checkCanvasEmpty();
                });
              };
              reader.readAsText(file);
            }
          })
          .catch((error) => console.error(error));
      }
    },
    checkCanvasEmpty() {
      setTimeout(() => {
        if (!this.canvas) {
          console.error("Canvas not initialized in checkCanvasEmpty.");
          return;
        }

        const hasTables = this.canvas
          .getObjects()
          .some((obj) => obj.type === "group");
        this.isCanvasEmpty = !hasTables;

        if (this.isCanvasEmpty) {
          this.$nextTick(() => {
            const $disclaimer = $(".content-disclaimer");
            $disclaimer.css({
              padding: "0",
              height: "0",
              opacity: 0,
              transition: "all 200ms ease-out",
            });

            $disclaimer.delay(100).queue(function (next) {
              $(this).css({
                padding: "15px",
                height: "auto",
                opacity: 1,
                transition: "all 200ms ease-out",
              });
              next();
            });

            const xMax = 50;
            anime({
              targets: ".content-disclaimer",
              easing: "easeInOutQuad",
              duration: 300,
              translateX: [
                { value: xMax * -1 },
                { value: xMax },
                { value: xMax / -2 },
                { value: xMax / 2 },
                { value: 0 },
              ],
              delay: 350,
            });
          });
          console.log("Canvas is empty and has no items");
        } else {
          console.log("Canvas has items");
        }
      }, 50);
    },

    addPersonsToTable(person) {
      if (!this.selectedTable.persons.includes(person)) {
        // remove person from previous table
        if (person.tableName) {
          let objects = this.canvas.getObjects();

          objects.forEach((obj) => {
            if (obj.type === "group") {
              obj.persons = obj.persons.filter(
                (p) => p.name !== person.name && p.id !== person.id
              );
            }
          });
        }
        this.selectedTable.persons.push(person);
        this.canvas.renderAll();
        this.updateTableInfo();
      }
    },
    removePersonFromTable(person) {
      this.selectedTable.persons = this.selectedTable.persons.filter(
        (p) => p !== person
      );
      this.canvas.renderAll();
      this.updateTableInfo();
    },
    updateTableInfo() {
      let objects = this.canvas.getObjects();

      this.guestlist.forEach((guest) => {
        guest.tableName = "";
        guest.tableId = "";
      });

      objects.forEach((obj) => {
        if (obj.type === "group") {
          obj.persons.forEach((person) => {
            this.guestlist.forEach((guest) => {
              if (person.id === guest.id && person.name === guest.name) {
                guest.tableName = obj.getObjects()[2].text;
                guest.tableId = obj.tableId;
              }
            });
          });
        }
      });
    },
    async saveDesign() {
      this.loading = true;
      this.triggerAutosave();
      const originalDimensions = {
        width: this.canvas.getWidth(),
        height: this.canvas.getHeight(),
      };
      this.canvas.setDimensions({
        width: this.realWidth,
        height: this.realHeight,
      });

      this.canvas.forEachObject((obj) => {
        obj.set({
          scaleX: (obj.scaleX * this.realWidth) / originalDimensions.width,
          scaleY: (obj.scaleY * this.realHeight) / originalDimensions.height,
          left: (obj.left * this.realWidth) / originalDimensions.width,
          top: (obj.top * this.realHeight) / originalDimensions.height,
        });
        obj.setCoords();
      });

      const filteredCanvasData = this.canvas
        .toJSON([
          "selectable",
          "lockMovementX",
          "lockMovementY",
          "lockRotation",
          "lockScalingX",
          "lockScalingY",
          "persons",
          "tableId",
          "tableSize",
        ])
        .objects.filter((obj) => obj.type !== "text");

      const jsonData = JSON.stringify({
        canvasData: { objects: filteredCanvasData },
        realWidth: this.realWidth,
        realHeight: this.realHeight,
        tablePersons: this.tablePersons,
      });

      const url = await blobstoragehelper.uploadJsonToStorage(
        jsonData,
        this.offerId
      );

      this.closeViewFloorPlanModal();

      const token = await this.$auth0.getAccessTokenSilently();
      await updateFloorplan(token, this.offerId, this.floorplanId, {
        FloorPlanUrl: url,
      }).then(() => {
        this.loading = false;
        this.triggerAutosave();
      });
    },

    initializeCanvas() {
      const containerWidth = this.$refs.canvasContainer.clientWidth;
      const containerHeight = this.$refs.canvasContainer.clientHeight;

      this.canvas = new fabric.Canvas(this.$refs.canvas, {
        width: containerWidth,
        height: containerHeight,
        backgroundColor: "#FFFDFB",
        selection: false,
        selectable: false,
      });

      this.canvas.on("before:selection:cleared", (event) => {
        if (event.target && event.target.isType("activeSelection")) {
          event.target.set({
            hasControls: false,
            hasBorders: false,
            selectable: false,
          });
          event.target.forEachObject((obj) => {
            obj.set("active", false);
          });
          this.canvas.discardActiveObject();
          return false;
        }
      });

      this.canvas.on("mouse:down", (options) => {
        if (options.target) {
          this.handleClick(options.target);
        }
      });

      this.canvas.renderAll();

      this.resizeCanvas();
    },

    resizeCanvas() {
      const containerWidth = this.$refs.canvasContainer.clientWidth;
      const previousScaleFactor = this.scaleFactor;
      const newScaleFactor = containerWidth / this.realWidth;
      this.scaleFactor = newScaleFactor;

      this.canvas.forEachObject((obj) => {
        if (obj !== this.heightText && obj !== this.widthText) {
          const scaleX = (obj.scaleX * newScaleFactor) / previousScaleFactor;
          const scaleY = (obj.scaleY * newScaleFactor) / previousScaleFactor;
          obj.set({
            scaleX: scaleX,
            scaleY: scaleY,
            left: (obj.left * newScaleFactor) / previousScaleFactor,
            top: (obj.top * newScaleFactor) / previousScaleFactor,
          });
          obj.setCoords();
        }
      });

      this.canvas.setDimensions({
        width: containerWidth,
        height: this.realHeight * newScaleFactor,
      });

      this.canvas.renderAll();
    },
    calculateFontSize() {
      const computedFontSize = 18 * (this.scaleFactor * 1.25);

      if (computedFontSize < 10) {
        return 10;
      } else {
        return computedFontSize;
      }
    },

    addDimensionText(containerWidth, containerHeight) {
      const transformedHeight = this.realHeight / 100;
      const heightText = new fabric.Text(`${transformedHeight}m`, {
        fontSize: this.calculateFontSize(),
        fontFamily: "Poppins",
        fill: "#DBD4CA",
        left: this.canvas.getWidth() / 2,
        top: this.canvas.getHeight() - this.scaleFactor * 20 - 10,
        originX: "center",
        originY: "center",
        selectable: false,
        hasControls: false,
        cursorStyle: "none",
        evented: false,
      });
      this.canvas.add(heightText);

      const transformedWidth = this.realWidth / 100;
      const widthText = new fabric.Text(`${transformedWidth}m`, {
        fontSize: this.calculateFontSize(),
        fontFamily: "Poppins",
        fill: "#DBD4CA",
        left: this.canvas.getWidth() - this.scaleFactor * 20 - 10,
        top: this.canvas.getHeight() / 2,
        originX: "right",
        originY: "center",
        selectable: false,
        hasControls: false,
        cursorStyle: "none",
        evented: false,
      });
      this.canvas.add(widthText);
    },
    handleClick(target) {
      if (target.tableId !== "bar") {
        this.selectedTableName = target.getObjects()[2].text;
        this.selectedTable = target;
        this.viewFloorPlanModal = true;
      }
    },
  },
  beforeUnmounted() {
    window.removeEventListener("resize", this.resizeCanvas);
  },
};
</script>
