<template>
  <div class="card service">
    <img :src="offerPackage.photos[0].url" alt="Balloons">
    
    <div class="service-content">
      <div class="top-details">
        <p class="secondary">{{ offerPackage.type === 0 ? "Sonorizare" : "Lumini" }}</p>
      </div>
  
      <h1>{{ offerPackage.name }}</h1>
      <p v-for="product in offerPackage.products" :key="product.name">{{ product.name }}</p>
    </div>


  
    
    
  </div>
</template>
<script>
// import { ref } from 'vue';

export default {
  name: 'Service-Card',
  props: {
    offerPackage: {
      type: Object,
      required: true
    }
  },
  // setup(props) {

  //   return {
  //     photo,
  //     name
  //   };
  // }
}
</script>