<template>
  <div
    class="modal bottom-sheet-on-mobile"
    id="view-guest-modal"
    :class="{ active: showViewGuestModal }"
    @click="closeModalFromBackground"
  >
    <div class="modal-content" :class="{ confirmed: guest.confirmed }">
      <div class="modal-header">
        <div class="header-name">
          <h1>{{ guest.name }}</h1>
          <div class="status confirmed" v-if="guest.confirmed">
            <span class="material-symbols-rounded"> check_circle </span>
            Confirmat
          </div>
          <div class="status unconfirmed" v-else>
            <span class="material-symbols-rounded"> cancel </span>
            Respins
          </div>
        </div>

        <span
          class="close-modal splash-rounded material-symbols-rounded fill"
          @click="closeModal"
        >
          close
        </span>
      </div>
      <form>
        <div>
          <div class="persons-display">
            {{ this.numberOfPersons }}
            <span class="material-symbols-rounded"> account_circle </span>
          </div>
          <h2>{{ guest.phone }}</h2>
        </div>
        <div class="person">
          <h2>
            Invitat - <span>{{ guest.name }}</span>
          </h2>
          <div class="question-wrapper">
            <div
              class="question"
              v-for="answer in guest.quizAnswers"
              :key="answer"
            >
              <h3>{{ answer.question.value }}</h3>
              <h4>{{ answer.answer.value }}</h4>
            </div>
          </div>
        </div>
        <div
          class="person"
          v-for="companion in guest.companion"
          :key="companion"
        >
          <h2>
            Invitat - <span>{{ companion.name }}</span>
          </h2>
          <div class="question-wrapper">
            <div
              class="question"
              v-for="answer in companion.answers"
              :key="answer"
            >
              <h3>{{ answer.question.value }}</h3>
              <h4>{{ answer.answer.value }}</h4>
            </div>
          </div>
        </div>
      </form>
      <div class="modal-footer">
        <button class="primary small" @click="removeGuest">
          Sterge rǎspuns
        </button>
      </div>
      <div class="modal-footer">
        <button class="primary small" @click="closeModal()">Inchide</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { markResponseSeen, removeInvitationResponse } from "@/api";
import { useAuth0 } from "@auth0/auth0-vue";

export default {
  name: "ViewGuestModal",
  props: {
    showViewGuestModal: Boolean,
    guest: Object,
    offerId: String,
  },
  setup(props, { emit }) {
    const numberOfPersons = ref(props.guest.companion.length + 1);
    const { getAccessTokenSilently } = useAuth0();

    function closeModalFromBackground(event) {
      if (event.target.classList.contains("modal")) {
        emit("closeModal");
      }
    }

    function closeModal() {
      emit("closeModal");
    }

    async function removeGuest() {
      const token = await getAccessTokenSilently();

      await removeInvitationResponse(token, props.guest.id, props.offerId);
      emit("refreshGuestlist");
      emit("closeModal");
    }

    onMounted(async () => {
      if (!props.guest.seen) {
        // eslint-disable-next-line vue/no-mutating-props
        props.guest.seen = true;
        const token = await getAccessTokenSilently();

        markResponseSeen(token, props.guest.id, props.offerId);
      }
    });

    return {
      numberOfPersons,
      removeGuest,
      closeModalFromBackground,
      closeModal,
    };
  },
};
</script>
