<template>
    <div class="card module my-privent-card memories-collection-card" :class="[containerClass]">
      <div class="inside">
        <div class="text-content">
          <h2>Bugetare</h2>
          <div class="module-button" @click="onClick">
            <span class="material-symbols-rounded fill">
                account_balance_wallet
            </span>
            <p>Vezi bugetarea<br>detaliata</p>
          </div>
        </div>
        <div class="elements-content">
            <div class="detail-content">
                <div class="group">
                  <h2>costuri<br>totale</h2>
                  <h1>{{ budgetTotalCost }}</h1>
                </div>
                <div class="group">
                  <h2>rest de<br>plată</h2>
                  <h1>{{ budgetToPay }}</h1>
                </div>
            </div>
        </div>
      </div>
      <img class="background-image" :src="resolvedImage" alt="Budget Background">
    </div>
  </template>
  <script>
  import { ref, onMounted, computed } from 'vue';

  export default {
    name: 'Budget-Card',
    props: {
      isActivated: { type: Boolean },
      budgetToPay: {type: Number},
      budgetTotalCost: {type: Number},
      image: { type: String, default: '' },
      onClick: { type: Function },
      containerClass: {
        type: String,
        default: ''
      },
    },
    computed: {
    },
    setup(props) {
        const resolvedImage = computed(() => {
          return require('@/assets/img/dashboard/' + 'budgetBackground.png');
        });

    return {
      resolvedImage,
    };
  }
  }   
  </script>