<template>
  <div class="modal bottom-sheet-on-mobile" id="add-edit-floor-plan-modal"
    :class="{ active: showAddEditFloorPlanModel }" @click="closeModalFromBackground">

    <div class="modal-content">

      <div class="modal-header">
        <h1 v-if="isEditing">Editează floor plan</h1>
        <h1 v-else>Adaugă floor plan</h1>

        <span class="close-modal splash-rounded material-symbols-rounded fill" @click="closeModal">
          close
        </span>
      </div>

      <form ref="formElement" class="container" v-show="!loading">
       
        <div class="floor-plan-selector">
          <p class="label">Formă salon</p>
          <div class="elements">
            <div class="floor-plan-element-selector" :class="{'selected': shapeIndex == 0}" @click="shapeIndex = 0">
              <img src="@/assets/img/floorplanShapes/roundShape.svg"/>
              <p>Rotund</p>
            </div>
            <div class="floor-plan-element-selector" :class="{'selected': shapeIndex == 1}" @click="shapeIndex = 1">
              <img src="@/assets/img/floorplanShapes/squareShape.svg"/>
              <p>Patrată</p>
            </div>
            <div class="floor-plan-element-selector" :class="{'selected': shapeIndex == 2}" @click="shapeIndex = 2">
              <img src="@/assets/img/floorplanShapes/rectangularShape.svg"/>
              <p>Dreptunghică</p>
            </div>
          </div>
         
        </div>
        <div class="floor-plan-selector">
          <p class="label">Capacitate salon</p>
          <div class="elements">
            <div class="floor-plan-element-selector" :class="{'selected': dimensionIndex == 0}" @click="dimensionIndex = 0">
              <img src="@/assets/img/floorplanShapes/smallCapacity.svg"/>
              <p>Mică</p>
            </div>
            <div class="floor-plan-element-selector" :class="{'selected': dimensionIndex == 1}" @click="dimensionIndex = 1">
              <img src="@/assets/img/floorplanShapes/mediumCapacity.svg"/>
              <p>Medie</p>
            </div>
            <div class="floor-plan-element-selector" :class="{'selected': dimensionIndex == 2}" @click="dimensionIndex = 2">
              <img src="@/assets/img/floorplanShapes/largeCapacity.svg"/>
              <p>Mare</p>
            </div>
          </div>
          
        </div>
      </form>

      <button class="primary small" v-if="isEditing" @click="save">Salvează</button>
      <button class="primary small" v-else @click="createFloorplan">Creează floorplan</button>
    </div>

  </div>
</template>

<script>


import { ref, onMounted, nextTick } from 'vue';
 import * as Pristine from 'pristinejs';

export default {
  name: 'AddEditFloorPlanModal',
  props: {
    showAddEditFloorPlanModel: Boolean,
    offerId: String,
    isEditing: Boolean
  },
  setup(props, { emit }) {
    const loading = ref(true);
    const widthModel = ref();
    const heightModel = ref();

    const shapeIndex = ref(0);
    const dimensionIndex = ref(0);

    const defaultConfig = {
      classTo: 'input-wrapper',
      errorClass: 'has-danger',
      successClass: 'has-succes',
      errorTextParent: 'input-wrapper',
      errorTextClass: 'text-help'
    };
    const formElement = ref(null);
    let pristine;

    const initPristine = () => {
      nextTick(() => {
        if (formElement.value) {
          pristine = new Pristine(formElement.value, defaultConfig);
        }
      });
    };

    const save = () => {
      console.log("asdfas")
      if (formifyClient()) {
        emit('save', widthModel.value, heightModel.value);
      }
    };

    function closeModalFromBackground(event) {
      if (event.target.classList.contains('modal')) {
        emit('closeModal');
      }
    }

    function closeModal() {
      emit('closeModal');
    }

    function createFloorplan() {

      const realWidth = ref();
      const realHeight = ref();

      if (shapeIndex.value == 0 || shapeIndex.value == 1) {
        if (dimensionIndex.value == 0) {
          realWidth.value = 1000;
          realHeight.value = 1000;
        } else if( dimensionIndex.value == 1) {
          realWidth.value = 2000;
          realHeight.value = 2000;
        } else {
          realWidth.value = 3000;
          realHeight.value = 3000;
        }
      }
      else if (shapeIndex.value == 2) {
        if (dimensionIndex.value == 0) {
          realWidth.value = 1500;
          realHeight.value = 750;
        } else if( dimensionIndex.value == 1) {
          realWidth.value = 2250;
          realHeight.value = 1125;
        } else {
          realWidth.value = 3000;
          realHeight.value = 1500;
        }
      }
      emit('createFloorplan', shapeIndex, realWidth, realHeight);
      emit('closeModal');
    }

    const formifyClient = () => {
      if (pristine) {
        const valid = pristine.validate();
        if (valid) {
          pristine.reset();
          return true;

        }
      }
      return false;
    };

    onMounted(async () => {
      initPristine();

      loading.value = false;
    });

    return {
      loading,
      shapeIndex,
      dimensionIndex,
      widthModel,
      heightModel,
      formElement,
      createFloorplan,
      save,
      closeModalFromBackground,
      closeModal
    }
  }
}
</script>