<template>
  <div class="module card-border" @click="redirectToContract">
    <div class="row align-x-left align-y-center">
      <span class="material-symbols-rounded fill main gradient orange">
        folder
      </span>
      <div class="column">
        <h3>Documente</h3>
        <h2 class="gradient">Contract<br>prestari servicii</h2>
      </div>
    </div>

  </div>
</template>
<script>
import { ref } from 'vue';

export default {
  name: 'Contract-Card',
  props: {
    contractNumber: {
      type: String,
      required: true
    },
    contractUrl: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const number = ref(props.contractNumber);
    const url = ref(props.contractUrl);

    const redirectToContract = () => {
      window.location.href = url.value;
    };

    return {
      number,
      redirectToContract
    };
  }
}
</script>