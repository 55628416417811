<template>
  <div class="wrapper-content memories-collection admin">
    <edit-memories-collection-modal :showEditMemoriesCollectionModal="showEditMemoriesCollectionModal"
      v-if="showEditMemoriesCollectionModal" :offerId="id" :galleryName="gallery.name"
      :coverPhotoUrl="gallery.coverPhotoUrl" @closeModal="closeEditMemoriesCollection" />

    <share-link-modal :showShareLinkModal="seeShareLinkModal" v-if="this.seeShareLinkModal" :offerId="id"
      @closeModal="closeShareLinkModal" />

    <payment-benefits-modal :showPaymentBenefitsModal="seePaymentBenefitsModal" v-if="this.seePaymentBenefitsModal"
      :offerId="offerId" @closeModal="closePaymentBenefitsModal()" />

    <div class="header space-between">
      <div class="vertical-align gap-10 splash-rounded splash-padding"
        @click="this.$router.push('/memoriesCollections')">
        <span class="material-symbols-rounded"> arrow_back </span>
        <h1>{{ gallery.name }}</h1>
      </div>
      <div class="actions">
        <div class="header-button primary" @click="showPaymentBenefitsModal">
          <div class="highlight">
            <span class="material-symbols-rounded"> credit_card </span>
          </div>
          <div class="text">Plată</div>
        </div>
        <div class="header-button primary" @click="showEditMemoriesCollection">
          <div class="highlight">
            <span class="material-symbols-rounded"> edit </span>
          </div>
          <div class="text">Editează</div>
        </div>
        <div class="header-button primary" @click="showSeeShareLinkModal">
          <div class="highlight">
            <span class="material-symbols-rounded"> share </span>
          </div>
          <div class="text">Obține linkul</div>
        </div>
        <div class="header-button secondary" @click="getMediaToEmail()">
          <div class="highlight">
            <span class="material-symbols-rounded" v-if="!loadingDownload"> download </span>
            <vLottiePlayer name="lottie" class="lottie" loop
              :animationData="require('@/assets/lottie/simpleDotsLoader.json')" v-else />
          </div>
          <div class="text">Descarcă fotografii</div>
        </div>
      </div>
    </div>
    <div class="content-loading" v-if="loadingCollections">
      <vLottiePlayer name="lottie" class="lottie" loop
        :animationData="require('@/assets/lottie/simpleDotsLoader.json')" />
    </div>
    <div class="content" v-else>
      <div v-if="uploaders.length > 0">
        <div class="wrapper-uploaded">
          <div v-for="(uploader, index) in uploaders" :key="index" class="uploaded-card">
            <div v-if="uploader.mediaItems.length > 0">
              <div class="stackone-container">
                <div class="stackone" :style="{
                  backgroundImage: `url(${uploader.mediaItems[0].urlThumbnail})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  cursor: 'pointer',
                }" @click="showImage(0, uploader)" :alt="'First Image'"></div>
              </div>

              <h2 class="person-title">{{ uploader.name }}.</h2>
              <h3 v-if="uploader.mediaItems.length > 1" class="person-image-count">
                {{ uploader.mediaItems.length }} fisiere
              </h3>
              <h3 v-else class="person-image-count">
                {{ uploader.mediaItems.length }} fisier
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="empty-state">
        <img src="@/assets/img/emptyStates/emptyGallery.svg" alt="" />
        <div class="text-content">
          <h2>Nimeni nu a incarcat nici o fotografie</h2>
          <p>Fotografiile incarcate de alte persoane vor aparea aici</p>
        </div>

      </div>

      <LightBox ref="lightboxRef" @remove="removeItem" :loadingItemAction="loadingItemAction" :media="allMedia"
        :show-thumbs="false" :show-caption="true" :interface-hide-time="100000" :autoplay="false" :isClient="false">
        <template v-slot:customCaption="slotProps">
          <div class="caption">
            <div class="captionUser">
              {{ slotProps.currentMedia.name }}
            </div>
            <div class="captionContent">
              {{ slotProps.currentMedia.caption }}
            </div>
          </div>
        </template>
      </LightBox>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from "vue";

import EditMemoriesCollectionModal from "@/views/memoriesCollection/components/EditMemoriesCollectionModal.vue";
import ShareLinkModal from "@/views/memoriesCollection/components/ShareLinkModal.vue";
import LightBox from "@/components/Lightbox/Lightbox.vue";
import videoThumb1 from "@/assets/img/videoThumbnails/videoPlaceholder-1.jpg";
import videoThumb2 from "@/assets/img/videoThumbnails/videoPlaceholder-2.jpg";
import videoThumb3 from "@/assets/img/videoThumbnails/videoPlaceholder-3.jpg";
import videoThumb4 from "@/assets/img/videoThumbnails/videoPlaceholder-4.jpg";
import videoThumb5 from "@/assets/img/videoThumbnails/videoPlaceholder-5.jpg";
import { useRoute } from "vue-router";
import { useAuth0 } from "@auth0/auth0-vue";
import { useShare } from "@vueuse/core";

import { notify } from "@kyvg/vue3-notification";
import PaymentBenefitsModal from "@/views/memoriesCollection/components/PaymentBenefitsModal.vue";

import {
  getMediaUploaders,
  getGallery,
  deleteMediaItem,
  getMediaZip,
} from "@/api";

export default {
  name: "memories-collection",
  components: {
    EditMemoriesCollectionModal,
    LightBox,
    ShareLinkModal,
    PaymentBenefitsModal,
  },
  setup() {
    const { share } = useShare();
    const route = useRoute();
    const { getAccessTokenSilently } = useAuth0();

    const videoThumbImageList = [
      videoThumb1,
      videoThumb2,
      videoThumb3,
      videoThumb4,
      videoThumb5,
    ];
    const videoThumbSrcList = ref([]);
    const id = ref("");
    const label = ref("test");
    const url = ref("");
    const isGalleryDisplayed = ref(false);
    const showIndex = ref(null);
    const name = ref("");
    const allMedia = ref([]);
    const uploaders = ref([]);
    const loadingItemAction = ref(false);
    const gallery = ref({});

    const pageVisited = ref(false);
    const extensionsVideo = [
      ".mp4",
      ".avi",
      ".mov",
      ".wmv",
      ".flv",
      ".mkv",
      ".hevc",
    ];
    const lightboxRef = ref(null);
    const showEditMemoriesCollectionModal = ref(false);
    const loadingCollections = ref(true);
    const loadingDownload = ref(false)

    const baseLink = ref(process.env.VUE_APP_eventApp_url);

    const isMobile = () => {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    };

    const seeShareLinkModal = ref(false);

    const showSeeShareLinkModal = () => {
      if (isMobile()) {
        share({
          title: "Memoriesc Collection la eveniment!",
          text: "Link-ul catre platforma în care poți încărca fotografiile:",
          url: baseLink.value + "/memoriesCollection/" + id.value,
        });
      } else {
        seeShareLinkModal.value = true;
      }
    };

    const closeShareLinkModal = () => {
      seeShareLinkModal.value = false;
    };
    const seePaymentBenefitsModal = ref(false);

    const showPaymentBenefitsModal = () => {
      seePaymentBenefitsModal.value = true;
    };

    const closePaymentBenefitsModal = () => {
      seePaymentBenefitsModal.value = false;
    };

    const getRandomNumber = () => {
      const random = Math.random();
      const randomNumber = Math.floor(random * 4);
      return randomNumber;
    };

    const isVideo = (item) => {
      return extensionsVideo.includes(
        item.url.substring(item.url.lastIndexOf(".")).toLowerCase()
      );
    };

    const showImage = (index, uploader) => {
      const mediaItemsBeforeIndex = getMediaItemsBeforeIndex(uploader);
      lightboxRef.value.showImage(mediaItemsBeforeIndex, allMedia.value);
    };

    const getMediaItemsBeforeIndex = (uploader) => {
      const uploaderIndex = uploaders.value.indexOf(uploader);
      const uploadersBeforeIndex = uploaders.value.slice(0, uploaderIndex);
      return uploadersBeforeIndex.reduce(
        (total, uploader) => total + uploader.mediaItems.length,
        0
      );
    };

    const checkImages = async () => {
      try {
        uploaders.value = await getMediaUploaders(id.value);

        allMedia.value = uploaders.value.flatMap((uploader) =>
          uploader.mediaItems.map((item) => createMediaItem(uploader, item))
        );

        isGalleryDisplayed.value = uploaders.value.length > 0;

        loadingCollections.value = false;
      } catch (error) {
        notify({
          type: "error",
          title: "Eroare",
          text: "Nu am putut genera imaginile.",
        });
      }
    };

    const createMediaItem = (uploader, item) => {
      if (isVideo(item)) {
        item.urlThumbnail = videoThumbSrcList.value[getRandomNumber()];
        let videoType = getVideoType(item.url);
        return {
          sources: [
            {
              src: item.url,
              type: `video/${videoType}`,
              caption: uploader.message,
            },
          ],
          thumb: videoThumbSrcList.value[getRandomNumber()],
          width: 400,
          height: 600,
          type: "video",
          caption: uploader.message,
          name: uploader.name,
        };
      } else {
        item = reactive(item);
        return {
          src: item.url,
          thumb: item.urlThumbnail,
          type: "image",
          name: uploader.name,
          caption: uploader.message,
        };
      }
    };

    const getVideoType = (url) => {
      let videoType = url.substring(url.lastIndexOf(".") + 1);
      return videoType.toLowerCase() === "mov" ? "mp4" : videoType;
    };

    const removeItem = async (index) => {
      //TODO: start loader deleting item
      loadingItemAction.value = true;

      const token = await getAccessTokenSilently();
      const itemToRemove = allMedia.value[index];
      const itemToRemoveUrl =
        itemToRemove.type === "video"
          ? itemToRemove.sources[0].src
          : itemToRemove.src;

      await deleteMediaItem(token, id.value, { ItemUrl: itemToRemoveUrl }).then(
        (response) => {
          if (response) {
            allMedia.value.splice(index, 1);

            uploaders.value.forEach((uploader) => {
              const index = uploader.mediaItems.findIndex(
                (item) => item.url === itemToRemoveUrl
              );
              if (index !== -1) {
                uploader.mediaItems.splice(index, 1);
              }
            });
          }

          loadingItemAction.value = false;
        }
      );
    };

    const loadGallery = async () => {
      try {
        gallery.value = await getGallery(id.value);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const getMediaToEmail = async () => {
      loadingDownload.value = true;
      try {
        const token = await getAccessTokenSilently();
        await getMediaZip(token, id.value).then(() => {
          notify({
            type: "success",
            title: "Linkul a fost trimis",
            text: "Fotografiile au fost comprimate și trimise pe email!",
            duration: 5000,
          });
          loadingDownload.value = false
        });
      } catch (error) {
        console.error("Error:", error);
      }
    };

    // Mounted
    onMounted(async () => {
      id.value = route.params.offerId;
      checkVisitedPage();
      await fetchVideoThumbnails();
      loadGallery();
      localStorage.setItem("visitedMemoryCollectionPage", "true");
    });

    const checkVisitedPage = () => {
      const visitedPage = localStorage.getItem("visitedMemoryCollectionPage");
      if (visitedPage) {
        pageVisited.value = true;
      }
    };

    const fetchVideoThumbnails = async () => {
      try {
        const videoThumbs = await Promise.all(
          videoThumbImageList.map(fetchThumbnail)
        );
        videoThumbSrcList.value = videoThumbs;
        checkImages();
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    const fetchThumbnail = (thumb) =>
      fetch(thumb)
        .then((response) => response.blob())
        .then((blob) => URL.createObjectURL(blob));

    const openDrawer = () => {
      document.querySelector(".drawer-wrapper").classList.add("open");
    };

    const showEditMemoriesCollection = () => {
      showEditMemoriesCollectionModal.value = true;
    };

    const closeEditMemoriesCollection = (event) => {
      if (event) {
        if (event.target.classList.contains("modal")) {
          showEditMemoriesCollectionModal.value = false;
        }
      } else {
        showEditMemoriesCollectionModal.value = false;
        loadGallery();
      }
    };

    return {
      loadingCollections,
      seeShareLinkModal,
      closeShareLinkModal,
      showSeeShareLinkModal,
      showEditMemoriesCollectionModal,
      openDrawer,
      showEditMemoriesCollection,
      closeEditMemoriesCollection,
      removeItem,
      videoThumbImageList,
      gallery,
      videoThumbSrcList,
      id,
      label,
      url,
      showIndex,
      name,
      uploaders,
      extensionsVideo,
      lightboxRef,
      getRandomNumber,
      isVideo,
      showImage,
      allMedia,
      loadingItemAction,
      getMediaToEmail,
      seePaymentBenefitsModal,
      showPaymentBenefitsModal,
      closePaymentBenefitsModal,
      loadingDownload
    };
  },
};
</script>
