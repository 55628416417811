import { useAuth0 } from '@auth0/auth0-vue';

const authGuard = async (to, from, next) => {
    const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

    // Wait for the Auth0 client to be ready
    while (isLoading.value) {
        await new Promise(resolve => setTimeout(resolve, 100));
    }

    if (isAuthenticated.value) {
        // If the user is authenticated, proceed to the route
        return next();
    } else {
        // If the user is not authenticated, redirect to the login route
        await loginWithRedirect({ appState: { targetUrl: to.fullPath } });
    }
};

export default authGuard;