const themeHelper = {
  selectTheme(themeId) {
    if (themeId === 'minimalistShades') {
      console.log(minimalistShades);
      return minimalistShades;
    }
    else if (themeId === 'oceansAway') {
      console.log(oceansAway);
      return oceansAway;
    }
    else if (themeId === 'babyClouds') {
      console.log(babyClouds);
      return babyClouds;
    }
    else if (themeId === 'openDay') {
      return openDay;
    }
  }
};

export default themeHelper;

const minimalistShades = {
  data: {
    invitationType: "Card",
    themeStyle: 0,
    themeColors: [
      ["#fff", "#fff", "#fff", "#ffffff", "#D8B06E", "#D8B06E", "rgba(0, 0, 0, 0.3)"],
      ["#000", "#222", "#222", "#d6d6d6", "#5d5d5d", "#282828", "rgba(255, 255, 255, 0.6)"],
      ["#edc4bc", " #edc4bc", " #edc4bc", "#ffffff", "#d1aea7", "#d1aea7", "rgba(36, 3, 11, 0.4)"]
    ],
    location: "46.0022136,25.0730091",
    locationName: "'Barn House'",
    fonts: ["MADE Mirage", "Montserrat"],
    backgroundImage:
      "https://images.unsplash.com/photo-1563808599481-34a342e44508?q=80&w=2000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    backgroundColorOverlay: null
  },
  elements: [
    {
      type: "textbox",
      text: "JOIN US FOR\nTHE WEDDING OF",
      width: 700,
      left: 50,
      top: 425,
      fontFamily: "Montserrat",
      fontSize: 32,
      lineHeight: 1.1,
      textAlign: 'left'
    },
    {
      type: "rect",
      left: 50,
      top: 525,
      width: 700,
      height: 2,
    },
    {
      type: "textbox",
      text: "Olivia Wilson\n& Jackson Smith\nSept. 15, 4pm",
      width: 700,
      left: 50,
      top: 550,
      fontFamily: "MADE Mirage",
      fontWeight: "300",
      fontSize: 96,
      lineHeight: 1,
      letterSpacing: "-0.5%",
      textAlign: 'left'
    },
    {
      type: "rect",
      left: 50,
      top: 900,
      width: 700,
      height: 2,
    },
    {
      type: "textbox",
      text: "SHADOW HILLS OASIS\nPALM DESERT",
      width: 700,
      left: 50,
      top: 925,
      fontFamily: "Montserrat",
      fontSize: 32,
      lineHeight: 1.1,
      textAlign: 'left'
    },
  ],
};

const oceansAway = {
  data: {
    invitationType: "Card",
    themeStyle: 0,
    themeColors: [
      ["#4D4425", "#4D4425", "#fff", "#ffffff", "#D8B06E", "#D8B06E", "rgba(0, 0, 0, 0.3)"],
      ["#000", "#222", "#222", "#d6d6d6", "#5d5d5d", "#282828", "rgba(255, 255, 255, 0.6)"],
      ["#edc4bc", " #edc4bc", " #edc4bc", "#ffffff", "#d1aea7", "#d1aea7", "rgba(36, 3, 11, 0.4)"]
    ],
    location: "46.0022136,25.0730091",
    locationName: "'Barn House'",
    fonts: ["MADE Mirage", "Montserrat"],
    backgroundImage: "https://i.postimg.cc/xTg9kM2w/Ami-Invitation.png",
    backgroundColorOverlay: null,
    irregularShape: false,
  },
  elements: [
    {
      type: "textbox",
      text: "noi,",
      width: 39,
      left: 700,
      top: 454,
      fontFamily: "EyesomeScript",
      fontSize: 32,
      lineHeight: 1.1,
      textAlign: 'right'
    },
    {
      type: "textbox",
      text: "Ami",
      width: 142,
      left: 529,
      top: 492,
      fontFamily: "EyesomeScript",
      fontSize: 77,
      lineHeight: 1.1,
      textAlign: 'left'
    },
    {
      type: "textbox",
      text: "și",
      width: 39,
      left: 595,
      top: 575,
      fontFamily: "Gelica",
      fontSize: 20,
      lineHeight: 1.1,
      textAlign: 'left'
    },
    {
      type: "textbox",
      text: "Alex",
      width: 135,
      left: 600,
      top: 560,
      fontFamily: "EyesomeScript",
      fontSize: 77,
      lineHeight: 1.1,
      textAlign: 'left'
    },
    {
      type: "textbox",
      text: "AVEM PLĂCEREA DE A VĂ INVITA\nSĂ NE FIȚI ALĂTURI ÎN ZIUA\nCUNUNIEI NOASTRE CIVILE",
      width: 294,
      left: 500,
      top: 700,
      fontFamily: "Gelica",
      fontSize: 16,
      lineHeight: 1.1,
      textAlign: 'right'
    },
    {
      type: "textbox",
      text: "06 SEPTEMBRIE 2024",
      width: 294,
      left: 545,
      top: 792,
      fontFamily: "Gelica",
      fontSize: 20,
      lineHeight: 1.1,
      textAlign: 'right'
    },
    {
      type: "textbox",
      text: "CUNUNIA CIVILĂ - 18:00\nPETRECERE - 19:00",
      width: 294,
      left: 570,
      top: 832,
      fontFamily: "Gelica",
      fontSize: 16,
      lineHeight: 1.1,
      textAlign: 'right'
    },
    {
      type: "textbox",
      text: "LOCATIE - SNAGOV CLUB",
      width: 294,
      left: 560,
      top: 904,
      fontFamily: "Gelica",
      fontSize: 16,
      lineHeight: 1.1,
      textAlign: 'right'
    },
    {
      type: "textbox",
      text: "Va asteptam cu drag!",
      width: 294,
      left: 490,
      top: 957,
      fontFamily: "EyesomeScript",
      fontSize: 32,
      lineHeight: 1.1,
      textAlign: 'right'
    },
  ],
};

const babyClouds = {
  data: {
    invitationType: "Card",
    themeStyle: 0,
    themeColors: [
      ["#627674", "#627674", "#fff", "#ffffff", "#D8B06E", "#D8B06E", "rgba(0, 0, 0, 0.3)"],
    ],
    location: "44.8661943,24.8449418",
    locationName: "'Biserica Sfântul Dumitru'",
    fonts: ["Playwrite US Trad", "Gelica"],
    backgroundImage: "https://i.ibb.co/RhXJ2nQ/baby-Clouds-Background.jpg",
    backgroundColorOverlay: null,
    irregularShape: false,
  },
  elements: [
    {
      type: "textbox",
      text: "Mă numesc",
      left: 326,
      top: 434,
      fontFamily: "Playwrite US Trad",
      fontSize: 22,
      lineHeight: 1.1,
      textAlign: 'center'
    },
    {
      type: "textbox",
      text: "EDWIN WONGWANICH",
      left: 208,
      top: 468,
      fontFamily: "Gelia",
      fontSize: 28,
      lineHeight: 1.1,
      textAlign: 'center',
      charSpacing: 200,

    },
    {
      type: "textbox",
      text: "iar alături de părinții",
      left: 272,
      top: 516,
      fontFamily: "Playwrite US Trad",
      fontSize: 22,
      lineHeight: 1.1,
      textAlign: 'center'
    },
    {
      type: "textbox",
      text: "MUTTAMAS ȘI\nEDUARD BULF",
      left: 311,
      top: 552,
      fontFamily: "Gelica",
      fontSize: 20,
      lineHeight: 1.1,
      textAlign: 'center',
      charSpacing: 200
    },

    {
      type: "textbox",
      text: "și împreună cu nașul",
      left: 275,
      top: 616,
      fontFamily: "Playwrite US Trad",
      fontSize: 22,
      lineHeight: 1.1,
      textAlign: 'center'
    },
    {
      type: "textbox",
      text: "DAN CĂPĂȚÎNĂ",
      left: 306,
      top: 652,
      fontFamily: "Gelica",
      fontSize: 20,
      lineHeight: 1.1,
      textAlign: 'center',
      textTransform: 'uppercase',
      charSpacing: 200
    },

    {
      type: "textbox",
      text: "Vă invităm la botezul meu pe 24 octombrie 2024!",
      left: 116,
      top: 697,
      fontFamily: "Playwrite US Trad",
      fontSize: 22,
      lineHeight: 1.1,
      textAlign: 'center'
    },
    {
      type: "textbox",
      text: "Taina botezului va fi săvârșită la",
      left: 213,
      top: 766,
      fontFamily: "Playwrite US Trad",
      fontSize: 22,
      lineHeight: 1.1,
      textAlign: 'center'
    },
    {
      type: "textbox",
      text: "BISERICA SFÂNTUL DUMITRU - ORA 17:00",
      left: 110,
      top: 802,
      fontFamily: "Gelica",
      fontSize: 22,
      lineHeight: 1.1,
      textAlign: 'center',
      charSpacing: 200
    },
    {
      type: "textbox",
      text: "GĂVANA, STR. MITROPOLIT ANTIM IVIREANU",
      left: 150,
      top: 830,
      fontFamily: "Gelica",
      fontSize: 18,
      lineHeight: 1.1,
      textAlign: 'center',
      charSpacing: 200
    },
    {
      type: "textbox",
      text: "Petrecerea se va ține la",
      left: 272,
      top: 880,
      fontFamily: "Playwrite US Trad",
      fontSize: 22,
      lineHeight: 1.1,
      textAlign: 'center'
    },
    {
      type: "textbox",
      text: "BOHEME NOBLESSE - ORA 19:00",
      left: 175,
      top: 916,
      fontFamily: "Gelica",
      fontSize: 22,
      lineHeight: 1.1,
      textAlign: 'center',
      charSpacing: 200
    },
    {
      type: "textbox",
      text: "Vă rugăm să ne confirmați prezența pâna la\ndata de 17 octombrie 2024",
      left: 160,
      top: 954,
      fontFamily: "Playwrite US Trad",
      fontSize: 20,
      lineHeight: 1.4,
      textAlign: 'center'
    },
    {
      type: "textbox",
      text: "VĂ AȘTEPTĂM CU DRAG!",
      left: 262,
      top: 1032,
      fontFamily: "Gelica",
      fontSize: 20,
      lineHeight: 1.4,
      textAlign: 'center',
      charSpacing: 200
    },
  ],
};

const openDay = {
  data: {
    invitationType: "Card",
    themeStyle: 0,
    themeColors: [
      ["#F20000", "#F20000", "#fff", "#ffffff", "#D8B06E", "#D8B06E", "rgba(0, 0, 0, 0.3)"],
    ],
    location: "44.8661943,24.8449418",
    locationName: "'Biserica Sfântul Dumitru'",
    fonts: ["Playwrite US Trad", "Gelica"],
    backgroundImage: "https://i.ibb.co/KsTj6DP/open-Day-Bg.jpg",
    backgroundColorOverlay: null,
    irregularShape: false,
  },
  elements: [
    {
      type: "textbox",
      elementRole: "primary-text",
      text: "Open Day",
      left: 79.67,
      top: 464.67,
      fontFamily: "MonteCarlo",
      fontSize: 185.73,
      lineHeight: 1.1,
      textAlign: 'center',
      fontWeight: 300
    },
    {
      type: "textbox",
      elementRole: "secondary-text",
      text: "Join us for a cocktail evening in celebration of the\nlaunch of our new boutique!",
      left: 145,
      top: 708,
      fontFamily: "Satoshi-Variable",
      fontSize: 24,
      lineHeight: 1.1,
      textAlign: 'center',
      fontWeight: 300
    },
    {
      type: "textbox",
      elementRole: "secondary-text",
      text: "Începând cu",
      left: 115,
      top: 887,
      fontFamily: "Satoshi-Variable",
      fontSize: 18,
      lineHeight: 1.1,
      textAlign: 'center',
      fontWeight: 300
    },
    {
      type: "textbox",
      elementRole: "secondary-text",
      text: "ORA 11:00",
      left: 110,
      top: 914,
      fontFamily: "Satoshi-Variable",
      fontSize: 24,
      lineHeight: 1.1,
      textAlign: 'center',
      fontWeight: 300
    },
    {
      type: "textbox",
      elementRole: "primary-text",
      text: "31",
      left: 356,
      top: 802,
      fontFamily: "MonteCarlo",
      fontSize: 100,
      lineHeight: 1.1,
      textAlign: 'center',
      fontWeight: 300
    },
    {
      type: "textbox",
      elementRole: "secondary-text",
      text: "OCTOMBRIE",
      left: 332,
      top: 918,
      fontFamily: "Satoshi-Variable",
      fontSize: 22,
      lineHeight: 1.1,
      textAlign: 'center',
      fontWeight: 300,
      charSpacing: 100
    },
    {
      type: "textbox",
      elementRole: "secondary-text",
      text: "Piscani, Dârmănești",
      left: 545,
      top: 889,
      fontFamily: "Satoshi-Variable",
      fontSize: 22,
      lineHeight: 1.1,
      textAlign: 'center',
      fontWeight: 300
    },
    {
      type: "textbox",
      elementRole: "secondary-text",
      text: "Strada Principală 70",
      left: 555,
      top: 919,
      fontFamily: "Satoshi-Variable",
      fontSize: 20,
      lineHeight: 1.1,
      textAlign: 'center',
      fontWeight: 300
    },
    {
      type: "textbox",
      elementRole: "secondary-text",
      text: "Vă rugăm să ne confirmați prezențva pâna\nla data de 24 octombrie 2024",
      left: 215,
      top: 993,
      fontFamily: "Satoshi-Variable",
      fontSize: 20,
      lineHeight: 1.1,
      textAlign: 'center',
      fontWeight: 300
    },
  ],
};

