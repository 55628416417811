<template>
  <div
    class="modal bottom-sheet-on-mobile"
    id="select-event-modal"
    :class="{ active: showSelectEventModal }"
    @click="closeModal()"
  >
    <add-new-event
      :showAddNewEvent="showAddNewEventModal"
      v-if="this.showAddNewEventModal"
      @closeModal="closeAddNewEventModal()"
    />
    <div class="modal-content">
      <h1>Alege evenimentul tău</h1>
      <div v-for="offer in offers" :key="offer.offerId" @click="selectOffer(offer.offerId)" class="event-small" :class="{'selected' : offer.offerId === selectedOffer}">
        <h1 class="dash-circle">{{ offer.eventTypeString }}</h1>
        <div class="event-details">
          <p class="subtitle">Data și ora</p>
          <p class="title">
            {{ offer.eventDateString }}
          </p>
        </div>
      </div>
      <div class="add-event-small">
     
        <h1 class="dash-circle" @click="showAddEvent">Adauga eveniment</h1>
        <span class="material-symbols-rounded"> add_ad </span>
      </div>
      <div class="loading" v-if="loading">
        <vLottiePlayer
          name="lottie"
          class="lottie"
          loop
          :animationData="require('@/assets/lottie/simpleDotsLoader.json')"
        />
      </div>
      <button v-else @click="enableCollection" class="primary small">
        Continuă
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { enableMemoriesCollection } from "@/api";
import { useAuth0 } from "@auth0/auth0-vue";
import { useRouter } from "vue-router";

import AddNewEvent from "@/components/AddEventModal.vue";

export default {
  name: "SelectEventModal",
  components: {
    AddNewEvent,
    
  },
  props: {
    showSelectEventModal: Boolean,
    offers: Array,
  },
  emits: ["closeModal"],
  setup(props, { emit }) {
    const router = useRouter();
    const { getAccessTokenSilently } = useAuth0();

    const selectedOffer = ref("");
    const loading = ref(false);
    const showAddNewEventModal = ref(false);

    const selectOffer = (offerId) => {
      selectedOffer.value = offerId;
    };

    const enableCollection = async () => {
      loading.value = true;
      const token = await getAccessTokenSilently();

      const result = await enableMemoriesCollection(token, selectedOffer.value);
      loading.value = false;

      if (result !== false) {
        router.push(`${selectedOffer.value}`);
      }
    };

    const closeModal = () => {
      emit("closeModal");
    };

    const showAddEvent = () => {
      showAddNewEventModal.value = true;
    };

    const closeAddNewEventModal = () => {
      showAddNewEventModal.value = false;
    };

    return {
      selectedOffer,
      loading,
      showAddNewEventModal,
      enableCollection,
      selectOffer,
      closeModal,
      closeAddNewEventModal,
      showAddEvent,
    };
  },
};
</script>
