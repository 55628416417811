// modal.js
import { reactive } from 'vue';

export const modalState = reactive({
  display: false,
  status: false,
  title: null,
  subtitle: null,
  buttonText: null,
});

export function displayModal(display, status, title, subtitle, buttonText, ) {
  modalState.display = display;
  modalState.status = status;
  modalState.title = title
  modalState.subtitle = subtitle
  modalState.buttonText = buttonText

  // Update other properties based on the type
  // You can implement the logic similar to what you have in your original code
}