<template>
    <div class="wrapper-content invitations">
        <div class="header space-between">
            <div class="vertical-align gap-5 splash-rounded splash-padding" @click="this.$router.go(-1)">
                <span class="material-symbols-rounded">
                    arrow_back
                </span>
                <h1>Your invitations</h1>
            </div>
            <div class="actions">
                <div class="header-button primary" @click="redirectToNewInvitation()">
                    <div class="highlight">
                        <span class="material-symbols-rounded">
                            library_add
                        </span>
                    </div>
                    <div class="text">Invitație nouă</div>
                </div>
            </div>
        </div>
        <div class="content-loading" v-if="loading">
            <vLottiePlayer name="lottie" class="lottie" loop
                :animationData="require('@/assets/lottie/simpleDotsLoader.json')" />
        </div>
        <div class="content" v-else>
            <div class="card module">
                <h1>Invitațiile tale</h1>
                <table-component>
                    <tr v-for="invitation in invitations" :key="invitation.id"
                        @click="redirectToInvitation(invitation.id)">
                        <td class="name">
                            <img class="preview-invitation" :src="getInvitationModelImage(invitation.templateName)" />
                            {{ invitation.templateName }}
                        </td>
                        <td class="model" v-if="this.isDesktop()">
                            <div class="vertical">{{ invitation.templateName }}<span>{{ invitation.eventType
                                    }}</span></div>
                        </td>
                        <td v-if="this.isDesktop()">{{ formatDate(invitation.deadline) }}</td>
                        <td class="status">
                            <p v-if="invitation.published" :class="{ 'published': invitation.published }">Publicat</p>
                            <p v-else>Nepublicat</p>
                        </td>
                        <td class="actions">
                            <div class="horizontal" v-if="this.isDesktop()">
                                <div class="action" @click.stop="redirectToInvitationSettings(invitation.id)">
                                    <span class="material-symbols-rounded">
                                        settings
                                    </span>
                                </div>
                                <div class="action" @click.stop="duplicate(invitation.id)">
                                    <span class="material-symbols-rounded">
                                        file_copy
                                    </span>
                                </div>
                                <div class="action" @click.stop="remove(invitation.id)">
                                    <span class="material-symbols-rounded">
                                        delete
                                    </span>
                                </div>
                            </div>
                            <div v-else>
                                <dropdown-menu direction="right" @click.stop>
                                    <template #trigger>
                                        <div class="action">
                                            <span class="material-symbols-rounded">
                                                grid_view
                                            </span>
                                        </div>
                                    </template>

                                    <template #body>
                                        <div class="dropdown-action"
                                            @click.stop="redirectToInvitationSettings(invitation.id)">

                                            <span class="material-symbols-rounded">
                                                settings
                                            </span>
                                            Setări
                                        </div>
                                        <div class="dropdown-action" @click.stop="duplicate(invitation.id)">

                                            <span class="material-symbols-rounded">
                                                file_copy
                                            </span>
                                            Duplică
                                        </div>
                                        <div class="dropdown-action" @click.stop="remove(invitation.id)">

                                            <span class="material-symbols-rounded">
                                                delete
                                            </span>
                                            Șterge
                                        </div>

                                    </template>

                                </dropdown-menu>
                            </div>
                        </td>
                    </tr>
                </table-component>
            </div>

        </div>


    </div>
</template>

<script>

import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import { getInvitations, removeInvitation, duplicateInvitation } from '@/api';
import TableComponent from "@/views/invitations/components/TableComponent.vue";
import DropdownMenu from 'v-dropdown-menu'
import { format, parseISO } from 'date-fns';

import { useAuth0 } from '@auth0/auth0-vue';
import { notify } from '@kyvg/vue3-notification';

export default {
    name: 'My-Invitations',
    metaInfo: {
        title: 'About Us'
    },
    components: {
        TableComponent,
        DropdownMenu,


    },
    methods: {
        isDesktop() {
            const screenWidth = window.innerWidth;
            return screenWidth > 575.98;
        },
        getInvitationModelImage(model) {
            if (model === 'Bohemian' || model === 'Bohemian11') {
                return require('@/assets/img/invitation/mockups/BohemianInvitationMockup.png');

            } else if (model === 'GoldAccents' || model === 'GoldAccents11') {
                return require('@/assets/img/invitation/mockups/GoldenAccentsInvitationMockup.png');
            }
            else if (model === 'GreenishVibes' || model === 'GreenishVibes11') {
                return require('@/assets/img/invitation/mockups/GreenishVibesInvitationMockup.png');
            }
            else {
                return require('@/assets/img/invitation/mockups/MinimalistShadesMockup.png');
            }
        },
    },
    setup() {
        const route = useRoute()
        const router = useRouter();
        const { getAccessTokenSilently } = useAuth0();

        const showSelectEventModal = ref(false);
        const offerId = ref("");
        const invitations = ref([]);
        const loading = ref(true);

        const openDrawer = () => {
            document.querySelector('.drawer-wrapper').classList.add('open');
        };

        const showSelectEvent = () => {
            showSelectEventModal.value = true;
        };

        const redirectToInvitation = (invitationId) => {
            router.push(`/invitation/${offerId.value}/${invitationId}/edit`);
        }

        const redirectToInvitationSettings = (invitationId) => {
            router.push(`/invitation/${offerId.value}/${invitationId}/wizard`);
        }

        const redirectToNewInvitation = () => {
            router.push(`/invitations/${offerId.value}/select`);
        }

        const closeSelectEventModal = (event) => {
            if (event.target.classList.contains('modal')) {
                showSelectEventModal.value = false;
            }
        };

        const formatDate = (date) => {
            return format(parseISO(date), 'dd.MM.yyyy');
        };

        const duplicate = async (invitationId) => {
            loading.value = true

            try {
                const token = await getAccessTokenSilently();

                await duplicateInvitation(token, offerId.value, invitationId);

                await retrieveInvitations();
            } catch (e) {
                notify({
                    type: 'error',
                    title: 'Eroare',
                    text: 'Nu s-a putut duplica invitația'
                });
            }

            loading.value = false
        }

        const remove = async (invitationId) => {
            loading.value = true

            try {
                const token = await getAccessTokenSilently();

                await removeInvitation(token, offerId.value, invitationId);

                await retrieveInvitations();
            } catch (e) {
                notify({
                    type: 'error',
                    title: 'Eroare',
                    text: 'Nu s-a putut șterge invitația'
                });
            }
            loading.value = false
        }

        const retrieveInvitations = async () => {
            invitations.value = await getInvitations(offerId.value);
        }

        onMounted(async () => {
            offerId.value = route.params.offerId;

            await retrieveInvitations();

            loading.value = false;
        });

        return {
            showSelectEventModal,
            invitations,
            loading,
            offerId,
            formatDate,
            redirectToNewInvitation,
            openDrawer,
            showSelectEvent,
            closeSelectEventModal,
            redirectToInvitation,
            redirectToInvitationSettings,
            duplicate,
            remove
        }
    }
};

</script>