<template>
    <div class="modal bottom-sheet-on-mobile" id="payments-benefits-modal" :class="{ active: showPaymentBenefitsModal }"
        @click="closeModalFromBackground">
        <div class="modal-content">
            <div class="modal-header">
                <h1>Deblochează toate beneficiile</h1>
                <span class="close-modal splash-rounded material-symbols-rounded fill" @click="closeModal">
                    close
                </span>
            </div>
            <div class="features">
                <div class="feature">
                    <div class="icon">
                        <span class="material-symbols-rounded">
                            qr_code
                            </span>
                    </div>
                    <div class="text">
                        <h1 class="title">
                            Cod QR pentru mese
                        </h1>
                        <p class="subtitle">
                            Folosește codul QR  <span class="highlight">ready-to-print</span> pentru a printa table cards.
                        </p>
                    </div>
                </div>
            
                <div class="feature">
                    <div class="icon">
                        <span class="material-symbols-rounded">
                            save
                            </span>
                    </div>
                    <div class="text">
                        <h1 class="title">
                            Spațiu de stocare nelimitat 
                        </h1>
                        <p class="subtitle">
                            Invitații tăi pot adăuga oricât de multe fotografii sau videoclipuri iși doresc.
                        </p>
                    </div>
                </div>

                <div class="feature">
                    <div class="icon">
                        <span class="material-symbols-rounded">
                            schedule
                            </span>
                    </div>
                    <div class="text">
                        <h1 class="title">
                            Timp de stocare de 12 luni
                        </h1>
                        <p class="subtitle">
                            Ai suficient timp pentru a vedea amintirile tale tu și invitații tăi.
                        </p>
                    </div>
                </div>
          
            </div>
            <div class="payment-button">
                <p class="disclaimer">
                    Cu doar <span class="highlight">350 Lei</span> ai acces complet la toate beneficiile
                </p>
                <button class="primary small">Mergi către plată</button>
            </div>
           
        </div>
    </div>
</template>

<script>

import { ref } from 'vue';

export default {
    name: 'PaymentBenefitsModal',
    props: {
        showPaymentBenefitsModal: Boolean,
        offerId: String,
    },
    setup(props, { emit }) {

        const invitationLink = ref(null);
        const baseLink = ref(process.env.VUE_APP_eventApp_url);

        function closeModalFromBackground(event) {
            if (event.target.classList.contains('modal')) {
                emit('closeModal');
            }
        }

        function closeModal() {
            emit('closeModal');
        }

        return {
            closeModalFromBackground,
            closeModal,
            invitationLink,
            baseLink
        }
    }
};
</script>