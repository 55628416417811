<template>
  <div class="card module my-privent-card memories-collection-card" :class="[containerClass]">
    <div class="inside">
      <div class="text-content">
        <h2>Checklist</h2>
        <div class="module-button" @click="onClick">
          <span class="material-symbols-rounded fill">
            checklist
          </span>
          <p>Vezi toate<br>momentele</p>
        </div>
      </div>
      <div class="elements-content">
        <div class="detail-content">
          <div class="group">
            <h1>{{checklistItemsCompleted}}/{{checklistItemsTotal}}</h1>
            <h2>Momente<br>complete</h2>
          </div>
        </div>
      </div>
    </div>
    <img class="background-image" :src="resolvedImage" alt="Checklist Background">
  </div>
</template>
<script>
import { computed } from 'vue';

export default {
  name: 'Checklist-Card',
  components: {

  },
  props: {
    isActivated: { type: Boolean },
    onClick: { type: Function },
    checklistItemsCompleted: {type: Number},
    checklistItemsTotal: {type: Number},
    containerClass: {
      type: String,
      default: ''
    },
  },
  setup(props) {
    const resolvedImage = computed(() => {
      return require('@/assets/img/dashboard/' + 'checklistBackground.png');
    });

    return {

      resolvedImage,
    };
  }
}
</script>