<template>
    <div id="form" class="form">
        <div class="userData">
            <div class="input input-wrapper first">
                <input v-model="localName" class="input" type="text" name="clientName" placeholder=" " autocomplete="off"
                    required minlength="3" data-pristine-minlength-message="Introduceti minim 3 caractere"
                    data-pristine-required-message="Câmp obligatoriu" />
                <label class="label" for="name">Numele și prenumele tău*</label>
                <img class="checked" src="@/assets/img/check.svg" alt="" />
                <img class="error" src="@/assets/img/error.svg" alt="" />
            </div>
            <div class="special-message" @click="togglMessage">
                <h2>Vrei sa lasi un mesaj<br />pentru sarbatoriti?</h2>
                <img class="vector" src="@/assets/img/specialMessage.svg" alt="" />
            </div>
            <div class="input textAreaWrapper">
                <textarea v-model="localMessage" class="input" type="text" name="street_address" placeholder=" "
                    autocomplete="off" rows="5" minlength="4"
                    data-pristine-minlength-message="Introduceti minim 4 caractere"
                    data-pristine-required-message="Câmp obligatoriu" />
                <label class="label" for="adress">Mesajul tau pentru sarbatoriti</label>
                <img class="checked" src="@/assets/img/check.svg" alt="" />
                <img class="error" src="@/assets/img/error.svg" alt="" />
            </div>
        </div>

        <div class="terms-wrapper">
            <div class="terms">
                <div class="input">
                    <input id="checkbox" v-model="localTermsAndConditions1" type="checkbox" name="checkbox" required
                        data-pristine-required-message="Trebuie să acceptați aceasta conditie" />
                    <label for="checkbox">
                        <p>
                            Sunt de acord cu incarcarea fotografiilor si cu faptul ca doar
                            sarbatoritii le vor putea sterge
                        </p>
                    </label>
                </div>
                <div class="input last">
                    <input id="checkbox1" v-model="localTermsAndConditions" type="checkbox" name="checkbox1" required
                        data-pristine-required-message="Trebuie să acceptați termenii și condițiile pentru a incarca fotografiile" />
                    <label for="checkbox1">
                        <p>
                            Sunt de acord cu
                            <a href="https://storagedjbooking.blob.core.windows.net/terms-and-conditions/GDPR%20CONTRACT.pdf"
                                target="_blank">termenii si conditiile</a>
                        </p>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { ref, defineComponent, watch } from 'vue';

export default defineComponent({
    name: 'UserForm',
    props: {
        name: String,
        message: String,
        termsAndConditions1: Boolean,
        termsAndConditions: Boolean
    },
    setup(props, { emit }) {
        const localName = ref(props.name);
        const localMessage = ref(props.message);
        const localTermsAndConditions1 = ref(props.termsAndConditions1);
        const localTermsAndConditions = ref(props.termsAndConditions);

        const togglMessage = () => {
            emit('toggleMessage');
        };

        watch(localName, newVal => {
            emit('update:name', newVal);
        });

        watch(localMessage, newVal => {
            emit('update:message', newVal);
        });

        watch(localTermsAndConditions1, newVal => {
            emit('update:termsAndConditions1', newVal);
        });

        watch(localTermsAndConditions, newVal => {
            emit('update:termsAndConditions', newVal);
        });

        return {
            localName,
            localMessage,
            localTermsAndConditions1,
            localTermsAndConditions,
            togglMessage
        };
    }
});
</script>