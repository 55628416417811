<template>
    <div class="card module my-privent-card memories-collection-card" :class="[containerClass]">
      <div class="inside">
        <div class="text-content">
          <h2>Memories Collection</h2>
          <div class="module-button" @click="onClick" :class="{'primary': !isActivated}">
            <span class="material-symbols-rounded fill">
                add_to_photos
            </span>
            <p v-if="isActivated">Vezi toate<br>fotografiile</p>
            <p v-else>Activează modul</p>
          </div>
        </div>
        <div class="elements-content">
            <div class="detail-content" v-if="isActivated">
              <div class="group">
                <h1>{{ memoriesCollectionPhotosNumber }}</h1>
                <h2>fișiere<br>media</h2>
              </div>
            </div>
        </div>
      </div>
      <div class="disclaimer-container">
        <div class="disclaimer">
          <span class="material-symbols-rounded fill">
            new_releases
          </span>
          <p>Testează gratuit</p>
        </div>
      </div>
      <img class="background-image" :src="resolvedImage" alt="Memories Collection Background">
    </div>
  </template>
  <script>
  import { ref, onMounted, computed } from 'vue';

  export default {
    name: 'Memories-Collection-Card',
    props: {
      isActivated: { type: Boolean },
      memoriesCollectionPhotosNumber: {type: Number},
      image: { type: String, default: '' },
      onClick: { type: Function },
      containerClass: {
        type: String,
        default: ''
      },
    },
    computed: {
    },
    setup(props) {
        const resolvedImage = computed(() => {
        if (props.isActivated) {
            return require('@/assets/img/dashboard/' + 'memoriesCollectionBackground.png');
        } else {
            return require('@/assets/img/dashboard/' + 'memoriesCollectionEmptyBackground.png');
        }
        });

    return {
      resolvedImage,
    };
  }
  }   
  </script>