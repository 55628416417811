<template>
  <div class="wrapper-content invitations select-invitations">
    <div class="header space-between">
      <div class="vertical-align gap-5 splash-rounded splash-padding" @click="this.$router.back()">
        <span class="material-symbols-rounded"> arrow_back </span>
        <h1>Selectează modelul</h1>
      </div>
    </div>
    <div class="content">
      <h2>Invitație pagină</h2>
      <div class="listing page-invitations">
        <div class="card">
          <button @click="openInNewTab('/invitation/demo/BohemianInvitation')" class="primary small demo">
            VEZI DEMO
          </button>
          <div class="image">
            <div class="image-mover" :style="{
              'background-image': `url(${require('/src/assets/img/invitation/previews/bohemianDesktop.jpg')})`
            }" />
          </div>
          <div class="card-content">
            <h3 class="dash-circle">Bohemian</h3>
            <p>Recomandat pentru nunta, cununie civila, botez</p>
            <button class="primary small" @click="createHTMLInvitation('Bohemian11')">
              Selectează modelul
            </button>
          </div>
        </div>
        <div class="card">
          <button @click="openInNewTab('/invitation/demo/GoldAccents')" class="primary small demo">
            VEZI DEMO
          </button>
          <div class="image">
            <div class="image-mover" :style="{
              'background-image': `url(${require('/src/assets/img/invitation/previews/goldAccentsDesktop.jpg')})`
            }" />
          </div>
          <div class="card-content">
            <h3 class="dash-circle">Gold Accents</h3>
            <p>Recomandat pentru nunta, cununie civila, botez</p>
            <button class="primary small" @click="createHTMLInvitation('GoldAccents11')">
              Selectează modelul
            </button>
          </div>
        </div>
        <div class="card">
          <button @click="openInNewTab('/invitation/demo/GreenishVibes')" class="primary small demo">
            VEZI DEMO
          </button>
          <div class="image">
            <div class="image-mover" :style="{
              'background-image': `url(${require('/src/assets/img/invitation/previews/greenishVibesDesktop.jpg')})`
            }" />
          </div>
          <div class="card-content">
            <h3 class="dash-circle">Greenish Vibes</h3>
            <p>Recomandat pentru nunta, cununie civila</p>
            <button class="primary small" @click="createHTMLInvitation('GreenishVibes')">
              Selectează modelul
            </button>
          </div>
        </div>
      </div>
      <h2>Invitație card</h2>
      <div class="listing card-invitations">
        <div class="card" v-for="(cardModel, cardIndex) in cardModels" :key="cardIndex">
          <button @click="openInNewTab('/invitation/demo/WeddingMinimalist')" class="primary small demo">
            VEZI DEMO
          </button>
          <div class="card-content">
            <div class="image">
              <img src="@/assets/img/preview/card/envelopePreview.svg" :style="cardModel.images[cardModel.selectedIndex].brightnessFilter
                " />
              <img :src="cardModel.images[cardModel.selectedIndex].image" class="card-content-preview" />
            </div>
            <div class="colors">
              <div v-for="(color, colorIndex) in cardModel.colors" :key="colorIndex" class="color"
                :style="{ 'background-color': color }" :class="{ selected: colorIndex == cardModel.selectedIndex }"
                @click="
                  changeSelectedIndex(cardModel.name, cardIndex, colorIndex)
                  "></div>
            </div>
            <h3 class="dash-circle">{{ cardModel.displayedName }}</h3>
            <p>{{ cardModel.recommendedText }}</p>
            <button class="primary small" @click="createCardInvitation(cardModel.name)">
              Selectează modelul
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { createEmptyInvitation } from "@/api";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import { useAuth0 } from "@auth0/auth0-vue";

export default {
  name: "SelectInvitations",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { getAccessTokenSilently } = useAuth0();
    const offerId = route.path.split("/")[2];

    // TODO: use creatingInvitation to show a loader when true
    const creatingInvitation = ref(false);

    const openInNewTab = (path) => {
      window.open(path, "_blank");
    };

    const createHTMLInvitation = async (templateName) => {
      creatingInvitation.value = true;

      const token = await getAccessTokenSilently();
      const data = {
        TemplateName: templateName,
      };
      const invitationId = await createEmptyInvitation(token, offerId, data);

      router.push(`/invitation/${offerId}/${invitationId}/edit`);

      creatingInvitation.value = false;
    };

    const createCardInvitation = async (templateName) => {
      creatingInvitation.value = true;

      const token = await getAccessTokenSilently();

      const data = {
        TemplateName: templateName,
        TemplateTheme: selectedTemplateTheme.value,
      };
      const invitationId = await createEmptyInvitation(token, offerId, data);

      router.push(`/invitation/${offerId}/${invitationId}/edit`);
      router.push(`/invitation/${offerId}/${invitationId}/edit`);

      creatingInvitation.value = false;
    };

    const selectedTemplateName = ref("");
    const selectedTemplateTheme = ref(0);

    const cardModels = ref([
      {
        displayedName: 'Minimalist Shades',
        name: 'minimalistShades',
        selectedIndex: 0,
        colors: ['#D8B06E', '#5D5D5D', '#D1AEA7'],
        images: [
          {
            image: require("@/assets/img/preview/card/minimalistShades0.jpg"),
            brightnessFilter:
              'filter: brightness(0) saturate(100%) invert(77%) sepia(43%) saturate(412%) hue-rotate(352deg) brightness(88%) contrast(92%);',
          },
          {
            image: require("@/assets/img/preview/card/minimalistShades1.jpg"),
            brightnessFilter:
              'filter: brightness(0) saturate(100%) invert(9%) sepia(2%) saturate(24%) hue-rotate(321deg) brightness(93%) contrast(82%)',
          },
          {
            image: require("@/assets/img/preview/card/minimalistShades2.jpg"),
            brightnessFilter:
              'filter: brightness(0) saturate(100%) invert(81%) sepia(26%) saturate(258%) hue-rotate(322deg) brightness(87%) contrast(87%);',
          },
        ],
        recommendedText: 'Recomandat pentru nunta, cununie civila',
      },
      {
        displayedName: 'Oceans Away',
        name: 'oceansAway',
        selectedIndex: 0,
        colors: ['#2FABB9'],
        images: [
          {
            image: require("@/assets/img/preview/card/oceansAway.png"),
            brightnessFilter:
              'filter: brightness(0) saturate(100%) invert(87%) sepia(2%) saturate(4155%) hue-rotate(326deg) brightness(111%) contrast(96%);',
          },
        ],
        recommendedText: 'Recomandat pentru nunta, cununie civila',
      },
      {
        displayedName: 'Baby Clouds',
        name: 'babyClouds',
        selectedIndex: 0,
        colors: ['#627674'],
        images: [
          {
            image: require("@/assets/img/preview/card/babyClouds.jpg"),
            brightnessFilter:
              'filter: brightness(0) saturate(100%) invert(86%) sepia(8%) saturate(505%) hue-rotate(126deg) brightness(91%) contrast(88%);',
          },
        ],
        recommendedText: 'Recomandat pentru botez',
      },
      {
        displayedName: 'Open Day',
        name: 'openDay',
        selectedIndex: 0,
        colors: ['#696969'],
        images: [
          {
            image: require("@/assets/img/preview/card/openDay.jpg"),
            brightnessFilter:
              'filter: brightness(0) saturate(100%) invert(43%) sepia(1%) saturate(0%) hue-rotate(12deg) brightness(92%) contrast(87%)',
          },
        ],
        recommendedText: 'Recomandat pentru corporate',
      },
    ])

    const changeSelectedIndex = (themeName, cardIndex, colorIndex) => {
      cardModels.value[cardIndex].selectedIndex = colorIndex;
      selectedTemplateName.value = themeName;
      selectedTemplateTheme.value = colorIndex;
    };

    return {
      creatingInvitation,
      createHTMLInvitation,
      createCardInvitation,
      openInNewTab,
      cardModels,
      changeSelectedIndex,
      selectedTemplateName,
      selectedTemplateTheme,
    };
  },
};
</script>
