<template>
  <div class="wrapper-content timeline">
    <add-task-modal :showAddTaskModal="showAddTaskModal" v-if="showAddTaskModal" @closeModal="closeAddTask"
      @taskAdded="addTaskToCalendar" />

    <custom-event-modal :calendarEvent="selectedEvent" :showEventModal="showEventModal" v-if="showEventModal"
      @closeModal="closeEventModal()" @deleteEvent="removeEvent"></custom-event-modal>

    <div class="header space-between">
      <div class="mobile-only">
        <div class="menu-toggle" @click="openDrawer">
          <span class="material-symbols-rounded">menu</span>
        </div>
      </div>
      <h1>Timeline eveniment</h1>
      <div class="actions"></div>
    </div>
    <div class="content-loading" v-if="loading">
      <vLottiePlayer name="lottie" class="lottie" loop
        :animationData="require('@/assets/lottie/simpleDotsLoader.json')" />
    </div>

    <div class="content" v-else>
      <button class="save-button pulse" @click="showAddTask">
        <span class="material-symbols-rounded"> add_circle </span>Adaugă moment
      </button>
      <div class="view">
        <ScheduleXCalendar :calendar-app="calendar" :custom-components="customComponents">
        </ScheduleXCalendar>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted } from "vue";
import AddTaskModal from "@/views/timeline/components/AddTaskModal.vue";
import { ScheduleXCalendar } from "@schedule-x/vue";
import { createCalendar, viewDay } from "@schedule-x/calendar";
import { createDragAndDropPlugin } from "@schedule-x/drag-and-drop";
import { createEventModalPlugin } from "@schedule-x/event-modal";
import { createResizePlugin } from "@schedule-x/resize";
import { updateTimeline, getTimeline, getClientOffer } from "@/api";
import { useAuth0 } from "@auth0/auth0-vue";
import { useRoute } from "vue-router";

import CustomEventModal from "@/views/timeline/components/CustomEventModal.vue";

import "@schedule-x/theme-default/dist/index.css";
import { notify } from "@kyvg/vue3-notification";

export default {
  name: "MyTimeline",
  components: {
    AddTaskModal,
    ScheduleXCalendar,
    CustomEventModal,
  },
  setup() {
    const { getAccessTokenSilently, user } = useAuth0();
    const route = useRoute();
    const loading = ref(true);

    let offerId = null;
    const calendar = ref(
      createCalendar({
        views: [viewDay],
        datePicker: {
          selectedDate: "2024-04-27",
        },
        defaultView: viewDay.name,
        locale: "ro-RO",
        events: [],
        calendars: {
          event: {
            colorName: "event",
            lightColors: {
              main: "#F47027",
              container: "#F47027",
              onContainer: "#F47027",
            },
            darkColors: {
              main: "#F47027",
              onContainer: "#F47027",
              container: "#F47027",
            },
          },
        },
        plugins: [
          createDragAndDropPlugin(),
          // createEventModalPlugin(),
          createResizePlugin(),
        ],
        callbacks: {
          onEventUpdate(updatedEvent) {
            saveCalendar();
          },
          onEventClick(event) {
            openEventModal(event); // Open modal when event is clicked
          },
        },
        dayBoundaries: {
          start: "07:00",
          end: "06:00",
        },
      })
    );

    const showAddTaskModal = ref(false);
    const showEventModal = ref(false); // State for the custom event modal
    const selectedEvent = ref(null); // Store the selected event data
    const offer = ref(null);

    const openEventModal = (event) => {
      selectedEvent.value = event; // Store the selected event data
      showEventModal.value = true; // Open the custom event modal
    };

    const closeEventModal = () => {
      showEventModal.value = false; // Close the custom event modal
    };

    const showAddTask = () => {
      showAddTaskModal.value = true;
    };

    const closeAddTask = () => {
      showAddTaskModal.value = false;
    };

    const customComponents = {
      eventModal: CustomEventModal,
    };

    onMounted(async () => {
      const token = await getAccessTokenSilently();
      offerId = route.params.offerId;

      const response = await getClientOffer(token, offerId);
      offer.value = response;

      const timelineEvents = await getTimeline(token, offerId);

      timelineEvents.forEach((event) => {
        event.start = formatDate(new Date(event.start));
        event.end = formatDate(new Date(event.end));
      });

      let date = new Date(offer.value.eventDate);
      let formattedDate = `${date.getFullYear()}-${String(
        date.getMonth() + 1
      ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

      calendar.value.events.set(timelineEvents);
      calendar.value.$app.datePickerState.selectedDate.v = formattedDate;

      calendar.value.$app.calendarState.handleDateSelection(formattedDate);

      const calendarEl = document.getElementById("calendar");
      if (calendarEl) {
        calendar.value.render(calendarEl);
      }

      loading.value = false;
    });

    const addTaskToCalendar = async (taskDetails) => {
      const selectedDate =
        calendar.value.events.$app.datePickerState.selectedDate;

      let startDate = new Date(selectedDate);
      let hours = parseInt(taskDetails.start.split(":")[0]);
      let minutes = parseInt(taskDetails.start.split(":")[1]);
      startDate.setHours(hours, minutes, 0, 0);

      let endDate = new Date(selectedDate);
      let hoursEnd = parseInt(taskDetails.end.split(":")[0]);
      let minutesEnd = parseInt(taskDetails.end.split(":")[1]);
      endDate.setHours(hoursEnd, minutesEnd, 0, 0);

      calendar.value.events.add({
        id: calendar.value.events.getAll().length + 1,
        title: taskDetails.title,
        description: taskDetails.description,
        start: formatDate(startDate),
        end: formatDate(endDate),
      });

      closeAddTask();

      await saveCalendar();

      notify({
        type: "success",
        text: "Momentul a fost adăugată cu succes",
      });
    };

    const removeEvent = (event) => {
      calendar.value.events.remove(event.id);
      showEventModal.value = false;

      saveCalendar();
    };

    const saveCalendar = async () => {
      const events = calendar.value.events.getAll();
      const token = await getAccessTokenSilently();

      const eventsToSave = [];
      events.forEach((event) => {
        if (event.id) {
          event.start = new Date(event.start);
          event.end = new Date(event.end);

          eventsToSave.push({
            Title: event.title,
            Description: event.description,
            Start: new Date(
              Date.UTC(
                event.start.getFullYear(),
                event.start.getMonth(),
                event.start.getDate(),
                event.start.getHours(),
                event.start.getMinutes(),
                0,
                0
              )
            ),
            End: new Date(
              Date.UTC(
                event.end.getFullYear(),
                event.end.getMonth(),
                event.end.getDate(),
                event.end.getHours(),
                event.end.getMinutes(),
                0,
                0
              )
            ),
          });
        }
      });

      await updateTimeline(token, eventsToSave, offerId);
    };

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      const hours = ("0" + date.getHours()).slice(-2);
      const minutes = ("0" + date.getMinutes()).slice(-2);
      return `${year}-${month}-${day} ${hours}:${minutes}`;
    };

    return {
      showAddTaskModal,
      calendar,
      customComponents,
      loading,
      showAddTask,
      closeAddTask,
      addTaskToCalendar,
      saveCalendar,
      showEventModal,
      closeEventModal,
      selectedEvent,
      removeEvent,
    };
  },
};
</script>
