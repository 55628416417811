<template>
    <div class="modal bottom-sheet-on-mobile" id="change-location-modal" :class="{ active: showShareLinkModal }"
        @click="closeModalFromBackground">
        <div class="modal-content">
            <div class="modal-header">
                <h1>Obține link-ul</h1>
                <span class="close-modal splash-rounded material-symbols-rounded fill" @click="closeModal">
                    close
                </span>
            </div>

            <h3>Link-ul către Memories Collection: <br><br>
                <a ref="invitationLink" :href="`${baseLink}/memoriesCollection/${offerId}`"
                    target="_blank">
                    {{ baseLink }}/memoriesCollection/{{ offerId }}/
                </a>
            </h3>
            <button class="primary small" @click="copyLinkToClipboard">Copiază linkul</button>
        </div>m
    </div>
</template>

<script>

import { notify } from "@kyvg/vue3-notification";
import { ref } from 'vue';

export default {
    name: 'ShareLinkModal',
    props: {
        showShareLinkModal: Boolean,
        offerId: String,
    },
    setup(props, { emit }) {

        const invitationLink = ref(null);
        const baseLink = ref(process.env.VUE_APP_eventApp_url);

        function closeModalFromBackground(event) {
            if (event.target.classList.contains('modal')) {
                emit('closeModal');
            }
        }

        function closeModal() {
            emit('closeModal');
        }

        async function copyLinkToClipboard() {
            if (invitationLink.value) {
                try {
                    await navigator.clipboard.writeText(invitationLink.value.href);
                    notify({ type: "success", title: "Linkul a fost copiat", text: "Linkul a fost copiat in clipboard!" });
                    closeModal();
                } catch (err) {
                    notify({ type: "error", title: "Eroare la copiere", text: "Nu am putut copia linkul in clipboard." });
                    closeModal();
                }
            }
        }

        return {
            closeModalFromBackground,
            closeModal,
            copyLinkToClipboard,
            invitationLink,
            baseLink
        }
    }
};
</script>