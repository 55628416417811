<template>
  <div class="modal bottom-sheet-on-mobile" id="view-event-modal" :class="{ active: showEventModal }"
    @click="closeModalFromBackground">
    <div class="modal-content">
      <div class="modal-header">
        <div class="header-name">
          <h1>{{ calendarEvent.title }}</h1>
        </div>
        <span class="close-modal splash-rounded material-symbols-rounded fill" @click="closeModal">
          close
        </span>
      </div>
      <div class="event-details">
        <div class="event-time">
          Interval: <span class="bold">{{ extractTime(calendarEvent.start) }} - {{ extractTime(calendarEvent.end) }}</span></div>
        <div class="event-description" v-if="calendarEvent.description !== '' && calendarEvent.description !== null">Description: <br><span class="bold">{{ calendarEvent.description }}</span></div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-50">
            <button class="secondary small" @click="deleteEvent()">
              <span class="close-modal splash-rounded material-symbols-rounded">
                delete
              </span>
              Șterge</button>
          </div>
          <div class="col-50">
            <button class="primary small" @click="closeModal()">Inchide</button>
          </div>
        </div>
      </div>
     
  
    </div>


  </div>
</template>

<script>
export default {
  name: "CustomEventModal",
  props: {
    calendarEvent: Object,
    showEventModal: Boolean,
  },
  setup(props, { emit }) {

    function closeModalFromBackground(event) {
      if (event.target.classList.contains("modal")) {
        emit("closeModal");
      }
    }

    function deleteEvent() {
      emit("deleteEvent", props.calendarEvent);
    }

    function closeModal() {
      emit("closeModal");
    }

    const extractTime = (dateTimeString) => {
    const dateTimeParts = dateTimeString.split(' ');
    if (dateTimeParts.length !== 2) {
      return '';
    }
    return dateTimeParts[1];
  };

    return {
      deleteEvent,
      closeModalFromBackground,
      closeModal,
      extractTime
          };
  },
};
</script>

<style>
.custom-event-modal {
  position: absolute;
  border-radius: 4px;
  background-color: @primary-white;
  font-family: inherit;
  line-height: 1.5;
  width: 100%;
  height: 100%;
  height: 200px;
  max-width: 400px;
  z-index: 99;
}
</style>
