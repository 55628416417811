<template>
  <div class="wrapper-content invitation" :class="{
    'bohemian':
      invitation.templateName === 'Bohemian' ||
      invitation.templateName === 'Bohemian11',
    'gold-accents':
      invitation.templateName === 'GoldAccents' ||
      invitation.templateName === 'GoldAccents11',
    'greenish-vibes': invitation.templateName === 'GreenishVibes'
  }">
    <see-collaborators-modal :showSeeCollaboratorsModal="seeCollaboratorsModal" :offerId="offerId"
      v-if="seeCollaboratorsModal" @closeModal="closeSeeCollaboratorsModal()" />
    <change-profile-details-modal :showChangeProfileDetailsModal="showChangeProfileDetails"
      v-if="showChangeProfileDetailsModal" @closeModal="closeChangeProfileDetails" />

    <share-link-modal :showShareLinkModal="seeShareLinkModal" v-if="this.seeShareLinkModal" :offerId="offerId"
      :invitationId="invitationId" :hasCustomUrl="invitation.hasCustomUrl" :customUrl="invitation.customUrl"
      @closeModal="closeShareLinkModal()" />

    <payment-benefits-modal :showPaymentBenefitsModal="seePaymentBenefitsModal" v-if="this.seePaymentBenefitsModal"
      :offerId="offerId" :invitationId="invitationId" @closeModal="closePaymentBenefitsModal()" />

    <div v-if="isEditable" class="header space-between">
      <div class="vertical-align gap-5 splash-rounded splash-padding"
        @click="this.$router.push(`/dashboard/${offerId}`)">
        <span class="material-symbols-rounded"> arrow_back </span>
        <h1>Mergi la eveniment</h1>
      </div>

      <div class="actions">
        <div class="header-button primary" @click="showPaymentBenefitsModal">
          <div class="highlight">
            <span class="material-symbols-rounded"> credit_card </span>
          </div>
          <div class="text">Plată</div>
        </div>
        <div class="header-button primary" @click="getLink()">
          <div class="highlight">
            <span class="material-symbols-rounded"> share </span>
          </div>
          <div class="text" v-if="invitation.published">Obține linkul</div>
          <div class="text" v-else>Publică invitația</div>
        </div>
        <div class="header-button secondary" @click="
          this.$router.push(`/invitation/${offerId}/${invitationId}/wizard`)
          ">
          <div class="highlight">
            <span class="material-symbols-rounded"> edit </span>
          </div>
          <div class="text">Editează preferințele</div>
        </div>
      </div>
    </div>

    <div
      v-if="isEditable && invitation.templateName !== 'minimalistShades' && invitation.templateName !== 'oceansAway' && invitation.templateName !== 'babyClouds' && invitation.templateName !== 'openDay'"
      class="sub-header" :class="{ active: selectedElement != null }">
      <div class="preview" v-if="!isMobile">
        <div class="preview-icons">
          <div class="preview-icon" @click="setWidthMobile" :class="{ active: isWidthMobile }">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                d="M15.045,0c-.027-.001-6.063-.001-6.09,0-2.736,.024-4.955,2.258-4.955,4.999v14c0,2.757,2.243,5,5,5h6c2.757,0,5-2.243,5-5V5C20,2.258,17.781,.025,15.045,0Zm2.955,18.999c0,1.654-1.346,3-3,3h-6c-1.654,0-3-1.346-3-3V5c0-1.453,1.038-2.667,2.411-2.942l.694,1.389c.169,.339,.516,.553,.895,.553h4c.379,0,.725-.214,.895-.553l.694-1.389c1.373,.274,2.411,1.489,2.411,2.942v14Zm-5,1h-2c-.552,0-1-.448-1-1h0c0-.552,.448-1,1-1h2c.552,0,1,.448,1,1h0c0,.552-.448,1-1,1Z" />
            </svg>
          </div>
          <div class="preview-icon" @click="setWidthTablet" :class="{ active: isWidthTablet }">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                d="M17,0H7A5.006,5.006,0,0,0,2,5V19a5.006,5.006,0,0,0,5,5H17a5.006,5.006,0,0,0,5-5V5A5.006,5.006,0,0,0,17,0ZM7,2H17a3,3,0,0,1,3,3V17H4V5A3,3,0,0,1,7,2ZM17,22H7a3,3,0,0,1-3-3h7v1a1,1,0,0,0,2,0V19h7A3,3,0,0,1,17,22Z" />
            </svg>
          </div>
          <div class="preview-icon" @click="setWidthDesktop" :class="{ active: isWidthDesktop }">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path
                d="M19,1H5A5.006,5.006,0,0,0,0,6v8a5.006,5.006,0,0,0,5,5h6v2H7a1,1,0,0,0,0,2H17a1,1,0,0,0,0-2H13V19h6a5.006,5.006,0,0,0,5-5V6A5.006,5.006,0,0,0,19,1ZM5,3H19a3,3,0,0,1,3,3v7H2V6A3,3,0,0,1,5,3ZM19,17H5a3,3,0,0,1-2.816-2H21.816A3,3,0,0,1,19,17Z" />
            </svg>
          </div>
        </div>
      </div>
      <div class="divider" v-if="!isMobile" />
      <div class="width" v-if="!isMobile">
        Lățime:
        <span :contenteditable="isEditable" @input="updateInvitationWidth($event)" ref="editable">{{ invitationWidth
          }}</span>
        <span> px</span>
      </div>

      <div class="divider" v-if="checkIfHasFontStyling() && !isMobile" />
      <div class="font-style" v-if="checkIfHasFontStyling()">
        Font:
        <div class="elements">
          <span class="material-symbols-rounded" @click="updateSelectedElementFontFamily('secondary-font')"
            :class="{ selected: selectedElementFontFamily == 'secondary-font' }">
            font_download
          </span>
          <span class="material-symbols-rounded" @click="updateSelectedElementFontFamily('primary-font')"
            :class="{ selected: selectedElementFontFamily == 'primary-font' }">
            serif
          </span>
          <span class="material-symbols-rounded" @click="updateSelectedElementFontFamily('tertiary-font')"
            :class="{ selected: selectedElementFontFamily == 'tertiary-font' }">
            slab_serif
          </span>
          <span class="material-symbols-rounded" @click="updateSelectedElementFontFamily('quaternary-font')" :class="{
            selected: selectedElementFontFamily == 'quaternary-font',
          }">
            font_download
          </span>
        </div>
      </div>
      <div class="divider" v-if="checkIfHasFontStyling()" />
      <div class="font-weight" v-if="checkIfHasFontStyling()">
        Grosime:
        <div class="elements">
          <span class="material-symbols-rounded" @click="updateSelectedElementFontWeight('light-font-weight')" :class="{
            selected: selectedElementFontWeight == 'light-font-weight',
          }">
            highlighter_size_1
          </span>
          <span class="material-symbols-rounded" @click="updateSelectedElementFontWeight('medium-font-weight')" :class="{
            selected: selectedElementFontWeight == 'medium-font-weight',
          }">
            highlighter_size_3
          </span>
          <span class="material-symbols-rounded" @click="updateSelectedElementFontWeight('bold-font-weight')" :class="{
            selected: selectedElementFontWeight == 'bold-font-weight',
          }">
            highlighter_size_5
          </span>
        </div>
      </div>
      <div class="divider" v-if="checkIfHasFontStyling()" />
      <div class="color" v-if="checkIfHasFontStyling()">
        Color:
        <div class="elements">
          <span class="color" @click="updateSelectedElementColor('primary-color')"
            :class="{ selected: selectedElementColor == 'primary-color' }" />
          <span class="color" @click="updateSelectedElementColor('primary-text-color')"
            :class="{ selected: selectedElementColor == 'primary-text-color' }" />
          <span class="color" @click="updateSelectedElementColor('secondary-text-color')" :class="{
            selected: selectedElementColor == 'secondary-text-color',
          }" />
          <span class="color" @click="updateSelectedElementColor('tertiary-text-color')"
            :class="{ selected: selectedElementColor == 'tertiary-text-color' }" />
        </div>
      </div>
    </div>

    <div class="content-loading" v-if="loadingInvitation && isEditable">
      <vLottiePlayer name="lottie" class="lottie" loop
        :animationData="require('@/assets/lottie/enginesLoading.json')" />
    </div>

    <div class="content-loading" v-else-if="loadingInvitation && !isEditable"></div>

    <div class="canvas-password" v-else-if="
      invitation.hasPassword &&
      invitation.accessPassword &&
      invitation.accessPassword.length > 0 &&
      !isPasswordGood &&
      !isEditable
    ">
      <div class="content">
        <span class="material-symbols-rounded"> lock </span>
        <h1>Pagina este blocată de către administrator</h1>

        <div class="password-container">
          <div class="input-wrapper">
            <input class="input" type="password" id="name" name="name" placeholder-shown="true" autocomplete="off"
              required minlength="2" v-model="temporaryAccessPassword" placeholder="●●●●●●●●●"
              data-pristine-minlength-message="Introduceti minim 2 caractere"
              data-pristine-required-message="Câmp obligatoriu" />
            <label class="label" for="name">Introdu parola mai jos*</label>
          </div>
          <button class="primary small" @click="checkTemporaryAccess">
            Intră
          </button>
        </div>
      </div>
    </div>

    <div class="canvas" v-else-if="
      !saving &&
      (isPasswordGood ||
        isEditable ||
        !invitation.hasPassword ||
        invitation.accessPassword.length === 0)
    ">
      <div ref="widthRef" class="canvas-zone" :style="{ 'max-width': `${invitationWidth}px` }">
        <!-- TODO: Update google component here -->
        <div class="google-translate" v-if="!isEditable && invitation.hasTranslation"
          :class="{ [invitation.templateName]: true }">
          <GoogleTranslateSelect default-language-code="ro" default-page-language-code="ro"
            :fetch-browser-language="false" trigger="click" @select="handleGoogleTranslateSelect"
            :languages="invitation.languagesToTranslate" />
          <p>Traducere automată</p>
        </div>

        <bohemian-invitation :isInvitationEditable="isEditable" @saveJson="saveJson"
          @sendInvitationResponse="sendInvitationResponse" :invitationData="invitation" :offerId="offerId"
          :invitationId="invitationId" :isWidthMobile="isWidthMobile" :isWidthTablet="isWidthTablet"
          :isWidthDesktop="isWidthDesktop" v-if="invitation.templateName === 'Bohemian'" />

        <bohemian-invitation-11 :isInvitationEditable="isEditable" @saveJson="saveJson"
          @sendInvitationResponse="sendInvitationResponse" :invitationData="invitation" :offerId="offerId"
          :invitationId="invitationId" :isWidthMobile="isWidthMobile" :isWidthTablet="isWidthTablet"
          :isWidthDesktop="isWidthDesktop" @select="handleElementSelection" @deselect="handleElementDeselection"
          v-else-if="invitation.templateName === 'Bohemian11'" />

        <gold-accents-invitation :isInvitationEditable="isEditable" @saveJson="saveJson"
          @sendInvitationResponse="sendInvitationResponse" :invitationData="invitation" :offerId="offerId"
          :invitationId="invitationId" :isWidthMobile="isWidthMobile" :isWidthTablet="isWidthTablet"
          :isWidthDesktop="isWidthDesktop" v-else-if="invitation.templateName === 'GoldAccents'" />

        <gold-accents-invitation-11 :isInvitationEditable="isEditable" @saveJson="saveJson"
          @sendInvitationResponse="sendInvitationResponse" :invitationData="invitation" :offerId="offerId"
          :invitationId="invitationId" :isWidthMobile="isWidthMobile" :isWidthTablet="isWidthTablet"
          :isWidthDesktop="isWidthDesktop" @select="handleElementSelection"
          v-else-if="invitation.templateName === 'GoldAccents11'" />

        <greenish-vibes :isInvitationEditable="isEditable" @saveJson="saveJson"
          @sendInvitationResponse="sendInvitationResponse" :invitationData="invitation" :offerId="offerId"
          :invitationId="invitationId" :isWidthMobile="isWidthMobile" :isWidthTablet="isWidthTablet"
          :isWidthDesktop="isWidthDesktop" @select="handleElementSelection"
          v-else-if="invitation.templateName === 'GreenishVibes'" />

        <invitation-card v-else-if="invitation.templateName === 'minimalistShades'" :isInvitationEditable="isEditable"
          @saveJson="saveJson" @sendInvitationResponse="sendInvitationResponse" :invitationData="invitation"
          :offerId="offerId" :isWidthMobile="isWidthMobile" :isWidthTablet="isWidthTablet"
          :isWidthDesktop="isWidthDesktop" />

        <invitation-card v-else-if="invitation.templateName === 'oceansAway'" :isInvitationEditable="isEditable"
          @saveJson="saveJson" @sendInvitationResponse="sendInvitationResponse" :invitationData="invitation"
          :offerId="offerId" :isWidthMobile="isWidthMobile" :isWidthTablet="isWidthTablet"
          :isWidthDesktop="isWidthDesktop" />

        <invitation-card v-else-if="invitation.templateName === 'babyClouds'" :isInvitationEditable="isEditable"
          @saveJson="saveJson" @sendInvitationResponse="sendInvitationResponse" :invitationData="invitation"
          :offerId="offerId" :isWidthMobile="isWidthMobile" :isWidthTablet="isWidthTablet"
          :isWidthDesktop="isWidthDesktop" />

        <invitation-card v-else-if="invitation.templateName === 'openDay'" :isInvitationEditable="isEditable"
          @saveJson="saveJson" @sendInvitationResponse="sendInvitationResponse" :invitationData="invitation"
          :offerId="offerId" :isWidthMobile="isWidthMobile" :isWidthTablet="isWidthTablet"
          :isWidthDesktop="isWidthDesktop" />

        <bohemian-invitation v-else :isInvitationEditable="isEditable" @saveJson="saveJson"
          @sendInvitationResponse="sendInvitationResponse" :invitationData="invitation" :offerId="offerId"
          :invitationId="invitationId" :isWidthMobile="isWidthMobile" :isWidthTablet="isWidthTablet"
          :isWidthDesktop="isWidthDesktop" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watchEffect } from "vue";
import InvitationCard from "@/views/invitation/InvitationCard.vue";
import BohemianInvitation from "@/views/invitation/pageInvitationModels/BohemianInvitation.vue";
import BohemianInvitation11 from "@/views/invitation/pageInvitationModels/version11/BohemianInvitation11.vue";
import GoldAccentsInvitation from "@/views/invitation/pageInvitationModels/GoldAccentsInvitation.vue";
import GoldAccentsInvitation11 from "@/views/invitation/pageInvitationModels/version11/GoldAccentsInvitation11.vue";
import GreenishVibes from "@/views/invitation/pageInvitationModels/version11/GreenishVibes.vue";
import SeeCollaboratorsModal from "@/views/invitation/components/SeeCollaboratorsModal.vue";
import ShareLinkModal from "@/views/invitation/components/ShareLinkModal.vue";
import PaymentBenefitsModal from "@/views/invitation/components/PaymentBenefitsModal.vue";
import ChangeProfileDetailsModal from "@/views/settings/components/ChangeProfileDetailsModal.vue";
import {
  saveInvitation,
  getInvitation,
  createInvitationResponse,
  publishInvitation,
  getEventAppUser,
  getInvitationForClient,
} from "@/api";
import { useAuth0 } from "@auth0/auth0-vue";
import { useRoute, useRouter } from "vue-router";

import { notify } from "@kyvg/vue3-notification";
import { useShare } from "@vueuse/core";
import GoogleTranslateSelect from "@google-translate-select/vue3";

export default {
  name: "Invitation-Page",
  components: {
    BohemianInvitation,
    BohemianInvitation11,
    GoldAccentsInvitation,
    GreenishVibes,
    InvitationCard,
    GoldAccentsInvitation11,
    SeeCollaboratorsModal,
    ShareLinkModal,
    ChangeProfileDetailsModal,
    GoogleTranslateSelect,
    PaymentBenefitsModal,
  },
  setup() {
    const { share } = useShare();

    const { getAccessTokenSilently, user } = useAuth0();
    const seeCollaboratorsModal = ref(false);
    const showChangeProfileDetailsModal = ref(false);
    const seeShareLinkModal = ref(false);
    const seePaymentBenefitsModal = ref(false);
    const offerId = ref("");
    const invitation = ref({});
    const loadingInvitation = ref(true);
    const saving = ref(false);
    const confirming = ref(false);
    const isEditable = ref(false);
    const userProfile = ref(null);
    const invitationId = ref(0);
    const tinyUrlDomain = ref(process.env.VUE_APP_tinyurl_domain);

    const baseLink = ref(process.env.VUE_APP_eventApp_url);

    const router = useRouter();
    const route = useRoute();
    const temporaryAccessPassword = ref(null);
    const isPasswordGood = ref(false);

    const invitationWidth = ref("100%");
    const widthRef = ref(null);
    const elementWidth = ref(0);

    const isWidthMobile = ref(false);
    const isWidthTablet = ref(false);
    const isWidthDesktop = ref(false);

    const selectedElement = ref(null);
    const selectedElementColor = ref(null);
    const selectedElementFontFamily = ref(null);
    const selectedElementFontWeight = ref(null);

    const handleElementSelection = (element, color, fontFamily, fontWeight) => {
      selectedElement.value = element;
      selectedElementColor.value = color;
      selectedElementFontFamily.value = fontFamily;
      selectedElementFontWeight.value = fontWeight;
    };

    const handleElementDeselection = () => {
      selectedElement.value = null;
      selectedElementColor.value = null;
      selectedElementFontFamily.value = null;
      selectedElementFontWeight.value = null;
    };

    const updateSelectedElementColor = (color) => {
      if (selectedElement.value && invitation.value && invitation.value.json) {
        invitation.value.json[selectedElement.value + "Color"] = color;
        selectedElementColor.value = color;
      }
    };

    const updateSelectedElementFontFamily = (font) => {
      if (selectedElement.value && invitation.value && invitation.value.json) {
        invitation.value.json[selectedElement.value + "FontFamily"] = font;
        selectedElementFontFamily.value = font;
      }
    };

    const updateSelectedElementFontWeight = (font) => {
      if (selectedElement.value && invitation.value && invitation.value.json) {
        invitation.value.json[selectedElement.value + "FontWeight"] = font;
        selectedElementFontWeight.value = font;
      }
    };

    const handleGoogleTranslateSelect = (language) => {
      console.log(language);
    };

    const isMobile = () => {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    };

    const updateInvitationWidth = (event) => {
      invitationWidth.value = event.target.innerText;
    };

    const setWidthMobile = () => {
      invitationWidth.value = "575";
      isWidthMobile.value = true;
      isWidthTablet.value = false;
      isWidthDesktop.value = false;
    };

    const setWidthTablet = () => {
      invitationWidth.value = "990";
      isWidthMobile.value = false;
      isWidthTablet.value = true;
      isWidthDesktop.value = false;
    };

    const setWidthDesktop = () => {
      invitationWidth.value = "1400";

      isWidthMobile.value = false;
      isWidthTablet.value = false;
      isWidthDesktop.value = true;
    };

    const openDrawer = () => {
      document.querySelector(".drawer-wrapper").classList.add("open");
    };

    const showSeeCollaboratorsModal = () => {
      seeCollaboratorsModal.value = true;
    };

    const showSeeShareLinkModal = () => {
      if (isMobile()) {
        share({
          title: "Invitația mea la eveniment!",
          text: "Link-ul catre invitația mea este:",
          url:
            baseLink.value +
            "/invitation/" +
            offerId.value +
            "/" +
            invitationId.value,
        });
      } else {
        seeShareLinkModal.value = true;
      }
    };

    const showPaymentBenefitsModal = () => {
      seePaymentBenefitsModal.value = true;
    };

    const closePaymentBenefitsModal = () => {
      seePaymentBenefitsModal.value = false;
    };

    const getLink = async () => {
      if (!invitation.value.published) {
        if (
          !userProfile.value.email ||
          !userProfile.value.email.includes("@")
        ) {
          showChangeProfileDetails();
        } else {
          await confirmPublish();
        }
      } else {
        showSeeShareLinkModal();
      }
    };

    const closeSeeCollaboratorsModal = () => {
      seeCollaboratorsModal.value = false;
    };

    const closeShareLinkModal = () => {
      seeShareLinkModal.value = false;
    };

    const saveJson = async (json) => {
      try {
        saving.value = true;

        const token = await getAccessTokenSilently();

        await saveInvitation(
          token,
          offerId.value,
          invitationId.value,
          JSON.stringify(json)
        );
        saving.value = false;

        notify({
          type: "success",
          title: "Salvat",
          text: "Invitatia a fost salvata cu succes.",
        });
        router.go(0);
      } catch (error) {
        notify({
          type: "error",
          title: "Eroare",
          text: "Nu am putut salva invitatia. Va rugam sa incercati din nou.",
        });
      }
    };

    const sendInvitationResponse = async (invitees) => {
      // TODO: use confirming to check if is saving the response
      confirming.value = false;

      try {
        let companions = Array.from(invitees);
        companions.shift();

        const data = {
          Name: invitees[0].name,
          Phone: invitees[0].phone,
          Email: invitees[0].email,
          Notification: true,
          CompanionsValue: companions,
          Confirmed: invitees[0].confirmed,
          QuestionAnswers: invitees[0].questions,
        };

        await createInvitationResponse(offerId.value, invitationId.value, data);
      } catch (error) {
        if (error.response.status === 409) {
          notify({
            type: "error",
            title: "Eroare",
            text: "Nu puteti trimite raspunsul deoarece ati trimis deja un raspuns pentru aceasta invitatia.",
          });
        } else {
          notify({
            type: "error",
            title: "Eroare",
            text: "Nu am putut salva raspunsul. Va rugam sa incercati din nou.",
          });
        }
      }

      confirming.value = false;
    };

    const showChangeProfileDetails = () => {
      showChangeProfileDetailsModal.value = true;
    };

    const confirmPublish = async () => {
      if (
        window.confirm(
          "Sunteti sigur ca doriti sa obtineti link-ul? Aceasta confirmare va face invitatia publica."
        )
      ) {
        const token = await getAccessTokenSilently();
        await publishInvitation(token, offerId.value, invitationId.value);

        invitation.value.published = true;

        window.open(
          `/invitation/${offerId.value}/${invitationId.value}`,
          "_blank"
        );
      }
    };

    const closeChangeProfileDetails = async (informationComplete) => {
      if (informationComplete === true) {
        userProfile.value = await getUser();
        await confirmPublish();
      }
      showChangeProfileDetailsModal.value = false;
    };

    const isWithinDeadline = () => {
      const deadline = new Date(invitation.value.deadline);
      const today = new Date();
      return deadline > today;
    };

    const getInvitationValue = async () => {
      loadingInvitation.value = true;

      if (isEditable.value === true) {
        invitation.value = await getInvitation(
          offerId.value,
          invitationId.value
        );
      } else {
        invitation.value = await getInvitationForClient(
          offerId.value,
          invitationId.value
        );
      }

      invitation.value.json = JSON.parse(invitation.value.json);

      if (
        invitation.value.hasTranslation &&
        invitation.value.languagesToTranslate !== null
      ) {
        invitation.value.languagesToTranslate = JSON.parse(
          invitation.value.languagesToTranslate
        );
        invitation.value.languagesToTranslate.push({
          code: "ro",
          name: "limba română",
          cname: "罗马尼亚语",
          ename: "Romanian",
        });
      }

      loadingInvitation.value = false;
    };

    const getUser = async () => {
      const token = await getAccessTokenSilently();

      return await getEventAppUser(token, user.value.phone_number);
    };

    const checkTemporaryAccess = () => {
      if (temporaryAccessPassword.value === invitation.value.accessPassword) {
        isPasswordGood.value = true;
      }
    };

    const checkIfHasFontStyling = () => {
      if (
        invitation.value.templateName === "Bohemian11" ||
        invitation.value.templateName === "GoldAccents11" ||
        invitation.value.templateName === "GreenishVibes"
      ) {
        return true;
      } else {
        return false;
      }
    };

    onMounted(async () => {
      offerId.value = route.params.offerId;
      invitationId.value = route.params.invitationId ?? 0;

      isEditable.value = route.path.includes("edit");

      if (isEditable.value) {
        userProfile.value = await getUser();
      }
      await getInvitationValue();

      // HTML Invitation specific code
      if (invitation.value.templateName !== "minimalistShades" || invitation.value.templateName !== "oceansAway" || invitation.value.templateName !== "babyClouds" || invitation.value.templateName !== "openDay") {
        if (isEditable.value) {
          if (widthRef.value) {
            elementWidth.value = widthRef.value.offsetWidth;
            invitationWidth.value = widthRef.value.offsetWidth;

            if (elementWidth.value < 576) {
              isWidthMobile.value = true;
            } else if (elementWidth.value > 575 && elementWidth.value < 991) {
              isWidthTablet.value = true;
            } else {
              isWidthDesktop.value = true;
            }
          }

          const updateWidthOnResize = () => {
            if (widthRef.value) {
              elementWidth.value = widthRef.value.offsetWidth;
              invitationWidth.value = widthRef.value.offsetWidth;

              if (elementWidth.value < 576) {
                isWidthMobile.value = true;
                isWidthTablet.value = false;
                isWidthDesktop.value = false;
              } else if (elementWidth.value > 575 && elementWidth.value < 991) {
                invitationWidth.value = widthRef.value.offsetWidth;

                isWidthMobile.value = false;
                isWidthTablet.value = true;
                isWidthDesktop.value = false;
              } else {
                invitationWidth.value = widthRef.value.offsetWidth;

                isWidthMobile.value = false;
                isWidthTablet.value = false;
                isWidthDesktop.value = true;
              }
            }
          };
          window.addEventListener("resize", updateWidthOnResize);

          watchEffect((onInvalidate) => {
            onInvalidate(() => {
              window.removeEventListener("resize", updateWidthOnResize);
            });
          });
        }
      }
    });

    return {
      isMobile,
      selectedElement,
      selectedElementColor,
      selectedElementFontFamily,
      selectedElementFontWeight,
      handleElementSelection,
      handleElementDeselection,
      updateSelectedElementColor,
      updateSelectedElementFontFamily,
      updateSelectedElementFontWeight,
      checkIfHasFontStyling,
      seeCollaboratorsModal,
      seeShareLinkModal,
      seePaymentBenefitsModal,
      offerId,
      baseLink,
      invitation,
      invitationId,
      loadingInvitation,
      saving,
      confirming,
      isEditable,
      userProfile,
      invitationWidth,
      setWidthMobile,
      setWidthTablet,
      setWidthDesktop,
      showChangeProfileDetailsModal,
      showChangeProfileDetails,
      closeChangeProfileDetails,
      isWithinDeadline,
      openDrawer,
      showSeeCollaboratorsModal,
      closeSeeCollaboratorsModal,
      closeShareLinkModal,
      saveJson,
      sendInvitationResponse,
      getLink,
      elementWidth,
      widthRef,
      isWidthMobile,
      isWidthTablet,
      isWidthDesktop,
      updateInvitationWidth,
      handleGoogleTranslateSelect,
      temporaryAccessPassword,
      checkTemporaryAccess,
      isPasswordGood,
      tinyUrlDomain,
      showPaymentBenefitsModal,
      closePaymentBenefitsModal,
    };
  },
};
</script>
