<template>
  <div class="wrapper-printer floor-plan">
    <div class="content page" ref="pages">
      <img :src="canvasImage" alt="Exported Canvas Image" />
    </div>
    <div class="content page" ref="pages">
      <h1 class="tables-header">Floorplan - <span>24 martie 2024</span></h1>

      <div class="tables-grid">
        <div class="table-element" v-for="table in tables" :key="table">
          <div class="table-header">
            <p><span>Masa 2</span> - {{ table.persons.length }}/6</p>
          </div>
          <ol class="table-content">
            <li v-for="person in table.persons" :key="person">{{ person.name }} </li>

          </ol>
        </div>


      </div>
    </div>
    <div class="print-container">
      <button class="primary small" @click="generatePDF">
        <span class="material-symbols-rounded"> download </span>Descarcă PDF
      </button>

    </div>

  </div>
</template>

<script>

import { getFloorplan, getGuestlist } from "@/api";
import { useAuth0 } from "@auth0/auth0-vue";
import { fabric } from "fabric";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export default {
  data() {
    return {
      token: null,
      canvas: null,
      canvasImage: null,
      offerId: null,
      floorplanId: 0,
      tables: [

      ]

    };
  },
  async mounted() {
    this.offerId = this.$route.params.offerId;
    this.floorplanId = this.$route.params.floorplanId;

    const { getAccessTokenSilently } = useAuth0();
    this.token = await getAccessTokenSilently();

    await this.loadSavedDesign();

    this.canvasImage = localStorage.getItem('exportedCanvasImage');
    if (!this.canvasImage) {
      console.error('No canvas image found in local storage.');
    }
  },
  methods: {
    async loadSavedDesign() {
      const url = (
        await getFloorplan(this.token, this.offerId, this.floorplanId)
      ).floorPlanUrl;

      if (url.length > 0) {
        try {
          const response = await fetch(url);
          const blob = await response.blob();
          const file = new File([blob], "filename", { type: "mime/type" });

          if (file) {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();

              this.canvas = new fabric.Canvas('canvas-id');

              reader.onload = (e) => {
                const data = JSON.parse(e.target.result);

                this.canvas.loadFromJSON(data.canvasData, () => {

                  this.canvas.forEachObject((obj) => {
                    if (obj.type === 'group' && obj.persons !== undefined) {
                      this.tables.push({
                        table: obj,
                        persons: obj.persons
                      });
                    }
                  });

                  resolve();
                });
              };
              reader.onerror = (error) => reject(error);
              reader.readAsText(file);
            });
          }
        } catch (error) {
          console.error(error);
        }
      }

      this.loading = false;
    },

    // TODO: Leo, pune aici table idul declarat de user
    // getTableId(table) {
    //   return table.getObjects()[2].text;
    // },

    async generatePDF() {
      const pages = this.$refs.pages.children;

      const doc = new jsPDF();

      for (let i = 0; i < pages.length; i++) {
        const canvas = await html2canvas(pages[i]);
        const imgData = canvas.toDataURL('image/png');
        doc.addImage(imgData, 'PNG', 0, 0);
        if (i < pages.length - 1) {
          doc.addPage();
        }
      }

      doc.save('floorplan.pdf');
    }
  }
}
</script>
