<template>
    <div class="modal bottom-sheet-on-mobile" id="change-location-modal" :class="{ active: showChangeLocationModal }"
        @click="closeModal">
        <div class="modal-content">
            <h1>Schimbă locația</h1>


            <div class="input-wrapper">
                <input id="date" v-model="title" type="plain" class="input" required autocomplete="chrome-off"
                    name="date" />
                <label class="label" for="date">Titlu</label>
            </div>

            <div class="input-wrapper">
                <input id="date" v-model="date" type="date" class="input" required autocomplete="chrome-off"
                    name="date" />
                <label class="label" for="date">Data</label>
            </div>


            <div class="input-wrapper">
                <input id="time" v-model="time" type="time" class="input" required autocomplete="chrome-off"
                    name="date" />
                <label class="label" for="time">Ora</label>
            </div>

            <div class="input-wrapper">
                <input ref="autoComplete" id="city" v-model="place" type="text" class="input" required
                    autocomplete="chrome-off" name="city" />
                <label class="label" for="city">Locația ta</label>
            </div>

            <div id="map" style="width: 100%; height: 200px;"></div>

            <button class="primary small" @click="changeLocation">Schimbă locația</button>
        </div>
    </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue';

export default {
    name: 'ChangeLocationModal',
    props: {
        initialLocation: String,
        showChangeLocationModal: Boolean,
        initialTitle: String,
        initialDate: String,
        initialTime: String,
    },
    setup(props, { emit }) {
        const defaultLocation = ref({ lat: 45.9432, lng: 24.9668 });
        const placeName = ref(null);
        const placeAddress = ref(null);
        const placeCity = ref(null);
        const place = ref(props.initialLocation);
        const placeCounty = ref(null);
        const formattedLocation = ref(null);
        const map = ref(null);
        const google = ref(null);

        const title = ref(props.initialTitle);
        const date = ref(props.initialDate);
        const time = ref(props.initialTime);

        if (props.initialDate === null || props.initialDate === undefined) {
            this.date = Date.now()
        }

        let autocomplete = ref(null);
        const autoComplete = ref(null);

        onMounted(() => {
            window.initMapLocations = initMapLocations;

            const script = document.createElement('script');
            script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyBW1iQe89cgzpbujL4_jkRRaMb5TV6VagU&libraries=places&callback=initMapLocations";
            script.async = true;
            script.defer = true;
            window.document.body.appendChild(script);

            script.addEventListener('load', () => {
                initMapLocations();
            });
        });

        watch([placeName, placeAddress, placeCounty], () => {
            formattedLocation.value = `${placeName.value}, ${placeAddress.value}`;
        });

        watch(formattedLocation, (newVal, oldVal) => {
            if (newVal !== oldVal) {
                updateMapForLocation(newVal);
            }
        });

        const initMapLocations = () => {
            var locationOptions = {
                fields: ['formatted_address', 'name', 'address_components'],
                componentRestrictions: { country: 'RO' }
            }
            google.value = window.google

            autocomplete.value = new google.value.maps.places.Autocomplete(
                autoComplete.value,
                locationOptions
            )

            autocomplete.value.addListener('place_changed', () => {
                var place = autocomplete.value.getPlace()
                if (!place.address_components) {
                    alert(
                        'Aceasta locație nu are o adresa. Va rugam sa selectati alta locație sau sa introduceti adresa locației dorite.'
                    )
                } else {
                    placeName.value = place.name
                    placeAddress.value = place.formatted_address
                    placeCity.value = place.address_components[1].long_name,
                        place.value = place.formatted_address;
                }
            })

            const mapOptions = {
                center: { lat: 44.857073, lng: 24.868496 },
                zoom: props.initialLocation ? 16 : formattedLocation.value ? 16 : 8,
                disableDefaultUI: true,
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false
            };
            map.value = new google.value.maps.Map(document.getElementById("map"), mapOptions);

            if (props.initialLocation.length > 0) {
                updateMapForLocation(props.initialLocation);
            }
        }

        const updateMapForLocation = (location) => {
            if (!map.value) {
                console.error('Map not initialized');
                return;
            }

            const geocoder = new google.value.maps.Geocoder();
            if (!location || location.trim() === '') {
                setMapToLocation(defaultLocation.value);
                return;
            }

            geocoder.geocode({ 'address': location }, (results, status) => {
                if (status === 'OK') {
                    setMapToLocation(results[0].geometry.location);
                } else {
                    console.error('Geocode was not successful for the following reason: ' + status);
                    setMapToLocation(defaultLocation.value);
                }
            });
        };

        const setMapToLocation = (location) => {
            if (map.value) {
                map.value.setCenter(location);
                new google.value.maps.Marker({
                    map: map.value,
                    position: location
                });
            }
        };

        const changeLocation = () => {
            if (formattedLocation.value === null) {
                formattedLocation.value = place.value;
            }
            emit('changeLocation', formattedLocation.value, title.value, date.value, time.value,);
            closeModal();
        };


        const closeModal = (event) => {
            emit('closeModal', event);
        };

        return {
            defaultLocation,
            placeName,
            placeAddress,
            placeCity,
            placeCounty,
            formattedLocation,
            map,
            autoComplete,
            place,
            title,
            date,
            time,
            updateMapForLocation,
            setMapToLocation,
            changeLocation,
            closeModal
        };
    },
};
</script>