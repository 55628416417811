<template>
  <div
    class="modal bottom-sheet-on-mobile"
    id="add-category-modal"
    :class="{ active: showAddCategoryItemModal }"
    @click="closeModalFromBackground"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h1>Adaugă cheltuială</h1>
        <span
          class="close-modal splash-rounded material-symbols-rounded fill"
          @click="closeModal"
        >
          close
        </span>
      </div>

      <form ref="formElement" v-show="!loading" @submit.prevent @submit="addCategoryItem(selectedCategoryModel, inputModel)">
        <div class="input-wrapper select">
          <label class="label" for="name">Alege categoria*</label>
          <select v-model="selectedCategoryModel">
            <option
              v-for="(category, categoryIndex) in lists"
              :key="'category-' + categoryIndex"
              :value="category.name"
            >
              {{ category.name }}
            </option>
            <span class="material-symbols-rounded">
              lowercase
              </span>
          </select>
         
        </div>
        
        <div class="input-wrapper">
          <input
            v-model="inputModel"
            class="input"
            type="text"
            id="name"
            name="name"
            placeholder="Cheltuieli locație"
            placeholder-shown="false"
            autocomplete="off"
            required
            minlength="2"
            data-pristine-minlength-message="Introduceti minim 2 caractere"
            data-pristine-required-message="Câmp obligatoriu"
            ref="inputRef"
          />
          <label class="label" for="name">Nume cheltuială*</label>
          <img class="checked" src="@/assets/img/check.svg" alt="" />
          <img class="error" src="@/assets/img/error.svg" alt="" />
        </div>
      </form>

      <div class="modal-footer">
        <button
        class="primary small"
        @click="addCategoryItem(selectedCategoryModel, inputModel)"
      >
        Adauga cheltuială
      </button>
      </div>
    
    </div>
  </div>
</template>

<script>
import { ref, onMounted, nextTick } from "vue";
import * as Pristine from "pristinejs";

export default {
  name: "AddCategoryItemModal",
  props: {
    showAddCategoryItemModal: Boolean,
    selectedCategoryName: {type: String},
    lists: [],
  },
  setup(props, { emit }) {
    const loading = ref(true);
    const selectedCategoryModel = ref();
    const inputModel = ref();

    const defaultConfig = {
      classTo: "input-wrapper",
      errorClass: "has-danger",
      successClass: "has-success",
      errorTextParent: "input-wrapper",
      errorTextClass: "text-help",
    };
    const formElement = ref(null);
    const inputRef = ref(null);
    let pristine;

    const initPristine = () => {
      nextTick(() => {
        if (formElement.value) {
          pristine = new Pristine(formElement.value, defaultConfig);
        }
      });
    };

    const addCategoryItem = (selectedCategoryModel, inputModel) => {
      if (formifyClient()) {
        emit("addCategoryItem", selectedCategoryModel, inputModel);
      }
    };

    function closeModalFromBackground(event) {
      if (event.target.classList.contains("modal")) {
        emit("closeModal");
      }
    }

    function closeModal() {
      emit("closeModal");
    }

    const formifyClient = () => {
      if (pristine) {
        const valid = pristine.validate();
        if (valid) {
          pristine.reset();
          return true;
        }
      }
      return false;
    };

    onMounted(async () => {
      initPristine();

      if (props.lists.length > 0) {
        selectedCategoryModel.value = props.lists[0].name;
      }

      if (props.selectedCategoryName != null) {
        selectedCategoryModel.value = props.selectedCategoryName;
      }

      loading.value = false;
    });

    return {
      loading,
      formElement,
      selectedCategoryModel,
      inputModel,
      closeModalFromBackground,
      closeModal,
      addCategoryItem,
    };
  },
};
</script>
