<template>
  <div class="wrapper-content memories-collection">
    <div class="header space-between">
      <div class="splash-rounded splash-padding" v-if="!isGalleryDisplayed" @click="goBackToGallery()">
        <span class="material-symbols-rounded">
          arrow_back
        </span>
        <p>Înapoi către galerie</p>
      </div>

      <h1 v-else>{{ organizerName }}</h1>
      <h2 class="upper-case">nunta</h2>
    </div>

    <div id="loader-container" class="loader-container" v-if="loadingCollections">
      <vLottiePlayer name="lottie" class="lottie" loop :animationData="require('@/assets/lottie/memoriesCollectionLightAnimation.json')
        " />
    </div>

    <div class="content">
      <div v-if="!isGalleryDisplayed" id="upload-form" class="upload-form">
        <file-upload :id="id" ref="fileUploadRef" :url="url" :label="label" />

        <error-container :totalFileSize="totalFileSize" :extensionForbidden="extensionForbidden" />

        <user-form v-model:name="name" v-model:message="message" v-model:termsAndConditions1="termsAndConditions1"
          v-model:termsAndConditions="termsAndConditions" @toggleMessage="togglMessage" />
      </div>

      <div v-else-if="uploaders.length > 0">
        <div class="wrapper-uploaded">
          <div v-for="(uploader, index) in uploaders" :key="index" class="uploaded-card">
            <div v-if="uploader.mediaItems.length > 0">
              <div class="stackone-container">
                <div class="stackone" :style="{
                  backgroundImage: `url(${uploader.mediaItems[0].urlThumbnail})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  cursor: 'pointer',
                }" @click="showImage(0, uploader)" :alt="'First Image'"></div>
              </div>

              <h2 class="person-title">{{ uploader.name }}.</h2>
              <h3 v-if="uploader.mediaItems.length > 1" class="person-image-count">
                {{ uploader.mediaItems.length }} fisiere
              </h3>
              <h3 v-else class="person-image-count">
                {{ uploader.mediaItems.length }} fisier
              </h3>
            </div>
          </div>
        </div>
      </div>

      <LightBox ref="lightboxRef" :media="allMedia" :show-thumbs="false" :show-caption="true"
        :interface-hide-time="100000" :autoplay="false" :isClient="true">
        <template v-slot:customCaption="slotProps">
          <div class="caption">
            <div class="captionUser">
              {{ slotProps.currentMedia.name }}
            </div>
            <div class="captionContent">
              {{ slotProps.currentMedia.caption }}
            </div>
          </div>
        </template>
      </LightBox>

      <button class="button button-fixed" @click="handleUploadFormAction" :class="{ disabled: !isGalleryDisplayed }">
        <img v-if="isGalleryDisplayed" src="@/assets/img/uploadImageIcon.svg" alt="" width="20px" />
        <span v-if="isGalleryDisplayed">Încarcă fotografiile tale</span>
        <span v-else>Trimite către încarcare</span>
      </button>
    </div>
  </div>
</template>

<script>
import { reactive, ref, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import FileUpload from "@/components/Uploader/FileUpload.vue";
import { getMediaUploaders, uploadMedia, getGallery } from "@/api";
import * as Pristine from "pristinejs";
// import { LottieAnimation } from "lottie-web-vue"
import LightBox from "@/components/Lightbox/Lightbox.vue";
import { modal } from "@/store/modals.js";
import videoThumb1 from "@/assets/img/videoThumbnails/videoPlaceholder-1.jpg";
import videoThumb2 from "@/assets/img/videoThumbnails/videoPlaceholder-2.jpg";
import videoThumb3 from "@/assets/img/videoThumbnails/videoPlaceholder-3.jpg";
import videoThumb4 from "@/assets/img/videoThumbnails/videoPlaceholder-4.jpg";
import videoThumb5 from "@/assets/img/videoThumbnails/videoPlaceholder-5.jpg";

import ErrorContainer from "@/views/memoriesCollection/components/ErrorContainer.vue";
import UserForm from "@/views/memoriesCollection/components/UserForm.vue";

// import "vue-image-lightbox/dist/vue-image-lightbox.min.css";

const defaultConfig = {
  classTo: "input-wrapper",
  errorClass: "has-danger",
  successClass: "has-success",
  errorTextParent: "input-wrapper",
  errorTextTag: "p",
  errorTextClass: "text-help",
};

export default {
  name: "UploadView",

  components: {
    FileUpload,
    ErrorContainer,
    UserForm,

    LightBox,
  },
  setup() {
    const route = useRoute();

    const videoThumbImageList = [
      videoThumb1,
      videoThumb2,
      videoThumb3,
      videoThumb4,
      videoThumb5,
    ];
    const videoThumbSrcList = ref([]);
    const id = ref("");
    const label = ref("test");
    const url = ref("");
    const isGalleryDisplayed = ref(false);
    const showIndex = ref(null);
    const name = ref("");
    const allMedia = ref([]);
    const organizerName = ref("");
    const message = ref("");
    const termsAndConditions = ref(false);
    const termsAndConditions1 = ref(false);
    const validation = ref(false);
    const uploaders = ref([]);
    const totalFileSize = ref(0);
    const pageVisited = ref(false);
    const extensionForbidden = ref("");
    const extensionsVideo = [
      ".mp4",
      ".avi",
      ".mov",
      ".wmv",
      ".flv",
      ".mkv",
      ".hevc",
    ];
    const lightboxRef = ref(null);
    const fileUploadRef = ref(null);
    const loadingCollections = ref(true);

    // TODO: Use this variable to add loader to the page
    const loadingGallery = ref(true);

    const getRandomNumber = () => {
      const random = Math.random();
      const randomNumber = Math.floor(random * 4);
      return randomNumber;
    };

    const isVideo = (item) => {
      return extensionsVideo.includes(
        item.url.substring(item.url.lastIndexOf(".")).toLowerCase()
      );
    };

    const showImage = (index, uploader) => {
      const mediaItemsBeforeIndex = getMediaItemsBeforeIndex(uploader);
      lightboxRef.value.showImage(mediaItemsBeforeIndex, allMedia.value);
    };

    const getMediaItemsBeforeIndex = (uploader) => {
      const uploaderIndex = uploaders.value.indexOf(uploader);
      const uploadersBeforeIndex = uploaders.value.slice(0, uploaderIndex);
      return uploadersBeforeIndex.reduce(
        (total, uploader) => total + uploader.mediaItems.length,
        0
      );
    };

    const formifyClient = () => {
      const pristine = new Pristine(
        document.getElementById("form"),
        defaultConfig
      );
      const valid = pristine.validate();
      if (valid) {
        pristine.reset();
        pristine.destroy();
        validation.value = true;
      }
    };

    const checkImages = async () => {
      try {
        uploaders.value = await getMediaUploaders(id.value);

        allMedia.value = uploaders.value.flatMap((uploader) =>
          uploader.mediaItems.map((item) => createMediaItem(uploader, item))
        );

        isGalleryDisplayed.value = uploaders.value.length > 0;

        hideLoader();

        if (!pageVisited.value) {
          modal.displayModal(true, true, "uploadGallery");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const createMediaItem = (uploader, item) => {
      if (isVideo(item)) {
        item.urlThumbnail = videoThumbSrcList.value[getRandomNumber()];
        let videoType = getVideoType(item.url);
        return {
          sources: [
            {
              src: item.url,
              type: `video/${videoType}`,
              caption: uploader.message,
            },
          ],
          thumb: videoThumbSrcList.value[getRandomNumber()],
          width: 400,
          height: 600,
          type: "video",
          caption: uploader.message,
          name: uploader.name,
        };
      } else {
        item = reactive(item);
        return {
          src: item.url,
          thumb: item.urlThumbnail,
          type: "image",
          name: uploader.name,
          caption: uploader.message,
        };
      }
    };

    const getVideoType = (url) => {
      let videoType = url.substring(url.lastIndexOf(".") + 1);
      return videoType.toLowerCase() === "mov" ? "mp4" : videoType;
    };

    const hideLoader = () => {
      if (document.querySelector(".loader-container")) {
        document.querySelector(".loader-container").classList.add("anim-exit");
        setTimeout(() => {
          document.querySelector(".loader-container").classList.add("disabled");
          loadingCollections.value = false;
        }, 500);
      }
    };

    const togglMessage = () => {
      document.querySelector(".special-message").classList.add("disabled");
      setTimeout(() => {
        document.querySelector(".special-message").classList.add("hidden");
        document.querySelector(".textAreaWrapper").classList.add("active");
      }, 300);
    };

    const goBackToGallery = () => {
      isGalleryDisplayed.value = true;

      document.querySelector(".file-upload-error").classList.remove("add");
      document.querySelector(".button").classList.remove("disabled");

      document.querySelector(".upload-form-inner").classList.remove("pulse");
    };

    const buttonEnabler = () => {
      const areAllConditionsMet =
        fileUploadRef.value &&
        fileUploadRef.value.temporaryFiles.length !== 0 &&
        name.value !== "" &&
        termsAndConditions.value &&
        termsAndConditions1.value;

      if (areAllConditionsMet) {
        document.querySelector(".button").classList.remove("disabled");
      } else {
        document.querySelector(".button").classList.add("disabled");
      }
    };

    const handleUploadFormAction = () => {
      if (isGalleryDisplayed.value) {
        hideGallery();
      } else {
        processUploadForm();
      }
    };

    const hideGallery = () => {
      isGalleryDisplayed.value = false;
      addClass(".button", "disabled");
    };

    const processUploadForm = () => {
      formifyClient();
      if (fileUploadRef.value.temporaryFiles.length === 0) {
        showUploadError();
      } else {
        if (validation.value && fileUploadRef.value.temporaryFiles.length > 0) {
          fileUploadRef.value.upload();
          isGalleryDisplayed.value = false;
        }
        hideUploadError();
      }
    };

    const showUploadError = () => {
      removeClass(".file-upload-error", "disabled");
      addClass(".upload-form-inner", "pulse");
      setTimeout(() => {
        removeClass(".upload-form-inner", "pulse");
      }, 1250);
    };

    const hideUploadError = () => {
      removeClass(".file-upload-error", "add");
      removeClass(".button", "disabled");
      removeClass(".upload-form-inner", "pulse");
    };

    const addClass = (selector, className) => {
      document.querySelector(selector).classList.add(className);
    };

    const removeClass = (selector, className) => {
      document.querySelector(selector).classList.remove(className);
    };

    const saveFiles = async (files) => {
      const postData = createPostData(files);
      try {
        await uploadMedia(id.value, postData);
        isGalleryDisplayed.value = true;
        await updateUploaders();
        animateButton();
      } catch (error) {
        console.error(error);
      }
    };

    const createPostData = (files) => {
      return {
        MediaItems: files,
        Message: message.value,
        Name: name.value,
        TermsAndConditions: termsAndConditions.value,
      };
    };

    const updateUploaders = async () => {
      uploaders.value = await getMediaUploaders(id.value);
      uploaders.value.forEach((uploader) => {
        uploader.mediaItems.forEach((item) => {
          if (isVideo(item)) {
            item.urlThumbnail = videoThumbSrcList.value[getRandomNumber()];
          }
        });
      });

      allMedia.value = uploaders.value.flatMap((uploader) =>
        uploader.mediaItems.map((item) => createMediaItem(uploader, item))
      );
    };

    const animateButton = () => {
      const button = document.querySelector(".button");
      button.classList.add("success");
      setTimeout(() => {
        button.classList.remove("success");
        button.classList.remove("loading");
      }, 3000);
    };

    const loadGallery = async () => {
      try {
        const gallery = await getGallery(id.value);
        organizerName.value = gallery.name;
      } catch (error) {
        console.error("Error:", error);
      }
      loadingGallery.value = false;
    };

    // Watchers
    watch(
      [uploaders, name, termsAndConditions, termsAndConditions1],
      buttonEnabler
    );

    // Mounted
    onMounted(async () => {
      id.value = route.params.offerId;
      checkVisitedPage();
      await fetchVideoThumbnails();
      loadGallery();
      localStorage.setItem("visitedMemoryCollectionPage", "true");
    });

    const checkVisitedPage = () => {
      const visitedPage = localStorage.getItem("visitedMemoryCollectionPage");
      if (visitedPage) {
        pageVisited.value = true;
      }
    };

    const fetchVideoThumbnails = async () => {
      try {
        const videoThumbs = await Promise.all(
          videoThumbImageList.map(fetchThumbnail)
        );
        videoThumbSrcList.value = videoThumbs;
        checkImages();
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    const fetchThumbnail = (thumb) =>
      fetch(thumb)
        .then((response) => response.blob())
        .then((blob) => URL.createObjectURL(blob));

    return {
      loadingCollections,
      videoThumbImageList,
      videoThumbSrcList,
      id,
      label,
      url,
      isGalleryDisplayed,
      showIndex,
      name,
      organizerName,
      message,
      termsAndConditions,
      termsAndConditions1,
      validation,
      uploaders,
      totalFileSize,
      pageVisited,
      extensionForbidden,
      extensionsVideo,
      lightboxRef,
      fileUploadRef,
      loadingGallery,
      getRandomNumber,
      isVideo,
      showImage,
      formifyClient,
      checkImages,
      togglMessage,
      goBackToGallery,
      buttonEnabler,
      handleUploadFormAction,
      saveFiles,
      allMedia,
    };
  },
};
</script>
<style scoped>
.masonry-item {
  margin-bottom: 10px;
}

.item-image {
  width: 100%;
  display: block;
}

.items {
  border: solid 1px;
}

.item {
  border: solid 1px aqua;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(100px, auto);

  padding: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-auto-flow: dense;
}

.grid-item {
  border: solid 1px aqua;
  display: grid-item;
}

.grid-item[data-aspect="sq"] {
  grid-column-end: span 1;
  grid-row-end: span 1;
}

.grid-item[data-aspect="h"] {
  grid-column-end: span 1;
  grid-row-end: span 2;
}

.grid-item[data-aspect="v"] {
  grid-column-end: span 2;
  grid-row-end: span 1;
}

.grid-item img {
  vertical-align: bottom;
}
</style>
