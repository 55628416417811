<template>
  <section id="success" class="hidden">
    <p>
      We appreciate your business! A confirmation email will be sent to
      {{ customerEmail }}. If you have any questions, please email
      <a href="'mailto:contact@example.com">contact@example.com></a>.
    </p>
  </section>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { getPaymentSession, createInvoice } from "@/api";
import { useAuth0 } from "@auth0/auth0-vue";

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const customerEmail = ref("");
    const { getAccessTokenSilently, user } = useAuth0();

    onMounted(async () => {
      const sessionId = router.currentRoute.value.query.session_id;
      const token = await getAccessTokenSilently();

      const offerId = route.params.offerId;
      const invitationId = route.params.invitationId;

      const session = await getPaymentSession(
        token,
        sessionId,
        offerId,
        invitationId
      );

      if (session.status == "open") {
        router.push("/checkout");
      } else if (session.status == "complete") {
        document.getElementById("success").classList.remove("hidden");
        customerEmail.value = session.customer_email;

        await createInvoice(token, null, offerId, session.customer_email);
      }
    });

    return {
      customerEmail,
    };
  },
};
</script>

<style>
.hidden {
  display: none;
}
</style>
