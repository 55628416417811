import { createRouter, createWebHistory } from 'vue-router'
// import UploadView from './views/deprecated-UploadView.vue'
import NotFound from '@/views/NotFound.vue'
// import Dashboard from '@/views/Dashboard.vue'
import Settings from '@/views/settings/Settings.vue'
import Events from '@/views/Events.vue'
import MemoriesCollections from '@/views/memoriesCollection/MemoriesCollections.vue'
import MemoriesCollectionAdmin from '@/views/memoriesCollection/MemoriesCollectionAdmin.vue'
import MemoriesCollectionClient from '@/views/memoriesCollection/MemoriesCollectionClient.vue'
import Dashboard from '@/views/dashboard/Dashboard.vue'
import Budget from '@/views/budget/Budget.vue'
import EventService from '@/views/dashboard/eventService/EventService.vue'
import Guestlist from '@/views/guestlist/Guestlist.vue'
import Invitations from '@/views/invitations/Invitations.vue'
import InvitationDemo from '@/views/invitation/InvitationDemo.vue'
import SelectInvitations from '@/views/invitations/SelectInvitations.vue'
import Invitation from '@/views/invitation/Invitation.vue'
import InvitationWizard from '@/views/invitation/components/InvitationWizard.vue'
import FloorPlanBuilder from '@/views/floorPlan/FloorPlanBuilder.vue'
import FloorPlanViewer from '@/views/floorPlan/FloorPlanViewer.vue'
import FloorPlanPrinter from '@/views/floorPlan/FloorPlanPrinter.vue'
import Checklist from '@/views/checklist/Checklist.vue'
import Timeline from '@/views/timeline/Timeline.vue'
import StripeReturn from "@/components/Stripe/StripeReturn.vue"
import authGuard from "@/authGuard";
import FloorPlans from './views/floorPlan/FloorPlans.vue';
import InvitationCard from "@/views/invitation/InvitationCard.vue"

const routes = [
    {
        path: "/events",
        name: "Events",
        component: Events,
        beforeEnter: authGuard,
        meta: {
            ogImage: require('@/assets/img/ogImages/og.jpg')
        }
    },
    {
        path: "/",
        redirect: "/events",
    },
    {
        path: "/settings",
        name: "Settings",
        component: Settings,
        beforeEnter: authGuard,
    },
    {
        path: "/dashboard/:offerId",
        name: "Dashboard",
        component: Dashboard,
        beforeEnter: authGuard,
    },
    {
        path: "/budget/:offerId",
        name: "Budget",
        component: Budget,
        beforeEnter: authGuard,
    },
    {
        path: "/myEvent/service",
        name: "EventService",
        component: EventService,
        beforeEnter: authGuard,
    },
    {
        path: "/memoriesCollections",
        name: "MemoriesCollections",
        component: MemoriesCollections,
        beforeEnter: authGuard,
    },
    {
        path: "/invitations/:offerId",
        name: "Invitations",
        component: Invitations,
        beforeEnter: authGuard,
    },
    {
        path: "/invitations/:offerId/select",
        name: "Invitations Select",
        component: SelectInvitations,
        beforeEnter: authGuard,
    },
    {
        path: "/invitation/:offerId/:invitationId",
        name: "InvitationWithId",
        component: Invitation,
    },
    {
        path: "/invitation/:offerId",
        name: "Invitation",
        component: Invitation,
    },
    {
        path: "/invitation/:offerId/:invitationId/edit",
        name: "InvitationEdit",
        component: Invitation,
        beforeEnter: authGuard,
    },
    {
        path: "/invitation/card",
        name: "InvitationCard",
        component: InvitationCard,
        beforeEnter: authGuard,
    },
    {
        path: "/invitation/:offerId/:invitationId/wizard",
        name: "InvitationWizard",
        component: InvitationWizard,
        beforeEnter: authGuard,
    },
    {
        path: "/invitation/demo/:templateName",
        name: "InvitationDemo",
        component: InvitationDemo,
    },
    {
        path: "/memoriesCollectionAdmin/:offerId",
        name: "MemoriesCollectionAdmin",
        component: MemoriesCollectionAdmin,
        beforeEnter: authGuard,
    },
    {
        path: "/memoriesCollection/:offerId",
        name: "MemoriesCollection",
        component: MemoriesCollectionClient,
    },
    {
        path: "/guestlist/:offerId",
        name: "Guestlist",
        component: Guestlist,
        beforeEnter: authGuard,
    },
    {
        path: "/floorplans/:offerId",
        name: "FloorPlans",
        component: FloorPlans,
        beforeEnter: authGuard,
    },
    {
        path: "/floorPlan/:offerId/:floorplanId/builder",
        name: "Floor Plan Builder",
        component: FloorPlanBuilder,
        beforeEnter: authGuard,
    },
    {
        path: "/floorPlan/:offerId/:floorplanId/viewer",
        name: "Floor Plan Viewer",
        component: FloorPlanViewer,
        beforeEnter: authGuard,
    },
    {
        path: "/floorPlan/:offerId/:floorplanId/printer",
        name: "Floor Plan Printer",
        component: FloorPlanPrinter,
        beforeEnter: authGuard,
    },
    {
        path: "/checklist/:offerId",
        name: "Checklist",
        component: Checklist,
        beforeEnter: authGuard,
    },
    {
        path: "/timeline/:offerId",
        name: "Timeline",
        component: Timeline,
        beforeEnter: authGuard
    },
    {
        path: "/paymentSuccess/:offerId/:invitationId",
        name: "StripeReturn",
        component: StripeReturn,
        beforeEnter: authGuard,
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: NotFound
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router