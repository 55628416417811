<template>
  <div class="modal bottom-sheet-on-mobile" id="add-guest-modal" :class="{ active: showAddGuestModal }"
    @click="closeModalFromBackground">
    <div v-if="invitees.length > 0" class="modal-content">
      <div class="modal-header">
        <h1>Adaugă invitat</h1>
        <span class="close-modal splash-rounded material-symbols-rounded fill" @click="closeModal">
          close
        </span>
      </div>

      <form ref="formElement" v-show="!loading" @submit.prevent @submit="sendInvitationResponse(true)">
        <div class="input-wrapper">
          <input class="input" v-model="invitees[0].name" type="text" id="name" name="name"
            placeholder="Mariana Popescu" placeholder-shown="false" autocomplete="off" required minlength="2"
            data-pristine-minlength-message="Introduceti minim 2 caractere"
            data-pristine-required-message="Câmp obligatoriu" />
          <label class="label" for="name">Prenume si nume*</label>
          <img class="checked" src="@/assets/img/check.svg" alt="" />
          <img class="error" src="@/assets/img/error.svg" alt="" />
        </div>
        <div class="input-wrapper">
          <input class="input" v-model="invitees[0].phone" type="text" id="number" name="number"
            placeholder="+40 XXX XXX XXX" placeholder-shown="false" autocomplete="off" required minlength="2"
            data-pristine-minlength-message="Introduceti minim 2 caractere"
            data-pristine-required-message="Câmp obligatoriu" />
          <label class="label" for="number">Numar de telefon*</label>
          <img class="checked" src="@/assets/img/check.svg" alt="" />
          <img class="error" src="@/assets/img/error.svg" alt="" />
        </div>

        <div class="number-of-persons-section">
          <h2>Număr de persoane</h2>
          <div class="number-of-persons-container">
            <div class="number-of-persons">
              <span class="material-symbols-rounded fill" :class="{ active: invitees.length > 0 }">
                person
              </span>
              <span class="material-symbols-rounded fill" :class="{ active: invitees.length > 1 }">
                person
              </span>
              <span class="material-symbols-rounded fill" :class="{ active: invitees.length > 2 }">
                person
              </span>
              <span class="material-symbols-rounded fill" :class="{ active: invitees.length > 3 }">
                person
              </span>
              <span class="material-symbols-rounded fill" :class="{ active: invitees.length > 4 }">
                person
              </span>
            </div>

            <div class="persons-actions">
              <div class="rounded-button" @click="removePersons()">
                <span class="material-symbols-rounded fill"> remove </span>
              </div>
              <p>{{ invitees.length }}</p>
              <div class="rounded-button" @click="addPersons()">
                <span class="material-symbols-rounded fill"> add </span>
              </div>
            </div>
          </div>
        </div>
        <div class="person-questions" v-if="invitees.length != 0">
          <div ref="formElement" v-for="invitee in invitees" :key="invitee" class="question">
            <h3>Person {{ invitees.indexOf(invitee) + 1 }}</h3>
            <div class="input-wrapper">
              <input class="input" v-model="invitee.name" type="text" id="name" name="name"
                placeholder="Mariana Popescu" placeholder-shown="false" autocomplete="off" required minlength="2"
                data-pristine-minlength-message="Introduceti minim 2 caractere"
                data-pristine-required-message="Câmp obligatoriu" />
              <label class="label" for="name">Prenume si nume*</label>
              <img class="checked" src="@/assets/img/check.svg" alt="" />
              <img class="error" src="@/assets/img/error.svg" alt="" />
            </div>
            <div v-for="question in invitation.questions" :key="question" class="question" :id="'question' +
              invitees.indexOf(invitee) +
              invitation.questions.indexOf(question)
              ">
              <p>{{ question.value }}</p>
              <select v-model="invitees[invitees.indexOf(invitee)].questions.find(
                (q) => q.questionId === question.id
              ).answerId
                ">
                <option v-for="answer in question.answers" :key="answer.id" :value="answer.id">
                  {{ answer.value }}
                </option>
              </select>
              <div class="alert alert-danger margin-top-10" style="display: none">
                <i class="icon-remove-sign"></i>Te rog să ne spui dacă dorești
                cazare.
              </div>
            </div>
          </div>
        </div>
      </form>

      <div class="modal-footer">
        <button class="primary small" @click="sendInvitationResponse(true)">
          Adaugă invitat
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, nextTick /*watch*/ } from "vue";
import { getInvitation } from "@/api";
import * as Pristine from "pristinejs";

export default {
  name: "AddGuestModal",
  props: {
    showAddGuestModal: Boolean,
    offerId: String,
  },
  setup(props, { emit }) {
    const invitees = ref([]);
    const invitationId = ref(0);
    const invitation = ref(null);
    const loading = ref(true);

    const defaultConfig = {
      classTo: "input-wrapper",
      errorClass: "has-danger",
      successClass: "has-success",
      errorTextParent: "input-wrapper",
      errorTextClass: "text-help",
    };
    const formElement = ref(null);
    let pristine;

    const initPristine = () => {
      nextTick(() => {
        if (formElement.value) {
          pristine = new Pristine(formElement.value, defaultConfig);
        }
      });
    };

    // watch([() => props.showAddGuestModal, () => invitation.value.questions], initPristine, { deep: true, immediate: false });

    const addPersons = () => {
      invitees.value.push({ questions: getQuestions() });
    };

    const getQuestions = () => {
      const questions = [];
      invitation.value.questions.forEach((question) => {
        questions.push({
          questionId: question.id,
          answerId: question.answers.length > 0 ? question.answers[0].id : "",
        });
      });

      return questions;
    };

    const removePersons = () => {
      if (invitees.value.length > 1) {
        invitees.value.pop();
      }
    };

    function closeModalFromBackground(event) {
      if (event.target.classList.contains("modal")) {
        emit("closeModal");
      }
    }

    function closeModal() {
      emit("closeModal");
    }

    const formifyClient = () => {
      if (pristine) {
        const valid = pristine.validate();
        if (valid) {
          pristine.reset();
          return true;
        }
      }
      return false;
    };

    const sendInvitationResponse = (confirm) => {
      if (formifyClient()) {
        if (confirm === true) {
          invitees.value[0].confirmed = true;
        } else {
          invitees.value[0].confirmed = false;
        }

        emit("sendInvitationResponse", invitees.value, invitationId.value);
      }
    };

    onMounted(async () => {
      //TODO: get invitation list from the API -> to decide how to handle different types of invitations

      invitation.value = await getInvitation(props.offerId);

      invitees.value = [{ questions: getQuestions() }];
      initPristine();

      loading.value = false;
    });

    return {
      invitees,
      invitation,
      loading,
      formElement,
      sendInvitationResponse,
      closeModalFromBackground,
      addPersons,
      removePersons,
      closeModal,
    };
  },
};
</script>
