<template>
  <div class="card module my-privent-card memories-collection-card" :class="[containerClass]">
    <div class="inside">
      <div class="text-content">
        <h2>Timeline</h2>
        <div class="module-button" @click="onClick">
          <span class="material-symbols-rounded fill">
            calendar_clock
          </span>
          <p>Vezi<br>timelineul</p>
        </div>
      </div>
      <div class="elements-content">
        <div class="detail-content">
          <div class="group countdown">
            <Countdown :flipAnimation="false" :deadline="formattedDate" :labels="{days: 'zile',hours: 'ore',minutes: 'minute',seconds: 'secunde',}"/>
            <h2>până la<br>eveniment</h2>
          </div>
        </div>
      </div>
    </div>
    <img class="background-image" :src="resolvedImage" alt="Timeline Background">
  </div>
</template>
<script>
import { computed } from 'vue';
import { Countdown } from 'vue3-flip-countdown'

export default {
  name: 'Timeline-Card',
  components: {
    Countdown
  },
  props: {
    isActivated: { type: Boolean },
    timeTillEvent: { type: String },
    onClick: { type: Function },
    containerClass: {
      type: String,
      default: ''
    },
  },
  computed: {

  },
  setup(props) {
    const resolvedImage = computed(() => {
      return require('@/assets/img/dashboard/' + 'timelineBackground.png');
    });

    const formattedDate = computed(() => {
      const timeTillEvent = props.timeTillEvent;
      if (!timeTillEvent) {
        return '';
      }
      const [datePart, timePart] = timeTillEvent.split(' ');
      const [day, month, year] = datePart.split('.');
      const date = new Date(year, month - 1, day, ...timePart.split(':')); // Remove the -1 from month

      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${timePart}`;
    });

    return {
      formattedDate,
      resolvedImage,
    };
  }
}
</script>