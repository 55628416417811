<template>
  <div class="card invoice" @click="redirectToInvoice">
    <div class="row align-x-space-between align-y-center">
      <div class="column">
        <h3>{{ number }}</h3>
        <h2 class="">Factura finala</h2>
      </div>

      <span class="material-symbols-rounded fill main gradient green">
        file_present
      </span>

    </div>

  </div>
</template>
<script>
import { ref } from 'vue';

export default {
  name: 'Invoice-Card',
  props: {
    invoiceNumber: {
      type: String,
      required: true
    },
    invoiceUrl: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const number = ref(props.invoiceNumber);
    const url = ref(props.invoiceUrl);

    const redirectToInvoice = () => {
      window.location.href = url.value;
    };

    return {
      number,
      redirectToInvoice
    };
  }
}
</script>