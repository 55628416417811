<template>
  <div class="wrapper-content invitations see-all">
    <add-edit-floor-plan-modal
      :showAddEditFloorPlanModel="showAddEditFloorPlanModel"
      :isEditing="false"
      @createFloorplan="createFloorplanFunction"
      @closeModal="closeAddEditFloorPlan()"
    />

    <div class="header hide-bg-on-mobile space-between">
      <div
        class="vertical-align gap-5 splash-rounded splash-padding"
        @click="this.$router.go(-1)"
      >
        <span class="material-symbols-rounded"> arrow_back </span>
        <h1>Floorplans</h1>
      </div>
      <div class="actions">
        <div class="header-button primary" @click="showAddEditFloorPlan()">
          <div class="highlight">
            <span class="material-symbols-rounded"> library_add </span>
          </div>
          <div class="text">Adaugǎ floorplan</div>
        </div>
      </div>
    </div>
    <div class="content-loading" v-if="loading">
      <vLottiePlayer
        name="lottie"
        class="lottie"
        loop
        :animationData="require('@/assets/lottie/simpleDotsLoader.json')"
      />
    </div>
    <div class="content" v-else>
      <div class="empty-state" v-if="floorplans.length === 0"
      >
         <img src="@/assets/img/emptyStates/emptyCollection.svg" alt="" />
         <div class="text-content">
          <h2>Nu există nici un floorplan creat</h2>
          <p>Poți avea mai multe floorplan-uri pentru un singur eveniment. Lista cu acestea va fi afișată aici după creare.</p>
          <button @click="showAddEditFloorPlan()"><span class="material-symbols-rounded"> library_add </span><p>Creează floorplan</p></button>
         </div>
      </div>
      <table-component v-else>
        <tr
          v-for="floorplan in floorplans"
          :key="floorplan.id"
          @click="redirectToFloorplan(floorplan.id)"
        >
          <td class="name">
            {{ floorplan.name }}
          </td>
          <td class="actions">
            <div class="horizontal" v-if="this.isDesktop()">
              <div class="action" @click.stop="duplicate(floorplan.id)">
                <span class="material-symbols-rounded"> file_copy </span>
              </div>
              <div class="action" @click.stop="remove(floorplan.id)">
                <span class="material-symbols-rounded"> delete </span>
              </div>
            </div>
            <div v-else>
              <dropdown-menu direction="right" @click.stop>
                <template #trigger>
                  <div class="action">
                    <span class="material-symbols-rounded"> grid_view </span>
                  </div>
                </template>

                <template #body>
                  <div
                    class="dropdown-action"
                    @click.stop="duplicate(floorplan.id)"
                  >
                    <span class="material-symbols-rounded"> file_copy </span>
                    Duplică
                  </div>
                  <div
                    class="dropdown-action"
                    @click.stop="remove(floorplan.id)"
                  >
                    <span class="material-symbols-rounded"> delete </span>
                    Șterge
                  </div>
                </template>
              </dropdown-menu>
            </div>
          </td>
        </tr>
      </table-component>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  getFloorplans,
  removeFloorplan,
  duplicateFloorplan,
  createFloorplan,
} from "@/api";
import TableComponent from "@/views/floorPlan/components/TableComponent.vue";
import DropdownMenu from "v-dropdown-menu";
import { format, parseISO } from "date-fns";
import VueLottiePlayer from "vue-lottie-player";
import { useAuth0 } from "@auth0/auth0-vue";
import { notify } from "@kyvg/vue3-notification";
import AddEditFloorPlanModal from "@/views/floorPlan/components/AddEditFloorPlanModal.vue";

export default {
  name: "Floor-Plans",
  components: {
    TableComponent,
    DropdownMenu,
    vLottiePlayer: VueLottiePlayer,
    AddEditFloorPlanModal,
  },
  methods: {
    isDesktop() {
      const screenWidth = window.innerWidth;
      return screenWidth > 575.98;
    },
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { getAccessTokenSilently } = useAuth0();

    const offerId = ref("");
    const floorplans = ref([]);
    const loading = ref(true);

    const showAddEditFloorPlanModel = ref(false);

    const openDrawer = () => {
      document.querySelector(".drawer-wrapper").classList.add("open");
    };

    const redirectToFloorplan = (floorplanId) => {
      router.push(`/floorplan/${offerId.value}/${floorplanId}/viewer`);
    };

    const redirectToFloorplanBuilder = (floorplanId) => {
      router.push(`/floorplan/${offerId.value}/${floorplanId}/builder`);
    };

    const createFloorplanFunction = async (
      shapeIndex,
      realWidth,
      realHeight
    ) => {
      const token = await getAccessTokenSilently();

      const floorplanId = await createFloorplan(token, offerId.value, {
        Name: `Floorplan_${new Date().toLocaleString()}`,
        ShapeIndex: shapeIndex.value,
        RealWidth: realWidth.value,
        RealHeight: realHeight.value,
      });

      router.push(`/floorplan/${offerId.value}/${floorplanId}/builder`);

      //TODO: Trebuie sa ajunga toate datele astea in JSONul unui floor plan nou si implicit trebuie citte
    };

    const redirectToNewFloorplan = async () => {
      const token = await getAccessTokenSilently();

      const floorplanId = await createFloorplan(token, offerId.value, {
        Name: `Floorplan_${new Date().toLocaleString()}`,
        realWidth: 10,
        realHeight: 10,
      });

      router.push(`/floorplan/${offerId.value}/${floorplanId}/builder`);
    };

    const formatDate = (date) => {
      return format(parseISO(date), "dd.MM.yyyy");
    };

    const duplicate = async (floorplanId) => {
      loading.value = true;

      try {
        const token = await getAccessTokenSilently();

        await duplicateFloorplan(token, offerId.value, floorplanId);

        await retrieveFloorplans();
      } catch (e) {
        notify({
          type: "error",
          title: "Eroare",
          text: "Nu s-a putut duplica floorplan-ul.",
        });
      }

      loading.value = false;
    };

    const remove = async (invitationId) => {
      loading.value = true;

      try {
        const token = await getAccessTokenSilently();

        await removeFloorplan(token, offerId.value, invitationId);

        await retrieveFloorplans();
      } catch (e) {
        notify({
          type: "error",
          title: "Eroare",
          text: "Nu s-a putut șterge invitația",
        });
      }
      loading.value = false;
    };

    function showAddEditFloorPlan() {
      showAddEditFloorPlanModel.value = true;
    }

    function closeAddEditFloorPlan() {
      showAddEditFloorPlanModel.value = false;
    }

    const retrieveFloorplans = async () => {
      const token = await getAccessTokenSilently();

      floorplans.value = await getFloorplans(token, offerId.value);
    };

    onMounted(async () => {
      offerId.value = route.params.offerId;
      await retrieveFloorplans();
      loading.value = false;
    });

    return {
      floorplans,
      loading,
      offerId,
      formatDate,
      redirectToNewFloorplan,
      openDrawer,
      redirectToFloorplan,
      redirectToFloorplanBuilder,
      duplicate,
      createFloorplanFunction,
      remove,
      showAddEditFloorPlan,
      closeAddEditFloorPlan,
      showAddEditFloorPlanModel,
    };
  },
};
</script>
