<template>
  <div class="drawer-wrapper" @click="closeDrawer()">
    <add-new-event
      :showAddNewEvent="showAddNewEventModal"
      v-if="this.showAddNewEventModal"
      @closeModal="closeAddNewEventModal()"
    />
    <div class="drawer" @click.stop>
      <img
        class="logo"
        src="@/assets/img/brand/MyPriventDark.png"
        @click="navigateHome"
      />
      <img
        class="logo-collapsed"
        src="@/assets/img/brand/TriangleDarkColor.svg"
        @click="navigateHome"
      />
      <div class="drawer-content" :class="{ collapsed: isDrawerCollapsed }">
        <div class="drawer-section">
          <div class="section">
            <h1 @click="navigateAndCloseDrawer(`/events`)">My events</h1>
            <p @click="navigateAndCloseDrawer(`/events`)">Vezi toate</p>
          </div>

          <div class="events">
            <div v-if="loadingEvents" class="events">
              <div class="card event shimmer" v-for="index in 3" :key="index">
                <h1></h1>

                <div class="event-info">
                  <div class="event-info-container">
                    <p class="subtitle"></p>
                    <p class="title"></p>
                  </div>
                  <div class="event-info-container">
                    <p class="subtitle"></p>
                    <p class="title"></p>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              v-for="(event, index) in sortedEvents.slice(0, 3)"
              :key="event.offerId"
              :class="{ selected: route.path.includes('invitation') }"
            >
              <div class="card event-control" v-if="index == 0">
                <div
                  class="card event selected"
                  @click="navigateAndCloseDrawer(`/dashboard/${event.offerId}`)"
                >
                  <h1 class="dash-circle">{{ event.eventTypeString }}</h1>

                  <div class="event-info">
                    <div class="event-info-container">
                      <p class="subtitle">Data și ora</p>
                      <p class="title">{{ event.eventDateString }}</p>
                    </div>
                    <div class="event-info-container">
                      <p class="subtitle">Locația</p>
                      <p class="title">{{ event.location }}</p>
                    </div>
                  </div>
                </div>
                <div class="event-navigator">
                  <div
                    class="navigate-option"
                    :class="{
                      selected:
                        route.path.includes('invitation') ||
                        route.path.includes('invitations'),
                    }"
                    @click="
                      navigateAndCloseDrawer(`/invitations/${event.offerId}`)
                    "
                  >
                    <span class="material-symbols-rounded">
                      mark_as_unread
                    </span>
                    <p>Invitații</p>
                  </div>
                  <div
                    v-if="event.hasMemoriesCollection"
                    class="navigate-option"
                    :class="{
                      selected: route.path.includes('memoriesCollection'),
                    }"
                    @click="
                      navigateAndCloseDrawer(
                        `/memoriesCollectionAdmin/${event.offerId}`
                      )
                    "
                  >
                    <span class="material-symbols-rounded"> perm_media </span>
                    <p>Memories Collection</p>
                  </div>
                  <div
                    class="navigate-option"
                    :class="{ selected: route.path.includes('guestlist') }"
                    @click="
                      navigateAndCloseDrawer(`/guestlist/${event.offerId}`)
                    "
                  >
                    <span class="material-symbols-rounded"> group </span>
                    <p>Guestlist</p>
                  </div>
                  <div
                    class="navigate-option"
                    :class="{ selected: route.path.includes('checklist') }"
                    @click="
                      navigateAndCloseDrawer(`/checklist/${event.offerId}`)
                    "
                  >
                    <span class="material-symbols-rounded"> checklist </span>
                    <p>Checklist</p>
                  </div>
                  <div
                    class="navigate-option"
                    :class="{ selected: route.path.includes('timeline') }"
                    @click="
                      navigateAndCloseDrawer(`/timeline/${event.offerId}`)
                    "
                  >
                    <span class="material-symbols-rounded"> event_note </span>
                    <p>Timeline</p>
                  </div>
                  <div
                    class="navigate-option"
                    :class="{ selected: route.path.includes('budget') }"
                    @click="navigateAndCloseDrawer(`/budget/${event.offerId}`)"
                  >
                    <span class="material-symbols-rounded">
                      account_balance_wallet
                    </span>
                    <p>Buget</p>
                  </div>
                  <div
                    class="navigate-option"
                    :class="{ selected: route.path.includes('floorplan') }"
                    @click="
                      navigateAndCloseDrawer(`/floorplans/${event.offerId}`)
                    "
                  >
                    <span class="material-symbols-rounded">
                      table_restaurant
                    </span>
                    <p>Floorplans</p>
                  </div>
                </div>
              </div>

              <div
                v-else
                class="card event"
                @click="navigateAndCloseDrawer(`/dashboard/${event.offerId}`)"
              >
                <h1>{{ event.eventTypeString }}</h1>
                <div class="event-info">
                  <div class="event-info-container">
                    <p class="subtitle">Data și ora</p>
                    <p class="title">{{ event.eventDateString }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="events.length == 0 && !loadingEvents"
              class="card new-event"
              @click="addNewEvent()"
            >
              <h2>Adauga eveniment</h2>
              <span class="material-symbols-rounded"> add </span>
            </div>
          </div>
        </div>
      </div>

      <div class="footer">
        <div class="footer-link" @click="navigateAndCloseDrawer(`/settings`)">
          <span class="material-symbols-rounded"> discover_tune </span>

          <p>Setari</p>
        </div>
        <div
          class="footer-link hidden-on-mobile"
          @click="navigateAndCloseDrawer(`/settings`)"
        >
          <span class="material-symbols-rounded"> local_police </span>

          <p>Termeni si conditii</p>
        </div>
        <div
          class="footer-link hidden-on-mobile"
          @click="navigateAndCloseDrawer(`/settings`)"
        >
          <span class="material-symbols-rounded"> cookie </span>

          <p>Politica cookies</p>
        </div>
        <div
          class="footer-link hidden-on-mobile"
          @click="navigateAndCloseDrawer(`/settings`)"
        >
          <span class="material-symbols-rounded"> admin_panel_settings </span>

          <p>Politica de confidentialitate</p>
        </div>

        <div class="footer-link" v-if="userAuthenticated" @click="logout">
          <span class="material-symbols-rounded"> logout </span>

          <p>Deloghează-te</p>
        </div>

        <p class="copyright" v-if="isDrawerCollapsed">©2024</p>
        <p class="copyright" v-else>©2024 ERP Digital Collection SRL</p>
      </div>

      <div class="collapse-drawer" @click="collapseOrExpandDrawer">
        <span class="material-symbols-rounded"> keyboard_arrow_left </span>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import AddNewEvent from "@/components/AddEventModal.vue";
import { getClientOffers } from "@/api";
import { useAuth0 } from "@auth0/auth0-vue";
import anime from "animejs";
import $ from "jquery";

export default {
  name: "Drawer-Component",
  components: {
    AddNewEvent,
  },
  props: {
    isAuthenticated: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { user, getAccessTokenSilently, isLoading } = useAuth0();
    const router = useRouter();
    const route = useRoute();

    const showAddNewEventModal = ref(false);
    const events = ref([]);
    const loadingEvents = ref(true);
    const userAuthenticated = ref(props.isAuthenticated);

    const isDrawerCollapsed = ref(false);

    const closeDrawer = () => {
      if (event.target.classList.contains("drawer-wrapper")) {
        document.querySelector(".drawer-wrapper").classList.remove("open");
      }
    };

    const closeDrawerProgramatically = () => {
      document.querySelector(".drawer-wrapper").classList.remove("open");
    };

    const addNewEvent = () => {
      showAddNewEventModal.value = true;
    };

    const closeAddNewEventModal = () => {
      showAddNewEventModal.value = false;
    };

    const getEvents = async () => {
      let phone = "";
      await new Promise((resolve) => {
        const intervalId = setInterval(() => {
          if (!isLoading.value) {
            clearInterval(intervalId);
            resolve();
          }
        }, 100); // checks every 100ms
      });

      phone = user?.value.name;
      const token = await getAccessTokenSilently();

      let clientOffers = await getClientOffers(token, phone);

      events.value = clientOffers;

      loadingEvents.value = false;
    };

    const sortedEvents = computed(() => {
      const currentOfferId = route.params.offerId;
      if (!currentOfferId) return events.value;

      const sorted = [...events.value];
      const index = sorted.findIndex(
        (event) => event.offerId === currentOfferId
      );

      if (index !== -1) {
        const [event] = sorted.splice(index, 1);
        sorted.unshift(event);
      }

      return sorted.slice(0, 3);
    });

    const navigateHome = () => {
      router.push(`/`);
    };

    const navigateAndCloseDrawer = (route) => {
      closeDrawerProgramatically();
      router.push(route);
    };

    const collapseOrExpandDrawer = () => {
      if (isDrawerCollapsed.value) {
        expandDrawer();
      } else {
        collapseDrawer();
      }
    };

    const collapseDrawer = () => {
      anime({
        targets: ".drawer .logo",
        opacity: [1, 0],
        easing: "easeInOutQuad",
        duration: 150,
      });

      anime({
        targets: ".drawer-section .section",
        opacity: [1, 0],
        easing: "easeInOutQuad",
        duration: 50,
        delay: 50,
      });

      anime({
        targets:
          ".drawer-section .events .event-control .event-navigator p, .drawer-section .events .event .event-info, .drawer-section .events .event",
        opacity: [1, 0],
        easing: "easeInOutQuad",
        duration: 50,
        delay: 100,
      });

      anime({
        targets: ".drawer-section .events .event-control .event-navigator",
        padding: 5,
        easing: "easeInOutQuad",
        duration: 50,
        delay: 100,
      });

      anime({
        targets: ".footer-link p",
        opacity: [1, 0],
        easing: "easeInOutQuad",
        duration: 50,
        delay: 100,
      });

      anime({
        targets: "p.copyright",
        opacity: [1, 0],
        easing: "easeInOutQuad",
        duration: 50,
        delay: 150,
      });

      anime({
        targets: ".navigate-option, .drawer .footer .footer-link",
        padding: "10",
        easing: "easeInOutQuad",
        duration: 150,
        delay: 350,
      });

      anime({
        targets: ".navigate-option span, .footer-link span",
        fontSize: "1.5em",
        easing: "easeInOutQuad",
        duration: 150,
        delay: 350,
      });

      setTimeout(() => {
        isDrawerCollapsed.value = true;
        $(".drawer-wrapper").addClass("isCollapsing");
      }, 450);

      anime({
        targets: ".drawer-wrapper",
        width: 100,
        easing: "easeInOutQuad",
        duration: 50,
        delay: 450,
      });

      anime({
        targets: ".drawer-wrapper .drawer",
        width: 100,
        padding: 20,
        easing: "easeInOutQuad",
        duration: 50,
        delay: 450,
      });

      anime({
        targets: ".drawer .collapse-drawer span",
        rotate: 180,
        easing: "easeInOutQuad",
        duration: 150,
        delay: 450,
      });

      anime({
        targets: ".drawer .logo-collapsed",
        opacity: [0, 1],
        rotate: [15, 0],
        easing: "easeInOutQuad",
        duration: 50,
        delay: 650,
      });

      setTimeout(() => {
        isDrawerCollapsed.value = true;
        $(".wrapper").addClass("drawer-collapsed");
      }, 650);
    };

    const expandDrawer = () => {
      anime({
        targets: ".drawer .logo-collapsed",
        opacity: [1, 0],
        rotate: [0, 15],
        easing: "easeInOutQuad",
        duration: 50,
        delay: 0,
      });

      anime({
        targets: ".drawer .collapse-drawer span",
        rotate: 0,
        easing: "easeInOutQuad",
        duration: 150,
        delay: 50,
      });

      setTimeout(() => {
        $(".drawer-wrapper, .drawer-wrapper .drawer").css({
          width: "",
          padding: "",
        });
      }, 200);

      setTimeout(() => {
        isDrawerCollapsed.value = false;
        $(".drawer-wrapper").removeClass("drawer-collapsed isCollapsing");
        $(".wrapper").removeClass("drawer-collapsed");
      }, 250);

      setTimeout(() => {
        $(".navigate-option, .drawer .footer .footer-link").css({
          padding: "",
        });
      }, 300);

      anime({
        targets: ".navigate-option span, .footer-link span",
        fontSize: "1em",
        easing: "easeInOutQuad",
        duration: 150,
        delay: 300,
      });

      anime({
        targets: "p.copyright",
        opacity: [0, 1],
        easing: "easeInOutQuad",
        duration: 50,
        delay: 450,
      });

      anime({
        targets: ".footer-link p",
        opacity: [0, 1],
        easing: "easeInOutQuad",
        duration: 50,
        delay: 450,
      });

      anime({
        targets: ".drawer-section .events .event-control .event-navigator",
        padding: 10,
        easing: "easeInOutQuad",
        duration: 50,
        delay: 500,
      });

      anime({
        targets:
          ".drawer-section .events .event-control .event-navigator p, .drawer-section .events .event .event-info, .drawer-section .events .event",
        opacity: [0, 1],
        easing: "easeInOutQuad",
        duration: 50,
        delay: 550,
      });

      anime({
        targets: ".drawer-section .section",
        opacity: [0, 1],
        easing: "easeInOutQuad",
        duration: 50,
        delay: 600,
      });

      anime({
        targets: ".drawer .logo",
        opacity: [0, 1],
        easing: "easeInOutQuad",
        duration: 150,
        delay: 650,
      });
    };

    const logout = () => {
      emit("logout");
    };

    onMounted(() => {
      getEvents();
    });

    return {
      showAddNewEventModal,
      isDrawerCollapsed,
      events,
      loadingEvents,
      userAuthenticated,
      closeDrawer,
      collapseOrExpandDrawer,
      collapseDrawer,
      expandDrawer,
      addNewEvent,
      closeAddNewEventModal,
      getEvents,
      navigateHome,
      navigateAndCloseDrawer,
      logout,
      route,
      sortedEvents,
    };
  },
};
</script>
