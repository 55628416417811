<template>
  <div
    class="modal bottom-sheet-on-mobile"
    id="add-category-modal"
    :class="{ active: showEditCategoryModal }"
    @click="closeModalFromBackground"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h1>Editează categorie</h1>
        <span
          class="close-modal splash-rounded material-symbols-rounded fill"
          @click="closeModal"
        >
          close
        </span>
      </div>

      <form ref="formElement" v-show="!loading"  @submit.prevent @submit="editCategory(inputModel)">
        <div class="input-wrapper">
          <input
          v-model="inputModel"
            class="input"
            type="text"
            id="name"
            name="name"
            placeholder="Cheltuieli locație"
            placeholder-shown="false"
            autocomplete="off"
            required
            minlength="2"
            data-pristine-minlength-message="Introduceti minim 2 caractere"
            data-pristine-required-message="Câmp obligatoriu"
          />
          <label class="label" for="name">Nume categorie*</label>
          <img class="checked" src="@/assets/img/check.svg" alt="" />
          <img class="error" src="@/assets/img/error.svg" alt="" />
        </div>
      </form>

      <button class="primary small" @click="editCategory(inputModel)">
        Editează categorie
      </button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, nextTick /*watch*/ } from "vue";
import { getInvitation } from "@/api";
import * as Pristine from "pristinejs";

export default {
  name: "EditCategoryModal",
  props: {
    showEditCategoryModal: Boolean,
    selectedCategoryName: String,
    offerId: String,
  },
  setup(props, { emit }) {
    const loading = ref(true);
    const inputModel = ref();

    const defaultConfig = {
      classTo: "input-wrapper",
      errorClass: "has-danger",
      successClass: "has-success",
      errorTextParent: "input-wrapper",
      errorTextClass: "text-help",
    };
    const formElement = ref(null);
    let pristine;

    const initPristine = () => {
      nextTick(() => {
        if (formElement.value) {
          pristine = new Pristine(formElement.value, defaultConfig);
        }
      });
    };

    const editCategory = (inputModel) => {
      if (formifyClient()) {
        emit("editCategory", props.selectedCategoryName, inputModel);
      }
    };

    function closeModalFromBackground(event) {
      if (event.target.classList.contains("modal")) {
        emit("closeModal");
      }
    }

    function closeModal() {
      emit("closeModal");
    }

    const formifyClient = () => {
      if (pristine) {
        const valid = pristine.validate();
        if (valid) {
          pristine.reset();
          return true;
        }
      }
      return false;
    };

    onMounted(async () => {
      inputModel.value = props.selectedCategoryName;
      initPristine();
      loading.value = false;
    });

    return {
      loading,
      formElement,
      inputModel,
      closeModalFromBackground,
      closeModal,
      editCategory,
    };
  },
};
</script>
