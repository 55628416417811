import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createAuth0 } from '@auth0/auth0-vue';
import ModalPlugin from './modal-plugin';
import InvitationIcon from './helpers/invitationIconsHelper';
import mitt from 'mitt';
import '@/assets/less/privent.less';
import { AppInsightsPlugin } from 'vue3-application-insights';
import VueLottiePlayer from 'vue-lottie-player';
import { createHead, useHead } from 'unhead'

const app = createApp(App);
document.title = 'MyPrivent - Traiește evenimente memorabile';

const auth0Domain = process.env.VUE_APP_auth0_domain;
const auth0ClientId = process.env.VUE_APP_auth0_client_id;

const aiOptions = {
  appName: 'MyPrivent',
  appInsightsConfig: {
    config: {
      connectionString: process.env.VUE_APP_APP_INSIGHTS_KEY,
    },
  },
  router: router,
  trackAppErrors: true,
  trackInitialPageView: true,
};

app.use(
  createAuth0({
    domain: auth0Domain,
    clientId: auth0ClientId,
    connection: 'sms',
    authorizationParams: {
      redirect_uri: process.env.VUE_APP_auth0_host,
      scope: 'openid profile email phone',
      audience: process.env.VUE_APP_auth0_audience,
    },
    onRedirectCallback: (appState) => {
      router.push(
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname
      );
    },
    cacheLocation: 'localstorage',
  })
);

app.component('vLottiePlayer', VueLottiePlayer);

const emitter = mitt();

app.config.globalProperties.emitter = emitter;
app.use(router);

if (process.env.VUE_APP_eventApp_url !== 'http://localhost:8080') {
  app.use(router).use(AppInsightsPlugin, aiOptions);
}
app.component('InvitationIcon', InvitationIcon);
app.use(ModalPlugin);

createHead()

useHead({
  charset: 'utf-8',
  title: 'MyPrivent - Traiește evenimente memorabile',
  htmlAttrs: {
    lang: 'ro',
  },
  meta: [
    { name: 'HandheldFriendly', content: 'true' },
    { name: 'google', content: 'notranslate' },
    {
      name: 'viewport',
      content: 'width=device-width,initial-scale=1,maximum-scale=1,user-scalable=0, shrink-to-fit=no',
    },
    { name: 'theme-color', content: '#FFFDFB' },
    { name: 'color-scheme', content: 'light only' },
    { name: 'robots', content: 'index, nofollow' },
    {
      name: 'keywords',
      content: 'invitatii digitale, organizator evenimente, myprivent, privent, nunti, botezuri, cununie civila,corporate, invitatii, checklist eveniment, timeine eveniment, buget eveniment, budget eveniment, ',
    },
    {
      name: 'description',
      content: 'Organizează inteligent evenimentul tău prin invitații digitale, guestlist, floorplan, checklist și multe alte funcții.',
    },
    { name: 'publisher', content: 'Privent' },
    { property: 'og:title', content: 'MyPrivent - Traieste evenimente memorabile' },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: 'https://www.inviter.privent.ro' },
    {
      property: 'og:description',
      content: 'Organizează inteligent evenimentul tău prin invitații digitale, guestlist, floorplan, checklist și multe alte funcții.',
    },
    { property: 'og:image', content: `${process.env.VUE_APP_eventApp_url}/openGraph.jpg` },
    { property: 'twitter:title', content: 'MyPrivent - Traieste evenimente memorabile' },
    { property: 'twitter:type', content: 'website' },
    { property: 'twitter:url', content: 'https://www.inviter.privent.ro' },
    {
      property: 'twitter:description',
      content: 'Organizează inteligent evenimentul tău prin invitații digitale, guestlist, floorplan, checklist și multe alte funcții.',
    },
    { property: 'twitter:image', content: `${process.env.VUE_APP_eventApp_url}/openGraph.jpg` },
  ],
  link: [
    { rel: 'icon', href: `${process.env.VUE_APP_eventApp_url}/favicon.ico` }
  ],
});

app.mount('#app');
