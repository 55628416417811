<template>
  <div class="modal bottom-sheet-on-mobile" id="view-floor-plan-modal" :class="{ active: showViewFloorPlanModal }"
    @click="closeModalFromBackground">
    <div class="modal-content">
      <div class="modal-header">
        <div class="row">
          <h1>Masa {{ tableId }}</h1>
          <div class="persons">
            <span 
            class="material-symbols-rounded fill" 
            v-for="tablePerson in limitedTableSize" 
            :key="tablePerson" 
            :class="{ 'active': tablePerson <= persons.length }">
            person
          </span>
           
            <h3 class="number">{{ persons.length }} / {{ tableSize }} </h3>
          </div>
        </div>

        <span class="close-modal splash-rounded material-symbols-rounded fill" @click="closeModal">
          close
        </span>
      </div>

      <p class="explainer" v-if="tableIsFull">
        <span class="material-symbols-rounded"> warning </span>
        Ai așezat numărul maxim de persoane la această masă. Elimină alte persoane pentru a putea adăuga pe altcineva
      </p>

      <div class="seated-persons">
        <h2>Persoane așezate</h2>
        <div class="persons-list" v-if="persons.length > 0">
          <div class="person-container" v-for="person in persons" :key="person" @click="removePerson(person)">
            <div class="text">
              <span class="material-symbols-rounded fill"> person </span>
              <p>{{ person.name }}</p>
            </div>
            <span class="delete material-symbols-rounded" >
              remove
            </span>
          </div>
        </div>
      </div>
      <div class="search">
        <div class="search-bar">
          <input class="input" v-model="searchName" type="text" id="search" name="search"
            placeholder="Cauta o invitatie" placeholder-shown="true" autocomplete="off" required minlength="2"
            data-pristine-minlength-message="Introduceti minim 2 caractere"
            data-pristine-required-message="Câmp obligatoriu" />
          <span class="material-symbols-rounded"> search </span>
        </div>
        <div class="results">
          <div v-for="guest in filteredGuestlist" :key="guest" class="result" @click.self="!guest.tableName ? addPerson(guest) : removePerson(guest)">
            <div class="details">
              <p>{{ guest.name }}</p>

            </div>

            <span class="material-symbols-rounded fill" v-if="!guest.tableName">
              add
            </span>
            <div class="allocated-table" v-else-if="guest.tableName && guest.tableName != tableId">
              <span class="material-symbols-rounded fill">
                table_restaurant
              </span>
              <p>Masa {{ guest.tableName }}</p>

            </div>
            <span class="material-symbols-rounded fill" v-else>
              remove 
            </span>
          </div>
        </div>
      </div>
      <button class="primary small" @click="save">Salvează</button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, nextTick, computed } from "vue";
import * as Pristine from "pristinejs";

export default {
  name: "ViewFloorPlanTableModal",
  computed: {
    limitedTableSize() {
      return Math.min(this.tableSize, 6);
    }
  },
  props: {
    showViewFloorPlanModal: Boolean,
    offerId: String,
    persons: Array,
    guestlist: Array,
    tableId: String,
    tableSize: Number,
  },
  setup(props, { emit }) {
    const loading = ref(true);
    const widthModel = ref();
    const heightModel = ref();
    const searchName = ref("");
    const tableIsFull = ref(false);

    const defaultConfig = {
      classTo: "input-wrapper",
      errorClass: "has-danger",
      successClass: "has-succes",
      errorTextParent: "input-wrapper",
      errorTextClass: "text-help",
    };
    const formElement = ref(null);
    let pristine;

    const initPristine = () => {
      nextTick(() => {
        if (formElement.value) {
          pristine = new Pristine(formElement.value, defaultConfig);
        }
      });
    };

    const filteredGuestlist = computed(() => {
      if (props.guestlist.length > 0) {
        return props.guestlist.filter((guest) =>
          guest.name.toLowerCase().includes(searchName.value.toLowerCase())
        );
      } else {
        return props.guestlist;
      }
    });

    const addPerson = (person) => {
      if (props.persons.length < props.tableSize) {
        emit("addPersonsToTable", person);
      } else {
        tableIsFull.value = true;
      }
    };

    const removePerson = (person) => {
      emit("removePersonFromTable", person);
      tableIsFull.value = false;
    };

    const save = (person) => {
      emit("save");
    };

    function closeModalFromBackground(event) {
      if (event.target.classList.contains("modal")) {
        emit("closeModal");
      }
    }

    function closeModal() {
      emit("closeModal");
    }

    const formifyClient = () => {
      if (pristine) {
        const valid = pristine.validate();
        if (valid) {
          pristine.reset();
          return true;
        }
      }
      return false;
    };

    onMounted(async () => {
      initPristine();

      loading.value = false;
    });

    return {
      loading,
      widthModel,
      heightModel,
      formElement,
      searchName,
      filteredGuestlist,
      tableIsFull,
      save,
      addPerson,
      removePerson,
      closeModalFromBackground,
      closeModal,
    };
  },
};
</script>
