import { createApp, ref } from 'vue';
import Modal from './components/ModalGeneral.vue';

const modalPlugin = {
    install(app) {
        const isVisible = ref(false);
        const title = ref('');
        const subtitle = ref('');
        const buttonText = ref('');
        const modalContainer = document.createElement('div');
        document.body.appendChild(modalContainer);

        const modalApp = createApp(Modal, { isVisible, title, subtitle, buttonText });
        modalApp.mount(modalContainer);

        const showModal = (newTitle, newSubtitle, newButtonText) => {
            title.value = newTitle;
            subtitle.value = newSubtitle;
            buttonText.value = newButtonText;
            isVisible.value = true;
        };

        const hideModal = () => {
            isVisible.value = false;
        };

        app.config.globalProperties.$modal = {
            show: showModal,
            hide: hideModal,
        };
    },
};

export default modalPlugin;
