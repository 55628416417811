<template>
  <div class="modal bottom-sheet-on-mobile" id="edit-memories-collection"
    :class="{ active: showEditMemoriesCollectionModal }" @click="closeModal">
    <div class="modal-content">
      <h1>Editează invitație</h1>

      <div class="input-wrapper">
        <input class="input" type="text" id="name" v-model="name" name="name" placeholder="Andrei & Ioana"
          placeholder-shown="false" autocomplete="off" required minlength="2"
          data-pristine-minlength-message="Introduceti minim 2 caractere"
          data-pristine-required-message="Câmp obligatoriu" />
        <label class="label" for="name">Numele invitatiei*</label>
        <img class="checked" src="@/assets/img/check.svg" alt="" />
        <img class="error" src="@/assets/img/error.svg" alt="" />
      </div>

      <div class="modal-footer">
        <vLottiePlayer name="lottie" class="lottie loading" loop
          :animationData="require('@/assets/lottie/simpleDotsLoader.json')" v-if="loading" />
        <button class="primary small" @click="save" v-else>Editează colecție</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, toRefs } from 'vue';
import blobStorageHelper from '@/helpers/blobstoragehelper';
import { saveGalleryInformation } from '@/api';
import { useAuth0 } from '@auth0/auth0-vue';

export default {
  props: {
    showEditMemoriesCollectionModal: Boolean,
    galleryName: String,
    coverPhotoUrl: String,
    offerId: String
  },
  setup(props, { emit }) {
    const { getAccessTokenSilently } = useAuth0();

    const name = ref(props.galleryName);
    const loading = ref(false);

    const closeModal = (event) => {
      emit('closeModal', event);
    };

    const save = async () => {
      loading.value = true

      const token = await getAccessTokenSilently()

      const data = {
        Name: name.value,

      };

      await saveGalleryInformation(token, props.offerId, data);

      loading.value = false

      emit("loadGallery")

      closeModal();
    };

    return {
      ...toRefs(props),
      closeModal,
      save,
      name,

      loading
    };
  }
}
</script>
