<template>
  <div class="card service">
    <img :src="photo" alt="Balloons">

    <div class="service-content">
      <div class="top-details">
        <p class="secondary">DJ</p>
      </div>
      <h1>{{ name }}</h1>
      <button @click="redirectToProfile" class="primary extra-small">Vezi profilul</button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'Artist-Card',
  props: {
    artistPhoto: {
      type: String,
      required: true
    },
    artistName: {
      type: String,
      required: true
    },
    artistId: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const photo = ref(props.artistPhoto);
    const name = ref(props.artistName);
    const id = ref(props.artistId);

    const redirectToProfile = () => {
      window.location.href = `${process.env.VUE_APP_DjBooking}profile?artistId=${id.value}`;
    };

    return {
      photo,
      name,
      id,
      redirectToProfile
    };
  }
}
</script>