<template>
  <div
    class="modal bottom-sheet-on-mobile"
    id="payments-benefits-modal"
    :class="{ active: showPaymentBenefitsModal }"
    @click="closeModalFromBackground"
  >
    <div v-if="!showStripe" class="modal-content">
      <div class="modal-header">
        <h1>Deblochează toate beneficiile</h1>
        <span
          class="close-modal splash-rounded material-symbols-rounded fill"
          @click="closeModal"
        >
          close
        </span>
      </div>
      <div class="features">
        <div class="feature">
          <div class="icon">
            <span class="material-symbols-rounded"> chat_paste_go </span>
          </div>
          <div class="text">
            <h1 class="title">Primește răspunsuri nelimitate</h1>
            <p class="subtitle">
              Centralizează și gestionează toate răspunsurile detaliate în
              modulul <span class="highlight">Guestlist</span>.
            </p>
          </div>
        </div>
        <div class="feature">
          <div class="icon">
            <span class="material-symbols-rounded"> bolt </span>
          </div>
          <div class="text">
            <h1 class="title">Notificări la fiecare completare</h1>
            <p class="subtitle">
              Rămâi mereu la curent cu statusul evenimentului tău prin
              notificări automate.
            </p>
          </div>
        </div>
        <div class="feature">
          <div class="icon">
            <span class="material-symbols-rounded"> table_bar </span>
          </div>
          <div class="text">
            <h1 class="title">Construiește planul evenimentului tău</h1>
            <p class="subtitle">
              Creează în mod intuitiv planul evenimentului tău și așează
              invitații tăi prin modulul
              <span class="highlight">Floorplan</span>.
            </p>
          </div>
        </div>
        <div class="feature">
          <div class="icon">
            <span class="material-symbols-rounded"> captive_portal </span>
          </div>
          <div class="text">
            <h1 class="title">Link personalizat al invitației tale</h1>
            <p class="subtitle">
              Alege linkul pe care invitații tăi il vor primi complet gratuit.
              Ex:
              <span class="highlight">invitatie.privent.ro/IoanaAndrei</span>.
            </p>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="payment-button">
          <p class="disclaimer">
            Cu doar <span class="highlight">350 Lei</span> ai acces complet la
            toate beneficiile
          </p>
          <button class="primary small" @click="checkout">
            Mergi către plată
          </button>
        </div>
      </div>
    </div>

    <stripe-checkout v-else :offerId="offerId" :invitationId="invitationId" />
  </div>
</template>

<script>
import { ref } from "vue";
import StripeCheckout from "@/components/Stripe/StripeCheckout.vue";

export default {
  name: "PaymentBenefitsModal",
  props: {
    showPaymentBenefitsModal: Boolean,
    offerId: String,
    invitationId: Number,
  },
  components: {
    StripeCheckout,
  },
  setup(props, { emit }) {
    const invitationLink = ref(null);
    const baseLink = ref(process.env.VUE_APP_eventApp_url);
    const showStripe = ref(false);

    function closeModalFromBackground(event) {
      if (event.target.classList.contains("modal")) {
        emit("closeModal");
      }
    }

    function closeModal() {
      emit("closeModal");
    }

    function checkout() {
      showStripe.value = true;
    }

    return {
      closeModalFromBackground,
      closeModal,
      checkout,
      invitationLink,
      baseLink,
      showStripe,
    };
  },
};
</script>
