<template>
  <div class="invitation-wrapper greenish-vibes" :class="{
    isEditable: isEditable,
    'preview-tablet': isWidthMobile || isWidthTablet,
  }" @click="deselectTextElement">
    <change-location-modal :showChangeLocationModal="showChangeLocationModal" :initialLocation="initialLocation"
      :initialTitle="initialTitle" :initialDate="initialDate" :initialTime="initialTime" v-if="showChangeLocationModal"
      :locationToChange="modalLocationToChange" @changeLocation="changeLocation"
      @closeModal="closeChangeLocationModal" />
    <change-icon-modal :showChangeIconModal="showChangeIconModal" :initialIcon="initialIcon" v-if="showChangeIconModal"
      :iconToChange="modalIconToChxsange" @changeIcon="changeIcon" @closeModal="closeChangeIconModal" />
    <image-cropper-modal :showImageCropperModal="showImageCropperModal" :initialImage="initialImage"
      @cropped="handleCroppedImage" @closeModal="closeImageCropperModal" :aspectRatio="initialImageAspectRatio"
      v-if="showImageCropperModal" />

    <button v-if="isEditable" class="save-button pulse" @click="save">
      <span class="material-symbols-rounded"> save </span>Salvează modificările
    </button>
    <div class="image-loading" v-if="uploadingImage && isEditable">
      <span class="loader"></span>
      <p>{{ uploadProgress }}/100%</p>
    </div>

    <!-- Invitaton template START -->
    <div class="invitation-header">
      <contenteditable tag="p" :contenteditable="isEditable" v-model="data.headerTitle" :no-nl="true" :no-html="true"
        :class="getClassNames('headerTitle')" class="notranslate" @click.stop="selectTextElement('headerTitle')" />

      <ul v-if="isEditable">
        <li>
          <contenteditable tag="a" :contenteditable="true" v-model="data.headerAbout" :no-nl="false" :no-html="false"
            :class="getClassNames('headerAbout')" @click.stop="selectTextElement('headerAbout')" />
        </li>
        <li>
          <contenteditable tag="a" :contenteditable="true" v-model="data.headerSpouses" :no-nl="false" :no-html="false"
            :class="getClassNames('headerSpouses')" @click.stop="selectTextElement('headerSpouses')" />
        </li>
        <li>
          <contenteditable tag="a" :contenteditable="true" v-model="data.headerEvents" :no-nl="false" :no-html="false"
            :class="getClassNames('headerEvents')" @click.stop="selectTextElement('headerEvents')" />
        </li>
        <li>
          <contenteditable tag="a" :contenteditable="true" v-model="data.headerConfirm" :no-nl="false" :no-html="false"
            :class="getClassNames('headerConfirm')" @click.stop="selectTextElement('headerConfirm')"
            v-if="invitation.hasConfirmation" />
        </li>
      </ul>
      <ul v-else>
        <li>
          <a href="#hero" :class="getClassNames('headerAbout')">{{
            data.headerAbout
          }}</a>
        </li>
        <li>
          <a href="#couple" :class="getClassNames('headerSpouses')">{{
            data.headerSpouses
          }}</a>
        </li>
        <li>
          <a href="#venue-header" :class="getClassNames('headerEvents')">{{
            data.headerEvents
          }}</a>
        </li>
        <li>
          <a href="#rsvp" :class="getClassNames('headerConfirm')" v-if="invitation.hasConfirmation">{{
            data.headerConfirm }}</a>
        </li>
      </ul>
    </div>
    <div class="invitation-hero" :style="{
      background:
        'linear-gradient(0deg, rgba(38,57,31,.6), rgba(38,57,31,.6)), url(' +
        data.heroBackgroundImage +
        ')',
      'background-size': 'cover',
      'background-position': 'center',
    }" id="hero">
      <img src="@/assets/img/invitation/greenish-vibes/heroDecoration.png" class="leaf" id="hero-leaf" />

      <contenteditable tag="h1" :contenteditable="isEditable" v-model="data.heroTitle" :no-nl="false" :no-html="false"
        :class="getClassNames('heroTitle')" id="hero-title" class="notranslate"
        @click.stop="selectTextElement('heroTitle')" />

      <vuedatepicker v-if="isEditable" v-model="data.eventDate" placeholder="Start Typing ..."
        :enable-time-picker="false" format="dd/MM/yyyy" :clearable="false" input-class-name="custom-calendar-input"
        hide-input-icon />
      <h2 v-else id="hero-date">{{ formatDateEventDate }}</h2>

      <div class="change-image" @click="isEditable ? this.$refs.heroImageInput.click() : null" v-if="isEditable">
        <input type="file" @change="handleImageChangeHero" :accept="acceptedImageTypes" style="display: none"
          ref="heroImageInput" />

        <span class="material-symbols-rounded"> add_photo_alternate </span>
        <span class="text">Schimbă<br />imaginea</span>
      </div>
    </div>

    <div class="invitation-couple" id="couple" v-if="isEditable || data.coupleVisibility"
      :class="{ hidden: !data.coupleVisibility }">
      <div class="hide-section" :class="{ disabled: !data.coupleVisibility }" v-if="isEditable" @click="showHideCouple">
        <span class="material-symbols-rounded" v-if="data.coupleVisibility">
          visibility_off
        </span>
        <span class="material-symbols-rounded" v-else> visibility </span>
        <span class="text" v-if="data.coupleVisibility">Ascunde<br />secțiunea</span>
        <span class="text" v-else>Afișează<br />secțiunea</span>
      </div>

      <div class="couple-card" :class="{ 'animate-box': !isEditable }" id="couple-card">
        <div class="image">
          <div class="image-container">
            <img :src="data.coupleImageURL" style="width: 100%" />
          </div>
          <div class="change-image" @click="this.$refs.brideImageInput.click()" v-if="isEditable">
            <input type="file" @change="handleImageChangeBride" :accept="acceptedImageTypes" style="display: none"
              ref="brideImageInput" />

            <span class="material-symbols-rounded"> add_photo_alternate </span>
            <span class="text">Schimbă<br />imaginea</span>
          </div>
        </div>
        <div class="text">
          <img src="@/assets/img/invitation/greenish-vibes/coupleFlowerDecorationTop.png" class="leaf" />
          <contenteditable tag="h2" :contenteditable="isEditable" v-model="data.coupleTitle" :no-nl="true"
            :no-html="true" :class="getClassNames('coupleTitle')" class="notranslate"
            @click.stop="selectTextElement('coupleTitle')" />
          <contenteditable tag="h3" :contenteditable="isEditable" v-model="data.coupleSubtitle" :no-nl="true"
            :no-html="true" :class="getClassNames('coupleSubtitle')"
            @click.stop="selectTextElement('coupleSubtitle')" />
          <contenteditable tag="p" :contenteditable="isEditable" v-model="data.coupleDesc" :no-nl="false"
            :no-html="false" :class="getClassNames('coupleDesc')" @click.stop="selectTextElement('coupleDesc')" />
          <img src="@/assets/img/invitation/greenish-vibes/coupleFlowerDecorationBottom.png" class="leaf" />
        </div>
      </div>
    </div>

    <div class="invitation-parents" v-if="isEditable || data.parentsVisibility"
      :class="{ hidden: !data.parentsVisibility }" id="parents">
      <div class="hide-section" :class="{ disabled: !data.parentsVisibility }" v-if="isEditable"
        @click="showHideParents">
        <span class="material-symbols-rounded" v-if="data.parentsVisibility">
          visibility_off
        </span>
        <span class="material-symbols-rounded" v-else> visibility </span>
        <span class="text" v-if="data.parentsVisibility">Ascunde<br />secțiunea</span>

        <span class="text" v-else>Afișează<br />secțiunea</span>
      </div>

      <img src="@/assets/img/invitation/greenish-vibes/footerDecoration.png" class="leaf" />

      <div class="text" :class="{ 'animate-box': !isEditable }" id="godparents">
        <contenteditable tag="h2" :contenteditable="isEditable" v-model="data.godparentsSubtitle" :no-nl="true"
          :no-html="true" :class="getClassNames('godparentsSubtitle')"
          @click.stop="selectTextElement('godparentsSubtitle')" />
        <contenteditable tag="p" :contenteditable="isEditable" v-model="data.godparentsDesc" :no-nl="true"
          :no-html="true" :class="getClassNames('godparentsDesc')" @click.stop="selectTextElement('godparentsDesc')" />

        <div class="row">
          <div class="col">
            <contenteditable tag="h3" :contenteditable="isEditable" v-model="data.godparentsTitle" :no-nl="false"
              :no-html="true" :class="getClassNames('godparentsTitle')"
              @click.stop="selectTextElement('godparentsTitle')" />
          </div>
        </div>
      </div>

      <div class="text" :class="{ 'animate-box': !isEditable }" id="parents">
        <contenteditable tag="h2" :contenteditable="isEditable" v-model="data.parentsSubtitle" :no-nl="true"
          :no-html="true" :class="getClassNames('parentsSubtitle')"
          @click.stop="selectTextElement('parentsSubtitle')" />
        <contenteditable tag="p" :contenteditable="isEditable" v-model="data.parentsDesc" :no-nl="true" :no-html="true"
          :class="getClassNames('parentsDesc')" @click.stop="selectTextElement('parentsDesc')" />

        <div class="row">
          <div class="col">
            <contenteditable tag="h3" :contenteditable="isEditable" v-model="data.parentsTitle" :no-nl="false"
              :no-html="true" :class="getClassNames('parentsTitle')" @click.stop="selectTextElement('parentsTitle')" />
          </div>
        </div>
      </div>
    </div>

    <div class="invitation-venue-header" id="venue-header">
      <contenteditable tag="p" :contenteditable="isEditable" v-model="data.venueSubtitle" :no-nl="true" :no-html="true"
        :class="[
          isEditable ? '' : 'animate-box',
          getClassNames('venueSubtitle'),
        ]" @click.stop="selectTextElement('venueSubtitle')" />
      <contenteditable tag="h2" :contenteditable="isEditable" v-model="data.venueHeader" :no-nl="true" :no-html="true"
        :class="[isEditable ? '' : 'animate-box', getClassNames('venueHeader')]"
        @click.stop="selectTextElement('venueHeader')" />
    </div>

    <div class="invitation-row" id="venues">
      <div class="invitation-venue" :class="{
        hidden: !data.venues.civilWedding.isShown,
        'animate-box': !isEditable,
      }" v-if="isEditable || data.venues.civilWedding.isShown">
        <p class="hide-show-text" v-if="data.venues.civilWedding.isShown && isEditable" @click="showHideCivilWedding">
          Ascunde
        </p>
        <p class="hide-show-text" v-else-if="isEditable" @click="showHideCivilWedding">
          Arată
        </p>

        <p class="settings-text" v-if="isEditable" @click="
          openChangeLocationModal('civilWedding', data.venues.civilWedding)
          ">
          Editează
        </p>

        <div class="image" :style="{
          background: 'url(' + data.venues.civilWedding.image + ')',
          'background-size': 'cover',
          'background-position': 'center',
        }" :class="{
          empty:
            data.venues.civilWedding.image === null ||
            data.venues.civilWedding.image === undefined,
        }">
          <div class="image-controls" v-if="isEditable" @click="removeImageCivilWedding">
            <div class="remove-image">
              <span class="material-symbols-rounded"> delete </span>
            </div>
            <div class="change-icon" @click="
              data.venues.civilWedding.image !== null
                ? openChangeIconModal(
                  'civilWedding',
                  data.venues.civilWedding
                )
                : openChangeIconModal(
                  'civilWedding',
                  data.venues.civilWedding
                );
            removeImageCivilWedding;
            ">
              <span class="material-symbols-rounded">
                add_photo_alternate
              </span>
              <span class="text" v-if="data.venues.civilWedding.image !== null">Adaugă<br />icon</span>
              <span class="text" v-else>Schimbă<br />icon</span>
            </div>
            <div class="change-image" @click="
              isEditable ? this.$refs.civilWeddingImageInput.click() : null
              ">
              <input type="file" @change="handleImageChangeCivilWedding" :accept="acceptedImageTypes"
                style="display: none" ref="civilWeddingImageInput" />

              <span class="material-symbols-rounded">
                add_photo_alternate
              </span>
              <span class="text" v-if="data.venues.civilWedding.image !== null">Schimbă<br />imaginea</span>
              <span class="text" v-else>Adaugă<br />imagine</span>
            </div>
          </div>
        </div>

        <div class="venue-content">
          <div class="icon" v-if="
            data.venues.civilWedding.image === null ||
            data.venues.civilWedding.image === undefined
          " @click="
            openChangeIconModal('civilWedding', data.venues.civilWedding)
            ">
            <InvitationIcon :number="data.venues.civilWedding.icon" />
          </div>

          <h3 :class="getVenueClassNames('title', 'civilWedding')"
            @click.stop="selectTextElement('venues.civilWedding.date')">
            {{ data.venues.civilWedding.title }}
          </h3>
          <h2 v-text="formatDateVenue(data.venues.civilWedding.date) +
            ' ' +
            data.venues.civilWedding.time
            " :class="getVenueClassNames('date', 'civilWedding')"></h2>
          <p :class="getVenueClassNames('locationName', 'civilWedding')">
            {{ data.venues.civilWedding.locationName }}
          </p>

          <p class="see-map" @click="openGoogleMaps(data.venues.civilWedding.locationName)">
            Vezi harta
          </p>
        </div>
      </div>
      <div class="invitation-venue" :class="{
        hidden: !data.venues.religiousCeremony.isShown,
        'animate-box': !isEditable,
      }" v-if="isEditable || data.venues.religiousCeremony.isShown">
        <p class="hide-show-text" v-if="data.venues.religiousCeremony.isShown && isEditable"
          @click="showHideReligiousCeremony">
          Ascunde
        </p>
        <p class="hide-show-text" v-else-if="isEditable" @click="showHideReligiousCeremony">
          Arată
        </p>

        <p class="settings-text" v-if="isEditable" @click="
          openChangeLocationModal(
            'religiousCeremony',
            data.venues.religiousCeremony
          )
          ">
          Editează
        </p>

        <div class="image" :style="{
          background: 'url(' + data.venues.religiousCeremony.image + ')',
          'background-size': 'cover',
          'background-position': 'center',
        }" :class="{
          empty:
            data.venues.religiousCeremony.image === null ||
            data.venues.religiousCeremony.image === undefined,
        }">
          <div class="image-controls" v-if="isEditable" @click="removeImageReligiousCeremony">
            <div class="remove-image">
              <span class="material-symbols-rounded"> delete </span>
            </div>
            <div class="change-icon" @click="
              data.venues.religiousCeremony.image !== null
                ? openChangeIconModal(
                  'religiousCeremony',
                  data.venues.religiousCeremony
                )
                : openChangeIconModal(
                  'religiousCeremony',
                  data.venues.religiousCeremony
                );
            removeImageCivilWedding;
            ">
              <span class="material-symbols-rounded">
                add_photo_alternate
              </span>
              <span class="text" v-if="data.venues.religiousCeremony.image !== null">Adaugă<br />icon</span>
              <span class="text" v-else>Schimbă<br />icon</span>
            </div>
            <div class="change-image" @click="
              isEditable
                ? this.$refs.religiousCeremonyImageInput.click()
                : null
              ">
              <input type="file" @change="handleImageChangeReligiousCeremony" :accept="acceptedImageTypes"
                style="display: none" ref="religiousCeremonyImageInput" />

              <span class="material-symbols-rounded">
                add_photo_alternate
              </span>
              <span class="text" v-if="data.venues.religiousCeremony.image !== null">Schimbă<br />imaginea</span>
              <span class="text" v-else>Adaugă<br />imagine</span>
            </div>
          </div>
        </div>

        <div class="venue-content">
          <div class="icon" v-if="
            data.venues.religiousCeremony.image === null ||
            data.venues.religiousCeremony.image === undefined
          " @click="
            openChangeIconModal(
              'religiousCeremony',
              data.venues.religiousCeremony
            )
            ">
            <InvitationIcon :number="data.venues.religiousCeremony.icon" />
          </div>

          <h3 :class="getVenueClassNames('title', 'religiousCeremony')">
            {{ data.venues.religiousCeremony.title }}
          </h3>
          <h2 v-text="formatDateVenue(data.venues.religiousCeremony.date) +
            ' ' +
            data.venues.religiousCeremony.time
            " :class="getVenueClassNames('date', 'religiousCeremony')"></h2>
          <p :class="getVenueClassNames('locationName', 'religiousCeremony')">
            {{ data.venues.religiousCeremony.locationName }}
          </p>

          <p class="see-map" @click="openGoogleMaps(data.venues.religiousCeremony.locationName)">
            Vezi harta
          </p>
        </div>
      </div>
      <div class="invitation-venue" :class="{
        hidden: !data.venues.eventParty.isShown,
        'animate-box': !isEditable,
      }" v-if="isEditable || data.venues.eventParty.isShown">
        <p class="hide-show-text" v-if="data.venues.eventParty.isShown && isEditable" @click="showHideEventParty">
          Ascunde
        </p>
        <p class="hide-show-text" v-else-if="isEditable" @click="showHideEventParty">
          Arată
        </p>

        <p class="settings-text" v-if="isEditable"
          @click="openChangeLocationModal('eventParty', data.venues.eventParty)">
          Editează
        </p>

        <div class="image" :style="{
          background: 'url(' + data.venues.eventParty.image + ')',
          'background-size': 'cover',
          'background-position': 'center',
        }" :class="{
          empty:
            data.venues.eventParty.image === null ||
            data.venues.eventParty.image === undefined,
        }">
          <div class="image-controls" v-if="isEditable" @click="removeImageEventParty">
            <div class="remove-image">
              <span class="material-symbols-rounded"> delete </span>
            </div>
            <div class="change-icon" @click="
              data.venues.eventParty.image !== null
                ? openChangeIconModal('eventParty', data.venues.eventParty)
                : openChangeIconModal('eventParty', data.venues.eventParty);
            removeImageCivilWedding;
            ">
              <span class="material-symbols-rounded">
                add_photo_alternate
              </span>
              <span class="text" v-if="data.venues.eventParty.image !== null">Adaugă<br />icon</span>
              <span class="text" v-else>Schimbă<br />icon</span>
            </div>
            <div class="change-image" @click="
              isEditable ? this.$refs.eventPartyImageInput.click() : null
              ">
              <input type="file" @change="handleImageChangeEventParty" :accept="acceptedImageTypes"
                style="display: none" ref="eventPartyImageInput" />

              <span class="material-symbols-rounded">
                add_photo_alternate
              </span>
              <span class="text" v-if="data.venues.eventParty.image !== null">Schimbă<br />imaginea</span>
              <span class="text" v-else>Adaugă<br />imagine</span>
            </div>
          </div>
        </div>

        <div class="venue-content">
          <div class="icon" v-if="
            data.venues.eventParty.image === null ||
            data.venues.eventParty.image === undefined
          " @click="openChangeIconModal('eventParty', data.venues.eventParty)">
            <InvitationIcon :number="data.venues.eventParty.icon" />
          </div>

          <h3 :class="getVenueClassNames('title', 'eventParty')">
            {{ data.venues.eventParty.title }}
          </h3>
          <h2 v-text="formatDateVenue(data.venues.eventParty.date) +
            ' ' +
            data.venues.eventParty.time
            " :class="getVenueClassNames('date', 'eventParty')"></h2>
          <p :class="getVenueClassNames('locationName', 'eventParty')">
            {{ data.venues.eventParty.locationName }}
          </p>

          <p class="see-map" @click="openGoogleMaps(data.venues.eventParty.locationName)"
            :class="getClassNames('venues.eventParty.locationName')">
            Vezi harta
          </p>
        </div>
      </div>
    </div>

    <div class="invitation-rsvp" id="rsvp" :style="{
      background:
        'linear-gradient(0deg, rgba(38,57,31,.6), rgba(38,57,31,.6)), url(' +
        data.rsvpBackgroundImage +
        ')',
      'background-size': 'cover',
      'background-position': 'center',
    }" :class="{ completed: invitees.length > 1 }" v-show="isEditable || invitation.hasConfirmation">
      <div class="announcement" v-if="!invitation.hasConfirmation && isEditable">
        <span class="material-symbols-rounded"> error </span>
        Confirmarea invitației este dezactivată.
        <span class="underline" @click="this.$router.push(`/invitation/${offerId}/wizard`)">Mergi în setările invitației
          pentru a o activa</span>
      </div>
      <div class="contact-wrapper" id="rsvp" :class="{ 'animate-box': !isEditable }">
        <div v-show="!isWithinDeadline" class="expired">
          <vLottiePlayer class="lottie" name="lottie" loop
            :animationData="require('@/assets/lottie/expiredInvitation.json')" />
          <h2>Invitatia nu mai poate fi confirmata</h2>
        </div>

        <div v-if="isWithinDeadline && (isSentConfirmed || isSentDenied)" class="confirmed-denied">
          <vLottiePlayer class="lottie" name="lottie" loop
            :animationData="require('@/assets/lottie/checkmarkAnimated.json')" />
          <h2>Răspunsul tău a fost trimis cu succes!</h2>
        </div>

        <div v-show="isWithinDeadline && !(isSentConfirmed || isSentDenied)" class="confirm">
          <contenteditable tag="h2" :contenteditable="isEditable" v-model="data.rsvpTitle" :no-nl="true" :no-html="true"
            :class="getClassNames('rsvpTitle')" @click.stop="selectTextElement('rsvpTitle')" />
          <contenteditable tag="p" class="subtitle" :contenteditable="isEditable" v-model="data.rsvpSubtitle"
            :no-nl="true" :no-html="true" :class="getClassNames('rsvpSubtitle')"
            @click.stop="selectTextElement('rsvpSubtitle')" />
          <contenteditable tag="p" class="subtitle last" :contenteditable="isEditable" v-model="data.rsvpExplanation"
            :no-nl="true" :no-html="true" :class="getClassNames('rsvpExplanation')"
            @click.stop="selectTextElement('rsvpExplanation')" />

          <form ref="formElement">
            <div v-if="invitees.length > 0">
              <div class="input-wrapper" v-if="invitation.hasUniqueConfirmation">
                <input class="input" v-model="invitees[0].email" type="email" id="email" name="email"
                  placeholder-shown="true" autocomplete="off" required minlength="2" placeholder="Adresa ta de email*"
                  data-pristine-minlength-message="Introduceti minim 2 caractere"
                  data-pristine-required-message="Câmp obligatoriu" />
              </div>
              <div class="input-wrapper">
                <input class="input" v-model="invitees[0].name" type="text" id="name" name="name"
                  placeholder-shown="true" autocomplete="off" required minlength="2"
                  placeholder="Numele și prenumele tău*" data-pristine-minlength-message="Introduceti minim 2 caractere"
                  data-pristine-required-message="Câmp obligatoriu" />
              </div>
              <div class="input-wrapper phone-number">
                <vue-tel-input v-model="invitees[0].phone" :input-options="{
        placeholder: 'Scrie numărul tău de telefon',
      }" :defaultCountry="'RO'" :enabledFlags="true" :enabledCountryCode="true" :autoFormat="true" mode="auto"
        :validCharactersOnly="true" @blur="validatePhone" @validate="onValidate" name="phone" required></vue-tel-input>
      <div v-if="phoneError" class="text-help">Câmp obligatoriu sau lungime incorectă</div>
              </div>
              <div class="number-of-persons-section">
                <h2>Număr de persoane</h2>
                <div class="number-of-persons-container">
                  <div class="number-of-persons">
                    <span class="material-symbols-rounded fill notranslate" :class="{ active: invitees.length > 0 }">
                      person
                    </span>
                    <span class="material-symbols-rounded fill notranslate" :class="{ active: invitees.length > 1 }">
                      person
                    </span>
                    <span class="material-symbols-rounded fill notranslate" :class="{ active: invitees.length > 2 }">
                      person
                    </span>
                    <span class="material-symbols-rounded fill notranslate" :class="{ active: invitees.length > 3 }">
                      person
                    </span>
                    <span class="material-symbols-rounded fill notranslate" :class="{ active: invitees.length > 4 }">
                      person
                    </span>
                  </div>

                  <div class="persons-actions">
                    <div class="rounded-button" @click="removePersons()">
                      <span class="material-symbols-rounded fill notranslate">
                        remove
                      </span>
                    </div>
                    <p>{{ invitees.length }}</p>
                    <div class="rounded-button" @click="addPersons()">
                      <span class="material-symbols-rounded fill notranslate">
                        add
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-for="invitee in invitees" :key="invitee" class="person-questions">
                <h3 v-if="invitees.indexOf(invitee) == 0">
                  Nume invitat (persoana care
                  completează)
                </h3>
                <h3 v-else>Persoana {{ invitees.indexOf(invitee) + 1 }} (însoțitor)</h3>
                <div class="input-wrapper">
                  <input class="input" v-model="invitee.name" type="text"
                    :id="'nameInvitee' + invitees.indexOf(invitee) + 1" name="name" placeholder-shown="true"
                    autocomplete="off" required minlength="2" placeholder="Numele și prenumele tău*"
                    data-pristine-minlength-message="Introduceti minim 2 caractere"
                    data-pristine-required-message="Câmp obligatoriu" />
                </div>

                <div v-for="question in invitation.questions" :key="question" :id="'question' +
                  invitees.indexOf(invitee) +
                  invitation.questions.indexOf(question)
                  " class="question">
                  <div class="question-title">
                    <p>{{ question.value }}</p>
                  </div>
                  <div class="input-wrapper">
                    <select v-model="invitees[invitees.indexOf(invitee)].questions.find(
                      (q) => q.questionId === question.id
                    ).answerId
                      ">
                      <option v-for="answer in question.answers" :key="answer.id" :value="answer.id">
                        {{ answer.value }}
                      </option>
                    </select>
                  </div>

                  <div class="alert alert-danger margin-top-10" style="display: none">
                    <i class="icon-remove-sign notranslate"></i>Te rog să ne
                    spui dacă dorești cazare.
                  </div>
                </div>
              </div>

              <button class="small secondary edit-questions"
                v-if="!invitation.published && invitation.questions.length > 0" @click="
                  this.$router.push(
                    `/invitation/${offerId}/${invitationId}/wizard`
                  )
                  ">
                <p class="text">Editează întrebarile</p>
                <span class="material-symbols-rounded"> edit </span>
              </button>

              <button class="small secondary add-questions"
                v-if="!invitation.published && invitation.questions.length == 0" @click="
                  this.$router.push(
                    `/invitation/${offerId}/${invitationId}/wizard`
                  )
                  ">
                <p class="text">Adaugă întrebare</p>
                <span class="material-symbols-rounded"> add </span>
              </button>
              <!-- TODO: De facut altcumva validarea form ului -->
              <div class="buttons">
                <button @click.prevent="sendInvitationResponse(false)">
                  Nu pot să particip
                </button>
                <button class="accept" @click.prevent="sendInvitationResponse(true)">
                  Da, confirm prezența
                </button>
              </div>
              <p class="time-limit" @click="
                isEditable
                  ? this.$router.push(
                    `/invitation/${offerId}/${invitationId}/wizard`
                  )
                  : null
                ">
                Data limită de confirmare: {{ formatDateDeadline }}
              </p>
            </div>
          </form>
        </div>
      </div>

      <div class="change-image" @click="isEditable ? this.$refs.invitationImageInput.click() : null" v-if="isEditable">
        <input type="file" @change="handleImageChangeRSVP" :accept="acceptedImageTypes" style="display: none"
          ref="invitationImageInput" />

        <span class="material-symbols-rounded"> add_photo_alternate </span>
        <span class="text">Schimbă<br />imaginea</span>
      </div>
    </div>

    <div class="invitation-footer" v-if="isEditable || data.footerVisibility"
      :class="{ hidden: !data.footerVisibility }" id="footer">
      <div class="hide-section" :class="{ disabled: !data.footerVisibility }" v-if="isEditable" @click="showHideFooter">
        <span class="material-symbols-rounded" v-if="data.footerVisibility">
          visibility_off
        </span>
        <span class="material-symbols-rounded" v-else> visibility </span>
        <span class="text" v-if="data.footerVisibility">Ascunde<br />secțiunea</span>
        <span class="text" v-else>Afișează<br />secțiunea</span>
      </div>

      <img src="@/assets/img/invitation/greenish-vibes/footerDecoration.png" class="leaf" />
      <div class="personal-note">
        <contenteditable tag="h2" :contenteditable="isEditable" v-model="data.personalTitle" :no-nl="false"
          :no-html="true" :class="[
            isEditable ? '' : 'animate-box',
            getClassNames('personalTitle'),
          ]" @click.stop="selectTextElement('personalTitle')" />

        <div class="countdown" :class="{ 'animate-box': !isEditable }">
          <div class="countdown-container">
            <vue-countdown :time="countdownValue" v-slot="{ days }">
              {{ days }}
            </vue-countdown>
            <p>zile</p>
          </div>
          <div class="countdown-container">
            <vue-countdown :time="countdownValue" v-slot="{ hours }">
              {{ hours }}
            </vue-countdown>
            <p>ore</p>
          </div>
          <div class="countdown-container">
            <vue-countdown :time="countdownValue" v-slot="{ minutes }">
              {{ minutes }}
            </vue-countdown>
            <p>minute</p>
          </div>
          <div class="countdown-container">
            <vue-countdown :time="countdownValue" v-slot="{ seconds }">
              {{ seconds }}
            </vue-countdown>
            <p>secunde</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Invitaton template END -->
  </div>
</template>

<script>
import contenteditable from "vue-contenteditable";
import vuedatepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import VueCountdown from "@chenfengyuan/vue-countdown";
import ChangeLocationModal from "@/views/invitation/components/ChangeLocationModal.vue";
import ChangeIconModal from "@/views/invitation/components/ChangeIconModal.vue";
import ImageCropperModal from "@/views/invitation/components/ImageCropperModal.vue";
import blobStorageHelper from "@/helpers/blobstoragehelper.js";
import * as Pristine from "pristinejs";
import { ref, onMounted, onBeforeMount, nextTick, computed, watch } from "vue";
import { useRoute } from "vue-router";
import { notify } from "@kyvg/vue3-notification";
import $ from "jquery";
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

IntersectionObserver;

import "animate.css";

export default {
  name: "GreenishVibes",
  components: {
    contenteditable,
    vuedatepicker,
    VueCountdown,
    ChangeLocationModal,
    ChangeIconModal,
    ImageCropperModal,
    VueTelInput
  },
  data() {
    return {};
  },
  props: {
    invitationData: {
      type: Object,
      default: null,
    },
    offerId: {
      type: String,
      default: "",
    },
    invitationId: {
      type: String,
      default: "",
    },
    isInvitationEditable: {
      type: Boolean,
      default: false,
    },
    isWidthMobile: {
      type: Boolean,
      default: false,
    },
    isWidthTablet: {
      type: Boolean,
      default: false,
    },
    isWidthDesktop: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const route = useRoute();
    const invitees = ref([]);

    const invitationUpdated = ref(false);

    const selectTextElement = (elementName) => {
      emit(
        "select",
        elementName,
        data.value[elementName + "Color"],
        data.value[elementName + "FontFamily"],
        data.value[elementName + "FontWeight"]
      );
    };

    const deselectTextElement = () => {
      emit("deselect");
    };

    const getClassNames = (name) => {
      const colorClass = data.value[name + "Color"];
      const fontFamilyClass = data.value[name + "FontFamily"];
      const fontWeightClass = data.value[name + "FontWeight"];
      return colorClass + " " + fontFamilyClass + " " + fontWeightClass;
    };

    const getVenueClassNames = (name, venueName) => {
      if (!data.value || !data.value.venues || !data.value.venues[venueName]) {
        return "";
      }

      const venue = data.value.venues[venueName];
      if (!venue) {
        return "";
      }

      const colorClass = venue[name + "Color"];
      const fontFamilyClass = venue[name + "FontFamily"];

      if (colorClass === undefined || fontFamilyClass === undefined) {
        return "";
      }

      if (colorClass === "" || fontFamilyClass === "") {
        return "";
      }

      return colorClass + " " + fontFamilyClass;
    };

    const showImageCropperModal = ref(false);
    const initialImage = ref(null);
    const initialImageLocation = ref(null);
    const initialImageAspectRatio = ref(16 / 9);

    const showChangeIconModal = ref(false);
    const initialIcon = ref(null);

    const showChangeLocationModal = ref(false);
    const initialLocation = ref(null);
    const initialTitle = ref(null);
    const initialDate = ref(null);
    const initialTime = ref(null);

    const modalLocationToChange = ref("");
    const modalIconToChange = ref("");

    const isEditable = ref(props.isInvitationEditable);
    const isDemo = ref(false);

    const invitation = ref(props.invitationData);
    const uploadProgress = ref(0);
    const uploadingImage = ref(false);

    const isSentConfirmed = ref(false);
    const isSentDenied = ref(false);

    const data = ref(
      invitation.value !== null && invitation.value.json
        ? invitation.value.json
        : {
          initialLocation: null,

          themeStyle: "light",

          headerTitle: "Maria & Andrei",
          headerTitleFontFamily: "primary-font",
          headerTitleColor: "primary-text-color",
          headerTitleFontWeight: "light-font-weight",

          headerAbout: "Despre",
          headerAboutFontFamily: "secondary-font",
          headerAboutColor: "primary-text-color",
          headerAboutFontWeight: "light-font-weight",

          headerSpouses: "Oamenii de suflet",
          headerSpousesFontFamily: "secondary-font",
          headerSpousesColor: "primary-text-color",
          headerSpousesFontWeight: "light-font-weight",

          headerEvents: "Evenimentele",
          headerEventsFontFamily: "secondary-font",
          headerEventsColor: "primary-text-color",
          headerEventsFontWeight: "light-font-weight",

          headerConfirm: "Confirmare",
          headerConfirmFontFamily: "secondary-font",
          headerConfirmColor: "primary-text-color",
          headerConfirmFontWeight: "light-font-weight",

          heroSubtitle: "Ne căsătorim",
          heroSubtitleFontFamily: "primary-font",
          heroSubtitleColor: "primary-text-color",
          heroSubtitleFontWeight: "medium-font-weight",

          heroTitle: "Maria & Andrei",
          heroTitleFontFamily: "primary-font",
          heroTitleColor: "tertiary-text-color",
          heroTitleFontWeight: "light-font-weight",

          heroBackgroundImage:
            "https://prodmediahub.blob.core.windows.net/greenishvibesmedia/heroImage.jpg",
          eventDate: "2024-09-29",

          coupleVisibility: true,

          coupleTitle: "Ne casatorim",
          coupleTitleFontFamily: "primary-font",
          coupleTitleColor: "primary-color",
          coupleTitleFontWeight: "light-font-weight",

          coupleSubtitle: "Te invităm la nunta noastră!",
          coupleSubtitleFontFamily: "secondary-font",
          coupleSubtitleColor: "primary-text-color",
          coupleSubtitleFontWeight: "normal",

          coupleDesc:
            "Ne dorim din suflet să ne poți fi alături și să marcăm împreună ziua în care destinele noastre vor merge pe același drum.",
          coupleescFontFamily: "secondary-font",
          coupleDescColor: "secondary-text-color",
          coupleDescFontWeight: "medium-font-weight",

          coupleImageURL:
            "https://prodmediahub.blob.core.windows.net/greenishvibesmedia/coupleImage.png",

          godparentsVisibility: true,
          godparentsSubtitle: "Alături de nașii.",
          godparentsSubtitleFontFamily: "primary-font",
          godparentsSubtitleColor: "primary-color",
          godparentsSubtitleFontWeight: "light-font-weight",

          godparentsTitle: "Andreea și Marian Anghel",
          godparentsTitleFontFamily: "secondary-font",
          godparentsTitleColor: "primary-text-color",
          godparentsTitleFontWeight: "medium-font-weight",

          godparentsDesc: "Alături de care vom scrie povestea noastră.",
          godparentsDescFontFamily: "secondary-font",
          godparentsDescColor: "secondary-text-color",
          godparentsDescFontWeight: "light-font-weight",

          parentsVisibility: true,
          parentsSubtitle: "Alături de părinții.",
          parentsSubtitleFontFamily: "primary-font",
          parentsSubtitleColor: "primary-color",
          parentsSubtitleFontWeight: "light-font-weight",

          parentsTitle: "Andreea și Marian Anghel",
          parentsTitleFontFamily: "secondary-font",
          parentsTitleColor: "primary-text-color",
          parentsTitleFontWeight: "medium-font-weight",

          parentsDesc: "Alături de care vom scrie povestea noastră.",
          parentsDescFontFamily: "secondary-font",
          parentsDescColor: "secondary-text-color",
          parentsDescFontWeight: "light-font-weight",

          venueHeader: "Unde și când",
          venueHeaderFontFamily: "secondary-font",
          venueHeaderColor: "secondary-text-color",
          venueHeaderFontWeight: "light-font-weight",

          venueSubtitle: "Locații",
          venueSubtitleFontFamily: "primary-font",
          venueSubtitleColor: "primary-color",
          venueSubtitleFontWeight: "medium-font-weight",

          venues: {
            civilWedding: {
              isShown: true,
              icon: 4,
              title: "Cununie civilă",
              titleFontFamily: "secondary-font",
              titleColor: "primary-text-color",
              titleFontWeight: "medium-font-weight",

              locationName: "Lacul Snagov, Lacul Snagov, România",
              locationNameFontFamily: "secondary-font",
              locationNameColor: "primary-text-color",
              locationNameFontWeight: "medium-font-weight",

              date: "2024-07-13",
              dateFontFamily: "secondary-font",
              dateColor: "primary-text-color",
              dateFontWeight: "medium-font-weight",

              time: "12:30",
              image: null,
            },
            religiousCeremony: {
              isShown: true,
              icon: 5,
              title: "Cununie religioasă",
              titleFontFamily: "secondary-font",
              titleColor: "primary-text-color",
              titleFontWeight: "medium-font-weight",

              locationName:
                "Biserica Albă, Calea Victoriei 110, București 010091, România",
              locationNameFontFamily: "secondary-font",
              locationNameColor: "primary-text-color",
              locationNameFontWeight: "medium-font-weight",

              date: "2024-09-29",
              dateFontFamily: "secondary-font",
              dateColor: "primary-text-color",
              dateFontWeight: "medium-font-weight",

              time: "14:00",
              image: null,
            },
            eventParty: {
              isShown: true,
              icon: 9,
              title: "Petrecerea",
              titleFontFamily: "secondary-font",
              titleColor: "primary-text-color",
              titleFontWeight: "medium-font-weight",

              locationName:
                "Iris Orangerie, Str. Platanilor 16, Comuna Ciolpani 07705, România",
              locationNameFontFamily: "secondary-font",
              locationNameColor: "primary-text-color",
              locationNameFontWeight: "medium-font-weight",

              date: "2024-09-29",
              dateFontFamily: "secondary-font",
              dateColor: "primary-text-color",
              dateFontWeight: "medium-font-weight",

              time: "20:00",
              image: null,
            },
          },

          rsvpTitle: "Confirmare",
          rsvpTitleFontFamily: "primary-font",
          rsvpTitleColor: "primary-color",
          rsvpTitleFontWeight: "light-font-weight",

          rsvpSubtitle: "Te asteptam cu drag.",
          rsvpSubtitleFontFamily: "secondary-font",
          rsvpSubtitleColor: "secondary-text-color",
          rsvpSubtitleFontWeight: "medium-font-weight",

          rsvpExplanation:
            "Completează formularul de mai jos pentru a ne anunța decizia ta.",
          rsvpExplanationFontFamily: "secondary-font",
          rsvpExplanationColor: "secondary-text-color",
          rsvpExplanationFontWeight: "medium-font-weight",

          rsvpBackgroundImage:
            "https://prodmediahub.blob.core.windows.net/greenishvibesmedia/contactImage.jpg",

          footerVisibility: true,
          personalTitle: "Mulțumim!",
          personalTitleFontFamily: "primary-font",
          personalTitleColor: "secondary-text-color",
          personalTitleFontWeight: "medium-font-weight",

          dateFormat: "EEEE, d MMMM yyyy HH:mm",
        }
    );

    const defaultConfig = {
      classTo: "input-wrapper",
      errorClass: "has-danger",
      successClass: "has-success",
      errorTextParent: "input-wrapper",
      errorTextClass: "text-help",
    };
    const formElement = ref(null);
    let pristine;
    const phoneError = ref(false);

    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    const acceptedImageTypes = ref(getAcceptedImageTypes(isMobile));

    function getAcceptedImageTypes(isMobile) {
      if (isMobile) {
        return "image/jpeg, image/png, image/gif, image/bmp, image/webp, image/heif, image/heic";
      } else {
        return "image/jpeg, image/png, image/gif, image/bmp, image/webp";
      }
    }

    const isFirstInvitationUpdate = ref(true);
    watch(
      invitation.value,
      (newVal, oldVal) => {
        if (!isFirstInvitationUpdate.value) {
          invitationUpdated.value = true;
        }
        isFirstInvitationUpdate.value = false;
      },
      { deep: true }
    );

    const handleCroppedImage = async (file) => {
      if (file) {
        const imageURL = await uploadImage(file);

        switch (initialImageLocation.value) {
          case data.value.heroBackgroundImage:
            data.value.heroBackgroundImage = imageURL;
            break;
          case data.value.brideImageURL:
            data.value.brideImageURL = imageURL;
            break;
          case data.value.groomImageURL:
            data.value.groomImageURL = imageURL;
            break;
          case data.value.venues.civilWedding:
            data.value.venues.civilWedding.image = imageURL;
            break;
          case data.value.venues.religiousCeremony:
            data.value.venues.religiousCeremony.image = imageURL;
            break;
          case data.value.venues.eventParty:
            data.value.venues.eventParty.image = imageURL;
            break;
          case data.value.rsvpBackgroundImage:
            data.value.rsvpBackgroundImage = imageURL;
            break;
        }
      }
    };

    const closeImageCropperModal = () => {
      showImageCropperModal.value = false;
    };

    const handleImageChangeHero = (event) => {
      const file = event.target.files[0];
      if (file) {
        initialImageLocation.value = data.value.heroBackgroundImage;
        initialImageAspectRatio.value = 16 / 9;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          initialImage.value = e.target.result;
          showImageCropperModal.value = true;
        };
      }
    };

    const handleImageChangeBride = async (event) => {
      const file = event.target.files[0];
      if (file) {
        initialImageLocation.value = data.value.brideImageURL;
        initialImageAspectRatio.value = 1;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          initialImage.value = e.target.result;
          showImageCropperModal.value = true;
        };
      }
    };

    const handleImageChangeGroom = async (event) => {
      const file = event.target.files[0];
      if (file) {
        initialImageLocation.value = data.value.groomImageURL;
        initialImageAspectRatio.value = 1;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          initialImage.value = e.target.result;
          showImageCropperModal.value = true;
        };
      }
    };

    const handleImageChangeCivilWedding = async (event) => {
      const file = event.target.files[0];
      if (file) {
        initialImageLocation.value = data.value.venues.civilWedding;
        initialImageAspectRatio.value = 16 / 9;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          initialImage.value = e.target.result;
          showImageCropperModal.value = true;
        };
      }
    };

    const removeImageCivilWedding = () => {
      data.value.venues.civilWedding.image = null;
    };

    const handleImageChangeReligiousCeremony = async (event) => {
      const file = event.target.files[0];
      if (file) {
        initialImageLocation.value = data.value.venues.religiousCeremony;
        initialImageAspectRatio.value = 16 / 9;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          initialImage.value = e.target.result;
          showImageCropperModal.value = true;
        };
      }
    };

    const removeImageReligiousCeremony = () => {
      data.value.venues.religiousCeremony.image = null;
    };

    const handleImageChangeEventParty = async (event) => {
      const file = event.target.files[0];
      if (file) {
        initialImageLocation.value = data.value.venues.eventParty;
        initialImageAspectRatio.value = 16 / 9;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          initialImage.value = e.target.result;
          showImageCropperModal.value = true;
        };
      }
    };

    const removeImageEventParty = () => {
      data.value.venues.eventParty.image = null;
    };

    const handleImageChangeRSVP = async (event) => {
      const file = event.target.files[0];
      if (file) {
        initialImageLocation.value = data.value.rsvpBackgroundImage;
        initialImageAspectRatio.value = 16 / 9;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          initialImage.value = e.target.result;
          showImageCropperModal.value = true;
        };
      }
    };

    const addPersons = () => {
      invitees.value.push({ questions: getQuestions() });
      nextTick(() => {
        pristine = new Pristine(formElement.value, defaultConfig, true);
      });
    };

    const getQuestions = () => {
      const questions = [];
      invitation.value.questions.forEach((question) => {
        questions.push({
          questionId: question.id,
          answerId: question.answers.length > 0 ? question.answers[0].id : "",
        });
      });

      return questions;
    };

    const countdownValue = computed(() => {
      let deadline = null;
      if (invitation.value) {
        deadline = new Date(invitation.value.eventDate);
      } else {
        deadline = new Date(data.value.eventDate);
      }
      const diff = deadline.getTime() - new Date().getTime();

      return diff;
    });

    const removePersons = () => {
      if (invitees.value.length > 1) {
        invitees.value.pop();
      }
    };

    const showHideCivilWedding = () => {
      data.value.venues.civilWedding.isShown =
        !data.value.venues.civilWedding.isShown;
    };

    const showHideReligiousCeremony = () => {
      data.value.venues.religiousCeremony.isShown =
        !data.value.venues.religiousCeremony.isShown;
    };

    const showHideEventParty = () => {
      data.value.venues.eventParty.isShown =
        !data.value.venues.eventParty.isShown;
    };

    const showHideCouple = () => {
      data.value.coupleVisibility = !data.value.coupleVisibility;
    };

    const showHideGodparents = () => {
      data.value.godparentsVisibility = !data.value.godparentsVisibility;
    };

    const showHideParents = () => {
      data.value.parentsVisibility = !data.value.parentsVisibility;
    };

    const showHideFooter = () => {
      data.value.footerVisibility = !data.value.footerVisibility;
    };

    const openChangeLocationModal = (locationToChange, venue) => {
      if (isEditable.value) {
        initialLocation.value = venue.locationName;
        initialTitle.value = venue.title;
        initialDate.value = venue.date;
        initialTime.value = venue.time;
        showChangeLocationModal.value = true;
        modalLocationToChange.value = locationToChange;
      }
    };

    const closeChangeLocationModal = (event) => {
      if (!event) {
        showChangeLocationModal.value = false;
      } else if (event.target.classList.contains("modal")) {
        showChangeLocationModal.value = false;
      }
    };

    const changeLocation = (value, title, date, time) => {
      if (modalLocationToChange.value === "civilWedding") {
        data.value.venues.civilWedding.locationName = value;
        data.value.venues.civilWedding.title = title;
        data.value.venues.civilWedding.date = date;
        data.value.venues.civilWedding.time = time;
      } else if (modalLocationToChange.value === "religiousCeremony") {
        data.value.venues.religiousCeremony.locationName = value;
        data.value.venues.religiousCeremony.title = title;
        data.value.venues.religiousCeremony.date = date;
        data.value.venues.religiousCeremony.time = time;
      } else if (modalLocationToChange.value === "eventParty") {
        data.value.venues.eventParty.locationName = value;
        data.value.venues.eventParty.title = title;
        data.value.venues.eventParty.date = date;
        data.value.venues.eventParty.time = time;
      }

      showChangeIconModal.value = false;
    };

    const openChangeIconModal = (iconToChange, venue) => {
      if (isEditable.value) {
        initialIcon.value = venue.icon;
        showChangeIconModal.value = true;
        modalIconToChange.value = iconToChange;
      }
    };

    const closeChangeIconModal = () => {
      showChangeIconModal.value = false;
    };

    const changeIcon = (value) => {
      if (modalIconToChange.value === "civilWedding") {
        data.value.venues.civilWedding.icon = value;
      } else if (modalIconToChange.value === "religiousCeremony") {
        data.value.venues.religiousCeremony.icon = value;
      } else if (modalIconToChange.value === "eventParty") {
        data.value.venues.eventParty.icon = value;
      }

      showChangeIconModal.value = false;
    };

    const uploadImage = async (file) => {
      uploadingImage.value = true;

      const url = await blobStorageHelper.uploadImageToStorage(
        file,
        props.offerId,
        progressCallback
      );

      uploadingImage.value = false;
      return url;
    };

    const progressCallback = (progress) => {
      uploadProgress.value = Math.round(progress);
    };

    const openGoogleMaps = (address) => {
      if (address && !data.value.isEditable) {
        const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
          address
        )}`;
        window.open(url, "_blank");
      }
    };

    const save = () => {
      emit("saveJson", data.value);
      invitationUpdated.value = false;
      notify({
        type: "success",
        title: "Congratulations",
        text: "Your action has been done succesfully!",
      });
    };

    const validatePhone = () => {
      phoneError.value = !invitees.value[0].phone;
      return !phoneError.value;
    };

    const formifyClient = () => {
      pristine.addValidator(document.querySelector('.phone-number input'), validatePhone, "Câmp obligatoriu", 2, false);
      const valid = pristine.validate();
      if (valid) {
        pristine.reset();
        return true;
      }
      return false;
    };

    const sendInvitationResponse = (confirm) => {
      if (formifyClient()) {
        if (confirm) {
          invitees.value[0].confirmed = true;
          isSentDenied.value = false;
          isSentConfirmed.value = true;
        } else {
          invitees.value[0].confirmed = false;
          isSentConfirmed.value = false;
          isSentDenied.value = true;
        }
        emit("sendInvitationResponse", invitees.value);
      }
    };

    const formatDateEventDate = computed(() => {
      let dateToFormat = null;

      dateToFormat = new Date(data.value.eventDate);

      const months = [
        "Ianuarie",
        "Februarie",
        "Martie",
        "Aprilie",
        "Mai",
        "Iunie",
        "Iulie",
        "August",
        "Septembrie",
        "Octombrie",
        "Noiembrie",
        "Decembrie",
      ];
      const date = new Date(dateToFormat);
      const day = date.getDate();
      const monthIndex = date.getMonth();
      const year = date.getFullYear();

      return `${day < 10 ? "0" + day : day} ${months[monthIndex]} ${year}`;
    });

    const formatDateDeadline = computed(() => {
      let deadline = null;
      if (invitation.value) {
        deadline = new Date(invitation.value.deadline);
      } else {
        deadline = new Date(data.value.eventDate);
      }

      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const date = new Date(deadline);
      const day = date.getDate();
      const monthIndex = date.getMonth();
      const year = date.getFullYear();

      return `${day < 10 ? "0" + day : day} ${months[monthIndex]} ${year}`;
    });

    const isWithinDeadline = computed(() => {
      if (!invitation.value || !invitation.value.deadline) {
        return false;
      }

      const deadline = new Date(invitation.value.deadline);
      const today = new Date();

      // Check if 'deadline' is an invalid date
      if (isNaN(deadline.getTime())) {
        return false;
      }

      return deadline > today;
    });

    const formatDateVenue = (dateString) => {
      const months = [
        "Ianuarie",
        "Februarie",
        "Martie",
        "Aprilie",
        "Mai",
        "Iunie",
        "Iulie",
        "August",
        "Septembrie",
        "Octombrie",
        "Noiembrie",
        "Decembrie",
      ];

      let date = new Date(dateString);
      let day = date.getDate();
      let month = months[date.getMonth()];
      let year = date.getFullYear();

      return `${day} ${month} ${year}`;
    };

    onBeforeMount(() => {
      if (!route.path.includes("demo")) {
        invitees.value = [{ questions: getQuestions() }];
      }
    });

    onMounted(() => {
      isDemo.value = route.path.includes("demo");

      if (
        invitation.value.json === null ||
        invitation.value.json === undefined
      ) {
        save();
      }

      if (invitation.value.json !== null) {
        document.title = invitation.value.json.headerTitle + " - MyPrivent";
      }

      // Creaate new date with the client's timezone
      data.value.eventDate = new Date(data.value.eventDate.replace("Z", ""));

      pristine = new Pristine(formElement.value, defaultConfig);

      pristine.addValidator(
        formElement.value.querySelector('input[name="phone"]'),
        (value) => value && value.length > 0,
        "Câmp obligatoriu",
        2,
        false
      );

      if (!isEditable.value) {
        $("#hero-leaf").addClass("animate__animated animate__fadeInUp");

        setTimeout(() => {
          $("#hero-title").addClass("animate__animated animate__fadeInUp");
        }, 100);

        setTimeout(() => {
          $("#hero-date").addClass("animate__animated animate__fadeInUp");
        }, 150);

        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                if (entry.target.id === "couple") {
                  $("#couple-card").addClass(
                    "animate__animated animate__fadeInLeft"
                  );
                } else if (entry.target.id === "godparents") {
                  $("#godparents .text").addClass(
                    "animate__animated animate__fadeInUp"
                  );
                } else if (entry.target.id === "parents") {
                  $("#parents .text").addClass(
                    "animate__animated animate__fadeInUp"
                  );
                } else if (entry.target.id === "about") {
                  setTimeout(() => {
                    $("#about .image").addClass(
                      "animate__animated animate__fadeInUp"
                    );
                  }, 200);
                  $("#about .text").addClass(
                    "animate__animated animate__fadeInUp"
                  );
                } else if (entry.target.id === "venue-header") {
                  $("#venue-header p").addClass(
                    "animate__animated animate__fadeInUp"
                  );
                  $("#venue-header h2").addClass(
                    "animate__animated animate__fadeInUp"
                  );
                  setTimeout(() => {
                    $("#venue-header svg").addClass(
                      "animate__animated animate__fadeIn"
                    );
                  }, 100);
                } else if (entry.target.id === "venues") {
                  $("#venues .invitation-venue").addClass(
                    "animate__animated animate__fadeIn"
                  );
                } else if (entry.target.id === "rsvp") {
                  $("#rsvp .contact-wrapper").addClass(
                    "animate__animated animate__fadeIn"
                  );
                } else if (entry.target.id === "footer") {
                  $("#footer img").addClass(
                    "animate__animated animate__fadeIn"
                  );
                  $("#footer h2").addClass("animate__animated animate__fadeIn");
                  $("#footer .countdown").addClass(
                    "animate__animated animate__fadeIn"
                  );
                  $("#footer p").addClass("animate__animated animate__fadeIn");
                }
              }
            });
          },
          { threshold: 0.4 }
        );

        const elementsToObserve = document.querySelectorAll(
          "#couple, #godparents, #parents, #venue-header, #venues, #rsvp, #footer"
        );
        elementsToObserve.forEach((element) => observer.observe(element));
      }
    });

    return {
      deselectTextElement,
      selectTextElement,
      getClassNames,
      getVenueClassNames,
      acceptedImageTypes,
      handleImageChangeHero,
      handleImageChangeBride,
      handleImageChangeGroom,
      handleImageChangeRSVP,
      handleImageChangeCivilWedding,
      removeImageCivilWedding,
      handleImageChangeReligiousCeremony,
      removeImageReligiousCeremony,
      handleImageChangeEventParty,
      removeImageEventParty,
      addPersons,
      removePersons,
      showHideCivilWedding,
      showHideReligiousCeremony,
      showHideEventParty,
      showHideCouple,
      showHideGodparents,
      showHideParents,
      showHideFooter,
      closeImageCropperModal,
      openChangeIconModal,
      closeChangeIconModal,
      changeIcon,
      openChangeLocationModal,
      closeChangeLocationModal,
      changeLocation,
      uploadImage,
      formatDateVenue,
      formElement,
      sendInvitationResponse,
      invitation,
      handleCroppedImage,
      invitees,
      invitationUpdated,
      showImageCropperModal,
      initialImage,
      initialImageLocation,
      initialImageAspectRatio,
      showChangeIconModal,
      initialIcon,
      showChangeLocationModal,
      initialLocation,
      initialTitle,
      initialDate,
      initialTime,
      isEditable,
      data,
      modalLocationToChange,
      modalIconToChange,
      uploadProgress,
      uploadingImage,
      openGoogleMaps,
      isWithinDeadline,
      isSentConfirmed,
      isSentDenied,
      formatDateDeadline,
      formatDateEventDate,
      save,
      countdownValue,
      isDemo,
    };
  },
};
</script>
