<template>
  <div class="wrapper-content checklist">
    <div class="header space-between">
      <div
        class="vertical-align gap-5 splash-rounded splash-padding"
        @click="this.$router.go(-1)"
      >
        <span class="material-symbols-rounded"> arrow_back </span>
        <h1>Checklist</h1>
      </div>
      <div class="actions">
        <autosave-component
          :isSaving="isSaving"
          @update:isSaving="isSaving = $event"
        />
        <div class="header-button primary" @click="addNewCategory">
          <div class="highlight">
            <span class="material-symbols-rounded"> playlist_add </span>
          </div>
          <div class="text">Adaugă categorie</div>
        </div>
      </div>
    </div>

    <div class="content-loading" v-if="loading">
      <vLottiePlayer
        name="lottie"
        class="lottie"
        loop
        :animationData="require('@/assets/lottie/simpleDotsLoader.json')"
      />
    </div>

    <div class="content" v-else>
      <button class="save-button pulse" @click="saveChecklists()">
        <span class="material-symbols-rounded"> save </span>Salvează
        modificările
      </button>

      <masonry-wall
        v-if="checklists.length > 0"
        :items="checklists"
        :min-columns="1"
        :max-columns="3"
        :gap="20"
        :column-width="300"
        class="masonry"
      >
        <template #default="{ index }">
          <div class="card category" :item="checklists[index]">
            <div class="delete-category">
              <div class="edit" @click="deleteCategory(index)">
                <span class="material-symbols-rounded">delete</span>
              </div>
            </div>
            <div class="category-header">
              <h2
                contenteditable="true"
                @input="updateTitle($event, index)"
                class="text"
              >
                {{ checklists[index].name }}
              </h2>
              <CircleProgress
                :percent="calculateCompletionPercent(checklists[index])"
                :viewport="true"
                :show-percent="true"
                :is-gradient="true"
                :size="35"
                :border-width="6"
                :border-bg-width="6"
                empty-color="#E5E0D9"
                :gradient="{
                  angle: 90,
                  startColor: '#BC8129',
                  stopColor: '#F0BC6F',
                }"
              />
            </div>
            <div class="items">
              <div
                class="item input"
                v-for="(item, idx) in checklists[index].items"
                :key="`item-${index}-${idx}`"
              >
                <input
                  :id="`checkbox-${index}-${idx}`"
                  v-model="item.isChecked"
                  type="checkbox"
                  name="checkbox"
                />
                <label>
                  <div
                    class="clickable-area"
                    @click="toggleCheckbox(item)"
                  ></div>
                  <div class="editable">
                    <p
                      contenteditable="true"
                      @blur="handleBlur(index, idx, item.name)"
                      @keydown.enter.prevent="addNewItem(index, idx)"
                      @input="updateText($event, index, idx)"
                      class="text"
                    >
                      <span>{{ item.name }}</span>
                    </p>
                    <span
                      class="material-symbols-rounded splash-rounded"
                      @click="deleteItem(index, idx)"
                      >close</span
                    >
                  </div>
                </label>
                <div class="reminder-container">
                  <div
                    class="reminder"
                    :class="{ past: checkIfReminderIsPast(item.reminderValue) }"
                    v-if="
                      item.reminderValue != null &&
                      item.reminderValue != '0001-01-01T00:00:00'
                    "
                  >
                    <span
                      class="material-symbols-rounded repeat"
                      v-if="checkIfReminderIsPast(item.reminderValue)"
                      >event_repeat</span
                    >
                    <span class="material-symbols-rounded repeat" v-else
                      >event_upcoming</span
                    >
                    <input
                      type="datetime-local"
                      v-model="item.reminderValue"
                      @input="checkReminder(item)"
                      :min="minDate()"
                    />
                    <span
                      class="material-symbols-rounded delete splash-rounded"
                      @click="item.reminderValue = null"
                      >close</span
                    >
                  </div>

                  <div
                    class="reminder add"
                    v-else
                    @click="toggleReminder(item)"
                  >
                    <span class="material-symbols-rounded repeat"
                      >calendar_add_on</span
                    >
                    <p>Adaugă reminder</p>
                  </div>
                </div>
              </div>
            </div>
            <button
              class="add-new-task secondary extra-small"
              @click="addNewItem(index)"
            >
              Adauga task
            </button>
          </div>
        </template>
      </masonry-wall>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUpdated, onBeforeUnmount, nextTick } from "vue";
import sampleChecklist from "@/views/checklist/sample_checklist.json";
import CircleProgress from "vue3-circle-progress";
import "vue3-circle-progress/dist/circle-progress.css";
import { format, parseISO, isPast } from "date-fns";
import { getChecklists, updateChecklists } from "@/api";
import { useAuth0 } from "@auth0/auth0-vue";
import { useRoute } from "vue-router";
import MasonryWall from "@yeger/vue-masonry-wall";
import "@vuepic/vue-datepicker/dist/main.css";
import { notify } from "@kyvg/vue3-notification";
import AutosaveComponent from "@/components/AutosaveComponent.vue";

export default {
  name: "Checklist-Page",
  components: {
    CircleProgress,
    MasonryWall,
    AutosaveComponent
  },
  setup() {
    const { getAccessTokenSilently } = useAuth0();
    const route = useRoute();
    const offerId = ref(null);

    const loading = ref(true);

    const checklists = ref([]);
    const editingTitleIndex = ref(-1);

    const toggleCheckbox = (item) => {
      item.isChecked = !item.isChecked;
    };

    const checkIfReminderIsPast = (item) => {
      return isPast(item);
    };

    const updateText = (event, listIndex, itemIndex) => {
      checklists.value[listIndex].items[itemIndex].name =
        event.target.innerText;
    };

    const updateTitle = (event, listIndex) => {
      checklists.value[listIndex].name = event.target.innerText;
    };

    const handleBlur = (listIndex, itemIndex, text) => {
      if (itemIndex !== 0 && text.trim() === "") {
        checklists.value[listIndex].items.splice(itemIndex, 1);
      }
    };

    const handleEnter = (listIndex, itemIndex) => {
      const list = checklists.value[listIndex];
      if (list.items[itemIndex].name.trim() !== "") {
        const newTask = { name: "Task nou", isChecked: false, reminder: null };
        if (itemIndex + 1 === list.items.length) {
          list.items.push(newTask);
        } else {
          list.items.splice(itemIndex + 1, 0, newTask);
        }
        nextTick(() => {
          const editableTexts = document.querySelectorAll(".editable");
          editableTexts[itemIndex + 1].focus();
        });
      }
    };

    const addNewCategory = () => {
      const newCategory = {
        name: "New Category",
        items: [
          {
            name: "New Item",
            isChecked: false,
          },
        ],
      };

      checklists.value = [...checklists.value, newCategory];

      saveChecklists();
    };

    const addNewItem = (listIndex) => {
      const list = checklists.value[listIndex];
      const itemIndex = list.items.length - 1;
      if (list.items[itemIndex].name.trim() !== "") {
        const newTask = { name: "Task nou", isChecked: false, reminder: null };
        list.items.push(newTask);
        nextTick(() => {
          const editableTexts = document.querySelectorAll(".editable");
          editableTexts[itemIndex + 1].focus();
        });
      }

      saveChecklists();
    };

    const deleteItem = (listIndex, itemIndex) => {
      const list = checklists.value[listIndex];
      list.items.splice(itemIndex, 1);

      saveChecklists();
    };

    const editTitle = (index) => {
      editingTitleIndex.value = index;
    };

    const saveTitle = (index, newTitle) => {
      if (newTitle.trim() !== "") {
        checklists.value[index].name = newTitle;
      }
      editingTitleIndex.value = -1; // Reset editing index

      saveChecklists();
    };

    const deleteCategory = (index) => {
      checklists.value = checklists.value.filter((_, idx) => idx !== index);
      saveChecklists();
    };

    const calculateCompletionPercent = (list) => {
      const totalTasks = list.items.length;
      const completedTasks = list.items.filter((item) => item.isChecked).length;
      const completionPercent = (completedTasks / totalTasks) * 100;
      return completionPercent;
    };

    const attachEventListenerToReminders = () => {
      nextTick(() => {
        const reminders = document.querySelectorAll(".reminder");
        reminders.forEach((reminder) => {
          reminder.addEventListener("click", preventLabelClick);
        });
      });
    };

    const preventLabelClick = (event) => {
      event.stopPropagation();
    };

    const formattedDate = (dateTime) => {
      return format(parseISO(dateTime), "dd.MM.yyyy HH:mm");
    };

    const minDate = () => {
      return new Date().toISOString().split("T")[0];
    };

    const toggleReminder = (item) => {
      item.reminderValue = Date();
    };

    const checkReminder = (item) => {
      if (!item.reminderValue) {
        item.reminderValue = null;
      }
    };

    const saveChecklists = async () => {
      try {
        const token = await getAccessTokenSilently();

        await updateChecklists(token, offerId.value, checklists.value);

        notify({
          type: "success",
          title: "Checklist salvat",
          text: "Modificările au fost salvate cu succes!",
          duration: 5000,
        });
      } catch (error) {
        notify({
          type: "error",
          title: "Eroare",
          text: "A apărut o eroare la salvarea modificărilor!",
          duration: 5000,
        });
      }
    };

    onMounted(async () => {
      offerId.value = route.params.offerId;
      const token = await getAccessTokenSilently();
      const checklistRetrieved = await getChecklists(token, offerId.value);
      checklists.value =
        checklistRetrieved.length > 0
          ? checklistRetrieved
          : sampleChecklist.lists;
      loading.value = false;

      attachEventListenerToReminders();
    });
    onUpdated(attachEventListenerToReminders);

    onBeforeUnmount(() => {
      const reminders = document.querySelectorAll(".reminder");
      reminders.forEach((reminder) => {
        reminder.removeEventListener("click", preventLabelClick);
      });
    });

    return {
      loading,
      checklists,
      editingTitleIndex,
      toggleCheckbox,
      checkIfReminderIsPast,
      updateText,
      handleBlur,
      handleEnter,
      addNewCategory,
      addNewItem,
      deleteItem,
      editTitle,
      saveTitle,
      deleteCategory,
      calculateCompletionPercent,
      attachEventListenerToReminders,
      preventLabelClick,
      formattedDate,
      minDate,
      toggleReminder,
      checkReminder,
      saveChecklists,
      updateTitle,
    };
  },
};
</script>
