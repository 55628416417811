<template>
  <div class="modal bottom-sheet-on-mobile" id="add-task-modal" :class="{ active: showAddTaskModal }"
    @click="closeModal">
    <div class="modal-content">
      <div class="modal-header">
        <h1>Adaugă moment</h1>
        <span class="close-modal splash-rounded material-symbols-rounded fill" @click="closeModalFromButton">
          close
        </span>
      </div>

      <h3>Adaugă un moment nou completând datele de mai jos.</h3>

      <form ref="formElement" @submit.prevent @submit="save">
        <div class="input-wrapper">
          <input class="input" type="text" id="taskName" name="taskName" placeholder-shown="true" autocomplete="off"
            required minlength="2" v-model="this.taskName" placeholder="Scrie aici numele momentului"
            data-pristine-minlength-message="Introduceti minim 2 caractere"
            data-pristine-required-message="Câmp obligatoriu" />
          <label class="label" for="taskName">Nume moment*</label>
          <img class="checked" src="@/assets/img/check.svg" alt="" />
          <img class="error" src="@/assets/img/error.svg" alt="" />
        </div>
        <div class="input-wrapper">
          <textarea v-model="this.taskNotes" class="input" type="text" name="taskNotes"
            placeholder="Scrie aici notițele momentele" autocomplete="off" rows="5" minlength="4" />

          <label class="label" for="taskNotes">Notițele momentului</label>
          <img class="checked" src="@/assets/img/check.svg" alt="" />
          <img class="error" src="@/assets/img/error.svg" alt="" />
        </div>
        <div class="columns">
          <div class="input-wrapper col-50">
            <input class="input" type="time" id="taskStart" name="taskStart" placeholder-shown="true" autocomplete="off"
              required v-model="this.taskStart" placeholder="Alege ora"
              data-pristine-required-message="Câmp obligatoriu" />
            <label class="label" for="taskStart">Ora început*</label>
            <!-- <img class="checked" src="@/assets/img/check.svg" alt="" />
            <img class="error" src="@/assets/img/error.svg" alt="" /> -->
          </div>
          <div class="input-wrapper col-50">
            <input class="input" type="time" id="taskEnd" name="taskEnd" placeholder-shown="true" autocomplete="off"
              required v-model="this.taskEnd" placeholder="Alege ora"
              data-pristine-required-message="Câmp obligatoriu" />
            <label class="label" for="taskDuration">Ora final*</label>
            <img class="checked" src="@/assets/img/check.svg" alt="" />
            <img class="error" src="@/assets/img/error.svg" alt="" />
          </div>
        </div>
      </form>
      <button class="primary small" @click.prevent="save">
        Adaugă moment
      </button>
    </div>
  </div>
</template>

<script>
import { ref, toRefs, onMounted } from "vue";
import * as Pristine from "pristinejs";

export default {
  name: "AddTaskModal",
  props: {
    showAddTaskModal: Boolean,
  },
  setup(props, { emit }) {
    const taskName = ref("");
    const taskNotes = ref("");
    const taskStart = ref("");
    const taskEnd = ref("");
    const taskDuration = ref("");

    const saving = ref(false);

    const defaultConfig = {
      classTo: "input-wrapper",
      errorClass: "has-danger",
      successClass: "has-success",
      errorTextParent: "input-wrapper",
      errorTextClass: "text-help",
    };
    const formElement = ref(null);
    let pristine;

    const closeModal = (event) => {
      if (event.target.classList.contains("modal")) {
        emit("closeModal");
      }
    };
    const closeModalFromButton = () => {
      emit("closeModal");
    };

    const save = async () => {
      if (formifyClient()) {
        saving.value = true;

        emit("taskAdded", {
          title: taskName.value,
          start: taskStart.value,
          end: taskEnd.value,
          description: taskNotes.value,
        });

        saving.value = false;

        emit("closeModal", true);
      }
    };

    const formifyClient = () => {
      const valid = pristine.validate();
      if (valid) {
        pristine.reset();
        return true;
      }
      return false;
    };

    onMounted(async () => {
      pristine = new Pristine(formElement.value, defaultConfig);
    });

    return {
      ...toRefs(props),
      taskName,
      taskNotes,
      taskStart,
      taskEnd,
      saving,
      formElement,
      closeModal,
      closeModalFromButton,
      save,
    };
  },
};
</script>
