<template>
  <div class="wrapper-content events">
    <add-new-event
      :showAddNewEvent="showAddNewEventModal"
      v-if="this.showAddNewEventModal"
      @closeModal="closeAddNewEventModal()"
    />
    <div class="header">
      <div class="mobile-only">
        <div class="menu-toggle">
          <span class="material-symbols-rounded" @click="openDrawer()">
            menu
          </span>
        </div>
      </div>
      <h1>Events</h1>
    </div>
    <div class="content-loading" v-if="loadingOffers">
      <vLottiePlayer
        name="lottie"
        class="lottie"
        loop
        :animationData="require('@/assets/lottie/simpleDotsLoader.json')"
      />
    </div>
    <div class="content" v-else>
      <div class="grid-content">
        <div
          class="card event"
          @click="this.$router.push(`/dashboard/${offer.offerId}`)"
          v-for="offer in offers"
          :key="offer.offerId"
        >
          <div class="card-content">
            <h1>{{ offer.eventTypeString }}</h1>
            <div class="event-info">
              <div class="event-info-container">
                <p class="subtitle">Data și ora</p>
                <p class="title">{{ offer.eventDateString }}</p>
              </div>
              <div class="event-info-container">
                <p class="subtitle">Locația</p>
                <p class="title">{{ offer.location }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="card new-event" @click="addNewEvent()">
          <div class="card-content">
            <div class="container">
              <span class="material-symbols-rounded"> add </span>
            </div>
            <h1 class="dash-circle">Adauga eveniment</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import AddNewEvent from "@/components/AddEventModal.vue";
import { getClientOffers } from "@/api";
import { useAuth0 } from "@auth0/auth0-vue";

export default {
  name: "Memories-Collections",
  components: {
    AddNewEvent,
  },

  setup() {
    const { user, getAccessTokenSilently, isLoading } = useAuth0();

    const showAddNewEventModal = ref(false);
    const offers = ref([]);
    const loadingOffers = ref(true);

    const openDrawer = () => {
      document.querySelector(".drawer-wrapper").classList.add("open");
    };

    const addNewEvent = () => {
      showAddNewEventModal.value = true;
    };

    function closeAddNewEventModal() {
      showAddNewEventModal.value = false;
    }

    const loadOffers = async () => {
      try {
        await new Promise((resolve) => {
          const intervalId = setInterval(() => {
            if (!isLoading.value) {
              clearInterval(intervalId);
              resolve();
            }
          }, 100); // checks every 100ms
        });

        const phone = user?.value.name;
        const token = await getAccessTokenSilently();

        offers.value = await getClientOffers(token, phone);
        loadingOffers.value = false;
      } catch (error) {
        console.error("Error:", error);
      }
    };

    onMounted(loadOffers);

    return {
      showAddNewEventModal,
      openDrawer,
      addNewEvent,
      closeAddNewEventModal,
      offers,
      loadingOffers,
    };
  },
};
</script>
