<template>
  <div
    class="modal bottom-sheet-on-mobile"
    id="edit-event-modal"
    :class="{ active: showEditEventModal }"
    @click="closeModal()"
  >
    <div class="modal-content" @click.stop>
      <div class="modal-header">
        <h1>Modificǎ evenimentul</h1>
        <span
          class="close-modal splash-rounded material-symbols-rounded fill"
          @click="closeModal"
        >
          close
        </span>
      </div>

      <form>
        <date-picker
          :inline="true"
          class="calendar-picker"
          :value="eventDate"
          v-model="eventDate"
        >
        </date-picker>
        <p class="pristine-error text-help" v-if="showInvalid && !isValidTime">
          Selectează ora
        </p>

        <div class="input-wrapper" :class="{ 'has-danger': showInvalid }">
          <input
            id="hours"
            v-model="eventTime"
            type="time"
            name="hours"
            class="input creator"
            required
            data-pristine-required-message="Te rugăm să introduci ora evenimentului."
          />
          <label class="label" for="hours">Ora evenimentului*</label>
          <img class="checked" src="@/assets/img/check.svg" alt="" />
          <img class="error" src="@/assets/img/error.svg" alt="" />
          <p
            class="pristine-error text-help"
            v-if="showInvalid && !isValidTime"
          >
            Selectează ora
          </p>
        </div>

        <div class="input-wrapper" :class="{ 'has-danger': showInvalid }">
          <input
            id="autoLocation"
            v-model="eventLocation"
            ref="autoComplete"
            type="text"
            name="autoLocation"
            class="input pac-target-input"
          />

          <input
            id="city"
            type="text"
            class="input hidden-input"
            required
            data-pristine-required-message="Te rugăm să introduci locația din lista."
            autocomplete="chrome-off"
            readonly
          />
          <label class="label" for="city"
            >Introduceți locația evenimentului</label
          >
          <img class="checked" src="@/assets/img/check.svg" alt="" />
          <img class="error" src="@/assets/img/error.svg" alt="" />
          <p
            class="pristine-error text-help"
            v-if="showInvalid && !isValidTime"
          >
            Alege locația din listă
          </p>
        </div>

        <div class="input-wrapper" :class="{ 'has-danger': showInvalid }">
          <input
            v-model="eventPersons"
            @input="eventPersons = $event.target.value.replace(/\D/g, '')"
            class="input"
            type="text"
            name="clientName"
            placeholder="0"
            autocomplete="off"
            required
            minlength="3"
            data-pristine-minlength-message="Introduceti minim 3 caractere"
            data-pristine-required-message="Câmp obligatoriu"
          />
          <label class="label" for="clientName">Numarul de persoane</label>
          <img class="checked" src="@/assets/img/check.svg" alt="" />
          <img class="error" src="@/assets/img/error.svg" alt="" />
          <p
            class="pristine-error text-help"
            v-if="showInvalid && !isValidTime"
          >
            Introdu numărul de persoane
          </p>
        </div>
      </form>

      <div class="loading" v-if="loading">
        <vLottiePlayer
          name="lottie"
          class="lottie"
          loop
          :animationData="require('@/assets/lottie/simpleDotsLoader.json')"
        />
      </div>
      <div v-else class="modal-footer">
        <div class="secondary-action splash-rounded" @click="remove()">
          <span class="material-symbols-rounded"> delete </span>
          <p>Șterge evenimentul</p>
        </div>

        <div class="row">
          <button class="primary small col" @click="validate()">
            Salvează modificările
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import DatePicker from "vuejs3-datepicker";
import { useAuth0 } from "@auth0/auth0-vue";
import { editOffer } from "@/api";
import { useRouter } from "vue-router";

import { removeOffer } from "@/api";

export default {
  name: "EditEvent",
  components: {
    DatePicker,
    
  },
  props: {
    showEditEventModal: Boolean,
    offer: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const { user } = useAuth0();
    const step = ref(1);
    const eventDate = ref(new Date(props.offer.eventDate));
    const eventTime = ref("");
    const eventLocation = ref(props.offer.location);
    const eventPersons = ref(props.offer.nrAttendees);
    const placeCity = ref(null);
    const placeName = ref("");
    const placeAddress = ref(null);
    const offerId = ref("");
    const loading = ref(false);
    let autocomplete = ref(null);
    const autoComplete = ref(null);
    const { getAccessTokenSilently } = useAuth0();

    //validation
    const isValidDate = computed(() => !!eventDate.value);
    const isValidTime = computed(() => !!eventTime.value);
    const isValidLocation = computed(
      () => placeAddress.value !== "" && eventLocation.value !== null
    );
    const isValidPersons = computed(() => eventPersons.value > 0);
    const showInvalid = ref(false);

    const convertDateToTimeString = (dateString) => {
      let date = new Date(dateString);
      let hours = date.getHours();
      let minutes = date.getMinutes();
      return `${
        hours === 24 ? "00" : hours.toString().padStart(2, "0")
      }:${minutes.toString().padStart(2, "0")}`;
    };

    watch(placeName, () => {
      emit("input", {
        place: placeName.value,
        address: placeAddress.value,
        city: placeCity.value,
      });
    });

    watch(eventLocation, (newVal) => {
      if (placeAddress.value !== newVal) {
        placeAddress.value = "";
      }
    });

    onMounted(() => {
      window.initMap = initMap;

      const script = document.createElement("script");
      script.src =
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyBW1iQe89cgzpbujL4_jkRRaMb5TV6VagU&libraries=places&callback=initMap";
      script.async = true;
      script.defer = true;
      window.document.body.appendChild(script);

      script.addEventListener("load", () => {
        initMap();
      });

      eventTime.value = convertDateToTimeString(props.offer.eventDate);
    });

    const initMap = () => {
      var locationOptions = {
        fields: ["formatted_address", "name", "address_components"],
        componentRestrictions: { country: "RO" },
      };

      const google = window.google;

      autocomplete.value = new google.maps.places.Autocomplete(
        autoComplete.value,
        locationOptions
      );

      autocomplete.value.addListener("place_changed", () => {
        var place = autocomplete.value.getPlace();
        if (!place.address_components) {
          alert(
            "Aceasta locație nu are o adresa. Va rugam sa selectati alta locație sau sa introduceti adresa locației dorite."
          );
        } else {
          placeName.value = place.name;
          placeAddress.value = place.formatted_address;
          placeCity.value = place.address_components[1].long_name;
          eventLocation.value = place.formatted_address;
        }
      });
    };

    const editEvent = async () => {
      loading.value = true;

      const phone = user?.value.name;
      const token = await getAccessTokenSilently();

      let clientOffer = {
        Location: eventLocation.value,
        EventDate: ((d) => (
          d.setMinutes(d.getMinutes() - d.getTimezoneOffset()), d.toISOString()
        ))(
          new Date(
            `${new Date(eventDate.value).toISOString().slice(0, 10)}T${
              eventTime.value
            }:00`
          )
        ),
        City: placeCity.value,
        Phone: phone,
        EventAddress: eventLocation.value,
        NrAttendees: eventPersons.value,
      };

      offerId.value = await editOffer(token, props.offer.offerId, clientOffer);

      router.go(0);
      loading.value = false;
    };

    const validate = () => {
      if (
        isValidDate.value &&
        isValidTime.value &&
        isValidLocation.value &&
        isValidPersons.value
      ) {
        showInvalid.value = false;
        editEvent();
        emit("closeModal");
      } else {
        showInvalid.value = true;
      }
    };

    function closeModalFromBackground(event) {
      if (event.target.classList.contains("modal")) {
        emit("closeModal");
      }
    }

    const closeModal = () => {
      emit("closeModal");
    };

    const remove = async () => {
      loading.value = true;
      const token = await getAccessTokenSilently();
      await removeOffer(token, props.offer.offerId);

      window.location.href = "/events";
    };

    // Add your other methods here

    return {
      loading,
      step,
      eventLocation,
      placeCity,
      eventPersons,
      eventDate,
      eventTime,
      validate,
      closeModal,
      closeModalFromBackground,
      autoComplete,
      showInvalid,
      isValidDate,
      isValidTime,
      isValidLocation,
      isValidPersons,
      remove,
      // Return your other methods here
    };
  },
};
</script>
