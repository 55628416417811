<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div @click.stop="closeLightBox">
    <transition mode="out-in" name="vib-container-transition" @afterEnter="enableImageTransition"
      @beforeLeave="disableImageTransition">
      <div v-if="mediaItems && mediaItems.length > 0" v-show="lightBoxShown" ref="containerRef" class="vib-container">
        <div class="vib-content" @click.stop>
          <div v-if="loadingItemAction">Loading...</div>
          <div v-else-if="mediaItems[select].type == undefined || mediaItems[select].type == 'image'
            ">
            <v-lazy-image :key="mediaItems[select].src" :src="mediaItems[select].src"
              :srcset="mediaItems[select].srcset || ''" :src-placeholder="mediaItems[select].thumb" class="vib-image"
              :alt="mediaItems[select].caption" load="handleImageLoad" />
          </div>
          <div v-else-if="mediaItems[select].type == 'youtube'" class="video-background">
            <iframe :src="'https://www.youtube.com/embed/' +
              mediaItems[select].id +
              '?showinfo=0'
              " width="560" height="315" frameborder="0" allowfullscreen />
          </div>
          <video v-else-if="mediaItems[select].type == 'video'" :key="mediaItems[select].sources[0].src" ref="videoRef"
            preload="metadata" loading="lazy" controls :width="mediaItems[select].width"
            :height="mediaItems[select].height" :autoplay="mediaItems[select].autoplay">
            <source v-for="source in mediaItems[select].sources" :key="source.src" :src="source.src" :type="source.type">
          </video>
        </div>
        <!-- .vib-content -->

        <div v-if="showThumbs" class="vib-thumbnail-wrapper vib-hideable" :class="{ 'vib-hidden': controlsHidden }"
          @click.stop @mouseover="interfaceHovered = true" @mouseleave="interfaceHovered = false">
          <div v-for="(image, index) in imagesThumb" v-show="index >= thumbIndex.begin && index <= thumbIndex.end" :key="typeof image.thumb === 'string' ? `${image.thumb}${index}` : index
            " :style="{ backgroundImage: 'url(' + image.thumb + ')' }"
            :class="'vib-thumbnail' + (select === index ? '-active' : '')" @click.stop="showImage(index)">
            <slot v-if="image.type == 'video' || image.type == 'youtube'" name="videoIcon">
              <VideoIcon />
            </slot>
          </div>
        </div>
        <!-- .vib-thumbnail-wrapper -->

        <div class="vib-footer vib-hideable" :class="{ 'vib-hidden': controlsHidden }"
          @mouseover="interfaceHovered = true" @mouseleave="interfaceHovered = false">
          <slot name="customCaption" :current-media="mediaItems[select]">
            <div v-show="showCaption" v-html="mediaItems[select].caption" />
          </slot>
         
          <div class="vib-footer-count">
            <slot name="footer" :current="select + 1" :total="mediaItems.length">
              {{ select + 1 }} / {{ mediaItems.length }}
            </slot>
          </div>
        </div>

        <button v-if="isAdminPage" type="button" :title="deleteText" class="vib-delete vib-hideable"
          :class="{ 'vib-hidden': controlsHidden }" @click="remove" @mouseover="interfaceHovered = true"
          @mouseleave="interfaceHovered = false">
          <slot name="delete">
            <DeleteIcon />
            <span>Șterge fotografia</span>
          </slot>
        </button>

        <button v-if="!isAdminPage" type="button" :title="deleteText" class="vib-delete vib-hideable"
        :class="{ 'vib-hidden': controlsHidden }" @click="remove" @mouseover="interfaceHovered = true"
        @mouseleave="interfaceHovered = false">
        <slot name="download">
          <span class="material-symbols-rounded"> download </span> 
          <span>Descarcă</span>
        </slot>
      </button>


        <button v-if="closable" type="button" :title="closeText" class="vib-close vib-hideable"
          :class="{ 'vib-hidden': controlsHidden }" @mouseover="interfaceHovered = true"
          @mouseleave="interfaceHovered = false">
          <slot name="close">
            <CloseIcon />
          </slot>
        </button>

        <button v-if="mediaItems.length > 1" type="button" class="vib-arrow vib-arrow-left vib-hideable"
          :class="{ 'vib-hidden': controlsHidden }" :title="previousText" @click.stop="previousImage()"
          @mouseover="interfaceHovered = true" @mouseleave="interfaceHovered = false">
          <slot name="previous">
            <LeftArrowIcon />
          </slot>
        </button>

        <button v-if="mediaItems.length > 1" type="button" class="vib-arrow vib-arrow-right vib-hideable"
          :class="{ 'vib-hidden': controlsHidden }" :title="nextText" @click.stop="nextImage()"
          @mouseover="interfaceHovered = true" @mouseleave="interfaceHovered = false">
          <slot name="next">
            <RightArrowIcon />
          </slot>
        </button>
      </div>
      <!-- .vib-container -->
    </transition>
  </div>
</template>

<script>
import { ref, watch, onMounted, onBeforeUnmount } from "vue";
import LeftArrowIcon from "./LeftArrowIcon";
import RightArrowIcon from "./RightArrowIcon";
import CloseIcon from "./CloseIcon";
import DeleteIcon from "./DeleteIcon";
import VideoIcon from "./VideoIcon";
import VLazyImage from "v-lazy-image";

let Hammer;

// Check if window is defined (for server-side rendering)
if (typeof window !== "undefined") {
  Hammer = require("hammerjs");
}

export default {
  name: 'LightBox',
  components: {
    LeftArrowIcon,
    RightArrowIcon,
    CloseIcon,
    DeleteIcon,
    VideoIcon,
    VLazyImage,
  },

  props: {
    media: {
      type: Array,
      required: true,
    },

    disableScroll: {
      type: Boolean,
      default: true,
    },
    closable: {
      type: Boolean,
      default: true,
    },

    startAt: {
      type: Number,
      default: 0,
    },

    nThumbs: {
      type: Number,
      default: 7,
    },

    showThumbs: {
      type: Boolean,
      default: true,
    },

    // Mode
    autoPlay: {
      type: Boolean,
      default: false,
    },

    autoPlayTime: {
      type: Number,
      default: 3000,
    },

    interfaceHideTime: {
      type: Number,
      default: 3000,
    },

    showCaption: {
      type: Boolean,
      default: false,
    },

    lengthToLoadMore: {
      type: Number,
      default: 0,
    },

    closeText: {
      type: String,
      default: "Close (Esc)",
    },

    previousText: {
      type: String,
      default: "Previous",
    },

    nextText: {
      type: String,
      default: "Next",
    },
    loadingItemAction: {
      type: Boolean,
      default: false
    },
    isClient: {
      type: Boolean,
      default: true
    }
  },

  setup(props, { emit }) {
    const select = ref(props.startAt);
    const lightBoxShown = ref(false);
    const controlsHidden = ref(false);
    const imageTransitionName = ref("vib-image-no-transition");
    const timer = ref(null);
    const interactionTimer = ref(null);
    const interfaceHovered = ref(false);
    const loading = ref(true);
    const mediaItems = ref(props.media);
    const isAdminPage = ref(false);
    // const media[select] = ref(props.media[select.value]);

    const containerRef = ref(null);
    const videoRef = ref(null);

    const thumbIndex = () => {
      const halfDown = Math.floor(props.nThumbs / 2);

      if (select.value >= halfDown && select.value < props.media.length - halfDown) {
        return {
          begin: select.value - halfDown + (1 - (props.nThumbs % 2)),
          end: select.value + halfDown,
        };
      }

      if (select.value < halfDown) {
        return {
          begin: 0,
          end: props.nThumbs - 1,
        };
      }

      return {
        begin: props.media.length - props.nThumbs,
        end: props.media.length - 1,
      };
    };

    const remove = () => {
      const indexToRemove = select.value
      if (select.value === props.media.length - 1) {
        select.value = select.value - 1;
      }

      emit("remove", indexToRemove);


    }

    const imagesThumb = () => {
      return props.media.map(({ thumb, type }) => ({ thumb, type }));
    };

    const handleImageLoad = () => {
      loading.value = false;
    };

    const onLightBoxOpen = () => {
      emit("onOpened");

      if (props.disableScroll) {
        document.querySelector("html").classList.add("no-scroll");
      }

      document.querySelector("body").classList.add("vib-open");
      document.addEventListener("keydown", addKeyEvent);

      if (videoRef.value && videoRef.value.autoplay) {
        videoRef.value.play();
      }
    };

    const onLightBoxClose = () => {
      emit("onClosed");

      if (props.disableScroll) {
        document.querySelector("html").classList.remove("no-scroll");
      }

      document.querySelector("body").classList.remove("vib-open");
      document.removeEventListener("keydown", addKeyEvent);

      if (videoRef.value) {
        videoRef.value.pause();
        videoRef.value.currentTime = "0";
      }
    };

    const onToggleLightBox = (value) => {
      if (value) {
        onLightBoxOpen();
      } else {
        onLightBoxClose();
      }
    };

    const showImage = (index, media) => {
      mediaItems.value = media;
      select.value = index;
      controlsHidden.value = false;
      lightBoxShown.value = true;
    };

    const showLightBox = () => {
      lightBoxShown.value = true;
    };

    const addKeyEvent = (event) => {
      switch (event.keyCode) {
        case 37: // left arrow
          previousImage();
          break;
        case 39: // right arrow
          nextImage();
          break;
        case 27: // esc
          closeLightBox();
          break;
      }
    };

    const previousImage = () => {
      select.value = (select.value + props.media.length - 1) % props.media.length;
    };

    const nextImage = () => {
      select.value = (select.value + 1) % props.media.length;
    };

    const enableImageTransition = () => {
      handleMouseActivity();
      imageTransitionName.value = "vib-image-transition";
    };

    const disableImageTransition = () => {
      imageTransitionName.value = "vib-image-no-transition";
    };

    const handleMouseActivity = () => {
      clearTimeout(interactionTimer.value);

      if (controlsHidden.value) {
        controlsHidden.value = false;
      }

      if (interfaceHovered.value) {
        stopInteractionTimer();
      } else {
        startInteractionTimer();
      }
    };

    const startInteractionTimer = () => {
      interactionTimer.value = setTimeout(() => {
        controlsHidden.value = true;
      }, props.interfaceHideTime);
    };
    const closeLightBox = () => {
      if (videoRef.value) videoRef.value.pause();
      if (!props.closable) return;
      lightBoxShown.value = false;
    };

    const stopInteractionTimer = () => {
      interactionTimer.value = null;
    };

    // Watch for changes in select value
    watch(select, (newVal) => {
      emit("onImageChanged", newVal);

      if (newVal >= props.media.length - props.lengthToLoadMore - 1) {
        emit("onLoad");
      }

      if (newVal === props.media.length - 1) {
        emit("onLastIndex");
      }

      if (newVal === 0) {
        emit("onFirstIndex");
      }

      if (newVal === props.startAt) {
        emit("onStartIndex");
      }
    });

    // Handle component lifecycle hooks
    onMounted(() => {
      isAdminPage.value = window.location.pathname.includes('memoriesCollectionAdmin')
      if (props.autoPlay) {
        timer.value = setInterval(nextImage, props.autoPlayTime);
      }

      onToggleLightBox(lightBoxShown.value);


      if (containerRef.value) {
        const hammer = new Hammer(containerRef.value);

        hammer.on("swiperight", previousImage);
        hammer.on("swipeleft", nextImage);

        containerRef.value.addEventListener("mousedown", handleMouseActivity);
        containerRef.value.addEventListener("mousemove", handleMouseActivity);
        containerRef.value.addEventListener("touchmove", handleMouseActivity);
      }
    });

    onBeforeUnmount(() => {
      document.removeEventListener("keydown", addKeyEvent);

      if (props.autoPlay) {
        clearInterval(timer.value);
      }

      if (containerRef.value) {
        containerRef.value.removeEventListener("mousedown", handleMouseActivity);
        containerRef.value.removeEventListener("mousemove", handleMouseActivity);
        containerRef.value.removeEventListener("touchmove", handleMouseActivity);
      }
    });

    return {
      select,
      lightBoxShown,
      controlsHidden,
      imageTransitionName,
      timer,
      interactionTimer,
      interfaceHovered,
      loading,
      thumbIndex,
      remove,
      imagesThumb,
      handleImageLoad,
      onToggleLightBox,
      showImage,
      previousImage,
      nextImage,
      enableImageTransition,
      disableImageTransition,
      handleMouseActivity,
      startInteractionTimer,
      stopInteractionTimer,
      addKeyEvent,
      closeLightBox,
      showLightBox,
      containerRef,
      videoRef,
      mediaItems,
      isAdminPage
    };
  },
};
</script>

<style src="./style.css"></style>
