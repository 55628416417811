<template>
  <div class="header-info" id="autosave-component">
    <span class="material-symbols-rounded" :class="{'hidden': !isSaving}" id="sync"> sync </span>
    <span class="material-symbols-rounded valid" :class="{'hidden': isSaving}" id="checkmark"> check_circle </span>

    <p class="text" v-if="isSaving">Sincronizare</p>
    <p class="text" v-else>Sincronizat</p>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";
import anime from "animejs";
import { scales } from "chart.js";
import $ from "jquery";

export default {
  name: "Autosave-Component",
  props: {
    isSaving: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  watch: {
    isSaving(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.executeChildFunction(newValue); // Execute child function on change
      }
    },
  },
  methods: {
    executeChildFunction(isSaving) {
      console.log('Value of isSaving is:' + isSaving)
      if (isSaving) {
        $("#autosave-component").removeClass("flash");
      } else {
        $("#autosave-component").addClass("flash");
        this.$nextTick(() => {
          anime({
            targets: '.header-info .material-symbols-rounded#checkmark',
            easing: 'easeInOutQuad',
            scale: [1.2, 1],
            duration: 200,
          });
        });
      }
    }
  }
};
</script>