<template>
  <div class="card module my-privent-card memories-collection-card" :class="[containerClass]">
    <div class="inside">
      <div class="text-content">
        <h2>Listă de invitați</h2>
        <div class="module-button" @click="onClick">
          <span class="material-symbols-rounded fill">
            group
          </span>
          <p>Vezi lista<br>de invitați</p>
        </div>
      </div>
      <div class="elements-content">
        <div class="detail-content">
          <div class="group">
            <h1>{{guestlistConfirmations}}</h1>
            <h2>răspunsuri<br>primite</h2>
          </div>
          <div class="group" v-if="guestlistTotal != null">
            <h1>{{formatCounterPercent(attendesPercent)}}</h1>
            <h2>rată de<br>răspuns</h2>
          </div>
        </div>
      </div>
    </div>

    <img class="background-image" :src="resolvedImage" alt="Checklist Background">
  </div>
</template>
<script>
import { computed } from 'vue';

export default {
  name: 'Guestlist-Card',
  components: {
    
  },
  props: {
    isActivated: { type: Boolean },
    onClick: { type: Function },
    guestlistConfirmations: {type: Number},
    guestlistTotal: {type: Number},
    containerClass: {
      type: String,
      default: ''
    },
  },
  methods: {
    formatCounterPercent(value) {
      return `${value.toFixed(1)}%`;
    },
  },
  setup(props) {
    const resolvedImage = computed(() => {
      return require('@/assets/img/dashboard/' + 'guestlistBackground.png');
    });

    const attendesPercent = computed(() => {
      let percent = 0;
      percent = (props.guestlistConfirmations * 100) / props.guestlistTotal;
      return percent;
    });

    return {
      resolvedImage,
      attendesPercent
    };
  }
}
</script>