<template>
  <div class="modal bottom-sheet-on-mobile" id="change-profile-details-modal"
    :class="{ active: showChangeProfileDetailsModal }" @click="closeModal">
    <div class="modal-content">
      <div class="modal-header">
        <h1>Setările contului</h1>
        <span class="close-modal splash-rounded material-symbols-rounded fill" @click="closeModalFromButton">
          close
        </span>
      </div>

      <h3>Completați detaliile pentru a putea primi notificări prin email.</h3>

      <form ref="formElement" @submit.prevent>

        <div class="input-wrapper">
          <input class="input" type="text" id="name" name="name" placeholder-shown="true" autocomplete="off" required
            minlength="2" v-model="this.lastName" placeholder="Scrie aici numele tau"
            data-pristine-minlength-message="Introduceti minim 2 caractere"
            data-pristine-required-message="Câmp obligatoriu" />
          <label class="label" for="name">Nume*</label>
          <img class="checked" src="@/assets/img/check.svg" alt="" />
          <img class="error" src="@/assets/img/error.svg" alt="" />
        </div>
        <div class="input-wrapper">
          <input class="input" type="text" id="name" name="name" placeholder-shown="true" autocomplete="off" required
            minlength="2" v-model="this.firstName" placeholder="Scrie aici prenumele tau"
            data-pristine-minlength-message="Introduceti minim 2 caractere"
            data-pristine-required-message="Câmp obligatoriu" />
          <label class="label" for="name">Prenume*</label>
          <img class="checked" src="@/assets/img/check.svg" alt="" />
          <img class="error" src="@/assets/img/error.svg" alt="" />

        </div>
        <div class="input-wrapper disabled">
          <input class="input" type="text" id="name" name="name" placeholder-shown="true" autocomplete="off" required
            minlength="2" v-model="this.phoneNumber" disabled placeholder="Scrie aici numarul tau de telefon"
            data-pristine-minlength-message="Introduceti minim 2 caractere"
            data-pristine-required-message="Câmp obligatoriu" />
          <label class="label" for="name">Numar de telefon*</label>
          <img class="checked" src="@/assets/img/check.svg" alt="" />
          <img class="error" src="@/assets/img/error.svg" alt="" />
        </div>
        <div class="input-wrapper">
          <input class="input" type="text" id="name" name="name" placeholder-shown="true" autocomplete="off" required
            minlength="2" v-model="this.emailAddress" placeholder="Scrie aici adresa ta de email"
            data-pristine-minlength-message="Introduceti minim 2 caractere"
            data-pristine-required-message="Câmp obligatoriu" />
          <label class="label" for="name">Email*</label>
          <img class="checked" src="@/assets/img/check.svg" alt="" />
          <img class="error" src="@/assets/img/error.svg" alt="" />
        </div>
      </form>
      <!-- TODO: add loader here when saving is true -->
      <button class="primary small" @click.prevent="save">Schimba detaliile</button>
    </div>
  </div>
</template>

<script>
import { ref, toRefs, onMounted } from 'vue';
import { updateEventAppUser, getEventAppUser } from '@/api.js'
import { useAuth0 } from '@auth0/auth0-vue';
import { notify } from '@kyvg/vue3-notification';
import * as Pristine from 'pristinejs';

export default {
  name: 'ChangeProfileDetailsModal',
  props: {
    showChangeProfileDetailsModal: Boolean,
  },
  setup(props, { emit }) {
    const { getAccessTokenSilently, user } = useAuth0();

    const firstName = ref('');
    const lastName = ref('');
    const phoneNumber = ref(user.value.phone_number);
    const emailAddress = ref('myemail@mail.com');
    const birthday = ref(null);

    const saving = ref(false);

    const defaultConfig = {
      classTo: 'input-wrapper',
      errorClass: 'has-danger',
      successClass: 'has-success',
      errorTextParent: 'input-wrapper',
      errorTextClass: 'text-help'
    };
    const formElement = ref(null);
    let pristine;


    const closeModal = (event) => {
      if (event.target.classList.contains('modal')) {
        emit('closeModal');
      }
    };
    const closeModalFromButton = () => {
      emit('closeModal');

    };

    const save = async () => {
      if (formifyClient()) {
        saving.value = true;

        const token = await getAccessTokenSilently();

        const data = {
          FirstName: firstName.value,
          LastName: lastName.value,
          Phone: user.value.phone_number,
          Email: emailAddress.value,
        }

        await updateEventAppUser(token, data);

        saving.value = false;
        notify({
          type: 'success',
          title: 'Succes',
          text: 'Detaliile au fost schimbate cu succes',
        });

        emit('closeModal', true);
      }
    }

    const getUser = async () => {
      const token = await getAccessTokenSilently();


      return await getEventAppUser(token, user.value.phone_number)
    }

    const formifyClient = () => {
      const valid = pristine.validate();
      if (valid) {
        pristine.reset();
        return true;
      }
      return false;
    };

    onMounted(async () => {
      const user = await getUser();
      firstName.value = user.firstName;
      lastName.value = user.lastName;
      emailAddress.value = user.email;

      pristine = new Pristine(formElement.value, defaultConfig);
    });

    return {
      ...toRefs(props),
      firstName,
      lastName,
      phoneNumber,
      emailAddress,
      birthday,
      saving,
      formElement,
      closeModal,
      closeModalFromButton,
      save
    };
  }
}
</script>
  