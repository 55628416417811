import axios from 'axios';

const apiCall = async (method, url, token, data = null) => {
    // eslint-disable-next-line no-useless-catch
    try {
        const options = {
            method: method,
            url: `${process.env.VUE_APP_baseUrl}${url}`,
            headers: { Authorization: `Bearer ${token}` }
        };

        if (data) {
            options.data = data;
        }
        const response = await axios(options);
        return response.data;
    } catch (error) {
        // TODO: log error here
        throw error

    }
};

export const getClientOffers = (token, phone) => apiCall('get', `EventApp/ClientOffers/${phone}`, token);

export const getClientOffer = (token, id) => apiCall('get', `EventApp/ClientOffer/${id}`, token);

export const getGalleries = (token, phone) => apiCall('get', `MediaHub/Galleries/${phone}`, token);

export const getGallery = (id) => apiCall('get', `MediaHub/GetGallery/${id}`);

export const getMediaUploaders = (id) => apiCall('get', `MediaHub/MediaUploaders/${id}`);

export const uploadMedia = (id, postData) => apiCall('post', `MediaHub/Upload/${id}`, null, postData);

export const deleteMediaItem = (token, id, postData) => apiCall('post', `MediaHub/DeleteItem/${id}`, token, postData);

export const getOffersPreview = (token, phone) => apiCall('get', `EventApp/ClientOffersPreview/${phone}`, token);

export const enableMemoriesCollection = (token, id) => apiCall('post', `MediaHub/CreateGallery/${id}`, token);

export const saveGalleryInformation = (token, id, postData) => apiCall('post', `MediaHub/UpdateGallery/${id}`, token, postData);

export const createOffer = (token, postData) => apiCall('post', `EventApp/ClientOffer/Add`, token, postData);

export const editOffer = (token, id, postData) => apiCall('post', `EventApp/ClientOffer/${id}/Edit`, token, postData);

export const saveGeneralPreferencesInvitation = (token, offerId, invitationId, postData) => apiCall('post', `EventApp/Invitation/${offerId}/${invitationId}/SavePreferences`, token, postData);

export const saveInvitation = (token, offerId, invitationId, postData) => apiCall('post', `EventApp/Invitation/${offerId}/${invitationId}/Edit`, token, postData);

export const createInvitationResponse = (offerId, invitationId, postData) => apiCall('post', `EventApp/Invitation/${offerId}/${invitationId}/CreateResponse`, "", postData);

export const createEmptyInvitation = (token, id, postData) => apiCall('post', `EventApp/Invitation/${id}/CreateEmpty`, token, postData);

export const getInvitation = (offerId, invitationId) => apiCall('get', `EventApp/Invitation/ById/${offerId}/${invitationId ? invitationId : 0}`);

export const getInvitationForClient = (offerId, invitationId) => apiCall('get', `EventApp/Invitation/Client/ById/${offerId}/${invitationId ? invitationId : 0}`);

export const getInvitations = (offerId) => apiCall('get', `EventApp/Invitations/${offerId}`);

export const getGuestlist = (token, id) => apiCall('get', `EventApp/Invitation/Responses/${id}`, token);

export const publishInvitation = (token, offerId, invitationId, postData) => apiCall('post', `EventApp/Invitation/${offerId}/${invitationId}/Publish`, token, postData);

export const addUserToOffer = (token, id, postData) => apiCall('post', `EventApp/ClientOffer/${id}/Add`, token, postData);

export const addEventAppUser = (token, postData) => apiCall('post', `EventApp/User/Add`, token, postData);

export const updateEventAppUser = (token, postData) => apiCall('post', `EventApp/User/Update`, token, postData);

export const getEventAppUser = (token, phone) => apiCall('get', `EventApp/User/${phone}`, token);

export const getOfferUsers = (token, id) => apiCall('get', `EventApp/ClientOffer/${id}/Users`, token);

export const removeUserFromOffer = (token, id, phone) => apiCall('post', `EventApp/ClientOffer/${id}/Remove/${phone}`, token, null);

export const updateChecklists = (token, id, data) => apiCall('post', `EventApp/ClientOffer/${id}/Checklist/Update`, token, data);

export const getChecklists = (token, id) => apiCall('get', `EventApp/ClientOffer/${id}/Checklists`, token);

export const removeInvitation = (token, offerId, invitationId) => apiCall('delete', `EventApp/Invitation/${offerId}/${invitationId}/Remove`, token);

export const duplicateInvitation = (token, offerId, invitationId) => apiCall('post', `EventApp/Invitation/${offerId}/${invitationId}/Duplicate`, token);

export const removeOffer = (token, id) => apiCall('delete', `EventApp/ClientOffer/${id}/RemoveOffer`, token);

export const getCheckAliasExists = (token, id, data) => apiCall('post', `EventApp/Invitation/ExistsUrl/${id}`, token, data);

export const getFloorplan = (token, offerId, floorplanId) => apiCall('get', `EventApp/ClientOffer/FloorPlan/${offerId}/${floorplanId}`, token);

export const getFloorplans = (token, offerId) => apiCall('get', `EventApp/ClientOffer/FloorPlans/${offerId}/`, token);

export const updateFloorplan = (token, id, floorplanId, data) => apiCall('post', `EventApp/ClientOffer/FloorPlan/${id}/${floorplanId}`, token, data);

export const removeFloorplan = (token, id, floorplanId) => apiCall('delete', `EventApp/ClientOffer/FloorPlan/${id}/${floorplanId}`, token);

export const duplicateFloorplan = (token, id, floorplanId) => apiCall('post', `EventApp/ClientOffer/FloorPlan/Duplicate/${id}/${floorplanId}`, token);

export const createFloorplan = (token, id, data) => apiCall('post', `EventApp/ClientOffer/FloorPlan/Create/${id}`, token, data);

export const markResponseSeen = (token, responseId, offerId) => apiCall('post', `EventApp/Invitation/${offerId}/Response/${responseId}`, token);

export const removeInvitationResponse = (token, responseId, offerId) => apiCall('delete', `EventApp/Invitation/${offerId}/Response/${responseId}`, token);

export const createPaymentIntent = (token, data, id, invitationId) => apiCall('post', `EventApp/CreatePaymentIntent/${id}/${invitationId}`, token, data);

export const getPaymentSession = (token, sessionId, id, invitationId) => apiCall('get', `EventApp/GetPayment/${id}/${invitationId}/${sessionId}`, token);

export const updateTimeline = (token, data, offerId) => apiCall('post', `Timeline/UpdateEvents/${offerId}`, token, data);

export const getTimeline = (token, offerId) => apiCall('get', `Timeline/Events/${offerId}`, token);

export const updateBudget = (token, data, id) => apiCall('post', `Budget/Update/${id}`, token, data);

export const getBudget = (token, id) => apiCall('get', `Budget/${id}`, token);

export const getMediaZip = (token, id) => apiCall('get', `MediaHub/GenerateZip/${id}`, token);

export const createInvoice = (token, data, id, email) => apiCall('post', `EventApp/Invoice/Create/${id}/${email}`, token, data);

