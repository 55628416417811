<template>
  <div id="checkout">
    <!-- Checkout will insert the payment form here -->
    <!-- Example how to use the component -->
    <!-- <stripe-checkout :offerId="offerId" :invitationId="invitationId" /> -->
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { loadStripe } from "@stripe/stripe-js";
import { createPaymentIntent } from "@/api";
import { useAuth0 } from "@auth0/auth0-vue";

export default {
  name: "StripeCheckout",
  props: {
    offerId: {
      type: String,
      required: true,
    },
    invitationId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const stripe = ref(null);
    const checkout = ref(null);
    const { getAccessTokenSilently, user } = useAuth0();

    const fetchClientSecret = async () => {
      const token = await getAccessTokenSilently();
      const response = await createPaymentIntent(
        token,
        null,
        props.offerId,
        props.invitationId
      );

      return response.clientSecret;
    };

    onMounted(async () => {
      stripe.value = await loadStripe(process.env.VUE_APP_stripe_key);
      checkout.value = await stripe.value.initEmbeddedCheckout({
        fetchClientSecret,
      });
      checkout.value.mount("#checkout");
    });
  },
};
</script>
