<template>
    <div class="wrapper-content my-event">
      <div class="header">
        <div class="mobile-only">
          <div class="menu-toggle">
            <span class="material-symbols-rounded" @click="openDrawer()">
              menu
            </span>
          </div>
          <img class="logo" src="@/assets/img/brand/MyPriventDark.png">
        </div>

        <div class="tablet-only">
          <h1>Serviciu - Privent</h1>
          <div class="event-info">
            <div class="event-info-container">
              <p class="subtitle">Data și ora</p>
              <p class="title">22.01.02</p>
            </div>
            <div class="event-info-container">
              <p class="subtitle">Locația</p>
              <p class="title">Pitesti</p>
            </div>
            <div class="event-info-container">
              <p class="subtitle">Nr. participanti</p>
              <p class="title">22 pers</p>
            </div>
          </div>
        </div>
      </div>

      <div class="content-loading" v-if="loadingOffer">
        <vLottiePlayer
      name="lottie"
      class="lottie"
      loop
      :animationData="require('@/assets/lottie/simpleDotsLoader.json')"
  />
      </div>

      <div class="content" v-else>
        <div class="container services">
          <div class="row">
            <div
              class="col-100 gap-10">
              <h1>Servicii Privent</h1>
              <div class="horizontal-scroll offer">
                <artist-card :artistPhoto="'@/assets/img/eventSampleImage.jpg'"
                  :artistName="CiprianGaisteanu" />
                <!-- <service-card :offerPackage="offer.selectedSoundPackage" />
                <service-card :offerPackage="offer.selectedLightPackage" /> -->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-50 gap-10">
              <h1>Contract</h1>
              <contract-card :contractNumber="PRV-220" />
              <div class="col-100 gap-10">
                <h1>Facturi</h1>
                <div class="horizontal-scroll invoice">
                  <invoice-card :invoiceNumber="22" />
                  <invoice-card :invoiceNumber="22"/>
                </div>
              </div>
              <h1>Feedback</h1>
              <!--TODO: Use offer.feedbackCompleted -->
              <feedback-card :isCompleted="false" />
  
            </div>
            <div class="col-50 gap-10">
              <h1>Memories Collection</h1>
              <memories-collection-card />
              <h1>Preferinte muzicale</h1>
              <!--TODO: Use offer.playlistCompleted -->
              <music-preferences-card :isCompleted="false" />
             
              
            </div>
          </div>
        </div>
      </div>
  
    </div>
  </template> 
  
  <script>
import ContractCard from "@/views/dashboard/components/ContractCard";
import InvoiceCard from "@/views/dashboard/components/InvoiceCard";
import MusicPreferencesCard from "@/views/dashboard/components/MusicPreferencesCard";
// import EmptyModule from "@/views/dashboard/components/EmptyModule";
import FeedbackCard from "@/views/dashboard/components/FeedbackCard";
import ArtistCard from "@/views/dashboard/components/ArtistCard";
//import ServiceCard from "@/views/dashboard/components/ServiceCard";


export default {
  name: 'EventService',
  components: { ContractCard, InvoiceCard,  MusicPreferencesCard,  FeedbackCard, ArtistCard,         
 },
  setup() {
  
    const loadingOffer = false;

    const openDrawer = () => {
      document.querySelector('.drawer-wrapper').classList.add('open');
    };

    return {
      loadingOffer,
      openDrawer
    };
  }
}
</script>
  