<template>
  <div class="module" :class="{ 'card-border-green': this.isCompleted, 'card-border': !this.isCompleted }">
    <div class="row align-x-left align-y-center">
      <span class="material-symbols-rounded fill main" :class="{ 'gradient green': this.isCompleted, 'gradient orange': !this.isCompleted }">
        stars
      </span>
      <div class="column">
        <h3>Recenzii</h3>
        <h2 class="gradient" :class="{ green: this.isCompleted }">Ofera-ne<br>feedback</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Feedback-Card',
  props: {
    isCompleted: {
      type: Boolean,
      required: true
    }
  },
}
</script>
