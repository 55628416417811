<template>
  <div class="wrapper-content floor-plan">
    <add-edit-floor-plan-modal :showAddEditFloorPlanModel="showAddEditFloorPlanModel" :isEditing="true"
      @closeModal="closeAddEditFloorPlan()" />

    <div>
      <add-table-modal :showAddTableBool="showAddTableBool" :tableType="tableType" v-if="showAddTableBool"
        @addTableFunction="handleTableTypeSelected" @closeModal="closeAddTableModal" />

      <div class="header space-between hide-bg-on-mobile">
        <div class="mobile-only">
          <div class="menu-toggle">
            <span class="material-symbols-rounded" @click="openDrawer()">

            </span>
          </div>
        </div>
        <div class="tablet-only">
          <div class="vertical-align gap-5 splash-rounded splash-padding" @click="this.$router.go(-1)">
            <span class="material-symbols-rounded"> arrow_back </span>
            <h1>Părăsește modulul de editare</h1>
          </div>
        </div>


        <div class="actions">

          <!-- TODO: Leo trebuie sters clickul asta dupa ce implementezi autosaveul, componenta face tot ce trebuie singura, doar modifica valoarea isSaving -->
          <a @click="triggerAutosave()">
            autosave
          </a>
          <autosave-component :isSaving="isSaving" @update:isSaving="isSaving = $event" />

          <div class="header-button secondary" @click="showAddEditFloorPlan()">
            <div class="highlight">
              <span class="material-symbols-rounded"> edit </span>
            </div>
            <div class="text">Configurează</div>
          </div>
          <div class="header-button primary" @click="saveDesign">
            <div class="highlight">
              <span class="material-symbols-rounded"> save </span>
            </div>
            <div class="text">Salvează</div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="content-loader-overlay" v-if="isLoading">

          <vLottiePlayer name="lottie" class="lottie" loop
            :animationData="require('@/assets/lottie/simpleDotsLoader.json')" />

        </div>
        <div class="controls">
          <h2>Adauga elemente</h2>
          <div class="elements">
            <img src="@/assets/img/tables/RoundedTable.svg" draggable="false" alt="Description of the image"
              @click="showAddTableModal('roundTable')" />
            <img src="@/assets/img/tables/RoundedSquare.svg" draggable="false" alt="Description of the image"
              @click="showAddTableModal('squareTable')" />
            <img src="@/assets/img/tables/RoundedRectangle.svg" draggable="false" alt="Description of the image"
              @click="showAddTableModal('rectangleTable')" />
            <img src="@/assets/img/tables/PrezidiumTable.svg" draggable="false" alt="Description of the image"
              @click="showAddTableModal('prezidiumTable')" />
            <img src="@/assets/img/tables/BarTable.svg" draggable="false" alt="Description of the image"
              @click="addBarTable()" />
            <div class="delete-element" v-if="selectedTable" @click="deleteSelectedTable">
              <span class="material-symbols-rounded">
                cancel
              </span>
              <p>
                Șterge elementul
              </p>
            </div>
          </div>
        </div>
        <div ref="canvasContainer" class="canvas-container" style="width: 100%; height: 100%; position: relative">
          <canvas ref="canvas"></canvas>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fabric } from "fabric";
import AddTableModal from "@/views/floorPlan/components/AddTableModal.vue";
import blobstoragehelper from "@/helpers/blobstoragehelper";
import { updateFloorplan, getFloorplan } from "@/api";
import { useAuth0 } from "@auth0/auth0-vue";
import AddEditFloorPlanModal from "@/views/floorPlan/components/AddEditFloorPlanModal.vue";
import AutosaveComponent from "@/components/AutosaveComponent.vue";

export default {
  data() {
    return {
      canvas: null,
      realWidth: 0,
      realHeight: 0,
      scaleFactor: 1,
      heightText: null,
      widthText: null,
      selection: true,
      showAddTableBool: false,
      tableType: null,
      tableName: null,
      offerId: null,
      floorplanId: 0,
      shapeIndex: 1,
      isLoading: true,
      isSaving: false,
      showAddEditFloorPlanModel: false,
      floorplan: null,
      selectedTable: null,
      tableNameParameters: {
        fontSize: 18,
        fontFamily: "Poppins",
        fill: "#777777",
        lockRotation: true,
        lockScalingX: true,
        lockScalingY: true,
        hasRotatingPoint: false,
        hasBorders: false,
      },
      personsStringParameters: {
        fontSize: 14,
          fontFamily: "Poppins",
          fill: "#777777",
          lockRotation: true,
          lockScalingX: true,
          lockScalingY: true,
          hasRotatingPoint: false,
          hasBorders: false,
      }
    };
  },
  components: {
    AddTableModal,
    AddEditFloorPlanModal,
    AutosaveComponent
  },
  async mounted() {
    this.offerId = this.$route.params.offerId;
    this.floorplanId = this.$route.params.floorplanId;


    await this.loadSavedDesign();

    this.realWidth = this.floorplan.realWidth;
    this.realHeight = this.floorplan.realHeight;
    this.shapeIndex = this.floorplan.shapeIndex;

    this.isLoading = false;

    this.initializeCanvas();

    window.addEventListener("resize", this.resizeCanvas);
    window.addEventListener("keydown", this.handleKeyDown);
    this.canvas.on('object:modified', (e) => {
      e.target.setCoords();
    });

  },
  methods: {
    openDrawer() {
      document.querySelector('.drawer-wrapper').classList.add('open');
    },
    triggerAutosave() {
      this.isSaving = !this.isSaving;
    },
    addCustomControls() {
      const deleteIconSrc = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E"; // Base64 encoded delete icon
      const cloneIconSrc = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 55.699 55.699' width='100px' height='100px' xml:space='preserve'%3E%3Cpath style='fill:%23010002;' d='M51.51,18.001c-0.006-0.085-0.022-0.167-0.05-0.248c-0.012-0.034-0.02-0.067-0.035-0.1 c-0.049-0.106-0.109-0.206-0.194-0.291v-0.001l0,0c0,0-0.001-0.001-0.001-0.002L34.161,0.293c-0.086-0.087-0.188-0.148-0.295-0.197 c-0.027-0.013-0.057-0.02-0.086-0.03c-0.086-0.029-0.174-0.048-0.265-0.053C33.494,0.011,33.475,0,33.453,0H22.177 c-3.678,0-6.669,2.992-6.669,6.67v1.674h-4.663c-3.678,0-6.67,2.992-6.67,6.67V49.03c0,3.678,2.992,6.669,6.67,6.669h22.677 c3.677,0,6.669-2.991,6.669-6.669v-1.675h4.664c3.678,0,6.669-2.991,6.669-6.669V18.069C51.524,18.045,51.512,18.025,51.51,18.001z M34.454,3.414l13.655,13.655h-8.985c-2.575,0-4.67-2.095-4.67-4.67V3.414z M38.191,49.029c0,2.574-2.095,4.669-4.669,4.669H10.845 c-2.575,0-4.67-2.095-4.67-4.669V15.014c0-2.575,2.095-4.67,4.67-4.67h5.663h4.614v10.399c0,3.678,2.991,6.669,6.668,6.669h10.4 v18.942L38.191,49.029L38.191,49.029z M36.777,25.412h-8.986c-2.574,0-4.668-2.094-4.668-4.669v-8.985L36.777,25.412z M44.855,45.355h-4.664V26.412c0-0.023-0.012-0.044-0.014-0.067c-0.006-0.085-0.021-0.167-0.049-0.249 c-0.012-0.033-0.021-0.066-0.036-0.1c-0.048-0.105-0.109-0.205-0.194-0.29l0,0l0,0c0-0.001-0.001-0.002-0.001-0.002L22.829,8.637 c-0.087-0.086-0.188-0.147-0.295-0.196c-0.029-0.013-0.058-0.021-0.088-0.031c-0.086-0.03-0.172-0.048-0.263-0.053 c-0.021-0.002-0.04-0.013-0.062-0.013h-4.614V6.67c0-2.575,2.095-4.67,4.669-4.67h10.277v10.4c0,3.678,2.992,6.67,6.67,6.67h10.399 v21.616C49.524,43.26,47.429,45.355,44.855,45.355z'/%3E%3C/svg%3E%0A"; // Base64 encoded clone icon

      const deleteImg = new Image();
      deleteImg.src = deleteIconSrc;
      deleteImg.onload = () => {
        this.attachControls(deleteImg, "deleteControl");
      };

      const cloneImg = new Image();
      cloneImg.src = cloneIconSrc;
      cloneImg.onload = () => {
        this.attachControls(cloneImg, "cloneControl");
      };

      this.canvas.renderAll();
    },

    attachControls(iconImg, controlName) {
  const controlOptions = {
    x: controlName === "deleteControl" ? -0.5 : 0.5,
    y: -0.5,
    offsetY: 0,
    offsetX: controlName === "deleteControl" ? -16 : 16,
    sizeX: 24, sizeY: 24,
    cursorStyle: "pointer",
    mouseUpHandler: (eventData, transform) => {
      const target = transform.target;
      if (controlName === "deleteControl") {
        this.canvas.remove(target);
      } else if (controlName === "cloneControl") {
        const clone = fabric.util.object.clone(target);
        clone.set({
          left: target.left + 10,
          top: target.top + 10,
        });

        this.canvas.add(clone);
      }
      this.canvas.requestRenderAll();
    },
    render: this.renderIcon(iconImg),
    cornerSize: 24,
    hasRotatingPoint: false,
    evented: true,
  };

  fabric.Object.prototype.controls[controlName] = new fabric.Control(controlOptions);
  this.canvas.getObjects().forEach((obj) => {
    obj.controls = fabric.Object.prototype.controls;
  });

  this.canvas.requestRenderAll();
},

renderIcon(iconImg) {
  return function renderIcon(ctx, left, top, styleOverride, fabricObject) {
    const size = this.cornerSize;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
    ctx.drawImage(iconImg, -size / 2, -size / 2, size, size);
    ctx.restore();
  };
},

    async loadSavedDesign() {
      const { getAccessTokenSilently } = useAuth0();
      const token = await getAccessTokenSilently();
      this.floorplan = await getFloorplan(
        token,
        this.offerId,
        this.floorplanId
      );
      const url = this.floorplan.url;
      if (url !== null && url.length > 0) {
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            const file = new File([blob], "filename", { type: "mime/type" });

            if (file) {
              const reader = new FileReader();

              reader.onload = (e) => {

                const data = JSON.parse(e.target.result);
                const containerWidth = this.$refs.canvasContainer.clientWidth;
                const containerHeight = this.$refs.canvasContainer.clientHeight;


                const scaleX = containerWidth / this.realWidth;
                const scaleY = containerHeight / this.realHeight;

                //this.addCustomControls();

                this.canvas.getObjects().forEach(obj => {
                  if (obj instanceof fabric.Group) {
                    obj.setControlsVisibility({
                      deleteControl: true,
                      cloneControl: true,
                      mtr: false,
                    });
                  }
                });

                this.canvas.loadFromJSON(data.canvasData, () => {
                  this.canvas.forEachObject((obj) => {
                    obj.set({
                      scaleX: obj.scaleX * scaleX,
                      scaleY: obj.scaleY * scaleY,
                      left: obj.left * scaleX,
                      top: obj.top * scaleY,
                      selectable: true,
                      hasControls: true,
                      lockRotation: true,
                      lockScalingX: true,
                      lockScalingY: true,
                      hasRotatingPoint: false,
                      hasBorders: true,
                      cornerColor: "transparent",
                      cornerStyle: "none",
                      cornerSize: 0,
                      borderColor: "#F0BC6F",
                    });
                    obj.setCoords();
                  });
                  this.addDimensionText(containerWidth, containerHeight);
                  this.canvas.renderAll();

                  (this.canvas.backgroundColor = "#FFFDFB"),
                    this.canvas.renderAll();
                });
              };

              reader.readAsText(file);
            }
          })
          .catch((error) => console.error(error));
      }
    },

    async saveDesign() {
      this.isLoading = true;
      const originalDimensions = {
        width: this.canvas.getWidth(),
        height: this.canvas.getHeight(),
      };
      this.canvas.setDimensions({
        width: this.realWidth,
        height: this.realHeight,
      });

      this.canvas.forEachObject((obj) => {
        obj.set({
          scaleX: (obj.scaleX * this.realWidth) / originalDimensions.width,
          scaleY: (obj.scaleY * this.realHeight) / originalDimensions.height,
          left: (obj.left * this.realWidth) / originalDimensions.width,
          top: (obj.top * this.realHeight) / originalDimensions.height,
        });
        obj.setCoords();
      });

      const filteredCanvasData = this.canvas
        .toJSON([
          "selectable",
          "lockMovementX",
          "lockMovementY",
          "lockRotation",
          "lockScalingX",
          "lockScalingY",
          "persons",
          "tableId",
          "tableSize",
        ])
        .objects.filter((obj) => obj.type !== "text");

      const jsonData = JSON.stringify({
        canvasData: { objects: filteredCanvasData },
        realWidth: this.realWidth,
        realHeight: this.realHeight,
      });

      const url = await blobstoragehelper.uploadJsonToStorage(
        jsonData,
        this.offerId
      );

      const token = await this.$auth0.getAccessTokenSilently();
      await updateFloorplan(token, this.offerId, this.floorplanId, {
        FloorPlanUrl: url,
      }).then(() => {
        this.isLoading = false;
      });

      window.location.reload();
    },

    handleKeyDown(e) {
      if (e.key === "Delete" && this.showAddEditFloorPlanModel == false) {
        let activeObject = this.canvas.getActiveObject();
        if (activeObject) {
          this.canvas.remove(activeObject);
        }
      }
      if (e.key === "Backspace" && this.showAddEditFloorPlanModel == false) {
        let activeObject = this.canvas.getActiveObject();
        if (activeObject) {
          this.canvas.remove(activeObject);
        }
      }
    },

    deleteSelectedTable() {
      if (this.selectedTable) {
        this.canvas.remove(this.selectedTable);
        this.selectedTable = null;
      }
    },

    showAddEditFloorPlan() {
      this.showAddEditFloorPlanModel = true;
    },

    closeAddEditFloorPlan() {
      this.showAddEditFloorPlanModel = false;
    },

    initializeCanvas() {
  const containerWidth = this.$refs.canvasContainer.clientWidth;
  const containerHeight = this.$refs.canvasContainer.clientHeight;

  this.canvas = new fabric.Canvas(this.$refs.canvas, {
    width: containerWidth,
    height: containerHeight,
    backgroundColor: "#FFFDFB",
    selection: false,
  });

  fabric.Object.prototype.hasRotatingPoint = false;
  fabric.Object.prototype.lockRotation = true;
  fabric.Object.prototype.lockScalingX = true;
  fabric.Object.prototype.lockScalingY = true;
  fabric.Object.prototype.hasBorders = false;

  this.canvas.on('mouse:down', (options) => {
    const target = options.target;
    if (target) {
      this.canvas.setActiveObject(target);
      this.selectedTable = target;
    } else {
      this.selectedTable = null;
    }
  });

  this.canvas.selection = true;

  //this.addCustomControls();
  this.canvas.renderAll();
  this.resizeCanvas();
},


    resizeCanvas() {
      const containerWidth = this.$refs.canvasContainer.clientWidth;
      const containerHeight = this.$refs.canvasContainer.clientHeight;

      const previousScaleFactor = this.scaleFactor;
      const newScaleFactor = containerWidth / this.realWidth;
      this.scaleFactor = newScaleFactor;

      this.canvas.forEachObject((obj) => {
        if (obj !== this.heightText && obj !== this.widthText) {
          const scaleX = (obj.scaleX * newScaleFactor) / previousScaleFactor;
          const scaleY = (obj.scaleY * newScaleFactor) / previousScaleFactor;
          obj.set({
            scaleX: scaleX,
            scaleY: scaleY,
            left: (obj.left * newScaleFactor) / previousScaleFactor,
            top: (obj.top * newScaleFactor) / previousScaleFactor,
          });
          obj.setCoords();
        }
      });

      this.canvas.setDimensions({
        width: containerWidth,
        height: this.realHeight * newScaleFactor,
      });

      this.canvas.renderAll();
      console.log("Canvas resized. Elements adjusted");

      if (this.shapeIndex == 1) {
        const circleClipPath = new fabric.Circle({
          radius: containerWidth / 2,
          originX: "left",
          originY: "top",
        });

        this.canvas.clipPath = circleClipPath;
      }

      this.canvas.renderAll();
      this.canvas.controlsAboveOverlay = true;
      this.canvas.getObjects().forEach(obj => {
        obj.setCoords();
      });
      this.canvas.requestRenderAll();

    },
    calculateFontSize() {
      const computedFontSize = 18 * (this.scaleFactor * 1.25);

      if (computedFontSize < 10) {
        return 10;
      } else {
        return computedFontSize;
      }
    },

    addDimensionText(containerWidth, containerHeight) {
      const transformedHeight = this.realHeight / 100;
      const heightText = new fabric.Text(`${transformedHeight}m`, {
        fontSize: this.calculateFontSize(),
        fontFamily: "Poppins",
        fill: "#DBD4CA",
        left: this.canvas.getWidth() / 2,
        top: this.canvas.getHeight() - this.scaleFactor * 20 - 10,
        originX: "center",
        originY: "center",
        selectable: false,
        hasControls: false,
        cursorStyle: "none",
        evented: false,
      });
      this.canvas.add(heightText);

      const transformedWidth = this.realWidth / 100;
      const widthText = new fabric.Text(`${transformedWidth}m`, {
        fontSize: this.calculateFontSize(),
        fontFamily: "Poppins",
        fill: "#DBD4CA",
        left: this.canvas.getWidth() - this.scaleFactor * 20 - 10,
        top: this.canvas.getHeight() / 2,
        originX: "right",
        originY: "center",
        selectable: false,
        hasControls: false,
        cursorStyle: "none",
        evented: false,
      });
      this.canvas.add(widthText);
    },

    showAddTableModal(tableType) {
      this.showAddTableBool = true;
      this.tableType = tableType;
    },

    closeAddTableModal() {
      if (event.target.classList.contains("modal")) {
        this.showAddTableBool = false;
      }
    },

    handleTableTypeSelected(numberOfPersons, tableName) {
      this.showAddTableBool = false;

      if (this.tableType === "roundTable") {
        this.addRoundedTable(numberOfPersons, tableName);
      } else if (this.tableType === "squareTable") {
        this.addSquareTable(numberOfPersons, tableName);
      } else if (this.tableType === "rectangleTable") {
        this.addRectangularTable(numberOfPersons, tableName);
      } else if (this.tableType === "prezidiumTable") {
        this.addPrezidiumTable(numberOfPersons, tableName);
      }
    },

    addRoundedTable(numberOfPersons, tableName) {
      const radius = Math.sqrt(10000 / Math.PI) * this.scaleFactor;

      const tableCircle = new fabric.Circle({
        radius: radius,
        fill: "#DBD4CA",
        lockRotation: true,
        lockScalingX: true,
        lockScalingY: true,
        hasRotatingPoint: false,
        hasBorders: false,
      });

      const tableNameString = new fabric.Text(tableName.toString(), this.tableNameParameters);

      const personsString = new fabric.Text(
        numberOfPersons.toString() + " 👤",
        this.personsStringParameters
      );

      tableNameString.set({
        left: tableCircle.left - tableNameString.width / 2 + radius,
        top:
          tableCircle.top -
          tableNameString.height / 2 +
          radius -
          tableNameString.height / 2,
      });

      personsString.set({
        left: tableCircle.left - personsString.width / 2 + radius,
        top:
          tableCircle.top -
          personsString.height / 2 +
          radius +
          personsString.height / 1.5,
      });

      const tableGroup = new fabric.Group(
        [tableCircle, personsString, tableNameString],
        {
          selectable: true,
          hasControls: true,
          lockRotation: true,
          lockScalingX: true,
          lockScalingY: true,
          hasRotatingPoint: false,
          hasBorders: true,
          cornerColor: "transparent",
          cornerStyle: "none",
          cornerSize: 0,
          borderColor: "#F0BC6F",
          persons: [],
          tableSize: numberOfPersons,
          tableId: this.generateUniqueId(),
        }
      );

      const canvasCenterX = this.canvas.getWidth() / 2;
      const canvasCenterY = this.canvas.getHeight() / 2;
      const groupCenterX = tableGroup.left + tableGroup.width / 2;
      const groupCenterY = tableGroup.top + tableGroup.height / 2;

      tableGroup.set({
        left: canvasCenterX - groupCenterX,
        top: canvasCenterY - groupCenterY,
      });
      tableGroup.setCoords();

      this.canvas.add(tableGroup);
    },
    addSquareTable(numberOfPersons, tableName) {
      const sideLength = 100 * this.scaleFactor;

      const tableSquare = new fabric.Rect({
        width: sideLength,
        height: sideLength,
        fill: "#DBD4CA",
        rx: sideLength / 10,
        ry: sideLength / 10,
        lockRotation: true,
        lockScalingX: true,
        lockScalingY: true,
        hasRotatingPoint: false,
        hasBorders: false,
      });

      const tableNameString = new fabric.Text(tableName.toString(), this.tableNameParameters);

      const personsString = new fabric.Text(
        numberOfPersons.toString() + " 👤",
        this.personsStringParameters
      );

      tableNameString.set({
        left: tableSquare.left + sideLength / 2 - tableNameString.width / 2,
        top:
          tableSquare.top +
          sideLength / 2 -
          tableNameString.height / 2 -
          tableNameString.height / 2,
      });

      personsString.set({
        left: personsString.left + sideLength / 2 - personsString.width / 2,
        top:
          personsString.top +
          sideLength / 2 -
          personsString.height / 2 +
          personsString.height / 1.5,
      });

      const tableGroup = new fabric.Group(
        [tableSquare, personsString, tableNameString],
        {
          selectable: true,
          lockRotation: true,
          lockScalingX: true,
          lockScalingY: true,
          hasBorders: true,
          cornerColor: "transparent",
          cornerStyle: "none",
          cornerSize: 0,
          borderColor: "#F0BC6F",
          persons: [],
          tableSize: numberOfPersons,
          tableId: this.generateUniqueId(),
        }
      );

      const canvasCenterX = this.canvas.getWidth() / 2;
      const canvasCenterY = this.canvas.getHeight() / 2;
      const groupCenterX = tableGroup.left + tableGroup.width / 2;
      const groupCenterY = tableGroup.top + tableGroup.height / 2;

      tableGroup.set({
        left: canvasCenterX - groupCenterX,
        top: canvasCenterY - groupCenterY,
      });
      tableGroup.setCoords();

      this.canvas.add(tableGroup);
    },
    addRectangularTable(numberOfPersons, tableName) {
      const sideLength = 100 * this.scaleFactor;

      const tableSquare = new fabric.Rect({
        width: sideLength * 1.5,
        height: sideLength,
        fill: "#DBD4CA",
        rx: sideLength / 10,
        ry: sideLength / 10,
        lockRotation: true,
        lockScalingX: true,
        lockScalingY: true,
        hasRotatingPoint: false,
        hasBorders: false,
      });

      const tableNameString = new fabric.Text(tableName.toString(), this.tableNameParameters);

      const personsString = new fabric.Text(
        numberOfPersons.toString() + " 👤",
        this.personsStringParameters
      );

      tableNameString.set({
        left:
          tableNameString.left +
          (sideLength * 1.5) / 2 -
          tableNameString.width / 2,
        top:
          tableNameString.top +
          sideLength / 2 -
          tableNameString.height / 2 -
          tableNameString.height / 2,
      });

      personsString.set({
        left:
          personsString.left + (sideLength * 1.5) / 2 - personsString.width / 2,
        top:
          personsString.top +
          sideLength / 2 -
          personsString.height / 2 +
          personsString.height / 1.5,
      });

      const tableGroup = new fabric.Group(
        [tableSquare, personsString, tableNameString],
        {
          selectable: true,
          hasControls: true,
          lockRotation: true,
          lockScalingX: true,
          lockScalingY: true,
          hasBorders: true,
          cornerColor: "transparent",
          cornerStyle: "none",
          cornerSize: 0,
          borderColor: "#F0BC6F",
          persons: [],
          tableSize: numberOfPersons,
          tableId: this.generateUniqueId(),
        }
      );

      const canvasCenterX = this.canvas.getWidth() / 2;
      const canvasCenterY = this.canvas.getHeight() / 2;
      const groupCenterX = tableGroup.left + tableGroup.width / 2;
      const groupCenterY = tableGroup.top + tableGroup.height / 2;

      tableGroup.set({
        left: canvasCenterX - groupCenterX,
        top: canvasCenterY - groupCenterY,
      });
      tableGroup.setCoords();

      this.canvas.add(tableGroup);
    },

    addPrezidiumTable(numberOfPersons, tableName) {
      const sideLength = 100 * this.scaleFactor;

      const tableSquare = new fabric.Rect({
        width: sideLength * 1.5,
        height: sideLength,
        fill: "#DBD4CA",
        rx: sideLength / 2.5,
        ry: sideLength / 2.5,
        lockRotation: true,
        lockScalingX: true,
        lockScalingY: true,
        hasRotatingPoint: false,
        hasBorders: false,
      });

      const tableNameString = new fabric.Text(tableName.toString(), this.tableNameParameters);

      const personsString = new fabric.Text(
        numberOfPersons.toString() + " 👤",
        this.personsStringParameters
      );

      tableNameString.set({
        left:
          tableNameString.left +
          (sideLength * 1.5) / 2 -
          tableNameString.width / 2,
        top:
          tableNameString.top +
          sideLength / 2 -
          tableNameString.height / 2 -
          tableNameString.height / 2,
      });

      personsString.set({
        left:
          personsString.left + (sideLength * 1.5) / 2 - personsString.width / 2,
        top:
          personsString.top +
          sideLength / 2 -
          personsString.height / 2 +
          personsString.height / 1.5,
      });

      const tableGroup = new fabric.Group(
        [tableSquare, personsString, tableNameString],
        {
          selectable: true,
          lockRotation: true,
          lockScalingX: true,
          lockScalingY: true,
          hasBorders: true,
          cornerColor: "transparent",
          cornerStyle: "none",
          cornerSize: 0,
          borderColor: "#F0BC6F",
          persons: [],
          tableSize: numberOfPersons,
          tableId: this.generateUniqueId(),
        }
      );

      const canvasCenterX = this.canvas.getWidth() / 2;
      const canvasCenterY = this.canvas.getHeight() / 2;
      const groupCenterX = tableGroup.left + tableGroup.width / 2;
      const groupCenterY = tableGroup.top + tableGroup.height / 2;

      tableGroup.set({
        left: canvasCenterX - groupCenterX,
        top: canvasCenterY - groupCenterY,
      });
      tableGroup.setCoords();

      this.canvas.add(tableGroup);
    },
    addBarTable() {
      const sideLength = 100 * this.scaleFactor;

      const tableSquare = new fabric.Rect({
        width: sideLength * 1.5,
        height: sideLength,
        fill: "#DBD4CA",
        selectable: true,
        hasControls: true,
        rx: sideLength / 10,
        ry: sideLength / 10,
        skewX: 10,
      });

      const text = new fabric.Text("BAR", {
        fontSize: 18,
        fontFamily: "Poppins",
        fill: "#777777",
        selectable: false,
      });

      text.set({
        left: tableSquare.left + (sideLength * 1.6) / 2 - text.width / 2,
        top: tableSquare.top + sideLength / 2 - text.height / 2,
      });

      const tableGroup = new fabric.Group([tableSquare, text], {
        selectable: true,
        hasControls: false,
        lockRotation: true,
        lockScalingX: true,
        lockScalingY: true,
        persons: [],
        tableId: 'bar',
      });

      const canvasCenterX = this.canvas.getWidth() / 2;
      const canvasCenterY = this.canvas.getHeight() / 2;
      const groupCenterX = tableGroup.left + tableGroup.width / 2;
      const groupCenterY = tableGroup.top + tableGroup.height / 2;

      tableGroup.set({
        left: canvasCenterX - groupCenterX,
        top: canvasCenterY - groupCenterY,
      });
      tableGroup.setCoords();

      this.canvas.add(tableGroup);
    },
    generateUniqueId() {
      return Date.now().toString(36) + Math.random().toString(36).substr(2);
    },
  },
  beforeUnmounted() {
    window.removeEventListener("resize", this.resizeCanvas);
  },
};
</script>