import { BlobServiceClient } from "@azure/storage-blob";
import heic2any from "heic2any";

const path = require("path-browserify");
const crypto = require("crypto-browserify");

const blobSasUrl = process.env.VUE_APP_blobSasUrl;
const blobServiceClient = new BlobServiceClient(blobSasUrl);

const readFileAsBlob = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = (event) => {
      const result = event.target.result;
      const blob = new Blob([result], { type: file.type });
      resolve(blob);
    };

    fileReader.onerror = () => {
      reject(new Error("Error reading the file."));
    };

    fileReader.readAsArrayBuffer(file);
  });
};

const getImageSize = async (file) => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = function () {
      const imageSize = {
        width: img.width,
        height: img.height,
      };
      resolve(imageSize);
    };

    img.onerror = function () {
      reject(new Error("Failed to load image."));
    };

    img.src = URL.createObjectURL(file);
  });
};

const convertHeicToJpeg = async (file) => {
  try {
    const buffer = await file.arrayBuffer();
    const blob = new Blob([buffer], { type: 'image/heic' });
    const conversionResult = await heic2any({
      blob,
      toType: "image/jpeg",
      quality: 0.85,
    });

    return conversionResult;
  } catch (e) {
    // see error handling section
    console.error(e);
  }
};

const convertHeicToJpegThumbnail = async (file) => {
  try {
    const buffer = await file.arrayBuffer();
    const blob = new Blob([buffer], { type: 'image/heic' });
    const conversionResult = await heic2any({
      blob,
      toType: "image/jpeg",
      quality: 0.05,
    });

    return conversionResult;
  } catch (e) {
    // see error handling section
    console.error(e);
  }
};

const compressImage = async (file, maxWidth, maxHeight, quality) => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = function () {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob(
        (blob) => {
          resolve(blob);
        },
        "image/jpeg",
        quality
      );
    };

    img.onerror = function () {
      reject(new Error("Image loading failed."));
    };

    img.src = URL.createObjectURL(file);
  });
};

const generateUniqueBlobName = (originalFileName) => {
  const fileExtension = path.extname(originalFileName);
  const uniqueName = crypto.randomBytes(16).toString("hex");
  return `${uniqueName}${fileExtension}`;
};

const blobStorageHelper = {
  async createContainerIfNotExists(offerId) {
    let containerExists = false;
    let containerClient

    try {
      containerClient = blobServiceClient.getContainerClient(offerId);
      containerExists = await containerClient.exists();
    }
    catch (error) {
      // Ignore
    }

    if (!containerExists) {
      const options = { access: "blob" };
      try {
        await containerClient.create(options);
      } catch (error) {
        //Ignore
      }
    }
  },
  async uploadFile(file, progressCallback, offerId) {
    try {
      const isVideo = file.type.startsWith("video/");
      const containerClient = blobServiceClient.getContainerClient(offerId);

      const containerExists = await containerClient.exists();

      if (!containerExists) {
        const options = { access: "blob" };
        await containerClient.create(options);
      }

      blobServiceClient.setProperties({
        defaultServiceVersion: "2021-04-10"
      })
        .then()
        .catch(err => console.error("Set Properties error", err));


      const blobName = generateUniqueBlobName(file.name);
      const blobThumbnailName = `${blobName.replace(
        /\.([^.]+)$/,
        "-thumbnail.jpg"
      )}`;

      const blockBlobClient = containerClient.getBlockBlobClient(blobName);
      const blockBlobClientThumbnail =
        containerClient.getBlockBlobClient(blobThumbnailName);

      let fileData = await readFileAsBlob(file);

      if (!isVideo) {
        if (file.type === "image/heic") {
          fileData = await convertHeicToJpeg(file);
        }
        else {
          const imageSize = await getImageSize(file);
          fileData = await compressImage(
            file,
            imageSize.width,
            imageSize.height,
            0.7
          );
        }
      }

      const uploadOptions = {
        blobHTTPHeaders: { blobContentType: file.type },
        onProgress: (ev) => {
          const percentComplete = (ev.loadedBytes / fileData.size) * 100;
          progressCallback(percentComplete);
        },
      };

      await blockBlobClient.uploadData(fileData, uploadOptions);

      let fileDataThumbnail = null

      if (!isVideo) {
        if (file.type === "image/heic") {
          fileDataThumbnail = await convertHeicToJpegThumbnail(file);
        }
        else {
          const imageSize = await getImageSize(file);
          fileDataThumbnail = await compressImage(
            file,
            imageSize.width,
            imageSize.height,
            0.05
          );
        }


        const uploadOptionsThumbnail = {
          blobHTTPHeaders: { blobContentType: "image/jpeg" },
          onProgress: (ev) => {
            const percentComplete = (ev.loadedBytes / fileData.size) * 100;
            progressCallback(percentComplete);
          },
        };

        await blockBlobClientThumbnail.uploadData(
          fileDataThumbnail,
          uploadOptionsThumbnail
        );
      }

      const url = `${process.env.VUE_APP_storage}/${offerId}/${blobName}`;
      return url;
    } catch (error) {
      console.error("Error during concurrent file upload:", error);
    }
  },

  async uploadCoverImage(file, offerId) {
    try {
      const imageSize = await getImageSize(file);
      const fileData = await compressImage(
        file,
        imageSize.width,
        imageSize.height,
        0.7
      );

      const containerClient = blobServiceClient.getContainerClient(offerId);

      const containerExists = await containerClient.exists();

      if (!containerExists) {
        const options = { access: "blob" };
        await containerClient.create(options);
      }


      blobServiceClient.setProperties({
        defaultServiceVersion: "2021-04-10"
      })
        .then()
        .catch(err => console.error("Set Properties error", err));

      file.name = 'cover_' + file.name;
      const blockBlobClient = containerClient.getBlockBlobClient(file.name);
      const uploadOptions = {
        blobHTTPHeaders: { blobContentType: file.type },

      };

      await blockBlobClient.uploadData(fileData, uploadOptions);

      const url = `${process.env.VUE_APP_storage}/${offerId}/${file.name}`;
      return url;
    } catch (error) {
      console.error('Error during file upload:', error);
    }

  },

  async uploadImageToStorage(file, offerId, progressCallback) {
    try {
      const imageSize = await getImageSize(file);
      const fileData = await compressImage(
        file,
        imageSize.width,
        imageSize.height,
        0.7
      );
      const containerClient = blobServiceClient.getContainerClient(offerId);

      const containerExists = await containerClient.exists();

      if (!containerExists) {
        const options = { access: "blob" };
        await containerClient.create(options);
      }


      blobServiceClient.setProperties({
        defaultServiceVersion: "2021-04-10"
      })
        .then()
        .catch(err => console.error("Set Properties error", err));

      const blobName = generateUniqueBlobName(file.name);

      const blockBlobClient = containerClient.getBlockBlobClient(blobName);
      const uploadOptions = {
        blobHTTPHeaders: {
          blobContentType: file.type
        },
        onProgress: (ev) => {
          const percentComplete = (ev.loadedBytes / fileData.size) * 100;
          progressCallback(percentComplete);
        },
      };

      await blockBlobClient.uploadData(fileData, uploadOptions);

      const url = `${process.env.VUE_APP_storage}/${offerId}/${blobName}`;
      return url;
    } catch (error) {
      console.error('Error during file upload:', error);
    }

  },

  async uploadJsonToStorage(jsonData, offerId) {
    try {
      const containerClient = blobServiceClient.getContainerClient(offerId);

      const containerExists = await containerClient.exists();

      if (!containerExists) {
        const options = { access: "blob" };
        await containerClient.create(options);
      }

      blobServiceClient.setProperties({
        defaultServiceVersion: "2021-04-10"
      })
        .then()
        .catch(err => console.error("Set Properties error", err));

      const blobName = generateUniqueBlobName('data.json');

      const blockBlobClient = containerClient.getBlockBlobClient(blobName);
      const uploadOptions = {
        blobHTTPHeaders: {
          blobContentType: 'application/json'
        },
      };

      await blockBlobClient.uploadData(jsonData, uploadOptions);

      const url = `${process.env.VUE_APP_storage}/${offerId}/${blobName}`;
      return url;
    } catch (error) {
      console.error('Error during file upload:', error);
    }
  },
};


export default blobStorageHelper;
