<template>
  <div class="modal bottom-sheet-on-mobile" id="see-collaborators-modal" :class="{ active: showSeeCollaboratorsModal }"
    @click="closeModalFromBackground">

    <div class="modal-content">
      <div v-if="loading">
        <vLottiePlayer name="lottie" class="lottie" loop
          :animationData="require('@/assets/lottie/simpleDotsLoader.json')" />
      </div>
      <div v-else>
        <div class="modal-header">
          <h1>Colaboratori asociați</h1>
          <span class="close-modal splash-rounded material-symbols-rounded fill" @click="closeModal">
            close
          </span>
        </div>

        <div class="container">
          <div class="col-100">
            <div class="collaborators col-100" v-if="users.length !== 0">
              <div v-for="user in users" :key="user" class="collaborator">
                <div class="details">
                  <h1>{{ user.firstName }} {{ user.lastName }}</h1>
                  <h2>{{ user.phone }}</h2>
                </div>
                <span class="material-symbols-rounded fill splash-rounded" @click="removeUser(user.phone)">
                  group_remove
                </span>
              </div>
            </div>
            <h2 class="mt-10">Adaugă colaboratori</h2>
            <div class="input-wrapper phone-number button-done">

              <vue-tel-input v-model="phoneToAdd" :input-options="{
                placeholder: 'Scrie numărul tău de telefon',
              }" :defaultCountry="'RO'" :enabledFlags="true" :enabledCountryCode="true" :autoFormat="true" mode="auto"
                :validCharactersOnly="true" @validate="onValidate"></vue-tel-input>
              <button class="primary" @click="addUser"> <span class="material-symbols-rounded fill">
                  person_add
                </span></button>
            </div>
          </div>
        </div>
        <!--
      <button class="primary small">Salveaza detaliile</button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs, ref, onMounted, watch } from 'vue';
import { getOfferUsers, removeUserFromOffer, addUserToOffer } from '@/api';
import { useAuth0 } from '@auth0/auth0-vue';
import { notify } from '@kyvg/vue3-notification';
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';


export default {
  name: 'SeeCollaboratorsModal',
  components: {
    VueTelInput
  },
  props: {
    showSeeCollaboratorsModal: Boolean,
    offerId: String,

  },
  setup(props, { emit }) {
    const { getAccessTokenSilently } = useAuth0();

    const users = ref([]);
    const loading = ref(true);
    const phoneToAdd = ref('');

    const inputOptions = {
      placeholder: 'Scrie numărul de telefon',
    };

    const closeModalFromBackground = (event) => {
      if (event.target.classList.contains('modal')) {
        emit('closeModal');
      }
    };

    const closeModal = () => {
      emit('closeModal');
    };

    const removeUser = async (phone) => {
      loading.value = true;
      const token = await getAccessTokenSilently();

      await removeUserFromOffer(token, props.offerId, phone);
      // Remove the user from the users array
      users.value = users.value.filter(user => user.phone !== phone);
      loading.value = false;

      notify({ type: 'success', title: 'Colaboratorul a fost sters', text: '' });
    };

    const addUser = async () => {
      loading.value = true;
      const token = await getAccessTokenSilently();

      const data = {
        Phone: phoneToAdd.value
      }

      await addUserToOffer(token, props.offerId, data);
      users.value.push({ phone: phoneToAdd.value })
      loading.value = false;

      notify({ type: 'success', title: 'Colaboratorul a fost adaugat', text: '' });
    };

    onMounted(async () => {
      const token = await getAccessTokenSilently();

      const response = await getOfferUsers(token, props.offerId);

      users.value = response;

      loading.value = false;
    });

    return {
      ...toRefs(props),
      closeModal,
      closeModalFromBackground,
      removeUser,
      addUser,
      phoneToAdd,
      users,
      loading,
      inputOptions
    };
  }
}
</script>
