<template>
  <div
    class="wrapper-content invitation"
    :class="{
      bohemian: templateName === 'Bohemian' || templateName === 'Bohemian11',
      'gold-accents':
        templateName === 'GoldAccents' || templateName === 'GoldAccents11',
      bohemian:
        templateName !== 'Bohemian' ||
        templateName !== 'Bohemian11' ||
        templateName !== 'GoldAccents' ||
        templateName !== 'GoldAccents11',
    }"
  >
    <bohemian-invitation-11
      v-if="
        templateName === 'BohemianInvitation' ||
        templateName === 'BohemianInvitation11'
      "
      :invitationData="invitation"
      :isInvitationEditable="isEditable"
    />
    <gold-accents-invitation-11
      v-else-if="
        templateName === 'GoldAccents' || templateName === 'GoldAccents11'
      "
      :invitationData="invitation"
      :isInvitationEditable="isEditable"
    />
    <InvitationCard
      v-else-if="templateName === 'WeddingMinimalist'"
      :invitationData="invitation"
      :isInvitationEditable="isEditable"
    />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import InvitationCard from "@/views/invitation/InvitationCard.vue";
import BohemianInvitation11 from "@/views/invitation/pageInvitationModels/version11/BohemianInvitation11.vue";
import GoldAccentsInvitation11 from "@/views/invitation/pageInvitationModels/version11/GoldAccentsInvitation11.vue";


export default {
  name: "Invitation-Demo",
  components: {
    InvitationCard,
    BohemianInvitation11,
    GoldAccentsInvitation11,
  },
  setup() {
    const templateName = ref("BohemianInvitation");
    const isEditable = ref(false);
    const invitation = ref({ hasConfirmation: false });

    const route = useRoute();

    onMounted(async () => {
      templateName.value = route.params.templateName;
    });

    return {
      templateName,
      isEditable,
      invitation,
    };
  },
};
</script>
