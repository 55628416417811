<template>
  <div class="modal bottom-sheet-on-mobile" id="add-tabel-modal" :class="{ active: showAddTableBool }"
  @click="closeModal()">
    <div class="modal-content">
      <h1>Adauga {{ getTableTypeText() }}</h1>
      <div class="input-wrapper" :class="{ 'has-danger': showInvalid }">
        <input v-model="tableName" class="input"
          type="text" name="tableName" placeholder="T01" autocomplete="off" required minlength="3"
          data-pristine-minlength-message="Introduceti minim 3 caractere"
          data-pristine-required-message="Câmp obligatoriu" />
        <label class="label" for="tableName">Numărul mesei</label>
        <img class="checked" src="@/assets/img/check.svg" alt="" />
        <img class="error" src="@/assets/img/error.svg" alt="" />
        <p class="pristine-error text-help" v-if="showInvalid && !isValidTime">Invalid input</p>
      </div>
        <div class="number-of-persons-section">
          <h2>Numărul de persoane</h2>
          
          <div class="number-of-persons-container">
            <div class="number-of-persons">
              <span class="material-symbols-rounded fill" :class="{ active: numberOfPersons > 0 }">
                person
              </span>
              <span class="material-symbols-rounded fill" :class="{ active: numberOfPersons > 1 }">
                person
              </span>
              <span class="material-symbols-rounded fill" :class="{ active: numberOfPersons > 2 }">
                person
              </span>
              <span class="material-symbols-rounded fill" :class="{ active: numberOfPersons > 3 }">
                person
              </span>
              <span class="material-symbols-rounded fill" :class="{ active: numberOfPersons > 4 }">
                person
              </span>
              <span class="material-symbols-rounded fill" :class="{ active: numberOfPersons > 5 }">
                person
              </span>
              <span class="extra-persons" :class="{ active: numberOfPersons > 6 }" v-if="numberOfPersons > 6">
                +{{numberOfPersons - 6}}
              </span>
            </div>
           
           <div class="persons-actions">
            <div class="rounded-button" @click="removePersons()">
              <span class="material-symbols-rounded fill">
                remove
              </span>
            </div>
            <p>{{ this.numberOfPersons }}</p>
            <div class="rounded-button" @click="addPersons()">
              <span class="material-symbols-rounded fill">
                add
              </span>
            </div>

           </div>
          </div>
        </div>
      <button class="primary small" @click="addTableEmitter()">Adauga masa</button>
    </div>
  </div>
</template>

<script>


export default {
  name: 'AddTableModal',
  components: {
  },
  props: {
    showAddTableBool: Boolean,
    tableType: String,
    addTableFunction: Function,
  },
  mounted() {
  },
  data() {
        return {
            numberOfPersons: 2,
            tableTypeText: '',
            tableName: '01'
        }
    },
  methods: {
    getTableTypeText() {
      if (this.tableType == 'roundTable') {
        return 'masă rotundă';
      }
      else if (this.tableType == 'squareTable') {
        return 'masă pătrată';
      }
      else if (this.tableType == 'rectangleTable') {
        return 'masă dreptunghiulară';
      }
      else if (this.tableType == 'prezidiumTable') {
        return 'masă prezidiu';
      }
      else if (this.tableType == 'barTable') {
        return 'bar';
      }
    },
    addPersons() {
      this.numberOfPersons+=2;
    },   
    removePersons() {
      if (this.numberOfPersons > 2) {
        this.numberOfPersons-=2;
      }
    },     
    closeModal() {
      this.$emit('closeModal');
    },
    addTableEmitter() {
      this.$emit('addTableFunction', this.numberOfPersons, this.tableName);
    }
  }
}
</script>
  