<template>
  <div class="wrapper-content budget">
    <add-category-modal :showAddCategoryModal="showAddCategoryModal" v-if="this.showAddCategoryModal"
      @closeModal="closeAddCategoryModal()" @addCategory="addCategory" :offerId="offerId" />

    <edit-category-modal :showEditCategoryModal="showEditCategoryModal" v-if="this.showEditCategoryModal"
      @closeModal="closeEditCategoryModal()" @editCategory="editCategory" :offerId="offerId"
      :selectedCategoryName="selectedCategoryName" />

    <add-category-item-modal :showAddCategoryItemModal="showAddCategoryItemModal" v-if="this.showAddCategoryItemModal"
      @closeModal="closeAddCategoryItemModal()" :selectedCategoryName="selectedCategoryName" @addCategoryItem="addCategoryItem" :offerId="offerId" :lists="lists" />

    <div class="header space-between">
      <div class="vertical-align gap-5 splash-rounded splash-padding" @click="this.$router.go(-1)">
        <span class="material-symbols-rounded"> arrow_back </span>
        <h1>Bugetare</h1>
      </div>
      <div class="actions">
        <div class="header-button secondary" @click="showAddCategory()">
          <div class="highlight">
            <span class="material-symbols-rounded"> library_add </span>
          </div>
          <div class="text">Adaugă categorie</div>
        </div>
        <div class="header-button primary" @click="showAddCategoryItem()" v-if="lists.length > 0">
          <div class="highlight">
            <span class="material-symbols-rounded"> payments </span>
          </div>
          <div class="text">Adaugă cheltuială</div>
        </div>
      </div>
    </div>

    <div class="content-loading" v-if="loading">
      <vLottiePlayer name="lottie" class="lottie" loop
        :animationData="require('@/assets/lottie/simpleDotsLoader.json')" />
    </div>

    <div class="content" v-else>
      <div class="card module">
        <h1>Statistici buget</h1>
        <div class="stats-container">
          <div class="stat-container">
            <span class="number">
              {{ calculateGlobalTotalCost }}
            </span>
            <p>cost<br />estimat</p>  
          </div>
          <div class="stat-container">
            <span class="number">
              {{ calculateGlobalTotalPaid }}
            </span>
            <p>plătit</p>
          </div>
          <div class="stat-container">
            <span class="number">
              {{ calculateGlobalTotalRemaining }}
            </span>
            <p>rest de<br />plată</p>
          </div>
        </div>

      </div>

      <div class="card module">
        <table-component>

      
        


          <template v-for="(category, categoryIndex) in lists" :key="'category-' + categoryIndex">
            <tr class="category-header">
              <td class="title">
                <h1>{{ category.name }}</h1>
              </td>
              <td class="cost">
                <p>{{ calculateCategoryTotal(categoryIndex, "cost") }}</p>
              </td>
              <td class="cost" v-if="this.isDesktop()">
                <p>{{ calculateCategoryTotal(categoryIndex, "paid") }}</p>
              </td>
              <td class="cost" v-if="this.isDesktop()">
                <p>{{ calculateCategoryTotalRemaining(categoryIndex) }}</p>
              </td>

              <td class="actions">
                <div class="horizontal" v-if="this.isDesktop()">
                  <div class="action" @click.stop="deleteCategory(categoryIndex)">
                    <span class="material-symbols-rounded"> delete </span>
                  </div>
                  <div class="action" @click.stop="showEditCategory(category.name)">
                    <span class="material-symbols-rounded"> settings </span>
                  </div>
                </div>
                <div v-else>
                  <dropdown-menu direction="right" @click.stop>
                    <template #trigger>
                      <div class="action">
                        <span class="material-symbols-rounded"> grid_view </span>
                      </div>
                    </template>

                    <template #body>
                      <div class="dropdown-action" @click.stop="showEditCategory(category.name)">
                        <span class="material-symbols-rounded"> settings </span>
                        Șterge
                      </div>
                      <div class="dropdown-action" @click.stop="deleteCategory(categoryIndex)">
                        <span class="material-symbols-rounded"> delete </span>
                        Șterge
                      </div>
                    </template>
                  </dropdown-menu>
                </div>
              </td>
            </tr>
            
            <tr v-for="(element, elementIndex) in category.elements" :key="'element-' + elementIndex"
              class="budget-element" >
              <td class="name">
                <div class="name">
                  {{ element.name }}
                </div>
              </td>

              <td class="cost">
                <div class="input-wrapper">
                  <input type="number" class="input" v-model="element.cost" @change="saveBudget" min="0" required
                    autocomplete="chrome-off" placeholder="250 Lei" @input="handleInput(element)" />
                </div>
              </td>

              <td class="cost" v-if="this.isDesktop()">
                <div class="input-wrapper" :class="{ 'has-warning': element.cost - element.paid < 0 }">
                  <input type="number" class="input" v-model="element.paid" @change="saveBudget" min="0" required
                    autocomplete="chrome-off" placeholder="150 Lei" @input="handleInput(element)" />
                  <img class="warning" src="@/assets/img/warning.svg" alt="" />
                </div>
              </td>

              <td class="cost" v-if="this.isDesktop()">
                <p>
                  {{ calculateElementTotalRemaining(element.cost, element.paid) }}
                </p>
              </td>
              <td class="actions">
                <div class="horizontal" v-if="this.isDesktop()">
                  <div class="action" @click.stop="deleteCategoryItem(categoryIndex, elementIndex)">
                    <span class="material-symbols-rounded"> delete </span>
                  </div>
                </div>
                <div v-else>
                  <dropdown-menu direction="right" @click.stop>
                    <template #trigger>
                      <div class="action">
                        <span class="material-symbols-rounded"> grid_view </span>
                      </div>
                    </template>

                    <template #body>
                      <div class="dropdown-action" @click.stop="">
                        <span class="material-symbols-rounded"> visibility </span>
                        Setări
                      </div>
                      <div class="dropdown-action" @click.stop="
                        deleteCategoryItem(categoryIndex, elementIndex)
                        ">
                        <span class="material-symbols-rounded"> delete </span>
                        Șterge
                      </div>
                    </template>
                  </dropdown-menu>
                </div>
              </td>
            </tr>


            <tr class="budget-element last">
              <td colspan="5" class="empty-content-row">
                  <div class="no-categories-button add-items" @click="showAddCategoryItemWithCategory(category.name)">
                    <span class="material-symbols-rounded fill">
                      add
                    </span>
                    <p>Adaugă o cheltuială</p>
                  </div>
              </td>
            </tr>
          </template>

          <tr>
            <td colspan="5" class="empty-content-row">
              <div class="middle">
                <div class="no-categories-button" @click="showAddCategory()">
                  <span class="material-symbols-rounded fill">
                    add
                  </span>
                  <p>Adaugă o categorie</p>
                </div>
              </div>
            </td>
           
          
          </tr>

        </table-component>
      </div>

    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed, watch } from "vue";
import { useRoute } from "vue-router";
import { getBudget, updateBudget } from "@/api";
import { useAuth0 } from "@auth0/auth0-vue";
import TableComponent from "@/views/budget/components/TableComponent.vue";
import DropdownMenu from "v-dropdown-menu";
import AddCategoryModal from "@/views/budget/components/AddCategoryModal.vue";
import AddCategoryItemModal from "@/views/budget/components/AddCategoryItemModal.vue";
import EditCategoryModal from "@/views/budget/components/EditCategoryModal.vue";
import { notify } from "@kyvg/vue3-notification";
import anime from "animejs";

export default {
  name: "Budget-Page",
  components: {
    TableComponent,
    DropdownMenu,
    AddCategoryModal,
    AddCategoryItemModal,
    EditCategoryModal,
  },
  methods: {
    isDesktop() {
      const screenWidth = window.innerWidth;
      return screenWidth > 575.98;
    },
    formatCounterSimple(value) {
      return `${value.toFixed(0)}`;
    },
    formatCounterPercent(value) {
      return `${value.toFixed(1)}%`;
    },
  },
  setup() {
    const route = useRoute();
    const { getAccessTokenSilently } = useAuth0();
    const offerId = ref("");
    const loading = ref(true);

    const showAddCategoryModal = ref(false);
    const showAddCategoryItemModal = ref(false);
    const showEditCategoryModal = ref(false);

    const selectedCategoryName = ref();

    const statsCanBeRefreshed = ref(false);

    function showAddCategory() {
      showAddCategoryModal.value = true;
    }

    function closeAddCategoryModal() {
      showAddCategoryModal.value = false;
    }

    function showAddCategoryItem() {
      showAddCategoryItemModal.value = true;
    }

    function showAddCategoryItemWithCategory(categoryName) {
      selectedCategoryName.value = categoryName;
      showAddCategoryItemModal.value = true;
    }

    function closeAddCategoryItemModal() {
      showAddCategoryItemModal.value = false;
    }

    function showEditCategory(categoryName) {
      selectedCategoryName.value = categoryName;

      showEditCategoryModal.value = true;
    }

    function closeEditCategoryModal() {
      showEditCategoryModal.value = false;
    }

    const lists = ref([
      {
        name: "Cheltuieli cu Locația",
        elements: [
          { name: "Test locație", cost: 250, paid: 150 },
          { name: "Test locaite", cost: 250, paid: 150 },
          { name: "Test locație", cost: 250, paid: 150 },
        ],
      },
      {
        name: "Alte cheltuieli",
        elements: [
          { name: "Test cheltuiala", cost: 250, paid: 150 },
          { name: "Test cheltuiala", cost: 250, paid: 150 },
        ],
      },
    ]);

    const handleInput = (element) => {
      element.cost = Math.max(0, parseFloat(element.cost) || 0);
      element.paid = Math.max(0, parseFloat(element.paid) || 0);
    };

    const setMin = (inputModel) => {
      if (inputModel.value < 1) inputModel.value = null;
    };

    const calculateCategoryTotal = (index, field) => {
      let total = 0;
      lists.value[index].elements.forEach((element) => {
        if (element[field] !== undefined && element[field] !== null) {
          total += element[field];
        }
      });
      return total;
    };

    const calculateCategoryTotalRemaining = (index) => {
      const totalCost = calculateCategoryTotal(index, "cost");
      const totalPaid = calculateCategoryTotal(index, "paid");
      return totalCost - totalPaid;
    };

    const calculateElementTotalRemaining = (cost, spent) => {
      let totalRemainingElement = 0;
      totalRemainingElement = cost - spent;
      return totalRemainingElement;
    };

    const calculateGlobalTotalCost = computed(() => {
      const calculateTotalForElements = (elements) => {
        return elements.reduce((total, element) => {
          if (element.elements) {
            return total + calculateTotalForElements(element.elements);
          } else {
            return total + element.cost;
          }
        }, 0);
      };

      return lists.value.reduce((total, category) =>
        total + calculateTotalForElements(category.elements), 0
      );
    });

    const calculateGlobalTotalPaid = computed(() => {
      const calculatePaidForElements = (elements) => {
        return elements.reduce((total, element) => {
          if (element.elements) {
            return total + calculatePaidForElements(element.elements);
          } else {
            return total + element.paid;
          }
        }, 0);
      };

      return lists.value.reduce((total, category) =>
        total + calculatePaidForElements(category.elements), 0
      );
    });

    const calculateGlobalTotalRemaining = computed(() => {
      return calculateGlobalTotalCost.value - calculateGlobalTotalPaid.value;
    });

    const deleteCategory = async (index) => {
      lists.value.splice(index, 1);
      await saveBudget();
    };

    const deleteCategoryItem = async (categoryIndex, index) => {
      lists.value[categoryIndex].elements.splice(index, 1);
      await saveBudget();
    };

    const addCategory = async (categoryName) => {
      console.log("test");
      const newCategory = {
        name: categoryName,
        elements: [],
      };

      lists.value.push(newCategory);
      await saveBudget();
      closeAddCategoryModal();
    };

    const addCategoryItem = async (categoryName, itemName) => {
      const categoryIndex = lists.value.findIndex(
        (category) => category.name === categoryName
      );
      if (categoryIndex !== -1) {
        lists.value[categoryIndex].elements.push({
          name: itemName,
          cost: 0,
          paid: 0,
        });
      } else {
        console.error(`Category "${categoryName}" does not exist.`);
      }
      await saveBudget();
      closeAddCategoryItemModal();
    };

    const editCategory = async (oldCategoryName, newCategoryName) => {
      const categoryIndex = lists.value.findIndex(
        (category) => category.name === oldCategoryName
      );
      if (categoryIndex !== -1) {
        lists.value[categoryIndex].name = newCategoryName;
      } else {
        console.error(`Category "${oldCategoryName}" does not exist.`);
      }
      await saveBudget();
      closeEditCategoryModal();
    };

    const saveBudget = async () => {
      try {
        const token = await getAccessTokenSilently();

        await updateBudget(token, lists.value, offerId.value);
      } catch (error) {
        notify({
          type: "error",
          title: "Eroare",
          text: "Nu am putut salva datele de bugetare.",
        });
      }
    };

    watch([
      calculateGlobalTotalCost, 
      calculateGlobalTotalPaid, 
      calculateGlobalTotalRemaining
    ], ([newTotalCost, newTotalPaid, newTotalRemaining], [oldTotalCost, oldTotalPaid, oldTotalRemaining]) => {
      if (newTotalCost !== oldTotalCost || newTotalPaid !== oldTotalPaid || newTotalRemaining !== oldTotalRemaining) {
        statsCanBeRefreshed.value = true;
        anime({
        targets: '.stats-container .recalculate-stats',
        opacity: 1,
        easing: 'easeInOutQuad',
        duration: 200,
        delay: 350
      })
      }
    });

    onMounted(async () => {
      try {
        const token = await getAccessTokenSilently();
        offerId.value = route.params.offerId;

        const response = await getBudget(token, offerId.value);
        lists.value = response;
        console.log(response);  
        loading.value = false;
      } catch (error) {
        notify({
          type: "error",
          title: "Eroare",
          text: "Nu am putut incarca datele de bugetare.",
        });
      }
      
    });

    return {
      loading,
      offerId,
      lists,
      handleInput,
      setMin, 
      calculateCategoryTotal,
      calculateCategoryTotalRemaining,
      calculateElementTotalRemaining,
      calculateGlobalTotalCost,
      calculateGlobalTotalPaid,
      calculateGlobalTotalRemaining,
      statsCanBeRefreshed,
      selectedCategoryName,
      deleteCategory,
      deleteCategoryItem,
      showAddCategoryModal,
      showAddCategory,
      closeAddCategoryModal,
      showAddCategoryItem,
      showAddCategoryItemWithCategory,
      showAddCategoryItemModal,
      closeAddCategoryItemModal,
      showEditCategory,
      showEditCategoryModal,
      closeEditCategoryModal,
      addCategory,
      addCategoryItem,
      editCategory,
      saveBudget,
    };
  },
};
</script>
