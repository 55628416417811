<template>
  <div
    class="modal bottom-sheet-on-mobile"
    id="change-icon-modal"
    :class="{ active: showChangeIconModal }"
    @click="closeModalFromBackground"
  >
    <div class="modal-content" @click.stop>
      <h1>Schimbă iconița</h1>
      <div class="icons">
        <div
          class="icon"
          id="1"
          @click="setSelectedIcon(1)"
          :class="{ selected: selectedIcon === 1 }"
        >
          <InvitationIcon :number="1" />
        </div>
        <div
          class="icon"
          id="2"
          @click="setSelectedIcon(2)"
          :class="{ selected: selectedIcon === 2 }"
        >
          <InvitationIcon :number="2" />
        </div>
        <div
          class="icon"
          id="3"
          @click="setSelectedIcon(3)"
          :class="{ selected: selectedIcon === 3 }"
        >
          <InvitationIcon :number="3" />
        </div>
        <div
          class="icon"
          id="4"
          @click="setSelectedIcon(4)"
          :class="{ selected: selectedIcon === 4 }"
        >
          <InvitationIcon :number="4" />
        </div>
        <div
          class="icon"
          id="5"
          @click="setSelectedIcon(5)"
          :class="{ selected: selectedIcon === 5 }"
        >
          <InvitationIcon :number="5" />
        </div>
        <div
          class="icon"
          id="6"
          @click="setSelectedIcon(6)"
          :class="{ selected: selectedIcon === 6 }"
        >
          <InvitationIcon :number="6" />
        </div>
        <div
          class="icon"
          id="7"
          @click="setSelectedIcon(7)"
          :class="{ selected: selectedIcon === 7 }"
        >
          <InvitationIcon :number="7" />
        </div>
        <div
          class="icon"
          id="8"
          @click="setSelectedIcon(8)"
          :class="{ selected: selectedIcon === 8 }"
        >
          <InvitationIcon :number="8" />
        </div>
        <div
          class="icon"
          id="9"
          @click="setSelectedIcon(9)"
          :class="{ selected: selectedIcon === 9 }"
        >
          <InvitationIcon :number="9" />
        </div>
        <div
          class="icon"
          id="10"
          @click="setSelectedIcon(10)"
          :class="{ selected: selectedIcon === 10 }"
        >
          <InvitationIcon :number="10" />
        </div>
        <div
          class="icon"
          id="11"
          @click="setSelectedIcon(11)"
          :class="{ selected: selectedIcon === 11 }"
        >
          <InvitationIcon :number="11" />
        </div>
        <div
          class="icon"
          id="12"
          @click="setSelectedIcon(12)"
          :class="{ selected: selectedIcon === 12 }"
        >
          <InvitationIcon :number="12" />
        </div>
      </div>
      <button class="primary small" @click="changeIcon">
        Salvează modificarea
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "ChangeIconModal",
  props: {
    initialIcon: Number,
    showChangeIconModal: Boolean,
  },
  setup(props, { emit }) {
    const selectedIcon = ref(props.initialIcon || 0);

    const setSelectedIcon = (iconNumber) => {
      selectedIcon.value = iconNumber;
    };

    const changeIcon = () => {
      emit("changeIcon", selectedIcon.value);
    };

    const closeModal = () => {
      emit("closeModal");
    };

    const closeModalFromBackground = (event) => {
      if (event.target.classList.contains("modal")) {
        emit("closeModal");
      }
    };

    return {
      setSelectedIcon,
      selectedIcon,
      changeIcon,
      closeModal,

      closeModalFromBackground,
    };
  },
};
</script>
