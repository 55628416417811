<template>
  <div class="card module my-privent-card" :class="[containerClass]">
    <div class="inside">
      <div class="text-content">
        <h2>Planul sălii</h2>
        <div class="module-button" @click="onClick">
          <span class="material-symbols-rounded fill">
            design_services
          </span>
          <p>Configurează<br>planurile</p>
        </div>
      </div>
      <div class="elements-content">
      
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Floor-Plan-Card',
  props: {
    isActivated: { type: Boolean },
    onClick: { type: Function },
    containerClass: {
      type: String,
      default: ''
    },
  },
  computed: {
  }
}   
</script>