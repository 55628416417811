<template>
  <div class="wrapper-content invitation-wizard">
    <share-link-modal
      :showShareLinkModal="seeShareLinkModal"
      v-if="this.seeShareLinkModal"
      :offerId="offerId"
      :invitationId="invitationId"
      :hasCustomUrl="invitation.hasCustomUrl"
      :customUrl="invitation.customUrl"
      @closeModal="closeShareLinkModal()"
    />

    <div class="header space-between">
      <div
        class="vertical-align gap-10 splash-rounded splash-padding"
        @click="
          this.$router.push(`/invitation/${offerId}/${invitationId}/edit`)
        "
      >
        <span class="material-symbols-rounded"> arrow_back </span>
        <h1>Înapoi către invitație</h1>
      </div>
      <div class="actions">
        <div class="header-button primary" @click="getLink()">
          <div class="highlight">
            <span class="material-symbols-rounded"> share </span>
          </div>
          <div class="text" v-if="invitation.published">Obține linkul</div>
          <div class="text" v-else>Publică invitația</div>
        </div>
      </div>
    </div>
    <div class="content-loading" v-if="loading || savingPreferences">
      <vLottiePlayer
        name="lottie"
        class="lottie"
        loop
        :animationData="require('@/assets/lottie/simpleDotsLoader.json')"
      />
    </div>
    <div v-else>
      <!-- TODO: Disable the save button here -->
      <div v-if="loadingAliasExists || aliasAlreadyExists"></div>
      <button v-else class="save-button pulse" @click="saveGeneralPreferences">
        <span class="material-symbols-rounded"> save </span>Salvează
        modificările
      </button>

      <div class="content">
        <div class="container services">
          <div class="row">
            <div class="col-50 gap-10">
              <div class="card module">
                <div class="card-content">
                  <h2>Preferințe personale</h2>
                  <div class="input-wrapper">
                    <input
                      id="name"
                      v-model="generalPreferences.name"
                      type="plain"
                      name="name"
                      class="input creator"
                      required
                      data-pristine-required-message="Te rugăm să introduci numele invitatiei."
                    />
                    <label class="label" for="password"
                      >Numele invitatiei</label
                    >
                  </div>
                  <p class="explainer">
                    <span class="material-symbols-rounded"> help </span>
                    Adaugă întrebarile tale aici iar invitații vor raspunde la
                    acestea atunci când vor confirma invitația ta
                  </p>
                  <div
                    v-for="(question, index) in generalPreferences.questions"
                    :key="index"
                    class="question-container"
                  >
                    <div class="input-wrapper question">
                      <input
                        v-model="generalPreferences.questions[index].value"
                        placeholder=""
                        class="input"
                        type="text"
                        id="question"
                        name="question"
                        placeholder-shown="true"
                        autocomplete="off"
                        required
                        minlength="2"
                        data-pristine-minlength-message="Introduceti minim 2 caractere"
                        data-pristine-required-message="Câmp obligatoriu"
                      />
                      <label class="label" for="question">Intrebare</label>
                      <img
                        class="checked"
                        src="@/assets/img/check.svg"
                        alt=""
                      />
                      <img class="error" src="@/assets/img/error.svg" alt="" />
                      <button class="primary" @click="removeQuestion(index)">
                        <span class="material-symbols-rounded fill">
                          close
                        </span>
                      </button>
                    </div>
                    <div class="answers-container">
                      <div
                        v-for="(answer, answerIndex) in generalPreferences
                          .answers[index]"
                        :key="answerIndex"
                        class="input-wrapper answer"
                      >
                        <input
                          v-model="
                            generalPreferences.answers[index][answerIndex].value
                          "
                          placeholder=""
                          class="input"
                          type="text"
                          id="answer"
                          name="answer"
                          placeholder-shown="true"
                          autocomplete="off"
                          required
                          minlength="2"
                          data-pristine-minlength-message="Introduceti minim 2 caractere"
                          data-pristine-required-message="Câmp obligatoriu"
                        />
                        <label class="label" for="answer"
                          >Raspuns {{ answerIndex + 1 }}</label
                        >
                        <img
                          class="checked"
                          src="@/assets/img/check.svg"
                          alt=""
                        />
                        <img
                          class="error"
                          src="@/assets/img/error.svg"
                          alt=""
                        />
                        <button
                          class="primary small"
                          @click="removeAnswer(index, answerIndex)"
                        >
                          <span class="material-symbols-rounded fill">
                            close
                          </span>
                        </button>
                      </div>
                      <button
                        class="primary small add-answer"
                        @click="addAnswer(index)"
                      >
                        Adaugă răspuns
                      </button>
                    </div>
                  </div>
                  <button
                    class="primary small add-question"
                    @click="addQuestion"
                  >
                    Adaugă întrebare
                  </button>

                  <div class="input-wrapper">
                    <input
                      id="date"
                      v-model="generalPreferences.confirmationDateLimit"
                      type="date"
                      name="date"
                      class="input creator"
                      required
                      data-pristine-required-message="Te rugăm să introduci data limită a confirmării."
                    />
                    <label class="label" for="date"
                      >Data limita confirmare*</label
                    >
                  </div>
                  <p class="explainer">
                    <span class="material-symbols-rounded"> help </span>
                    Data limită de confirmare reprezinta ziua din care invitația
                    ta digitală nu va mai putea fi confirmată. Aceasta va putea
                    fi vizualizată în continuare ca invitații tăi să aibe mereu
                    datele evenimentului la îndemână.
                  </p>

                  <div class="input-wrapper choice">
                    <h4>Link personalizat</h4>
                    <input
                      class="tgl tgl-light"
                      id="hasCustomUrl"
                      type="checkbox"
                      v-model="generalPreferences.hasCustomUrl"
                    />
                    <label class="tgl-btn" for="hasCustomUrl"></label>
                  </div>

                  <div
                    class="input-wrapper"
                    v-if="generalPreferences.hasCustomUrl"
                    :class="{'has-danger': aliasAlreadyExists, 'has-success': !aliasAlreadyExists && customAlias != '' && !loadingAliasExists, 'has-loading': loadingAliasExists}"
                  >
                    <div
                      id="alias"
                      name="alias"
                      style="display: flex; align-items: center"
                    >
                      <span>{{ tinyUrlDomain }} </span>
                      <input
                        id="alias-value"
                        type="text"
                        v-model="customAlias"
                        name="alias-value"
                        class="input creator"
                        required
                        data-pristine-required-message="Te rugăm să introduci textul personalizat."
                      />
                      <img class="checked" src="@/assets/img/check.svg" alt="" />
                      <img class="error" src="@/assets/img/error.svg" alt="" />
                      <img class="loading" src="@/assets/img/loading.svg" alt="" />

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-50 gap-10">
              <div class="card module">
                <div class="card-content">
                  <h2>Setări acțiuni invitați</h2>
                  <div class="input-wrapper choice">
                    <h4>Oferă posibilitatea de confirmare</h4>
                    <input
                      class="tgl tgl-light"
                      id="confirmationAvailable"
                      type="checkbox"
                      v-model="generalPreferences.confirmationAvailable"
                    />
                    <label class="tgl-btn" for="confirmationAvailable"></label>
                  </div>
                  <p class="explainer">
                    <span class="material-symbols-rounded"> help </span>
                    Bifează această opțiune dacă dorești ca invitații sã iți
                    confirme prezența la eveniment. În caz contrar, formularul
                    de confirmare nu va fi afișat în invitație.
                  </p>
                  <div class="input-wrapper choice">
                    <h4>Limitează la o singură confirmare</h4>
                    <input
                      class="tgl tgl-light"
                      id="limitConfirmationToOne"
                      type="checkbox"
                      v-model="generalPreferences.limitConfirmationToOne"
                    />
                    <label class="tgl-btn" for="limitConfirmationToOne"></label>
                  </div>

                  <p class="explainer">
                    <span class="material-symbols-rounded"> help </span>
                    Bifează această opțiune pentru a te asigura cã nu vei primi
                    rãspunsuri multiple de la aceeași persoană. Vom solicita
                    adresa de email a invitatului în formularul de confirmare.
                  </p>

                  <div class="input-wrapper choice">
                    <h4>Limitează accesul prin parolă</h4>
                    <input
                      class="tgl tgl-light"
                      id="hasAccessPassword"
                      type="checkbox"
                      v-model="generalPreferences.hasPassword"
                    />
                    <label class="tgl-btn" for="hasAccessPassword"></label>
                  </div>

                  <div
                    class="input-wrapper"
                    v-if="generalPreferences.hasPassword"
                  >
                    <input
                      id="password"
                      :type="showPassword ? 'text' : 'password'"
                      v-model="generalPreferences.accessPassword"
                      name="password"
                      class="input creator"
                      required
                      data-pristine-required-message="Te rugăm să introduci o parolă."
                    />
                    <label class="label" for="password"
                      >Parola de acces (optional)*</label
                    >
                    <button
                      class="toggle-password"
                      @click="togglePasswordVisibility"
                    >
                      <span
                        class="material-symbols-rounded"
                        v-if="showPassword"
                      >
                        visibility_off
                      </span>
                      <span class="material-symbols-rounded" v-else>
                        visibility
                      </span>
                    </button>
                  </div>

                  <p class="explainer">
                    <span class="material-symbols-rounded"> help </span>
                    Introdu o parola dacă dorești ca invitația să fie protejată
                    și doar invitații care au parola să o poata vizualiza. Lasă
                    câmpul gol dacã nu dorești ca invitația să aibă parolă.
                  </p>
                  <div class="input-wrapper choice">
                    <h4>Permite traducerea automată a invitației</h4>
                    <input
                      class="tgl tgl-light"
                      id="hasTranslation"
                      type="checkbox"
                      v-model="generalPreferences.hasTranslation"
                    />
                    <label class="tgl-btn" for="hasTranslation"></label>
                  </div>
                  <p class="explainer">
                    <span class="material-symbols-rounded"> help </span>
                    Bifează această opțiune dacă dorești ca invitații sã poată
                    traduce conținutul invitației in mai multe limbi. Traducerea
                    este automată, fiind oferită de Google Translate poate avea
                    greșeli.
                  </p>

                  <div class="input-wrapper multiselect">
                    <VueMultiselect
                      v-model="selectedLanguages"
                      :options="languagesOptions"
                      :searchable="false"
                      :multiple="true"
                      :selectLabel="'Selectează'"
                      :selectedLabel="'Selectat'"
                      :deselectLabel="'Deselectează'"
                      :placeholder="'Alege limbile disponibile'"
                    >
                    </VueMultiselect>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, watch } from "vue";
import {
  saveGeneralPreferencesInvitation,
  getInvitation,
  getCheckAliasExists,
  publishInvitation,
  getEventAppUser,
} from "@/api";
import { useAuth0 } from "@auth0/auth0-vue";
import { useRoute } from "vue-router";
import { notify } from "@kyvg/vue3-notification";

import { languages } from "@/helpers/languages";
import VueMultiselect from "vue-multiselect";
import { useShare } from "@vueuse/core";
import ShareLinkModal from "@/views/invitation/components/ShareLinkModal.vue";

export default {
  name: "Invitation-Wizard",
  components: {
    
    VueMultiselect,
    ShareLinkModal,
  },
  setup(props, { emit }) {
    const { share } = useShare();

    const { getAccessTokenSilently } = useAuth0();
    const generalPreferences = reactive({
      questions: ["Tip meniu"],
      answers: [["Normal", "Vegatarian", "Copil"]],
      confirmationDateLimit: null,
      confirmationAvailable: true,
      limitConfirmationToOne: false,
      hasTranslation: true,
      hasPassword: false,
      accessPassword: null,
      name: "",
    });

    const { user } = useAuth0();

    const invitation = ref({});
    const step = ref(1);
    const offerId = ref("");
    const route = useRoute();
    const loading = ref(true);
    const savingPreferences = ref(false);
    const invitationId = ref(0);
    const showPassword = ref(false);
    const selectedLanguages = ref([]);
    const tinyUrlDomain = ref(process.env.VUE_APP_tinyurl_domain);
    const aliasAlreadyExists = ref(false);
    const loadingAliasExists = ref(false);
    const customAlias = ref("");

    const userProfile = ref(null);
    const showChangeProfileDetailsModal = ref(false);
    const seeShareLinkModal = ref(false);
    const baseLink = ref(process.env.VUE_APP_eventApp_url);

    let timeout;

    const languagesOptions = ref(languages.map((language) => language.name));

    const isMobile = () => {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    };

    const togglePasswordVisibility = () => {
      showPassword.value = !showPassword.value;
    };

    const showChangeProfileDetails = () => {
      showChangeProfileDetailsModal.value = true;
    };

    const confirmPublish = async () => {
      if (
        window.confirm(
          "Sunteti sigur ca doriti sa obtineti link-ul? Aceasta confirmare va face invitatia publica."
        )
      ) {
        const token = await getAccessTokenSilently();
        await publishInvitation(token, offerId.value, invitationId.value);

        invitation.value.published = true;

        window.open(
          `/invitation/${offerId.value}/${invitationId.value}`,
          "_blank"
        );
      }
    };

    const getLink = async () => {
      if (!invitation.value.published) {
        if (
          !userProfile.value.email ||
          !userProfile.value.email.includes("@")
        ) {
          showChangeProfileDetails();
        } else {
          await confirmPublish();
        }
      } else {
        showSeeShareLinkModal();
      }
    };

    const showSeeShareLinkModal = () => {
      if (isMobile()) {
        share({
          title: "Invitația mea la eveniment!",
          text: "Link-ul catre invitația mea este:",
          url:
            baseLink.value +
            "/invitation/" +
            offerId.value +
            "/" +
            invitationId.value,
        });
      } else {
        seeShareLinkModal.value = true;
      }
    };

    const closeShareLinkModal = () => {
      seeShareLinkModal.value = false;
    };

    const openDrawer = () => {
      document.querySelector(".drawer-wrapper").classList.add("open");
    };

    const nextStep = () => {
      if (step.value < 3) {
        step.value++;
      } else if (step.value == 3) {
        emit("closeModal");
      }
    };

    const prevStep = () => {
      if (step.value > 1) {
        step.value--;
      }
    };

    const addQuestion = () => {
      generalPreferences.questions.push({ id: 0, value: "" });
      generalPreferences.answers.push([]);
    };

    const removeQuestion = (index) => {
      generalPreferences.questions.splice(index, 1);
      generalPreferences.answers.splice(index, 1);
    };

    const addAnswer = (questionIndex) => {
      generalPreferences.answers[questionIndex].push({ id: 0, value: "" });
    };

    const removeAnswer = (questionIndex, answerIndex) => {
      //TODO: Afiseaza un confirm dialog care te anunta ca persoanele care vor completa nu vor vedea intrebarea pe care ai sters-o
      generalPreferences.answers[questionIndex].splice(answerIndex, 1);
    };

    const saveGeneralPreferences = async () => {
      savingPreferences.value = true;

      const token = await getAccessTokenSilently();

      const questions = [];

      generalPreferences.questions.forEach((question, index) => {
        if (question) {
          const answers = [];

          generalPreferences.answers[index].forEach((answer) => {
            answers.push({ Id: answer.id, Value: answer.value });
          });

          questions.push({
            Id: question.id,
            Value: question.value,
            Answers: answers,
          });
        }
      });

      const languagesToSave = selectedLanguages.value.map((name) =>
        languages.find((language) => language.name === name)
      );

      const data = {
        Questions: questions,
        Deadline: new Date(
          generalPreferences.confirmationDateLimit
        ).toISOString(),
        HasConfirmation: generalPreferences.confirmationAvailable,
        HasUniqueConfirmation: generalPreferences.limitConfirmationToOne,
        HasTranslation: generalPreferences.hasTranslation,
        HasPassword: generalPreferences.hasPassword,
        HasCustomUrl: generalPreferences.hasCustomUrl,
        CustomUrl: `https://${tinyUrlDomain.value}${customAlias.value}`,
        AccessPassword: generalPreferences.accessPassword,
        Name: generalPreferences.name,
        LanguagesToTranslate: JSON.stringify(languagesToSave),
      };

      await saveGeneralPreferencesInvitation(
        token,
        offerId.value,
        invitationId.value,
        data
      );

      savingPreferences.value = false;

      notify({
        type: "success",
        title: "Succes",
        text: "Preferințele au fost salvate cu succes",
      });
    };

    const checkAliasExists = async () => {
      loadingAliasExists.value = true;

      const token = await getAccessTokenSilently();

      if (customAlias.value !== invitation.value.customUrl?.split("/").pop()) {
        aliasAlreadyExists.value = await getCheckAliasExists(
          token,
          offerId.value,
          { url: `https://${tinyUrlDomain.value}${customAlias.value}` }
        );
      }
      loadingAliasExists.value = false;
    };

    const debouncedCheckAliasExists = () => {
      clearTimeout(timeout);
      timeout = setTimeout(checkAliasExists, 500); // 500ms delay
    };

    const getUser = async () => {
      const token = await getAccessTokenSilently();

      return await getEventAppUser(token, user.value.phone_number);
    };

    watch(customAlias, debouncedCheckAliasExists, { immediate: false });

    onMounted(async () => {
      offerId.value = route.params.offerId;
      invitationId.value = route.params.invitationId;
      userProfile.value = await getUser();

      invitation.value = await getInvitation(offerId.value, invitationId.value);

      const answers = [];

      invitation.value.questions.forEach((question) => {
        const answersToThisQuestion = [];

        question.answers.forEach((answer) => {
          answersToThisQuestion.push(answer);
        });

        answers.push(answersToThisQuestion);
      });

      const questions = invitation.value.questions;

      generalPreferences.questions = questions;
      generalPreferences.answers = answers;
      generalPreferences.confirmationAvailable =
        invitation.value.hasConfirmation;
      generalPreferences.limitConfirmationToOne =
        invitation.value.hasUniqueConfirmation;
      generalPreferences.accessPassword = invitation.value.accessPassword;
      generalPreferences.hasTranslation = invitation.value.hasTranslation;
      generalPreferences.name = invitation.value.name;
      generalPreferences.hasPassword = invitation.value.hasPassword;
      generalPreferences.hasCustomUrl = invitation.value.hasCustomUrl;
      customAlias.value = invitation.value.customUrl
        ? invitation.value.customUrl.split("/").pop()
        : "";

      if (invitation.value.languagesToTranslate) {
        selectedLanguages.value = JSON.parse(
          invitation.value.languagesToTranslate
        ).map((language) => language.name);
      }

      let deadline = new Date(invitation.value.deadline);
      generalPreferences.confirmationDateLimit = `${deadline.getFullYear()}-${String(
        deadline.getMonth() + 1
      ).padStart(2, "0")}-${String(deadline.getDate()).padStart(2, "0")}`;

      loading.value = false;
    });

    return {
      getLink,
      seeShareLinkModal,
      closeShareLinkModal,
      generalPreferences,
      showPassword,
      togglePasswordVisibility,
      step,
      loading,
      savingPreferences,
      offerId,
      invitation,
      invitationId,
      selectedLanguages,
      languagesOptions,
      tinyUrlDomain,
      aliasAlreadyExists,
      loadingAliasExists,
      customAlias,
      openDrawer,
      nextStep,
      prevStep,
      addQuestion,
      removeQuestion,
      addAnswer,
      removeAnswer,
      saveGeneralPreferences,
    };
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
