<template>
  <div class="modal bottom-sheet-on-mobile" id="add-new-event" :class="{ active: showAddNewEvent }"
    @click="closeModal()">
    <div class="modal-content" @click.stop>
      <h1>Adaugă eveniment</h1>

      <div v-show="step === 1" class="modal-step">

        <form>
          <div class="radio">
            <input v-model="eventType" id="nunta" type="radio" name="type" value="Nunta" required />
            <label for="nunta">
              <img alt="Nunta - DJ Booking" src="@/assets/img/icons/nunta.svg" />
              <span>Nuntă</span>
            </label>
          </div>

          <div class="radio">
            <input v-model="eventType" id="botez" type="radio" name="type" value="Botez" required />
            <label for="botez">
              <img alt="Botez - DJ Booking" src="@/assets/img/icons/botez.svg" />
              <span>Botez</span>
            </label>
          </div>

          <div class="radio">
            <input v-model="eventType" id="petrecereAniversara" type="radio" name="type" value="PetrecereA" required />
            <label for="petrecereAniversara">
              <img alt="Petrecere aniversara - DJ Booking" src="@/assets/img/icons/petrecere.svg" />
              <span>Petrecere Aniversara</span>
            </label>
          </div>

          <div class="radio">
            <input v-model="eventType" id='cununieCivila' type="radio" name="type" value="Cununie" required />
            <label for="cununieCivila">
              <img alt="Cununie civila - DJ Booking" src="@/assets/img/icons/cununieCivila.svg" />
              <span>Cununie civila</span>
            </label>
          </div>

          <div class="radio">
            <input v-model="eventType" id="majorat" type="radio" name="type" value="Majorat" required />
            <label for="majorat">
              <img alt="Majorat - DJ Booking" src="@/assets/img/icons/nunta.svg" />
              <span>Majorat</span>
            </label>
          </div>

          <div class="radio">
            <input v-model="eventType" id='corporate' type="radio" name="type" value="PetrecereP" required />
            <label for="corporate">
              <img alt="Corporate - DJ Booking" src="@/assets/img/icons/nunta.svg" />
              <span>Corporate</span>
            </label>
          </div>
          <p class="error" v-if="showInvalid && !isValidType">Selecteaza un tip de eveniment</p>

        </form>

      </div>
      <div v-show="step === 2" class="modal-step">
        <form>

          <date-picker :inline="true" class="calendar-picker" v-model="eventDate">
          </date-picker>
          <p v-if="showInvalid && !isValidDate">Selectează data</p>


          <div class="input-wrapper" :class="{ 'has-danger': showInvalid }">
            <input id="hours" v-model="eventTime" type="time" name="hours" class="input creator" required
              data-pristine-required-message="Te rugăm să introduci ora evenimentului." />
            <label class="label" for="hours">Ora evenimentului*</label>
            <img class="checked" src="@/assets/img/check.svg" alt="" />
            <img class="error" src="@/assets/img/error.svg" alt="" />
            <p class="pristine-error text-help" v-if="showInvalid && !isValidTime">Selectează ora</p>
          </div>

        </form>



      </div>
      <div v-show="step === 3" class="modal-step">
       <form>
        <div class="input-wrapper" :class="{ 'has-danger': showInvalid }">
          <input id="autoLocation" v-model="eventLocation" ref="autoComplete" type="text" name="autoLocation"
            class="input pac-target-input" />

          <input id="city" type="text" class="input hidden-input" required
            data-pristine-required-message="Te rugăm să introduci locația din lista." autocomplete="chrome-off"
            readonly />
          <label class="label" for="city">Introduceți locația evenimentului</label>
          <img class="checked" src="@/assets/img/check.svg" alt="" />
          <img class="error" src="@/assets/img/error.svg" alt="" />
          <p class="pristine-error text-help" v-if="showInvalid && !isValidTime">Invalid input</p>
        </div>

        <img class="map-preview" v-if="placeName" :src="mapSrc" loading="async" width="650" height="250" />

        <div class="input-wrapper" :class="{ 'has-danger': showInvalid }">
          <input v-model="eventPersons" @input="eventPersons = $event.target.value.replace(/\D/g, '')" class="input"
            type="text" name="clientName" placeholder="0" autocomplete="off" required minlength="3"
            data-pristine-minlength-message="Introduceti minim 3 caractere"
            data-pristine-required-message="Câmp obligatoriu" />
          <label class="label" for="clientName">Numarul de persoane</label>
          <img class="checked" src="@/assets/img/check.svg" alt="" />
          <img class="error" src="@/assets/img/error.svg" alt="" />
          <p class="pristine-error text-help" v-if="showInvalid && !isValidTime">Invalid input</p>
        </div>
       </form>

       

      </div>
      <div v-if="loading">
        <vLottiePlayer name="lottie" class="lottie" loop
          :animationData="require('@/assets/lottie/simpleDotsLoader.json')" />
      </div>
      <button v-else class="primary small" @click="nextStep()">Continuă</button>
    </div>
  </div>
</template>

<script>

import { ref, onMounted, watch, computed } from 'vue';
import DatePicker from 'vuejs3-datepicker';
import { useAuth0 } from "@auth0/auth0-vue";
import { createOffer } from '@/api';


export default {
  name: 'AddNewEvent',
  components: {
    DatePicker,
    
  },
  props: {
    showAddNewEvent: Boolean,
  },
  setup(props, { emit }) {
    const { user } = useAuth0();
    const step = ref(1);
    const eventType = ref('');
    const eventDate = ref('');
    const eventTime = ref(null);
    const eventLocation = ref(null);
    const eventPersons = ref(null);
    const placeCity = ref(null);
    const placeName = ref('');
    const placeAddress = ref(null);
    const offerId = ref("");
    const loading = ref(false);
    let autocomplete = ref(null);
    const autoComplete = ref(null);
    const { getAccessTokenSilently } = useAuth0();

    //validation
    const isValidType = computed(() => eventType.value !== '');
    const isValidDate = computed(() => !!eventDate.value);
    const isValidTime = computed(() => !!eventTime.value);
    const isValidLocation = computed(() => placeAddress.value !== '' && eventLocation.value !== null);
    const isValidPersons = computed(() => eventPersons.value > 0);
    const showInvalid = ref(false);


    watch(placeName, () => {
      emit('input', {
        place: placeName.value,
        address: placeAddress.value,
        city: placeCity.value
      });
    });

    watch(eventLocation, (newVal) => {
      if (placeAddress.value !== newVal) {
        placeAddress.value = '';
      }
    });

    const mapSrc = computed(() => {
      const apiKey = 'AIzaSyBW1iQe89cgzpbujL4_jkRRaMb5TV6VagU';
      const center = encodeURIComponent(`${placeName.value},${placeCity.value}`);
      return `https://maps.googleapis.com/maps/api/staticmap?center=${center}&zoom=13&size=650x250&maptype=roadmap&key=${apiKey}`;
    });


    onMounted(() => {
      window.initMap = initMap;

      const script = document.createElement('script');
      script.src =
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyBW1iQe89cgzpbujL4_jkRRaMb5TV6VagU&libraries=places&callback=initMap";
      script.async = true;
      script.defer = true;
      window.document.body.appendChild(script);

      script.addEventListener('load', () => {
        initMap();
      });
    });

    const initMap = () => {
      var locationOptions = {
        fields: ['formatted_address', 'name', 'address_components'],
        componentRestrictions: { country: 'RO' }
      }

      const google = window.google

      autocomplete.value = new google.maps.places.Autocomplete(
        autoComplete.value,
        locationOptions
      )

      autocomplete.value.addListener('place_changed', () => {
        var place = autocomplete.value.getPlace()
        if (!place.address_components) {
          alert(
            'Aceasta locație nu are o adresa. Va rugam sa selectati alta locație sau sa introduceti adresa locației dorite.'
          )
        } else {
          placeName.value = place.name
          placeAddress.value = place.formatted_address
          placeCity.value = place.address_components.find(component => component.types.includes('locality'))?.long_name || '';
          eventLocation.value = place.formatted_address;
        }
      })
    }

    const createEvent = async () => {
      loading.value = true;

      const phone = user?.value.name
      const token = await getAccessTokenSilently();

      let clientOffer = {
        Location: eventLocation.value,
        EventDate: ((d) => (d.setMinutes(d.getMinutes() - d.getTimezoneOffset()), d.toISOString()))(new Date(`${new Date(eventDate.value).toISOString().slice(0, 10)}T${eventTime.value}:00`)),
        City: placeCity.value,
        Phone: phone,
        EventAddress: eventLocation.value,
        EventType: eventType.value,
        NrAttendees: eventPersons.value
      };

      offerId.value = await createOffer(token, clientOffer);

      window.location.href = `/dashboard/${offerId.value}`;

      loading.value = false;
    };

    const nextStep = () => {
      if (step.value === 1) {
        if (isValidType.value) {
          showInvalid.value = false
          step.value++;
        }
        else {
          showInvalid.value = true
        }
      }
      else if (step.value === 2) {
        if (isValidDate.value && isValidTime.value) {
          showInvalid.value = false
          step.value++;
        }
        else {
          showInvalid.value = true
        }
      }
      else if (step.value === 3) {
        if (isValidLocation.value && isValidPersons.value) {
          createEvent();
          emit('closeModal');
        }
        else {
          showInvalid.value = true
        }
      }
      else {
        showInvalid.value = true
      }
    };

    const prevStep = () => {
      if (step.value > 1) {
        step.value--;
      }
    };

    function closeModalFromBackground(event) {
      if (event.target.classList.contains('modal')) {
        emit('closeModal');
      }
    }

    const closeModal = () => {
      emit('closeModal');
    };

    return {
      loading,
      step,
      eventLocation,
      placeCity,
      eventType,
      eventPersons,
      eventDate,
      eventTime,
      createEvent,
      nextStep,
      prevStep,
      closeModal,
      closeModalFromBackground,
      autoComplete,
      showInvalid,
      isValidType,
      isValidDate,
      isValidTime,
      isValidLocation,
      isValidPersons,
      mapSrc
    };
  }
};
</script>
