<template>
  <div class="wrapper-content dashboard">
    <edit-event-modal :showEditEventModal="showEditEventModal" :offer="offer" v-if="this.showEditEventModal"
      @closeModal="closeEditModal()" />
    <see-collaborators-modal :showSeeCollaboratorsModal="seeCollaboratorsModal" :offerId="offerId"
      v-if="seeCollaboratorsModal" @closeModal="closeSeeCollaboratorsModal()" />
    <div class="header space-between">
      <div class="mobile-only">
        <div class="menu-toggle">
          <span class="material-symbols-rounded" @click="openDrawer()">
            menu
          </span>
        </div>
      </div>
      <div class="vertical-align gap-5">
        <h1>{{ eventTypeString }}</h1>
      </div>
      <div class="actions">
        <div class="header-button secondary" @click="showSeeCollaboratorsModal">
          <div class="highlight">
            <span class="material-symbols-rounded"> group_add </span>
          </div>
          <div class="text">Colaboratori</div>
        </div>
        <div class="header-button primary" @click="editEvent()">
          <div class="highlight">
            <span class="material-symbols-rounded"> edit </span>
          </div>
          <div class="text">Editează</div>
        </div>

        <!-- <div class="header-button primary" @click="navigateToChecklist()">
          <div class="highlight">
            <span class="material-symbols-rounded">
              edit
            </span>
          </div>
          <div class="text">Checklist</div>
        </div> -->
      </div>
    </div>

    <div class="content-loading" v-if="loadingOffer">
      <vLottiePlayer name="lottie" class="lottie" loop
        :animationData="require('@/assets/lottie/simpleDotsLoader.json')" />
    </div>
    <div class="content" v-else>
      <div class="mobile-only">
        <div class="card event-details">
          <h2>Data și ora</h2>
          <h1>
            {{ offer.eventDateString }}
          </h1>
        </div>
        <div class="card event-details">
          <h2>Locația</h2>
          <h1>
            {{ offer.location }}
          </h1>
        </div>
        <div class="card event-details">
          <h2>Nr participanți</h2>
          <h1>
            {{ offer.nrAttendees }}
          </h1>
        </div>
      </div>
      <div class="container services">
        <div class="row" v-if="
          offer.artistName ||
          offer.selectedSoundPackage ||
          offer.selectedLightPackage
        ">
          <div class="col-100 gap-10">
            <h1>Servicii Privent</h1>
            <div class="horizontal-scroll offer" ref="slider">
              <artist-card v-if="offer.artistName" :artistPhoto="offer.artistPhoto" :artistId="offer.artistId"
                :artistName="offer.artistName" />
              <service-card v-if="offer.selectedSoundPackage" :offerPackage="offer.selectedSoundPackage" />
              <service-card v-if="offer.selectedLightPackage" :offerPackage="offer.selectedLightPackage" />
            </div>
          </div>
        </div>

        <div v-if="offer.contractUrl.length > 0" class="row">
          <div class="service-details">
            <div class="section">
              <contract-card :contractNumber="offer.contractNumberString" :contractUrl="offer.contractUrl" />
            </div>
            <div class="section" v-if="isPrepaymentInvoiceAvailable || isFinalInvoiceAvailable">
              <invoice-card v-if="isPrepaymentInvoiceAvailable" :invoiceNumber="offer.invoicePrepaymentNumberString"
                :invoiceUrl="invoicePrepaymentUrl" />
              <invoice-card v-if="isFinalInvoiceAvailable" :invoiceNumber="offer.invoiceFinalNumberString"
                :invoiceUrl="invoiceFinalUrl" />
            </div>
            <div class="section">
              <!--TODO: Use offer.playlistCompleted -->
              <music-preferences-card @click="redirectToUrl(offer.playlistPreferenceUrl)" :isCompleted="false" />
            </div>
            <div class="section">
              <!--TODO: Use offer.feedbackCompleted -->
              <feedback-card @click="redirectToUrl(offer.feedbackUrl)" :isCompleted="false" />
            </div>
          </div>
        </div>

        <div class="my-privent-services">
          <img src="@/assets/img/brand/MyPriventDark.png" />
          <div class="divider"></div>
        </div>

        <div class="grid-container">
          <invitation-card :offerId="offerId" :isInvitationCreated="offer.invitationEnabled"
            :containerClass="offer.invitationEnabled ? 'three' : 'five'" />

          <guestlist-card v-if="offer.invitationEnabled" 
            buttonTitle="Vezi lista<br>de invitati"
            :onClick="navigateToGuestlist" :guestlistConfirmations="offer.guestlistConfirmations" :guestlistTotal="offer.nrAttendees" :containerClass="'five'" />

          <floor-plan-card :onClick="navigateToFloorplans" v-if="offer.invitationEnabled" :containerClass="'two'" />

          <memories-collection-card
            :onClick="memoriesCollectionOnTap"
            :isActivated="offer.hasMemoriesCollection"
            :containerClass="offer.invitationEnabled ? 'four' : 'five'"
            :memoriesCollectionPhotosNumber="offer.memoriesCollectionsPhotos"
            />

          <budget-card :onClick="navigateToBudget" :budgetTotalCost="offer.budgetTotalCost" :budgetToPay="offer.budgetToPay" :containerClass="'six'"/>

          <timeline-card :onClick="navigateToTimeline" :timeTillEvent="offer.eventDateString" :containerClass="'six'" />

          <checklist-card :onClick="navigateToChecklist" :checklistItemsCompleted="offer.checklistItemsCompleted" :checklistItemsTotal="offer.checklistItemsTotal" :containerClass="'four'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed, watch, onBeforeUnmount, nextTick } from "vue";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import ContractCard from "@/views/dashboard/components/ContractCard";
import InvoiceCard from "@/views/dashboard/components/InvoiceCard";
import MusicPreferencesCard from "@/views/dashboard/components/MusicPreferencesCard";
import InvitationCard from "@/views/dashboard/components/InvitationCard";
import FeedbackCard from "@/views/dashboard/components/FeedbackCard";
import ArtistCard from "@/views/dashboard/components/ArtistCard";
import ServiceCard from "@/views/dashboard/components/ServiceCard";
import {
  getClientOffer,
  enableMemoriesCollection,
  getOffersPreview,
} from "@/api";
import { useAuth0 } from "@auth0/auth0-vue";
import EditEventModal from "@/components/EditEventModal.vue";
import SeeCollaboratorsModal from "@/views/invitation/components/SeeCollaboratorsModal.vue";
import FloorPlanCard from "@/views/dashboard/components/FloorPlanCard.vue";
import MemoriesCollectionCard from "@/views/dashboard/components/MemoriesCollectionCard.vue";
import BudgetCard from "@/views/dashboard/components/BudgetCard.vue";
import TimelineCard from "@/views/dashboard/components/TimelineCard.vue";
import ChecklistCard from "@/views/dashboard/components/ChecklistCard.vue";
import GuestlistCard from "@/views/dashboard/components/GuestlistCard.vue";
import anime from "animejs";

export default {
  name: "DashboardPage",
  components: {
    ContractCard,
    InvoiceCard,
    MusicPreferencesCard,
    InvitationCard,
    FeedbackCard,
    ArtistCard,
    ServiceCard,
    EditEventModal,
    SeeCollaboratorsModal,
    MemoriesCollectionCard,
    FloorPlanCard,
    BudgetCard,
    TimelineCard,
    ChecklistCard,
    GuestlistCard
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { getAccessTokenSilently } = useAuth0();

    const offerId = ref(route.params.offerId);

    const offer = ref({});
    const loadingOffer = ref(true);
    const loadingEvent = ref(false);
    const showEditEventModal = ref(false);
    const seeCollaboratorsModal = ref(false);
    const loadingGallery = ref(false);

    const isOfferAvailable = computed(
      () => Object.keys(offer.value).length > 0
    );
    const isInvoiceAvailable = computed(
      () =>
        offer.value.invoiceFinalUrl?.length > 0 ||
        offer.value.invoicePrepaymentUrl?.length > 0
    );
    const isPrepaymentInvoiceAvailable = computed(
      () => offer.value.invoicePrepaymentUrl?.length > 0
    );
    const isFinalInvoiceAvailable = computed(
      () => offer.value.invoiceFinalUrl?.length > 0
    );

    const slider = ref(null);
    const isDragging = ref(false);
    const startX = ref(0);
    const scrollLeft = ref(0);

    const openDrawer = () => {
      document.querySelector(".drawer-wrapper").classList.add("open");
    };

    const getOffer = async () => {
      loadingOffer.value = true;
      const token = await getAccessTokenSilently();

      const response = await getClientOffer(token, offerId.value);
      offer.value = response;
      loadingOffer.value = false;

      nextTick(() => {
        initialDashboardAnimation();
      });
    };

    const redirectToUrl = (url) => {
      window.location.href = url;
    };

    watch(route, async () => {
      offerId.value = route.params.offerId;
      await getOffer();
    });

    const startDragging = (e) => {
      isDragging.value = true;
      startX.value = e.pageX - slider.value.getBoundingClientRect().left;
      scrollLeft.value = slider.value.scrollLeft;
    };

    const stopDragging = () => {
      isDragging.value = false;
    };

    const moveSlider = (e) => {
      if (!isDragging.value) return;
      e.preventDefault();
      const x = e.pageX - slider.value.getBoundingClientRect().left;
      const walk = (x - startX.value) * 3; // Multiply by 3 for faster scrolling
      slider.value.scrollLeft = scrollLeft.value - walk;
    };

    const editEvent = () => {
      showEditEventModal.value = true;
    };

    function closeEditModal() {
      showEditEventModal.value = false;
    }

    const showSeeCollaboratorsModal = () => {
      seeCollaboratorsModal.value = true;
    };

    const closeSeeCollaboratorsModal = () => {
      seeCollaboratorsModal.value = false;
    };

    const enableCollection = async () => {
      if (loadingGallery.value === false) {
        loadingGallery.value = true;
        const token = await getAccessTokenSilently();

        await enableMemoriesCollection(token, offerId.value);

        router.push(`/memoriesCollectionAdmin/${offerId.value}`);
      }
    };

    const memoriesCollectionOnTap = async () => {
      if (offer.value.hasMemoriesCollection.value) {
        navigateToMemoriesCollection();
      } else {
        enableCollection();
      }
    }

    const initialDashboardAnimation = () => {
      anime({
        targets: '.mobile-only .card',
        translateX: [50, 0],
        opacity: 1,
        easing: 'easeInOutQuad',
        duration: 200,
        delay: anime.stagger(200, { start: 350 })
      })

      anime({
        targets: '.my-privent-services',
        translateY: [-10, 0],
        opacity: 1,
        easing: 'easeInOutQuad',
        duration: 200,
        delay: 350
      })

      anime({
        targets: '.grid-container .card',
        opacity: 1,
        easing: 'easeInOutQuad',
        duration: 200,
        delay: anime.stagger(200, { start: 400 })
      })
    }

    onMounted(async () => {
      await getOffer();

      if (slider.value) {
        slider.value.addEventListener("mousedown", startDragging);
        window.addEventListener("mousemove", moveSlider);
        window.addEventListener("mouseup", stopDragging);
      }
    });

    onBeforeUnmount(() => {
      if (slider.value) {
        slider.value.removeEventListener("mousedown", startDragging);
        window.removeEventListener("mousemove", moveSlider);
        window.removeEventListener("mouseup", stopDragging);
      }
    });

    const getEventTypeString = (eventTypeNumber) => {
      const eventTypeMap = {
        1: "Nuntă",
        2: "Cununie civilă",
        3: "Botez",
        4: "Majorat",
        5: "Petrecere",
        6: "Petrecere",
        7: "Petrecere",
        8: "Balul Bobocilor",
        9: "Balul de Absolvire",
      };
      return eventTypeMap[eventTypeNumber] || "";
    };

    const navigateToInvitationEdit = () => {
      router.push(`/invitations/${offerId.value}`);
    };

    const navigateToInvitationCreate = () => {
      router.push(`/invitations/${offerId.value}/select`);
    };

    const navigateToGuestlist = () => {
      router.push(`/guestlist/${offerId.value}`);
    };

    const navigateToFloorplans = () => {
      router.push(`/floorplans/${offerId.value}`);
    };

    const navigateToChecklist = () => {
      router.push(`/checklist/${offerId.value}`);
    };

    const navigateToBudget = () => {
      router.push(`/budget/${offerId.value}`);
    };

    const navigateToTimeline = () => {
      router.push(`/timeline/${offerId.value}`);
    };

    const navigateToMemoriesCollection = () => {
      router.push(`/memoriesCollectionAdmin/${offerId.value}`);
    };

    const eventTypeString = computed(() =>
      getEventTypeString(offer.value.eventType)
    );

    return {
      offer,
      loadingOffer,
      isOfferAvailable,
      isInvoiceAvailable,
      isPrepaymentInvoiceAvailable,
      isFinalInvoiceAvailable,
      memoriesCollectionOnTap,
      loadingEvent,
      offerId,
      redirectToUrl,
      openDrawer,
      seeCollaboratorsModal,
      enableCollection,
      showSeeCollaboratorsModal,
      closeSeeCollaboratorsModal,
      eventTypeString,
      showEditEventModal,
      editEvent,
      closeEditModal,
      initialDashboardAnimation,
      navigateToInvitationEdit,
      navigateToInvitationCreate,
      navigateToGuestlist,
      navigateToFloorplans,
      navigateToChecklist,
      navigateToBudget,
      navigateToTimeline,
      navigateToMemoriesCollection,
    };
  },
};
</script>
