<template>
  <div class="card module my-privent-card invitation-card" :class="[containerClass]">
    <div class="inside">
      <div class="text-content">
        <div class="header-card">
          <h2>Invitații</h2>
          </div>
  
        <div class="module-button" @click="onClick" :class="{'primary': !this.isInvitationCreated}">
          <span class="material-symbols-rounded fill">
            drafts
          </span>
          <p v-if="this.isInvitationCreated">Vezi toate<br>
            invitatiile</p>
            <p v-else>Activează invitațiile</p>
        </div>

      </div>
      <div class="elements-content" v-if="this.isInvitationCreated">
        
          <div class="list-loading" v-if="loading">
            <div class="element card shimmer">
            </div>
            <div class="element card shimmer">
            </div>
          </div>
          <div class="list" v-else>
            <div class="element card" v-for="invitation in invitations" :key="invitation.id"
              @click="navigateToInvitation(invitation.id)">
              <h3> {{ invitation.templateName }}</h3>
            </div>
          </div>
      
      </div>
    </div>
    <div class="disclaimer-container">
      <div class="disclaimer">
        <span class="material-symbols-rounded fill">
          new_releases
        </span>
        <p>Testează gratuit</p>
      </div>
    </div>
    <img class="background-image" :src="resolvedImage" alt="Card background">
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import { getInvitations } from '@/api';
import { format, parseISO } from 'date-fns';

export default {
  name: 'Invitation-Card',
  props: {
    offerId: { type: String },
    isInvitationCreated: { type: Boolean },
    containerClass: {
      type: String,
      default: ''
    },
  },
  setup(props) {
    const router = useRouter();
    const invitations = ref([]);
    const loading = ref(true);

    const resolvedImage = computed(() => {
      if (props.isInvitationCreated) {
        return require('@/assets/img/dashboard/' + 'invitationsBackground.png');
      } else {
        return require('@/assets/img/dashboard/' + 'invitationsEmptyBackground.png');
      }
    });

    onMounted(async () => {
      invitations.value = await getInvitations(props.offerId);
      loading.value = false;
    });

    const navigateToInvitation = (invitationId) => {
      router.push(`/invitation/${props.offerId}/${invitationId}/edit`);
    }

    const navigateToInvitationList = () => {
      router.push(`/invitations/${props.offerId}`);
    };

    const navigateToInvitationCreate = () => {
      router.push(`/invitations/${props.offerId}/select`)
    };

    const onClick = () => {
      if (props.isInvitationCreated) {
        navigateToInvitationList();
      } else {
        navigateToInvitationCreate();
      }
    }

    return {
      invitations,
      loading,
      resolvedImage,
      navigateToInvitation,
      navigateToInvitationList,
      navigateToInvitationCreate,
      onClick
    };
  }
};   
</script>
