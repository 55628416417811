<template>
  <div class="wrapper-content guestlist">
    <!-- TODO: Add loader here -->
    <div v-if="creatingResponse">Acu' se adauga invitatul</div>
    <add-guest-modal
      :showAddGuestModal="showAddGuestModal"
      v-else-if="this.showAddGuestModal"
      @closeModal="closeAddGuestModal()"
      :offerId="offerId"
      @sendInvitationResponse="sendInvitationResponse"
    />
    <view-guest-modal
      :showViewGuestModal="showViewGuestModal"
      v-if="this.showViewGuestModal"
      :guest="selectedGuest"
      :offerId="offerId"
      @closeModal="closeViewGuestModal()"
      @refreshGuestlist="loadGuestlist"
    />

    <div class="header space-between">
      <div
        class="vertical-align gap-5 splash-rounded splash-padding"
        @click="this.$router.go(-1)"
      >
        <span class="material-symbols-rounded"> arrow_back </span>
        <h1>Your guestlist</h1>
      </div>
      <div class="actions">
        <div class="header-button primary" @click="showAddGuest()">
          <div class="highlight">
            <span class="material-symbols-rounded"> library_add </span>
          </div>
          <div class="text">Adaugă invitat</div>
        </div>
      </div>
    </div>
    <div class="content-loading" v-if="loadingGuestlist">
      <vLottiePlayer
        name="lottie"
        class="lottie"
        loop
        :animationData="require('@/assets/lottie/simpleDotsLoader.json')"
      />
    </div>
    <div v-else class="content">
      <div class="card module">
        <h1>Statistici guestlist</h1>
        <div class="stats-container">
          <div class="stat-container">
            <NumberAnimation
              ref="number1"
              :from="0"
              :to="offer.nrAttendees"
              :format="formatCounterSimple"
              :duration="1.5"
              autoplay
              easing="linear"
              class="number"
            />
            <p>nr. invitați<br />estimați</p>
          </div>
          <div class="stat-container">
            <NumberAnimation
              ref="number2"
              :from="0"
              :to="numberOfGuests"
              :format="formatCounterSimple"
              :duration="1.5"
              autoplay
              easing="linear"
              class="number"
            />
            <p>răspunsuri<br />primite</p>
          </div>
          <div class="stat-container green">
            <NumberAnimation
              ref="number3"
              :from="0"
              :to="countConfirmedGuests"
              :format="formatCounterSimple"
              :duration="1.5"
              autoplay
              easing="linear"
              class="number green"
            />
            <p>nr. persoane<br />confirmate</p>
          </div>
          <div class="stat-container green">
            <NumberAnimation
              ref="number4"
              :from="0"
              :to="attendesPercent"
              :format="formatCounterPercent"
              :duration="1.5"
              autoplay
              easing="linear"
              class="number green"
            />
            <p>rată de<br />completare</p>
          </div>
        </div>
        <h1>Statistici întrebari</h1>
        <div class="stats-questions">
          <!-- Render pie charts -->
          <div
            v-for="(questionData, index) in questionChartData"
            :key="index"
            class="question-container"
          >
            <h2>{{ questionData.question }}</h2>
            <Pie :data="questionData.chartData" :options="chartOptions" />
          </div>
        </div>
      </div>
      <div class="card module">
        <div class="container">
          <div class="row align-x-space-between align-y-center gap-10">
            <div class="col-25" v-if="this.isDesktop()">
              <h1>Invitați</h1>
              <h4>Caută în răspunsuri</h4>
            </div>
            <div class="col">
              <div class="search-bar">
                <input
                  class="input"
                  v-model="searchName"
                  type="text"
                  id="search"
                  name="search"
                  placeholder="Cauta o invitatie"
                  placeholder-shown="true"
                  autocomplete="off"
                  required
                  minlength="2"
                  data-pristine-minlength-message="Introduceti minim 2 caractere"
                  data-pristine-required-message="Câmp obligatoriu"
                />
                <span class="material-symbols-rounded"> search </span>
              </div>
            </div>
          </div>
        </div>

        <table-component>
          <tr
            v-for="guest in filteredGuestlist"
            :key="guest"
            @click="showViewGuest(guest)"
          >
            <td>
              <div class="status">
                <span
                  class="material-symbols-rounded confirmed"
                  v-if="guest.confirmed"
                >
                  check_circle
                </span>
                <span class="material-symbols-rounded unconfirmed" v-else>
                  cancel
                </span>
              </div>
            </td>

            <td class="name">
              <div class="name">
                {{ guest.name }}
                <span class="new" v-if="!guest.seen">Nou</span>
              </div>
            </td>

            <td>
              <div class="persons">
                <span class="material-symbols-rounded"> account_circle </span>
                {{ guest.companion.length + 1 }}
              </div>
            </td>

            <td class="model" v-if="this.isDesktop()">
              <div class="vertical">{{ guest.invitationName }}</div>
            </td>

            <td v-if="this.isDesktop()">
              {{
                guest.createdOnString !== "01.01.0001"
                  ? guest.createdOnString
                  : ""
              }}
            </td>

            <td class="actions">
              <div class="horizontal" v-if="this.isDesktop()">
                <div class="action" @click.stop="showViewGuest(guest)">
                  <span class="material-symbols-rounded"> visibility </span>
                </div>
                <div class="action" @click.stop="remove(guest.id)">
                  <span class="material-symbols-rounded"> delete </span>
                </div>
              </div>
              <div v-else>
                <dropdown-menu direction="right" @click.stop>
                  <template #trigger>
                    <div class="action">
                      <span class="material-symbols-rounded"> grid_view </span>
                    </div>
                  </template>

                  <template #body>
                    <div
                      class="dropdown-action"
                      @click.stop="showViewGuest(guest)"
                    >
                      <span class="material-symbols-rounded"> visibility </span>
                      Setări
                    </div>
                    <div class="dropdown-action" @click.stop="">
                      <span class="material-symbols-rounded"> delete </span>
                      Șterge
                    </div>
                  </template>
                </dropdown-menu>
              </div>
            </td>
          </tr>

          <tr>
            <td colspan="6" class="empty-content-row">
              <div
                class="no-categories-button add-items"
                @click="showAddGuest()"
              >
                <span class="material-symbols-rounded fill"> add </span>
                <p>Adaugă un invitat</p>
              </div>
            </td>
          </tr>
        </table-component>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import AddGuestModal from "@/views/guestlist/components/AddGuestModal.vue";
import ViewGuestModal from "@/views/guestlist/components/ViewGuestModal.vue";
import TableComponent from "@/views/guestlist/components/TableComponent.vue";
import DropdownMenu from "v-dropdown-menu";
import { getGuestlist, createInvitationResponse, getClientOffer } from "@/api";
import { useAuth0 } from "@auth0/auth0-vue";
import { useRoute } from "vue-router";
import { notify } from "@kyvg/vue3-notification";

import NumberAnimation from "vue-number-animation";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "vue-chartjs";

ChartJS.register(ArcElement, Tooltip, Legend);

export default {
  name: "EventGuestlist",
  components: {
    AddGuestModal,
    ViewGuestModal,

    TableComponent,
    DropdownMenu,
    NumberAnimation,
    Pie,
  },
  methods: {
    isDesktop() {
      const screenWidth = window.innerWidth;
      return screenWidth > 575.98;
    },
    formatCounterSimple(value) {
      return `${value.toFixed(0)}`;
    },
    formatCounterPercent(value) {
      return `${value.toFixed(1)}%`;
    },
  },
  setup() {
    const showAddGuestModal = ref(false);
    const showViewGuestModal = ref(false);
    const loadingGuestlist = ref(true);
    const guestlist = ref([{ companion: [], name: "" }]);
    const offerId = ref(null);
    const creatingResponse = ref(false);

    const offer = ref({});

    const searchName = ref("");

    const selectedGuest = ref({});
    const questionChartData = ref([]);

    const { getAccessTokenSilently } = useAuth0();
    const route = useRoute();

    function openDrawer() {
      document.querySelector(".drawer-wrapper").classList.add("open");
    }

    function showAddGuest() {
      showAddGuestModal.value = true;
    }

    function showViewGuest(guest) {
      selectedGuest.value = guest;
      showViewGuestModal.value = true;
    }

    function closeAddGuestModal() {
      showAddGuestModal.value = false;
    }

    function closeViewGuestModal() {
      showViewGuestModal.value = false;
    }

    const filteredGuestlist = computed(() => {
      if (guestlist.value.length > 0) {
        return guestlist.value.filter((guest) =>
          guest.name.toLowerCase().includes(searchName.value.toLowerCase())
        );
      } else {
        return guestlist.value;
      }
    });

    const countConfirmedGuests = () => {
      if (!filteredGuestlist.value || !Array.isArray(filteredGuestlist.value)) {
        return 0;
      }

      return filteredGuestlist.value.reduce((count, guest) => {
        if (guest.confirmed) {
          count += 1;
          count += guest.companion.length;
        }
        return count;
      }, 0);
    };

    const numberOfGuests = computed(() => {
      let counter = 0;
      guestlist.value.forEach((guest) => {
        counter += guest.companion.length + 1;
      });
      return counter;
    });

    const attendesPercent = computed(() => {
      let percent = 0;
      percent = (numberOfGuests.value * 100) / offer.value.nrAttendees;
      return percent;
    });

    const chartOptions = {
      elements: {
        arc: {
          borderWidth: 2,
          borderColor: "#E5E0D9",
        },
      },
      plugins: {
        legend: {
          position: "bottom",
        },
      },
    };

    const primaryColors = [
      "#222222",
      "#333333",
      "#444444",
      "#555555",
      "#666666",
    ];

    const calculateQuestionChartData = () => {
      const questions = [];
      const answersCount = {};

      guestlist.value.forEach((guest) => {
        guest.quizAnswers.forEach((answer) => {
          const question = answer.question.value;
          const value = answer.answer.value;

          if (!questions.includes(question)) {
            questions.push(question);
          }

          if (!answersCount[question]) {
            answersCount[question] = {};
          }

          if (!answersCount[question][value]) {
            answersCount[question][value] = 1;
          } else {
            answersCount[question][value]++;
          }

          guest.companion.forEach((companion) => {
            companion.answers.forEach((answer) => {
              const question = answer.question.value;
              const value = answer.answer.value;

              if (!questions.includes(question)) {
                questions.push(question);
              }

              if (!answersCount[question]) {
                answersCount[question] = {};
              }

              if (!answersCount[question][value]) {
                answersCount[question][value] = 1;
              } else {
                answersCount[question][value]++;
              }
            });
          });
        });
      });

      questionChartData.value = questions.map((question, index) => {
        const primaryColor = primaryColors[index % primaryColors.length];
        const backgroundColors = generateColorVariations(
          primaryColor,
          Object.keys(answersCount[question]).length
        );

        return {
          question,
          chartData: {
            labels: Object.keys(answersCount[question]),
            datasets: [
              {
                label: question,
                backgroundColor: backgroundColors,
                data: Object.values(answersCount[question]),
              },
            ],
          },
        };
      });
    };

    const generateColorVariations = (color, count) => {
      const variations = [];
      for (let i = 0; i < count; i++) {
        variations.push(adjustColor(color, i, count));
      }
      return variations;
    };

    const adjustColor = (color, index, count) => {
      const brightness = Math.floor(100 - (index / count) * 80);
      return adjustBrightness(color, brightness);
    };

    const adjustBrightness = (color, amount) => {
      let [r, g, b] = color.match(/\w\w/g).map((x) => parseInt(x, 16));
      r = Math.min(255, Math.max(0, r + amount));
      g = Math.min(255, Math.max(0, g + amount));
      b = Math.min(255, Math.max(0, b + amount));
      return `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`;
    };

    const sendInvitationResponse = async (invitees, invitationId) => {
      creatingResponse.value = false;

      let companions = Array.from(invitees);
      companions.shift();

      const data = {
        Name: invitees[0].name,
        Phone: invitees[0].phone,
        Notification: false,
        CompanionsValue: companions,
        Confirmed: invitees[0].confirmed,
        QuestionAnswers: invitees[0].questions,
      };

      await createInvitationResponse(offerId.value, invitationId, data);

      notify({
        type: "success",
        title: "Succes",
        text: "Actiunea s-a desfasurat cu success.",
      });

      creatingResponse.value = false;
      // Force page reload
      window.location.reload();
    };

    const getOffer = async () => {
      loadingGuestlist.value = true;
      const token = await getAccessTokenSilently();

      const response = await getClientOffer(token, offerId.value);
      offer.value = response;
    };

    const loadGuestlist = async () => {
      loadingGuestlist.value = true;
      const token = await getAccessTokenSilently();

      guestlist.value = await getGuestlist(token, offerId.value);
      calculateQuestionChartData();
      loadingGuestlist.value = false;
    };

    onMounted(async () => {
      offerId.value = route.params.offerId;

      await getOffer();
      const token = await getAccessTokenSilently();

      guestlist.value = await getGuestlist(token, offerId.value);
      calculateQuestionChartData();

      loadingGuestlist.value = false;
    });

    return {
      showAddGuestModal,
      showViewGuestModal,
      guestlist,
      offerId,
      offer,
      loadingGuestlist,
      creatingResponse,
      selectedGuest,
      searchName,
      filteredGuestlist,
      countConfirmedGuests,
      numberOfGuests,
      attendesPercent,
      createInvitationResponse,
      sendInvitationResponse,
      openDrawer,
      showAddGuest,
      showViewGuest,
      closeAddGuestModal,
      closeViewGuestModal,
      loadGuestlist,
      questionChartData,
      chartOptions,
    };
  },
};
</script>
